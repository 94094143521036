import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { sellPolicyData } from "../addpolicy/sellpolicyData.model";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from "src/config/app.config";
import { DatastorageService } from "../datastorage.service";
import * as moment from "moment";
import { Router } from "@angular/router";
import { MESSAGE_CONSTS } from "../ValidationMessages";
import { Step } from "../core/step.model";
import { CustomErrorHandler } from "../core/custom-error-handler";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { PolicyStatus } from "../core/policyStatus.model";

@Component({
  selector: "app-vehicledetails",
  templateUrl: "./vehicledetails.component.html",
  styleUrls: ["./vehicledetails.component.css"],
})
export class VehicledetailsComponent implements OnInit {
  private _apiUrl: string;

  public inclusiveCoverage: number;
  public maxvehage: number;
  vehicleDetailForm: FormGroup;
  sellPolicyData: sellPolicyData = new sellPolicyData();
  clientId: number;
  userId: number;
  modelList = [];
  colorList = [];

  isExtend: boolean = false;
  isAged: boolean = false;
  maxInsured: boolean = false;
  vinNumber: any = null;
  expiryDateofExistingPolicy: Date;
  isAlreadySaled: boolean = false;
  isEnableAutoValidate: boolean = false;
  clientName: string;
  step: Step = new Step();
  maxDate: moment.Moment;
  @ViewChild("policySearchModal") templateRef: TemplateRef<any>;
  templateDialogRef: NxModalRef<any>;
  isUnitySearch: boolean;
  maxYearsEligible: string;
  selectedModel: any;
  vehicleAge: number;
  policyStartDate: Date;
  selectedColor: any[];
  isSellCancelledPolicyAgain: boolean = false;
  lastavailDate: Date;
  CancellationApprovalPending: boolean = false;

  constructor(
    private authService: AuthService,
    private appConfig: AppConfig,
    private dataService: DatastorageService,
    private router: Router,
    private customErrorHandler: CustomErrorHandler,
    public dialogService: NxDialogService
  ) {
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.clientName = localStorage.getItem("CLIENT_NAME");
    this._apiUrl = appConfig.baseUrl;
    this.maxvehage = appConfig.maxvehicleAge;
    this.maxYearsEligible = moment(
      new Date().setFullYear(new Date().getFullYear() - this.maxvehage)
    ).format("YYYY-MM-DD");
  }

  ngOnInit() {
    this.vehicleDetailFormBuild();
    this.getModels();
    this.getColors();
    this.getVehicleDetails();
    this.initializeNav(3);
    this.maxDate = moment();
  }

  initializeNav(compltedStep: number) {
    for (let i = 0; i <= compltedStep; i++) {
      this.step.stepNumber = i;
      this.step.completed = false;
      this.dataService.nextClick.next(this.step);
    }
  }

  vehicleDetailFormBuild() {
    this.vehicleDetailForm = new FormBuilder().group({
      vinChassisNo: [
        this.sellPolicyData.vinChassisNo,
        [Validators.required, Validators.pattern(MESSAGE_CONSTS.VIN_REGEX)],
      ],
      licensePlateNo: [
        this.sellPolicyData.licensePlateNo?.toUpperCase(),
        [Validators.pattern(MESSAGE_CONSTS.NUMBER_PLATE_REGEX)],
      ],
      model: [this.sellPolicyData.model, [Validators.required]],
      colorName: [this.sellPolicyData.colorName, [Validators.required]],
      vehicleSaleDate: [
        this.sellPolicyData.vehicleSaleDate,
        [Validators.required],
      ],
      variant: [this.sellPolicyData.variant,[Validators.pattern(MESSAGE_CONSTS.ALLOWED_SPECIALCHAR_REGEX)]],
      invoiceDate: [
        {
          value: new Date(),
          disabled: true,
        },
        Validators.required,
      ],
      otherDetails: this.sellPolicyData.otherDetails,
      workshopId: null,
      clientId: this.clientId,
      policyStatusId: 1,
      ageOfVehicle: this.sellPolicyData.ageOfVehicle,
      brand: [ {
        value: this.clientName,
        disabled: true,
      }, [Validators.required]],
      effectiveDate: this.sellPolicyData.effectiveDate,
    });
  }

  get purchaseDate() {
    return this.vehicleDetailForm.get("vehicleSaleDate");
  }
  get VehicleDetailFormControls() {
    return this.vehicleDetailForm.controls;
  }

  getColors(): void {
    this.authService
      .get(this._apiUrl + "/api/v1/Colors?pageNumber=-1")
      .subscribe(
        (response) => {
          if (response) {
            this.colorList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getModels(): void {
    this.authService
      .get(this._apiUrl + "/api/v1/Models?pageNumber=-1")
      .subscribe(
        (response) => {
          if (response) {
            this.modelList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  //While coming back to page load data
  getVehicleDetails(): void {
    let resp = this.dataService.getData();
    if (resp) {
      this.sellPolicyData = resp;
      this.vehicleDetailFormBuild();
    }
  }

  //Based on Model Inclusive(default coverage will change)
  onModelSelected(value: string): void {
    this.selectedModel = this.modelList.filter((obj) => {
      if (
        obj.modelName.trim().toLowerCase() ==
        (value ?? " ").trim().toLowerCase()
      )
        return obj;
    });

    this.inclusiveCoverage =
      this.selectedModel.length == 0
        ? null
        : this.selectedModel[0].modelWarrenty;
    //console.info(this.inclusiveCoverage);
  }

  //On sale date change calculate vehicle age
  onVehicleSaleDateChange(): void {
    var saleDate = new Date(
      this.vehicleDetailForm.controls.vehicleSaleDate.value
    );
    var diffr = this.diffDate(saleDate, Date.now());

    if (diffr.years > 0) {
      this.vehicleAge = diffr.years; //diff in year > 0 then age is diff i years
      if (diffr.days > 0) this.vehicleAge = diffr.years + 1; //diff in year & days > 0 and then age is diff years +1
    } else {
      this.vehicleAge = 0; //0 years and 0 days diff then age = 1;
      if (diffr.days > 0) this.vehicleAge = 1; //0 years and more 1 daysdiff treated as 1 year
    }

    this.vehicleDetailForm.patchValue({
      ageOfVehicle: this.vehicleAge,
    });
  }

  Vinchanged(e): void {
    if (
      e.target.value != "" &&
      this.vehicleDetailForm.get("vinChassisNo").valid
    ) {
      this.resetForm();
      this.searchVinNumber(e.target.value);
    }
  }

  searchVinNumber(vin: any): void {
    this.sellPolicyData = new sellPolicyData();
    this.isExtend, this.isAged, (this.isUnitySearch = false);
    //only set if policy is exists
    this.expiryDateofExistingPolicy == undefined;

    this.vinNumber = vin;

    //Search On Own Repository
    this.authService
      .post(this._apiUrl + `/api/v1/PolicyDetails/SearchVin`, vin)
      .subscribe(
        (data) => {
          if (data) {
            this.prefillPolicy(data);
          } else {
            this.searchUnity();
          }
        },
        (error) => {
          this.templateDialogRef?.close();
          this.customErrorHandler.handleError(error);
        }
      );
  }

  searchUnity(): void {
    this.authService
      .post(this._apiUrl + `/api/v1/Unity/SearchVin`, this.vinNumber)
      .subscribe(
        (response) => {
          if (response.success) {
            this.prefillPolicy(response.data);
          } else {
            this.templateDialogRef?.close();
            this.customErrorHandler.handleCustomInfo(response.responsemsg);
          }
        },
        (error) => {
          this.customErrorHandler.handleError(
            "Sorry!!Failed to auto fill data"
          );
          this.templateDialogRef?.close();
        }
      );
  }

  prefillPolicy(data): void {
    this.templateDialogRef = this.dialogService.open(this.templateRef, {
      ariaLabel: "Search dialoge",
    });

    this.expiryDateofExistingPolicy = new Date(data.expiryDate);

    this.sellPolicyData = data;

    //bind color and model
    this.selectedModel = this.modelList.filter((obj) => {
      if (
        data.model &&
        obj.modelName.toLowerCase() == (data.model ?? " ").trim().toLowerCase()
      )
        return obj;
    });

    this.selectedColor = this.colorList.filter((obj) => {
      if (
        obj.colorName.toLowerCase() ==
        (data.colorName ?? " ").trim().toLowerCase()
      )
        return obj;
    });

    this.sellPolicyData.model =
      this.selectedModel.length == 0 ? null : this.selectedModel[0].modelName;
    this.sellPolicyData.colorName =
      this.selectedColor.length == 0 ? null : this.selectedColor[0].colorName;

    this.vehicleDetailFormBuild();
    //For recalculating vehicle age based on sale date
    this.onVehicleSaleDateChange();
    if (data.model) this.onModelSelected(data.model);
    //to trigger validation
    this.vehicleDetailForm.markAllAsTouched();

    let insuredyeardiff = this.diffDate(
      new Date(data.vehicleSaleDate),
      new Date(data.expiryDate)
    );
    let maxinsuredage = insuredyeardiff.years;

    if (PolicyStatus.Cancel == data.policyStatusId) {
      this.isSellCancelledPolicyAgain = true;
      this.lastavailDate = new Date(data.updatedDate);
      // set expirydate into cancelled date inorderd to renew the policy
      this.expiryDateofExistingPolicy = this.lastavailDate;
    } else if (PolicyStatus.CancelationApprovalPending == data.policyStatusId) {
      this.CancellationApprovalPending = true;
    } else if (this.expiryDateofExistingPolicy >= new Date()) {
      //check vehicle policy is to Extend it or not
      this.isAged = false;
      this.isExtend = true;
      this.maxInsured = false;
    } else if (this.vehicleAge >= this.maxvehage) {
      //incase of old vehicle which are not eligible for renewal
      this.isAged = true;
      this.isExtend = false;
      this.maxInsured = false;
    }
    //Incase if a vehicle is exhausted with maximum policy renewal
    else if (maxinsuredage >= this.maxvehage) {
      this.isAged = false;
      this.isExtend = false;
      this.maxInsured = true;
    } else {
      this.templateDialogRef?.close();
    }
  }

  setEffectivePolicyStartDate(): void {
    //If inclusiveCoverage is undefinded find model and calculate
    if (this.inclusiveCoverage == undefined)
      this.onModelSelected(this.sellPolicyData.model);

    var vehiclesaleDate = new Date(
      this.vehicleDetailForm.controls.vehicleSaleDate.value
    );

    var companywarrantyendDate =  new Date(
      vehiclesaleDate.getFullYear() + this.inclusiveCoverage,
      vehiclesaleDate.getMonth(),
      vehiclesaleDate.getDate()
    );
    //re calculate expiry date when page comes backwards
    if(this.sellPolicyData.expiryDate != undefined && this.expiryDateofExistingPolicy == undefined){
      let diff = this.diffDate(this.sellPolicyData.effectiveDate,this.sellPolicyData.expiryDate)
      let d = new Date(this.sellPolicyData.expiryDate);
      let year = d.getFullYear();
      let month = d.getMonth();
      let day = d.getDate();
      this.expiryDateofExistingPolicy = new Date(year - diff.years, month, day - diff.days);
    }
    //In case of New Policy
    if (this.expiryDateofExistingPolicy == undefined) {
      //Added Default cover to vehicle sale date
      this.policyStartDate = companywarrantyendDate;
      //Incaseof inclusive cover lapsed
      if (this.policyStartDate <= new Date()) {
        this.policyStartDate = new Date();
        //48 hours of activation period in case of expired policies
        this.policyStartDate.setDate(this.policyStartDate.getDate() + 2);
      }
    } else {
      //In case of policy extension
      //Policy is already expired
      if (this.expiryDateofExistingPolicy < new Date()) {
        this.policyStartDate = new Date();
        //48 hours of activation period in case of expired policies
        this.policyStartDate.setDate(this.policyStartDate.getDate() + 2);
        //incase expired/cancelled policy is covered in inclusive coverage 
        if(companywarrantyendDate > new Date())
        {
          this.policyStartDate = companywarrantyendDate;
        }
      } else {
        //set start date as expiry of previous policy
        this.policyStartDate = this.expiryDateofExistingPolicy;
      }
    }

    this.vehicleDetailForm.patchValue({
      effectiveDate: this.policyStartDate,
    });
  }

  extendPolicy(): void {
    //this.vehicleDetailFormBuild();
    /*to trigger validation*/
    this.vehicleDetailForm.markAllAsTouched();

    this.isExtend = false;
    this.isAged = false;
    this.maxInsured = false;
    this.templateDialogRef?.close();
  }

  saveVehicleDetails(): void {
    this.setEffectivePolicyStartDate();

    this.sellPolicyData.vinChassisNo =
      this.vehicleDetailForm.get("vinChassisNo").value;
    this.sellPolicyData.licensePlateNo =
      this.vehicleDetailForm.get("licensePlateNo").value;
    this.sellPolicyData.model = this.vehicleDetailForm.get("model").value;
    this.sellPolicyData.colorName =
      this.vehicleDetailForm.get("colorName").value;
    this.sellPolicyData.vehicleSaleDate = new Date(
      moment(this.vehicleDetailForm.get("vehicleSaleDate").value).format(
        "YYYY-MM-DD HH:mm"
      )
    );
    this.sellPolicyData.variant = this.vehicleDetailForm.get("variant").value;
    this.sellPolicyData.invoiceDate = new Date(
      moment(this.vehicleDetailForm.get("invoiceDate").value).format(
        "YYYY-MM-DD HH:mm"
      )
    );
    this.sellPolicyData.otherDetails =
      this.vehicleDetailForm.get("otherDetails").value;
    this.sellPolicyData.clientId = this.clientId;
    this.sellPolicyData.workshopId = null;
    this.sellPolicyData.policyStatusId = 1;
    this.sellPolicyData.ageOfVehicle =
      this.vehicleDetailForm.get("ageOfVehicle").value;
    this.sellPolicyData.brand = this.vehicleDetailForm.get("brand").value;
    this.sellPolicyData.effectiveDate =
      this.vehicleDetailForm.get("effectiveDate").value;
    this.sellPolicyData.planId = null;
    this.sellPolicyData.price = null;
    this.sellPolicyData.gstPrice = null;
    this.dataService.setData(this.sellPolicyData);
    this.router.navigate(["/sellpolicy/customerdetails"]);

    this.step.stepNumber = 0;
    this.step.completed = true;
    this.dataService.nextClick.next(this.step);
  }

  resetForm(): void {
    this.isExtend = false;
    this.isAged = false;
    this.maxInsured = false;
    this.CancellationApprovalPending = false;
    this.isSellCancelledPolicyAgain = false;
    this.vehicleDetailForm.reset({
      vinChassisNo: this.vehicleDetailForm.get("vinChassisNo").value,
      brand: this.vehicleDetailForm.get("brand").value,
      invoiceDate: this.vehicleDetailForm.get("invoiceDate").value,
    });
  }

  closeModal(): void {
    this.vehicleDetailForm.reset({
      brand: this.vehicleDetailForm.get("brand").value,
      invoiceDate: this.vehicleDetailForm.get("invoiceDate").value,
    });
    this.templateDialogRef?.close();
  }

  diffDate(startDate, endDate): any {
    var b = moment(startDate),
      a = moment(endDate),
      out: any = {};
    let intervals: moment.unitOfTime.DurationConstructor[] = ["years", "days"];

    for (var i = 0; i < intervals.length; i++) {
      var diff = a.diff(b, intervals[i]);
      b.add(diff, intervals[i]);
      out[intervals[i]] = diff;
    }
    return out;
  }
}
