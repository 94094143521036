<app-header></app-header>
<app-menu></app-menu>
<div nxLayout="grid">
    <div nxRow>
        <div nxCol="12">
            <nx-tab-group mobileAccordion="false">
                <nx-tab label="User List">
                  <div class="tab__content">
                    <app-userlist></app-userlist>
                  </div>
                </nx-tab>
                <nx-tab label="Workshop List">
                  <div class="tab__content">
                    Second tab content
                  </div>
                </nx-tab>
            </nx-tab-group>
        </div>
    </div>
</div>
<app-footer></app-footer>
