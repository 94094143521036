import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "../header/header.component";
import { FooterComponent } from "../footer/footer.component";
//import { NxExpertModule } from '@allianz/ngx-ndbx/config';
import { NxLinkModule } from "@aposin/ng-aquila/link";
import { NxHeaderModule } from "@aposin/ng-aquila/header";
import { NxButtonModule } from "@aposin/ng-aquila/button";
import { NxFooterModule } from "@aposin/ng-aquila/footer";
import { NxImageModule } from "@aposin/ng-aquila/image";
import { NxGridModule } from "@aposin/ng-aquila/grid";
import { NxHeadlineModule } from "@aposin/ng-aquila/headline";
//import { SidenavComponent } from '../sidenav/sidenav.component';
import { sharedRoutingModule } from "./shared-routing.module";
import { SidenavComponent } from "../sidenav/sidenav.component";
import { NxTreeModule } from "@aposin/ng-aquila/tree";
import { NxSidebarModule } from "@aposin/ng-aquila/sidebar";
import { NxIconModule } from "@aposin/ng-aquila/icon";
import { MenuComponent } from "../menu/menu.component";
import { NxMenuModule } from "@aposin/ng-aquila/menu";
/**for nsm,rsm,bd */
import { WorkshoplistComponent } from "../workshoplist/workshoplist.component";
import { NxTableModule } from "@aposin/ng-aquila/table";
import { NxBadgeModule } from "@aposin/ng-aquila/badge";
import { NxSwitcherModule } from "@aposin/ng-aquila/switcher";
import { NxFormfieldModule } from "@aposin/ng-aquila/formfield";
import { NxInputModule } from "@aposin/ng-aquila/input";
import { NxDropdownModule } from "@aposin/ng-aquila/dropdown";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NxDatefieldModule } from "@aposin/ng-aquila/datefield";
import { NxNativeDateModule } from "@aposin/ng-aquila/datefield";
import { NxMomentDateModule } from "@aposin/ng-aquila/moment-date-adapter";
import { NxModalModule } from "@aposin/ng-aquila/modal";
import { NxTabsModule } from "@aposin/ng-aquila/tabs";
import { NxContextMenuModule } from "@aposin/ng-aquila/context-menu";
import { NxTooltipModule } from "@aposin/ng-aquila/tooltip";
import { NxCardModule } from "@aposin/ng-aquila/card";
import { NxPaginationModule } from "@aposin/ng-aquila/pagination";
import { NxSpinnerModule } from "@aposin/ng-aquila/spinner";

import { NxRadioToggleModule } from "@aposin/ng-aquila/radio-toggle";
import { NxFileUploaderModule } from "@aposin/ng-aquila/file-uploader";
import { WorkshopviewComponent } from "../workshopview/workshopview.component";
import { WalletrequestlistComponent } from "../walletrequestlist/walletrequestlist.component";
import { PolicyrequestlistComponent } from "../policyrequestlist/policyrequestlist.component";
import { ReportComponent } from "../report/report.component";
import { PolicylistComponent } from "../policylist/policylist.component";
import { DashboardComponent } from "../dashboard/dashboard.component";
import { NxPopoverModule } from "@aposin/ng-aquila/popover";
import { NxRadioModule } from "@aposin/ng-aquila/radio-button";
import { IsActiveCheckPipe } from "../pipes/is-active-check.pipe";
import { TitleModule } from "../title/title.module";
import { UploadComponent } from "../upload/upload.component";
import { NxAccordionModule } from "@aposin/ng-aquila/accordion";
import { EditpolicyComponent } from "../editpolicy/editpolicy.component";
import { WorkshopreportComponent } from "../workshopreport/workshopreport.component";
import { WalletbalanceComponent } from "../walletbalance/walletbalance.component";
import { WalletstatementComponent } from "../walletstatement/walletstatement.component";
import { NxPageSearchModule } from "@aposin/ng-aquila/page-search";
import { ChangepasswordComponent } from "../changepassword/changepassword.component";
import { NxCopytextModule } from "@aposin/ng-aquila/copytext";
import { CheckUserRolePipe } from "../pipes/check-user-role.pipe";
import { nsmUserFilterPipe } from "../pipes/nsm-user-filter.pipe";
import { AciveDealerReportComponent } from "../acive-dealer-report/acive-dealer-report.component";
import { WalletUsageReportComponent } from "../wallet-usage-report/wallet-usage-report.component";
import { PolicySaleReportComponent } from "../policy-sale-report/policy-sale-report.component";
import { WalletStatementReportComponent } from "../wallet-statement-report/wallet-statement-report.component";
import { SalesreportmonthlyComponent } from "../salesreportmonthly/salesreportmonthly.component";
import { SalesreportWeeklyComponent } from "../salesreport-weekly/salesreport-weekly.component";
import { OfflineWalletReportComponent } from "../offline-wallet-report/offline-wallet-report.component";
import { PolicySaleDetailedReportComponent } from "../policy-sale-detailed-report/policy-sale-detailed-report.component";
import { PolicyReportWithPriceSplitupComponent } from "../policy-report-with-price-splitup/policy-report-with-price-splitup.component";
import { BulkuploadComponent } from "../bulkupload/bulkupload.component";
import { EInvoiceComponent } from "../e-invoice/e-invoice.component";
import { FinanceDashboardComponent } from "../finance-dashboard/finance-dashboard.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { WorkshopEditComponent } from "../workshop-edit/workshop-edit.component";
import { OnlinePaymentComponent } from "../online-payment/online-payment.component";
import { MaintenancePageComponent } from "../maintenance-page/maintenance-page.component";
import { NxMessageModule } from "@aposin/ng-aquila/message";
import { UserEmailsComponent } from "../user-emails/user-emails.component";
import { viewProtectedPipe } from "../pipes/view-potected.pipe";
import { NxSmallStageModule } from "@aposin/ng-aquila/small-stage";
import { DealerCommissionInvoiceComponent } from "../dealer-commission-invoice/dealer-commission-invoice.component";
import { UploadInvoiceComponent } from "../upload-invoice/upload-invoice.component";
import { DigitalSignatureComponent } from "../digital-signature/digital-signature.component";
import { NxCheckboxModule } from "@aposin/ng-aquila/checkbox";
import { DownloadDealerInvoiceComponent } from "../download-dealer-invoice/download-dealer-invoice.component";
import { NxToolbarModule } from "@aposin/ng-aquila/toolbar";
import { NxProgressStepperModule } from "@aposin/ng-aquila/progress-stepper";
import { NxCircleToggleModule } from "@aposin/ng-aquila/circle-toggle";


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidenavComponent,
    MenuComponent,
    WorkshoplistComponent,
    WorkshopviewComponent,
    WalletrequestlistComponent,
    PolicyrequestlistComponent,
    ReportComponent,
    PolicylistComponent,
    DashboardComponent,
    IsActiveCheckPipe,
    UploadComponent,
    CheckUserRolePipe,
    nsmUserFilterPipe,
    EditpolicyComponent,
    WorkshopreportComponent,
    WalletbalanceComponent,
    WalletstatementComponent,
    ChangepasswordComponent,
    AciveDealerReportComponent,
    WalletUsageReportComponent,
    PolicySaleReportComponent,
    WalletStatementReportComponent,
    SalesreportmonthlyComponent,
    SalesreportWeeklyComponent,
    OfflineWalletReportComponent,
    PolicySaleDetailedReportComponent,
    PolicyReportWithPriceSplitupComponent,
    BulkuploadComponent,
    EInvoiceComponent,
    FinanceDashboardComponent,
    OnlinePaymentComponent,
    WorkshopEditComponent,
    MaintenancePageComponent,
    UserEmailsComponent,
    viewProtectedPipe,
    DealerCommissionInvoiceComponent,
    UploadInvoiceComponent,
    DigitalSignatureComponent,
    DownloadDealerInvoiceComponent
  ],
  imports: [
    CommonModule,
    //NxExpertModule,
    NxLinkModule,
    NxHeaderModule,
    NxFooterModule,
    NxButtonModule,
    NxImageModule,
    NxGridModule,
    NxHeadlineModule,
    sharedRoutingModule,
    NxTreeModule,
    NxSidebarModule,
    NxIconModule,
    NxMenuModule,
    NxTableModule,
    NxBadgeModule,
    NxSwitcherModule,
    NxFormfieldModule,
    NxInputModule,
    NxDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    NxDatefieldModule,
    NxNativeDateModule,
    NxMomentDateModule,
    NxModalModule,
    NxPopoverModule,
    NxRadioModule,
    TitleModule,
    NxAccordionModule,
    NxTabsModule,
    NxContextMenuModule,
    NxTooltipModule,
    NxCardModule,
    NxPaginationModule,
    NxSpinnerModule,
    NxRadioToggleModule,
    NxPageSearchModule,
    NxCopytextModule,
    NxFileUploaderModule,
    ClipboardModule,
    NxMessageModule,
    NxSmallStageModule,
    NxCheckboxModule,
    NxToolbarModule,
    NxProgressStepperModule,
    NxCircleToggleModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidenavComponent,
    MenuComponent,
    WorkshoplistComponent,
    WalletrequestlistComponent,
    PolicyrequestlistComponent,
    ReportComponent,
    DashboardComponent,
    IsActiveCheckPipe,
    UploadComponent,
    CheckUserRolePipe,
    nsmUserFilterPipe,
    WorkshopreportComponent,
    ChangepasswordComponent,
    AciveDealerReportComponent,
    WalletUsageReportComponent,
    PolicySaleReportComponent,
    WalletStatementReportComponent,
    OfflineWalletReportComponent,
    DealerCommissionInvoiceComponent,
    UploadInvoiceComponent,
    DigitalSignatureComponent,
    NxProgressStepperModule 
  ],
})
export class SharedModule {}
