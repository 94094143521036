import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { EventEmitterService } from '../event-emitter.service';
import { NxMessageToastService } from '@aposin/ng-aquila/message';
import { WalletRefreshService }  from './walletbalance-service'
import { ActivatedRoute } from '@angular/router';
import { MESSAGE_CONSTS } from '../ValidationMessages';

@Component({
  selector: 'app-walletbalance',
  templateUrl: './walletbalance.component.html',
  styleUrls: ['./walletbalance.component.css']
})
export class WalletbalanceComponent implements OnInit {

  private _apiUrl;
  userRole;
  userId;
  walletBalance: number;
  DealerReg: boolean;
  changeDetectorRef: ChangeDetectorRef;
  private _activatedroute: ActivatedRoute;
  Messages: any;


  constructor(
  private authService: AuthService, private appConfig : AppConfig,
  private eventEmitterService: EventEmitterService,
  private messageToastService: NxMessageToastService,
  changeDetectorRef: ChangeDetectorRef,
  private walletRefreshService : WalletRefreshService
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.userRole = atob(localStorage.getItem("userRole"));
    this.DealerReg = (localStorage.getItem("DealerReg") == 'true' && this.userRole == 'Dealer');
    this.changeDetectorRef = changeDetectorRef;
    //this.getWalletBalance();
    this.Messages = MESSAGE_CONSTS;
  }

  ngOnInit() {
    // if (this.eventEmitterService.subsVar == undefined) {

    //   this.eventEmitterService.subsVar = this.eventEmitterService.WalletBalance.subscribe(() => {
    //     this.getWalletBalance();
    //   });
    // }
    this.walletRefreshService.walletBalanceListner.subscribe(refresh => {
      //code to refresh wallet balance
      this.getWalletBalance();
     });
  }
  public getWalletBalance() {

    if (this.userRole == 'WorkShop' || this.DealerReg) {
      this.authService.get(this._apiUrl + '/api/v1/WalletDetails/GetWalletBalance').subscribe(response => {
        if (response.success) {
          this.walletBalance = response.data != 0 ? <number>response.data.toFixed(2) : 0.00;

        }
        else {
          this.messageToastService.open(response.responsemsg, {
            context: "info",
            duration: 5000,
          });
          this.walletBalance = response.data != 0 ? <number>response.data.toFixed(2) : 0.00;
        }
        if(this._activatedroute)
        {
          var path = this._activatedroute.snapshot.url[0].path;
          if(path=="sellpolicy" && response.isMinBalance)
          {
            this.messageToastService.open(this.Messages.WALLET_LOW_ON_SELL_POLICY, {
              context: "info",
              duration: 5000,
            });
          } 
        }
      }, (error) => {
        console.error(error);
      });
    }
  }
  // ngOnDestroy() {
  //   this.changeDetectorRef.detach();
  // }

}
