import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AppConfig } from "src/config/app.config";
import { AuthService } from "../core/auth/auth.service";
import { CustomErrorHandler } from "../core/custom-error-handler";

@Component({
  selector: "app-user-emails",
  templateUrl: "./user-emails.component.html",
  styleUrls: ["./user-emails.component.css"],
})
export class UserEmailsComponent implements OnInit {
  private _apiUrl: string;
  clientId: number;
  userTypeList: any;
  userReports: any;
  addPermission: boolean;
  EDIT_FLAG: boolean;
  modalOpen: boolean;
  isSuccess: boolean;
  userEmailsForm: FormGroup;
  userType: any;
  emailList: any;
  errorMessage: string;
  confirmModalOpen: boolean;
  deleteId: any;
  userRoleValid: boolean = false;

  constructor(
    private authService: AuthService,
    private appConfig: AppConfig,
    private errorHandler: CustomErrorHandler
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.formBuilder();
  }

  formBuilder() {
    this.userEmailsForm = new FormBuilder().group({
      userTypeId: new FormControl(0),
      email: new FormControl("", [Validators.required, Validators.email]),
    });
  }

  ngOnInit(): void {
    this.getUserTypeList();
    this.formBuilder();
  }

  get f() {
    return this.userEmailsForm.controls;
  }

  toggleModal(EDIT: boolean, id) {
    this.EDIT_FLAG = EDIT;
    this.modalOpen = !this.modalOpen;
    this.isSuccess = false;
  }

  getUserTypeList() {
    this.authService
      .get(this._apiUrl + "/api/v1/UserTypes/client/" + this.clientId)
      .subscribe(
        (response) => {
          if (response) {
            this.userTypeList = response;
          }
        },
        (error) => {
          this.errorHandler.handleError(error);
        }
      );
  }

  onUserTypeChanges(userTypeId) {
    this.userRoleValid = false;
    this.addPermission = true;
    this.userType = userTypeId;
    this.getUserEmailList(userTypeId);
  }

  getUserEmailList(userType) {
    this.authService
      .get(
        this._apiUrl + "/api/v1/UserEmails/GetUserEmailsByUserType/" + userType
      )
      .subscribe(
        (response) => {
          if (response) {
            this.emailList = response;
          }
        },
        (error) => {
          this.errorHandler.handleError(error);
        }
      );
  }

  addUserEmail() {
    if (!this.userType) {
      this.userRoleValid = true;
      return;
    }
    this.userEmailsForm.patchValue({ userTypeId: this.userType });
    var userEmailDto = {
      userEmail: this.userEmailsForm.value,
    };

    this.authService
      .post(this._apiUrl + "/api/v1/UserEmails", userEmailDto)
      .subscribe(
        (response) => {
          if (response) {
            this.getUserEmailList(this.userType);
            this.isSuccess = true;
            this.formBuilder();
          }
        },
        (error) => {
          this.errorHandler.handleError(error);
        }
      );
  }

  confirmModal(id) {
    this.deleteId = id;
    this.confirmModalOpen = true;
  }

  confirmModalClose() {
    this.confirmModalOpen = false;
    this.getUserEmailList(this.userType);
  }

  delete() {
    this.authService
      .delete(this._apiUrl + "/api/v1/UserEmails/" + this.deleteId)
      .subscribe(
        (response) => {
          this.getUserEmailList(this.userType);
          this.isSuccess = true;
        },
        (error) => {
          this.errorHandler.handleError(error);
        }
      );

    this.confirmModalClose();
  }
}
