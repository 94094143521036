<!-- <div nxLayout="grid">
  <div nxRow>
    <div nxCol="2"> -->
      <nx-sidebar resizeHandleAriaLabel="Toggle sidebar width" resizeable minWidth="140">
        <nx-tree [dataSource]="_dataSource" [treeControl]="_treeControl">
          <nx-tree-node *nxTreeNodeDef="let node">
            <button nxAction
                nxTreeNodePadding
                nxTreeNodePaddingOffset="40"
                routerLink="./"
                [queryParams]="node.query"
                routerLinkActive="is-selected"
                title="{{node.label}}"
                type="button">
              <nx-icon *ngIf="node.icon" nxActionIcon [name]="node.icon"></nx-icon>
              {{node.label}}
            </button>
          </nx-tree-node>
          <nx-tree-node *nxTreeNodeDef="let node; when: _hasChild">
            <button nxAction
                nxTreeNodeToggle
                nxTreeNodePadding
                nxTreeNodePaddingOffset="40"
                expandable
                
                title="{{node.label}}"
                type="button">
              <nx-icon *ngIf="node.icon" nxActionIcon [name]="node.icon"></nx-icon>
              {{node.label}}
            </button>
          </nx-tree-node>
        </nx-tree>
      </nx-sidebar>
    <!-- </div>
  </div>
</div> -->

  