<app-header></app-header>
<app-menu></app-menu>

<div nxLayout="grid" class="content">
    <div nxRow>
        <div nxCol="12,6,3,2" nxColOffset="1">
            <nx-formfield>
                <input nxInput placeholder="Enter Keyword" [(ngModel)]="searchTerm" type="search">
                <span nxFormfieldPrefix>
                    <nx-icon name="search" size="s"></nx-icon>
                </span>
                <span nxFormfieldSuffix *ngIf="searchTerm" aria-hidden="true" (click)="searchTerm='';getWalletStatement()">
                    <nx-icon name="close" size="s"></nx-icon>
                </span>
            </nx-formfield>
        </div>
        <div nxCol="12,6,3,2" class="nx-margin-top-2xs">
            <button nxButton=" primary small-medium" type="button" (click)="getWalletStatement()">Search</button>
        </div>
    </div>
    <div nxRow>
        <div nxCol="10" nxColOffset="1">
            <table nxTable zebra class="nx-margin-right-5xl" nxSort (sortChange)="sortTable($event)">
                <thead>
                    <tr nxTableRow>
                        <th nxHeaderCell nxSortHeaderCell="id">
                            Tr.Id
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="policyNo">
                            Policy No
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="customerName">
                            Customer Name
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="invoiceNo">
                            Invoice No
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="transactionDetails">
                            Transaction Details
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="date">
                            Date
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="unitPrice">
                            Unit Price
                            <!-- <nx-icon aria-label="trigger by click" name="info-circle-o" size="s" [nxPopoverTriggerFor]="popoverClick"
                                nxPopoverTrigger="hover" tabindex="0" role="button" class="nx-margin-left-s margin-box">
                            </nx-icon> -->
                            
                            <nx-popover #popoverClick>
                                Unit Price = AWP Amount + Royality Amount
                            </nx-popover>
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="cessAmount">
                            Cess Amount
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="igst">
                            IGST
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="cgst">
                            CGST
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="sgst">
                            SGST
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="amount">
                            Amount
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="policyStatusId">
                            Status
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="outstandingBal">
                            Outstanding balance
                        </th>
                    </tr>
                </thead>
                <tbody style="text-align: center;" >
                    <tr nxTableRow *ngFor="let Req of walletStatementList">
                        <td nxTableCell>{{Req.transactionId}}</td>
                        <td nxTableCell>{{Req.policyNo}}</td>
                        <td nxTableCell>{{Req.customerName}}</td>
                        <td nxTableCell>{{Req.invoiceNo}}</td>
                        <td nxTableCell>{{Req.transactionDetails}}</td>
                        <td nxTableCell>{{Req.date | date:'MM/dd/yyyy'}}</td>
                        <td nxTableCell>{{Req.unitPrice}}</td>
                        <td nxTableCell>{{Req.cessAmount}}</td>
                        <td nxTableCell>{{Req.igst}}</td>
                        <td nxTableCell>{{Req.cgst}}</td>
                        <td nxTableCell>{{Req.sgst}}</td>
                        <td nxTableCell>{{Req.amount}}</td>
                        <td nxTableCell *ngIf="Req.status == 'Debit' else credit">
                            <nx-badge vibrant type="critical">Debit</nx-badge>
                        </td>
                        <ng-template #credit>
                            <td nxTableCell>
                                <nx-badge vibrant type="positive">Credit</nx-badge>
                            </td>
                        </ng-template>
                        <!-- <ng-template #modified>
                            <td nxTableCell *ngIf="Req.policyStatusId == PolicyStatus.Modified else cancelpending">
                                <nx-badge type="negative">Modified</nx-badge>
                            </td>
                        </ng-template>
                        <ng-template #cancelpending>
                            <td nxTableCell *ngIf="Req.policyStatusId == PolicyStatus.Modified else modifpending">
                                <nx-badge type="critical" [nxTooltipPosition]="'top'" [nxTooltip]="'Cancelation Approval Pending'" >Pending</nx-badge>
                            </td>
                        </ng-template>
                        <ng-template #modifpending>
                            <td nxTableCell>
                                <nx-badge type="negative" [nxTooltipPosition]="'top'" [nxTooltip]="'Modification Approval Pending'" >Pending</nx-badge>
                            </td>
                        </ng-template> -->
                        <td nxTableCell>{{Req.outstandingBal}}</td>
                    </tr>
                    <tr nxTableRow *ngIf="count==0">
                        <td nxTableCell colspan="11">
                            <nx-label>No Transaction found.</nx-label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div nxRow class="nx-margin-top-2m nx-margin-right-5xl" style="float: right;">
        <div nxCol="6">
            <nx-pagination [nxCount]="count" [nxPage]="pageNumber" [nxPerPage]="pageSize" nxType="advanced"
                (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
            </nx-pagination>
        </div>
    </div>
</div>
<app-footer class="footer"></app-footer>