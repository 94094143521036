<!-- <div class="loading-div" >
    <div class="nxt-spinner-wrapper">
        
        
    </div>
</div> -->

<div class="nxt-spinner-wrapper" *ngIf="isLoading | async">
    <img alt="Allianz Logo" src="/assets/Allianz_Logo.png" />
    <div class="nxt-spinner-margin-top">
        <div class="loader"></div>
    </div>
  </div>