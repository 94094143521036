<table nxTable condensed nxSort (sortChange)="sortTable($event)">
  <thead>
      <tr nxTableRow>
          <th nxHeaderCell nxSortHeaderCell="region">
              Region
          </th>
          <th nxHeaderCell nxSortHeaderCell="state">
              State
          </th>
          <th nxHeaderCell nxSortHeaderCell="city">
              City
          </th>
          <th nxHeaderCell nxSortHeaderCell="dealerCode">
              Dealer Code
          </th>
          <th nxHeaderCell nxSortHeaderCell="dealerName">
              Dealer Name
          </th>
          <th nxHeaderCell nxSortHeaderCell="currentWalletBalance">
              Current Wallet Balance
          </th>
          <th nxHeaderCell nxSortHeaderCell="lastRechargeAmount">
              Last Recharge Amount
          </th>
          <th nxHeaderCell nxSortHeaderCell="lastWalletRecharge">
              Last Recharge On
          </th>
      </tr>
  </thead>
  <tbody style="text-align: center;">
      <tr nxTableRow *ngFor="let item of ReportData">
          <td nxTableCell>{{item.region}}</td>
          <td nxTableCell>{{item.state}}</td>
          <td nxTableCell>{{item.city}}</td>
          <td nxTableCell>{{item.dealerCode}}</td>
          <td nxTableCell>{{item.dealerName}}</td>
          <td nxTableCell>{{item.currentWalletBalance}}</td>
          <td nxTableCell>{{item.lastRechargeAmount}}</td>
          <td nxTableCell>{{item.lastWalletRecharge | date:'MM/dd/yyyy'}}</td>
      </tr>
      <tr nxTableRow *ngIf="count==0">
        <td nxTableCell colspan="8">
          <ng-container>
              <div nxRow nxRowJustify="center">
                <div nxCol="12" class="nosearchresult"></div>
                <h3 nxHeadline="subsection-medium" class="nx-font-weight-semibold">No Records found !!!</h3>
              </div>
            </ng-container>
            </td>
        </tr>
  </tbody>
</table>

<div nxRow class="nx-margin-top-2m nx-margin-right-5xl" style="float: right;">
  <div nxCol="6">
      <nx-pagination [nxCount]="count" [nxPage]="pageNumber" [nxPerPage]="pageSize" nxType="advanced"
          (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
      </nx-pagination>
  </div>
</div>
