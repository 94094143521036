import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from "src/config/app.config";
import { workshopData } from "./workshopData.model";
import { UserType } from "../core/userType.model";
import { RequestIntegrity } from "../core/request-integrety";

@Component({
  selector: "app-workshopview",
  templateUrl: "./workshopview.component.html",
  styleUrls: ["./workshopview.component.css"],
})
export class WorkshopviewComponent implements OnInit {
  BaseType: typeof UserType;
  _apiUrl: string;
  userId: number;
  userRole: string;
  workShopForm: FormGroup;
  workshopDetails: any[] = [];
  workShopObj: workshopData = new workshopData();
  cancelChequePath: string;
  panCardPath: string;
  showPanDownlaodBtn: boolean = false;
  showChequeDownlaodBtn: boolean = false;
  isDealer: boolean = false;
  asmUsers: any;
  zoneList: any;
  clientId: string;
  gstCardPath: string;
  showGstDownlaodBtn: boolean;
  gstfilename: string;
  panfilename: string;
  chequefilename: string;
  cityList: any;
  stateList: any;

  constructor(
    private authService: AuthService,
    private appConfig: AppConfig,
    private requestIntegrity: RequestIntegrity
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.userRole = atob(localStorage.getItem("userRole"));
    this.clientId = atob(localStorage.getItem("CLIENT_ID"));
  }

  ngOnInit() {
    this.workShopFormBuilder();
    this.getWorkshopDetails();
    this.getASMUsers();
    this.getStateDetails();
  }
  workShopFormBuilder() {
    this.workShopForm = new FormBuilder().group({
      dealerCode: this.workShopObj.dealerCode,
      workshopCode: this.workShopObj.workshopCode,
      workshopName: this.workShopObj.workshopName,

      dealerName: this.workShopObj.workshopName,
      superUserId: this.workShopObj.superUserId,
      dealerAddress: this.workShopObj.workshopAddress,
      zone: this.workShopObj.zoneId,
      stateId: this.workShopObj.stateId,
      cityId: this.workShopObj.cityId,
      gstNumber: this.workShopObj.gstNumber,
      panNo: this.requestIntegrity.decryptUsingAES256(this.workShopObj.panNo),
      contactPerson: this.workShopObj.contactPerson,
      contactNumber: this.workShopObj.contactNumber,
      primaryEmailIdContactPerson: this.workShopObj.primaryEmailIdContactPerson,
      contactPersonEmailId: this.workShopObj.contactPersonEmailId,
      bankAccountNo: this.workShopObj.bankAccountNo,
      bankName: this.workShopObj.bankName,
      ifscCode: this.workShopObj.ifscCode,
      beneficiaryName: this.workShopObj.beneficiaryName,
      bankBranchAddress: this.workShopObj.bankBranchAddress,
    });
    
  }
  getWorkshopDetails() {
    this.authService
      .get(
        this._apiUrl +
          `/api/v1/Workshops/GetworkshopListByid/00000000-0000-0000-0000-000000000000`
      )
      .subscribe(
        (response) => {
          if (response) {
            this.workShopObj = response;
            this.workShopFormBuilder();
            this.getStateWiseCityList(this.workShopObj.stateId);

            if (this.workShopObj.userType == UserType.Dealer)
              this.isDealer = true;
            this.loadZone(this.workShopObj.superUserId);
            if (this.workShopObj.cancelChequeImage != "" && this.workShopObj.cancelChequeImage) {
              this.cancelChequePath =
                this._apiUrl +
                "/" +
                this.workShopObj.cancelChequeImage.replace(/\\/g, "/"); //replace backward slash with forward slash
              this.chequefilename =
                this.workShopObj.cancelChequeImage.split(/_(.+)/)[1];
              var extn = this.chequefilename.substring(
                this.chequefilename.lastIndexOf(".") + 1,
                this.chequefilename.length
              );

              if (extn.toLowerCase() == "pdf") {
                this.showChequeDownlaodBtn = true;
              }
            }
            if (this.workShopObj.panCardImage != "" && this.workShopObj.panCardImage) {
              this.panCardPath =
                this._apiUrl +
                "/" +
                this.workShopObj.panCardImage.replace(/\\/g, "/"); //replace backward slash with forward slash
              this.panfilename =
                this.workShopObj.panCardImage.split(/_(.+)/)[1];
              var extn = this.panfilename.substring(
                this.panfilename.lastIndexOf(".") + 1,
                this.panfilename.length
              );

              if (extn.toLowerCase() == "pdf") {
                this.showPanDownlaodBtn = true;
              }
            }
            if (this.workShopObj.gstCertificateImage != "" && this.workShopObj.gstCertificateImage) {
              this.gstCardPath =
                this._apiUrl +
                "/" +
                this.workShopObj.gstCertificateImage.replace(/\\/g, "/"); //replace backward slash with forward slash
              this.gstfilename =
                this.workShopObj.gstCertificateImage.split(/_(.+)/)[1];
              var extn = this.gstfilename.substring(
                this.gstfilename.lastIndexOf(".") + 1,
                this.gstfilename.length
              );

              if (extn.toLowerCase() == "pdf") {
                this.showGstDownlaodBtn = true;
              }
            }
            if (this.workShopObj.userType == UserType.Dealer)
              this.isDealer = true;
            this.loadZone(this.workShopObj.superUserId);
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
  //get all ASM/BD users for dealer
  getASMUsers() {
    this.authService
      .get(
        this._apiUrl +
          `/api/v1/Users/UserBaseType/${UserType.BD}/client/${this.clientId}`
      )
      .subscribe(
        (response) => {
          if (response) {
            this.asmUsers = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
  loadZone(id: any) {
    this.authService
      .get(this._apiUrl + `/api/v1/Zones/GetZoneByUserId/${id}`)
      .subscribe(
        (response) => {
          if (response) {
            this.zoneList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getStateDetails() {
    this.authService
      .get(
        this._apiUrl + "/api/v1/States/GetActiveStates/client/" + this.clientId
      )
      .subscribe(
        (response) => {
          if (response) {
            this.stateList = response;
          }
        },
        (error) => {}
      );
  }

  getStateWiseCityList(stateId) {
    if (stateId != 0 && stateId) {
      this.authService
        .get(this._apiUrl + `/api/v1/Cities/state/${stateId}`)
        .subscribe(
          (response) => {
            if (response) {
              this.cityList = response;
            }
          },
          (error) => {}
        );
    }
  }
}
