<div nxLayout="grid" >
    <div nxRow>
        <div nxCol="10">
            <button (click)="toggleModal()" nxButton="tertiary" type="button" style="float: right;">Add Policy</button>
        </div>

    </div>
    <div nxRow>
        <div nxCol="10" nxColOffset="1">
            <table nxTable zebra>
                <thead>
                    <tr nxTableRow>
                        <th nxHeaderCell>
                            Policy Name
                        </th>
                        <th nxHeaderCell>
                            Duration of Policy
                        </th>
                        <th nxHeaderCell>
                            Amount
                        </th>

                        <th nxHeaderCell>
                            Status
                        </th>
                        <th nxHeaderCell>
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr nxTableRow *ngFor="let item of planMasterList">
                        <td nxTableCell>{{item.planName}}</td>
                        <td nxTableCell>{{item.durationOfPlan}}</td>
                        <td nxTableCell>{{item.totalPrice}}</td>
                        <td nxTableCell *ngIf="item.isActive =='1' else inActive">
                            <nx-badge vibrant type="positive">Active</nx-badge>
                          </td>
                          <ng-template #inActive>
                            <td nxTableCell>
                              <nx-badge vibrant type="critical">In Active</nx-badge>
                            </td>
                          </ng-template>
                        <!-- <td nxTableCell *ngIf="item.status == 'Active'">
                            <nx-switcher [checked]="true"></nx-switcher>
                        </td>
                        <td nxTableCell *ngIf="item.status == 'In Active'">
                            <nx-switcher [checked]="false"></nx-switcher>
                        </td> -->
                        <td nxTableCell>
                            <nx-icon name="edit" size="s" (click)="toggleModal()"></nx-icon>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<nx-modal #addPolicy [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="policyModalOpen" [nxShowCloseIcon]="false">
    <form name='addplan-form' [formGroup]="policyMasterForm">

        <div nxLayout="grid">
            <div nxRow nxRowJustify="start,around">
                <h1 nxHeadline="page">Add Policy</h1>

            </div>
            <hr>
            <div nxRow>
                <div nxCol="12,6" nxAlignSelf="center">
                    <nx-formfield nxLabel="Policy Name">
                        <input nxInput formControlName="planName" />
                        <nx-error nxFormfieldError>
                            <strong>Please note:</strong> this field is required!
                        </nx-error>
                    </nx-formfield>
                </div>
                <div nxCol="12,6" nxAlignSelf="center">
                    <nx-formfield nxLabel="Duration(Years)">
                        <input nxInput formControlName="durationOfPlan" />
                        <nx-error nxFormfieldError>
                            <strong>Please note:</strong> this field is required!
                        </nx-error>
                    </nx-formfield>
                </div>
            </div>
            <div nxRow>
                <div nxCol="12,6" nxAlignSelf="center">
                    <nx-formfield nxLabel="Amount">
                        <input nxInput formControlName="totalPrice" />
                        <nx-error nxFormfieldError>
                            <strong>Please note:</strong> this field is required!
                        </nx-error>
                    </nx-formfield>
                </div>
                <div nxCol="12,6" nxAlignSelf="center">
                    <nx-formfield nxLabel="AWP Risk Price">
                        <input nxInput formControlName="awpRiskPrice" />
                        <nx-error nxFormfieldError>
                            <strong>Please note:</strong> this field is required!
                        </nx-error>
                    </nx-formfield>
                </div>
                <div nxCol="12,6" nxAlignSelf="center">
                    <nx-formfield nxLabel="Marketing Fund">
                        <input nxInput formControlName="marketingFund" />
                        <nx-error nxFormfieldError>
                            <strong>Please note:</strong> this field is required!
                        </nx-error>
                    </nx-formfield>
                </div>
                <div nxCol="12,6" nxAlignSelf="center">
                    <nx-formfield nxLabel="Customer Engagement Fund">
                        <input nxInput formControlName="customerEngementFund" />
                        <nx-error nxFormfieldError>
                            <strong>Please note:</strong> this field is required!
                        </nx-error>
                    </nx-formfield>
                </div>
                <div nxCol="12,6" nxAlignSelf="center">
                    <nx-formfield nxLabel="Customer Engagement Fund">
                        <input nxInput formControlName="customerEngementFund" />
                        <nx-error nxFormfieldError>
                            <strong>Please note:</strong> this field is required!
                        </nx-error>
                    </nx-formfield>
                </div>
                <div nxCol="12,6" nxAlignSelf="center">
                    <nx-formfield nxLabel="Royality">
                        <input nxInput formControlName="royality" />
                        <nx-error nxFormfieldError>
                            <strong>Please note:</strong> this field is required!
                        </nx-error>
                    </nx-formfield>
                </div>
            </div>
            <div nxRow style="margin-top: 5%;">
                <div nxCol="8" nxColOffset='2'>
                    <button (click)="toggleModal()" nxButton="primary" type="button"
                        >Submit</button>
                    <button (click)="toggleModal()" nxButton="secondary" type="button">Close</button>

                </div>
            </div>
        </div>
    </form>

</nx-modal>