<table nxTable zebra nxSort (sortChange)="sortTable($event)" style="text-align: center;">
    <thead>
        <tr nxTableRow>
            <th nxHeaderCell nxSortHeaderCell="dealerCode">
                Worksop/Dealer Code
            </th>
            <th nxHeaderCell nxSortHeaderCell="name">
                Workshop/Dealer Name
            </th>
            <th nxHeaderCell nxSortHeaderCell="asmName">
                Dealer Manager
            </th>
            <th nxHeaderCell nxSortHeaderCell="invoiceNo">
                Invoice No
            </th>
            <th nxHeaderCell nxSortHeaderCell="vinChassisNo">
                Vin Number
            </th>
            <th nxHeaderCell nxSortHeaderCell="licensePlateNo">
                Licence Plate No
            </th>
            <th nxHeaderCell nxSortHeaderCell="model">
                Model
            </th>
            <th nxHeaderCell nxSortHeaderCell="policyName">
                Policy Name
            </th>
            <th nxHeaderCell nxSortHeaderCell="effectiveDate">
                Effective Date
            </th>
            <th nxHeaderCell nxSortHeaderCell="expiryDate">
                Expiry Date
            </th>

            <th nxHeaderCell nxSortHeaderCell="awpRiskPrice">
                AWP Risk Price
            </th>
            <th nxHeaderCell nxSortHeaderCell="royality">
                Royality
            </th>
            <th nxHeaderCell nxSortHeaderCell="dealerMargin">
                Dealer Margin
            </th>
            <th nxHeaderCell nxSortHeaderCell="customerEngementFund">
                Customer Engagement Fund
            </th>  
            <th nxHeaderCell nxSortHeaderCell="marketingFund">
                Marketing Fund
            </th>
            <th nxHeaderCell nxSortHeaderCell="cessAmount">
                Cess Amount
            </th>  
            <th nxHeaderCell nxSortHeaderCell="gstPrice">
                GST
            </th> 
            <th nxHeaderCell nxSortHeaderCell="unitPrice">
                Unit Price
            </th>     


            <th nxHeaderCell nxSortHeaderCell="zonename">
                Zone
            </th>
            <th nxHeaderCell nxSortHeaderCell="customerFirstName">
                Customer Name
            </th>
            <th nxHeaderCell nxSortHeaderCell="customerMobileNo">
                Cust Mobile
            </th>
            <th nxHeaderCell nxSortHeaderCell="customerEmail">
                Cust Email
            </th>
            <th nxHeaderCell nxSortHeaderCell="invoiceDate">
                Policy Sale Date
            </th>
            <th nxHeaderCell nxSortHeaderCell="policyStatusId">
                Policy Status
            </th>
        </tr>
    </thead>
    <tbody>
        <tr nxTableRow *ngFor="let item of ReportData">
            <td nxTableCell>{{item.dealerCode}}</td>
            <td nxTableCell>{{item.name}}</td>
            <td nxTableCell>{{item.asmName}}</td>
            <td nxTableCell>{{item.policyDetails.invoiceNo}}</td>
            <td nxTableCell>{{item.policyDetails.vinChassisNo}}</td>
            <td nxTableCell>{{item.policyDetails.licensePlateNo}}</td>
            <td nxTableCell>{{item.policyDetails.model}}</td>
            <td nxTableCell>{{item.policyDetails.policyName}}</td>
            <td nxTableCell>{{item.policyDetails.effectiveDate | date}}</td>
            <td nxTableCell>{{item.policyDetails.expiryDate | date}}</td>
            <td nxTableCell>{{item.planDetails.awpRiskPrice}}</td>
            <td nxTableCell>{{item.planDetails.royality}}</td>
            <td nxTableCell>{{item.planDetails.dealerMargin}}</td>
            <td nxTableCell>{{item.planDetails.customerEngementFund}}</td>
            <td nxTableCell>{{item.planDetails.marketingFund}}</td>
            <td nxTableCell>{{item.policyDetails.cessAmount}}</td>
            <td nxTableCell>{{item.policyDetails.gstPrice}}</td>
            <td nxTableCell>{{item.policyDetails.unitPrice}}</td>
            <td nxTableCell>{{item.zonename}}</td>
            <td nxTableCell>{{item.policyDetails.customerFirstName}}&nbsp;{{item.policyDetails.customerLastName}}</td>
            <td nxTableCell>{{item.policyDetails.customerMobileNo}}</td>
            <td nxTableCell>{{item.policyDetails.customerEmail}}</td>
            <td nxTableCell>{{item.policyDetails.invoiceDate | date}}</td>
            <td nxTableCell *ngIf="item.policyDetails.policyStatusId == policyStatus.Active else cancel">
                <nx-badge type="positive">Issued</nx-badge>
            </td>
            <ng-template #cancel>
                <td nxTableCell *ngIf="item.policyDetails.policyStatusId == policyStatus.Cancel else modified">
                    <nx-badge type="negative">Cancelled</nx-badge>
                </td>
            </ng-template>
            <ng-template #modified>
                <td nxTableCell>
                    <nx-badge type="critical">Modified</nx-badge>
                </td>
            </ng-template>
        </tr>
        <tr nxTableRow *ngIf="count==0">
            <td nxTableCell colspan="8">
              <ng-container>
                  <div nxRow nxRowJustify="center">
                    <div nxCol="12" class="nosearchresult"></div>
                    <h3 nxHeadline="subsection-medium" class="nx-font-weight-semibold">No Records found !!!</h3>
                  </div>
                </ng-container>
                </td>
            </tr>
    </tbody>
</table>

<div nxRow class="nx-margin-top-2m nx-margin-right-5xl" style="float: right;" *ngIf="count!=0">
    <div nxCol="6">
        <nx-pagination [nxCount]="count" [nxPage]="pageNumber" [nxPerPage]="pageSize" nxType="advanced"
            (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
        </nx-pagination>
    </div>
</div>