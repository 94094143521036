export class userData {
    firstName: string;
    lastName: string;
    userTypeId: number;
    userRole: string;
    emailId: string;
    mobileNumber:string;
    zoneIds: Array<number>;
    dealerCode: string;
    dealerAddress: string;
    clientId: number;
    isActive: number;
    password: string;
    createdDate: string;
    createdBy: string;
    updatedBy: string;
    updatedDate: string;
    lastLoginDate: string;
    attemptCount: string;
    userId: number;
    userBaseTypeId: any;
    superUser:number;
    identifier: string;
}
