import { Component, OnInit, Inject } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControlName,
} from "@angular/forms";
import { sellPolicyData } from "../addpolicy/sellpolicyData.model";
import { DatastorageService } from "../datastorage.service";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from "src/config/app.config";
import { Router } from "@angular/router";
import { Step } from "../core/step.model";

@Component({
  selector: "app-plandetails",
  templateUrl: "./plandetails.component.html",
  styleUrls: ["./plandetails.component.css"],
})
export class PlandetailsComponent implements OnInit {
  planDetailForm: FormGroup;
  sellPolicyData: sellPolicyData = new sellPolicyData();
  _apiUrl: string;
  clientId: number;
  planMasterList = [];
  test;
  expiryDate;
  effectiveDate;
  PolicyTerm: number;
  newEffectiveDate: Date;
  cessList: any;
  IsCess: boolean = false;
  step: Step = new Step();
  IsOffer: boolean;
  offerList: any;

  constructor(
    private authService: AuthService,
    private appConfig: AppConfig,
    private dataService: DatastorageService,
    private router: Router
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
  }

  ngOnInit() {
    this.planDetailFormBuild();
    this.getPlanDetails();
    this.checkforDiscountedPlans();
    //this.getPlanMasterList();
    if (this.sellPolicyData.planId) {
      this.onPlanChanges(this.sellPolicyData.planId);
    }
    this.getCessandTaxesForState();
    this.initializeNav(1);
    this.getOffers();
  }

  initializeNav(compltedStep: number) {
    for (let i = 0; i <= compltedStep; i++) {
      this.step.stepNumber = i;
      this.step.completed = true;
      this.dataService.nextClick.next(this.step);
    }
  }

  checkforDiscountedPlans() {
    this.authService
      .get(this._apiUrl + `/api/v1/Plan/updatediscountapplicableplans`)
      .subscribe(
        (response) => {
          this.getPlanMasterList();
          //console.log(response);
        },
        (error) => {
          console.error(error);
        }
      );
  }
  getCessandTaxesForState() {
    this.authService.get(this._apiUrl + `/api/v1/CessTaxes/DealerId`).subscribe(
      (response) => {
        if (response.length > 0) {
          this.IsCess = true;
          this.cessList = response;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  planDetailFormBuild() {
    this.planDetailForm = new FormBuilder().group({
      planId: [this.sellPolicyData.planId, Validators.required],
      policyName: this.sellPolicyData.policyName,
      price: this.sellPolicyData.price,
      gstPrice: this.sellPolicyData.gstPrice,
    });
  }

  getPlanMasterList() {
    this.authService.get(this._apiUrl + `/api/v1/Plan/GetZonefilterPlans?pageNumber=-1`).subscribe(
      (response) => {
        if (response) {
          this.planMasterList = response.filter(
            (plan) =>
              parseInt(plan.maximumVehicleAge) >=
              this.sellPolicyData.ageOfVehicle
          );
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  savePlanDetails() {
    this.sellPolicyData.effectiveDate = this.effectiveDate;
    this.sellPolicyData.expiryDate = this.expiryDate;
    this.sellPolicyData.price = this.planDetailForm.get("price").value;
    this.sellPolicyData.gstPrice = this.planDetailForm.get("gstPrice").value;
    this.dataService.setData(this.sellPolicyData);
    this.router.navigate(["/sellpolicy/paymentdetails"]);

    this.step.stepNumber = 2;
    this.step.completed = true;
    this.dataService.nextClick.next(this.step);
  }

  getPlanDetails() {
    let resp = this.dataService.getData();
    if (resp) {
      this.sellPolicyData = this.dataService.getData();
      this.planDetailFormBuild();
    }
  }

  getOffers() {
    this.authService
      .get(this._apiUrl + `/api/v1/ManageOffers/CheckForOffer`)
      .subscribe(
        (response) => {
          if (response) {
            this.IsOffer = true;
            this.offerList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  onPlanChanges(id) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth();
    let day = currentDate.getDate();

    if (this.sellPolicyData.effectiveDate) {
      currentDate = new Date(this.sellPolicyData.effectiveDate);
      year = currentDate.getFullYear();
      month = currentDate.getMonth();
      day = currentDate.getDate() - 2;
    }

    this.authService.get(this._apiUrl + "/api/v1/Plan/" + id).subscribe(
      (response) => {
        if (response) {
          let gstPercentage = Math.round(
            (parseInt(response.totalPrice) / 100) * 18
          );
          let durationOfPlan = parseInt(response.durationOfPlan);
          this.PolicyTerm = durationOfPlan;

          this.expiryDate = new Date(year + durationOfPlan, month, day + 2);
          this.effectiveDate = new Date(year, month, day + 2);
          this.sellPolicyData.planId = response.planId;
          this.sellPolicyData.planIdentifier = response.identifier;
          this.sellPolicyData.policyName = response.planName;
          this.sellPolicyData.price = response.totalPrice;
          this.sellPolicyData.effectiveDate = this.effectiveDate;
          this.sellPolicyData.expiryDate = this.expiryDate;
          this.sellPolicyData.gstPrice = gstPercentage;
          this.planDetailFormBuild();

          if (this.IsOffer) {
            this.sellPolicyData.offerIdentifier = this.offerList.identifier;
            this.sellPolicyData.price =
              response.totalPrice -
              Math.round(
                (parseInt(response.totalPrice) / 100) *
                  this.offerList.discountPercent
              );
            //GST Calculated based on discounted price
            this.sellPolicyData.gstPrice = Math.round(
              (this.sellPolicyData.price / 100) * 18
            );
          }
          this.planDetailFormBuild();
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  PrevPage() {
    this.step.stepNumber = 1;
    this.step.completed = false;
    this.dataService.nextClick.next(this.step);
  }
}
