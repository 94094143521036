import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Moment } from "moment";
import { NxDatepickerComponent } from "@aposin/ng-aquila/datefield";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from "src/config/app.config";
import { planMaster, offerModel } from "./planmaster.model";
import { UserRights } from "../core/user-rights.model";
import { SortDirection, SortEvent } from "@aposin/ng-aquila/table";
import { CustomErrorHandler } from "../core/custom-error-handler";

@Component({
  selector: "app-plan",
  templateUrl: "./plan.component.html",
  styleUrls: ["./plan.component.css"],
})
export class PlanComponent implements OnInit {
  planModalOpen: boolean = false;
  offerModalOpen: boolean = false;
  minBalModalOpen: boolean = false;
  isminBalSuccess: boolean = false;

  @ViewChild("endDatepicker", { static: true })
  endDatepicker: NxDatepickerComponent<Moment>;

  offerStartDate: Moment = null;
  offerEndDate: Moment = null;
  planForm: FormGroup;
  offerForm: FormGroup;
  minBalForm: FormGroup;
  clientId: number;
  _apiUrl: string;
  planMasterList = [];
  planMasterData: planMaster = new planMaster();
  isSuccess: boolean = false;
  isOfferSuccess: boolean = false;
  EDIT_FLAG: boolean = false;
  VIEW_FLAG: boolean = false;
  planList: any;
  CLIENT_NAME: string;

  userName;
  planId: number;
  confirmModalOpen: boolean = false;
  planStatusDetails = {};
  zoneList: any;
  offer: offerModel = new offerModel();
  isDiscountedPlanCheck: Boolean = false;
  responsemessage: any;
  userRights: UserRights = new UserRights();
  
  /** sorting starts here */
  sortTable(sort: SortEvent) {
    //Sorts the table data by a certain category.
    this.planMasterList = this.planMasterList.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection
  ) {
    return (a < b ? -1 : 1) * (direction === "asc" ? 1 : -1);
  }
  /** sorting ends here */

  /**Pagination Start */
  count: number;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  prevPage() {
    this.pageNumber--;
    this.getPlanMasterList();
  }

  nextPage() {
    this.pageNumber++;
    this.getPlanMasterList();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getPlanMasterList();
  }
  /**Pagination end */

  constructor(
    private authService: AuthService,
    private appConfig: AppConfig,
    private errorHandler: CustomErrorHandler
  ) {
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.CLIENT_NAME = localStorage.getItem("CLIENT_NAME");
    this.userName = localStorage.getItem("USER_NAME");
    this._apiUrl = appConfig.baseUrl;
    this.isDiscountedPlanCheck = false;

    this.planMasterFormBuilder();

    this.offerForm = new FormBuilder().group({
      startDate: [this.offer.StartDate, Validators.required],
      endDate: [this.offer.EndDate, Validators.required],
      zoneIds: [this.offer.ZoneIds, Validators.required],
      offerName: [this.offer.OfferName, Validators.required],
      discountPercent: [this.offer.DiscountPercent, Validators.required],
      isActive: [1],
      clientId: this.clientId,
      createdBy: this.userName,
    });
  }

  ngOnInit() {
    this.getUserRights();
    this.getMinBalance();
    this.getPlanMasterList();
    this.getZones();
    this.minBalFormInit();
    this.getDropdownPlanMasterList();
  }

  getUserRights() {
    this.authService.userPermissions().subscribe((response: any) => {
      if (response) {
        this.userRights = response;
      }
    });
  }

  //get all zones
  getZones() {
    this.authService
      .get(this._apiUrl + `/api/v1/Zones/client/${this.clientId}?pageNumber=-1`)
      .subscribe(
        (response) => {
          if (response) {
            this.zoneList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  planMasterFormBuilder() {
    this.planForm = new FormBuilder().group({
      planName: [this.planMasterData.planName, Validators.required],
      durationOfPlan: [this.planMasterData.durationOfPlan, Validators.required],
      maximumVehicleAge: [
        this.planMasterData.maximumVehicleAge,
        Validators.required,
      ],
      totalPrice: [this.planMasterData.totalPrice, Validators.required],
      awpRiskPrice: [this.planMasterData.awpRiskPrice, Validators.required],
      dealerMargin: [this.planMasterData.dealerMargin, Validators.required],
      customerEngementFund: [
        this.planMasterData.customerEngementFund,
        Validators.required,
      ],
      royality: [this.planMasterData.royality, Validators.required],
      clientId: this.clientId,
      isActive: 1,
      createdDate: this.planMasterData.createdDate,
      createdBy: this.userName,
      updatedBy: this.userName,
      updatedDate: this.planMasterData.updatedDate,
      planId: this.planMasterData.planId,
      discountPolicyCount: this.planMasterData.discountPolicyCount,
      discountedPlanId: this.planMasterData.discountedPlanId,
      isDiscountedPlan: this.planMasterData.isDiscountedPlan,
      unityPlanId: [this.planMasterData.unityPlanId, Validators.required],
      unityBrand: [this.planMasterData.unityBrand, Validators.required],
      unityProductName: [
        this.planMasterData.unityProductName,
        Validators.required,
      ],
      zoneId: [this.planMasterData.zoneId, Validators.required],
    });
  }

  minBalFormInit() {
    this.minBalForm = new FormBuilder().group({
      MinWalletBalance: ["", Validators.required],
      clientId: [this.clientId],
    });
  }

  getPlanMasterList() {
    this.authService
      .getList(
        this._apiUrl +
          "/api/v1/Plan" +
          "?pageNumber=" +
          this.pageNumber +
          "&pageSize=" +
          this.pageSize +
          "&searchText=" +
          this.searchTerm
      )
      .subscribe(
        (response) => {
          if (response) {
            this.planMasterList = response.body;
            var pagingProp = JSON.parse(response.headers.get("X-Pagination"));
            this.count = pagingProp.TotalCount;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
  getDropdownPlanMasterList() {
    this.authService.get(this._apiUrl + "/api/v1/Plan").subscribe(
      (response) => {
        if (response) {
          this.planList = response;
          // console.log('plan master list', response)
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
  getMinBalance() {
    this.authService.get(this._apiUrl + `/api/v1/Plan/getMinBalance`).subscribe(
      (response) => {
        if (response.success) {
          this.minBalForm.patchValue({
            MinWalletBalance: response.data,
            clientId: this.clientId,
          });
        } else {
        }
        this.responsemessage = response.responsemsg;
      },
      (error) => {
        console.error(error);
      }
    );
  }
  saveWalletminBalance() {
    this.authService
      .post(
        this._apiUrl + "/api/v1/Plan/UpdateMinBalance",
        this.minBalForm.value
      )
      .subscribe(
        (response) => {
          if (response.success) {
            this.isminBalSuccess = !this.isminBalSuccess;
          } else {
          }
          this.responsemessage = response.responsemsg;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  toggleModal(EDIT: boolean, VIEW: boolean, planId) {
    this.VIEW_FLAG = VIEW;
    this.EDIT_FLAG = EDIT;
    this.planId = planId;
    this.planModalOpen = !this.planModalOpen;
    this.isSuccess = false;
    this.isDiscountedPlanCheck = false;
    if (this.planModalOpen) {
      if (this.EDIT_FLAG || this.VIEW_FLAG) {
        this.getPlanDetails(planId);
      }
    } else {
      this.planMasterData = new planMaster();
      this.planMasterData.isDiscountedPlan = false;
      this.planMasterFormBuilder();
      this.isSuccess = true;
    }
  }

  toggleOfferModal() {
    this.offerModalOpen = !this.offerModalOpen;
    this.isOfferSuccess = false;
  }
  toggleminBalModal() {
    this.minBalModalOpen = !this.minBalModalOpen;
    this.isminBalSuccess = false;
  }

  addOfferDetails() {
    this.authService
      .post(this._apiUrl + "/api/v1/ManageOffers", this.offerForm.value)
      .subscribe(
        (response) => {
          if (response) {
            this.getPlanMasterList();
            this.isOfferSuccess = true;
            this.offerModalOpen = false;
          }
        },
        (error) => {
          this.errorHandler.handleError(error);
        }
      );
  }

  addPlanMaster() {
    this.isDiscountedPlanCheck = false;
    this.planMasterData.totalPrice = parseInt(this.planForm.value);
    this.authService
      .post(this._apiUrl + "/api/v1/Plan/AddPlan", this.planForm.value)
      .subscribe(
        (response) => {
          if (response) {
            //console.log(response)
            this.getPlanMasterList();
            this.isSuccess = true;
          }
        },
        (error) => {
          this.errorHandler.handleError(error);
        }
      );
  }

  getPlanDetails(planId) {
    this.authService.get(this._apiUrl + "/api/v1/Plan/GetZonePlan/" + planId).subscribe(
      (response) => {
        if (response) {
          //  console.log(response);
          this.planMasterData = response;
          this.isDiscountedPlanCheck = this.planMasterData.isDiscountedPlan;
          this.planMasterFormBuilder();
        }
      },
      (error) => {
        this.errorHandler.handleError(error);
      }
    );
  }

  updatePlanMaster() {
    this.authService
      .put(this._apiUrl + "/api/v1/Plan/UpdatePlan/" + this.planId, this.planForm.value)
      .subscribe(
        (response) => {
          this.getPlanMasterList();
          this.isSuccess = true;
        },
        (error) => {
          this.errorHandler.handleError(error);
        }
      );
  }

  /**
   * open confirmation pop up for activate/deactiate user
   */
  confirmModal(planId, isChecked: boolean) {
    let isActive;
    this.confirmModalOpen = true;
    if (isChecked) {
      isActive = 1;
    } else {
      isActive = 0;
    }
    this.planStatusDetails = {
      id: planId,
      statusId: isActive,
    };
  }
  confirmModalClose() {
    this.confirmModalOpen = false;
    this.getPlanMasterList();
    this.planStatusDetails = {};
  }

  changePlanStatus() {
    this.authService
      .put(this._apiUrl + "/api/v1/Plan/StatusUpdate", this.planStatusDetails)
      .subscribe(
        (response) => {
          this.confirmModalClose();
        },
        (error) => {
          this.errorHandler.handleError(error);
        }
      );
  }

  isDiscountedPlanChanged(value: Boolean) {
    this.isDiscountedPlanCheck = !this.isDiscountedPlanCheck;
  }

  updatePrice() {
    var awpPrice = this.planForm.controls.awpRiskPrice.value;
    var CusEngageFund = this.planForm.controls.customerEngementFund.value;
    var dealerMargin = this.planForm.controls.dealerMargin.value;
    var clientMargin = this.planForm.controls.royality.value;

    var totalPrice = awpPrice + CusEngageFund + dealerMargin + clientMargin;

    this.planForm.patchValue({
      totalPrice: totalPrice,
    });
  }
}
