import { Component, Input } from "@angular/core";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from "src/config/app.config";
import { SortEvent, SortDirection } from "@aposin/ng-aquila/table/public-api";
import { PolicyStatus } from "../core/policyStatus.model";

@Component({
  selector: "app-dealer-commission-invoice",
  templateUrl: "./dealer-commission-invoice.component.html",
  styleUrls: ["./dealer-commission-invoice.component.css"],
})
export class DealerCommissionInvoiceComponent {
  @Input() policyMonth: string;
  @Input() policyYear: string;
  @Input() dealerCode: number;
  fileName: string;
  ReportData: any;
  policyMasterList: any;
  exportdata: any = [];
  _apiUrl: string;
  clientId: number;
  userTypeId: number;
  userBaseTypeId: number;
  userId: number;
  policyStatus: any;
  policyNumber: string = "";
  /** sorting starts here */
  sortTable(sort: SortEvent) {
    this.policyMasterList = this.policyMasterList.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection
  ) {
    return (a < b ? -1 : 1) * (direction === "asc" ? 1 : -1);
  }
  /** sorting ends here */
  /**Pagination Start */
  count: number = 0;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  prevPage() {
    this.pageNumber--;
    this.getPolicyMasterList();
  }

  nextPage() {
    this.pageNumber++;
    this.getPolicyMasterList();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getPolicyMasterList();
  }
  /**Pagination end */

  constructor(private authService: AuthService, private appConfig: AppConfig) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.userBaseTypeId = parseInt(
      atob(localStorage.getItem("USER_BASE_TYPE_ID"))
    );
    this.userTypeId = parseInt(atob(localStorage.getItem("USER_TYPE_ID")));
    this.policyStatus = PolicyStatus;
  }

  loadReport() {
    this.pageNumber = 1;
    this.getPolicyMasterList();
  }

  //Show all Polices of selected Month and Year
  getPolicyMasterList() {
    var policymonth = this.policyMonth;
    var policyyear = this.policyYear;
    var dealercode = this.dealerCode;
    
    this.authService
      .getList(
        this._apiUrl +
          "/api/v1/PolicyDetails" +
          "?policyNo=" +
          this.policyNumber +
          "&pageNumber=" +
          this.pageNumber +
          "&pageSize=" +
          this.pageSize +
          "&searchText=" +
          this.searchTerm +
          "&month=" +
          policymonth +
          "&year=" +
          policyyear +
          "&dealercode=" +
          dealercode
      )
      .subscribe(
        (response) => {
          if (response) {
            this.policyMasterList = response.body;
            var pagingProp = JSON.parse(response.headers.get("X-Pagination"));
            this.count = pagingProp.TotalCount;
            
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
