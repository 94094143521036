import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";

@Injectable()
export class RequestIntegrity {
  private keyAndIvBytes = CryptoJS.enc.Utf8.parse("Allianz Partners");

  uuidv4(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  HashInput(input): string {
    return CryptoJS.SHA512(input).toString();
  }

  encryptUsingAES256(data) {
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(JSON.stringify(data)),
      this.keyAndIvBytes,
      {
        keySize: 256,
        iv: this.keyAndIvBytes,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return encrypted.toString();
  }

  decryptUsingAES256(decString) {
    if (!decString) return;

    var decrypted = CryptoJS.AES.decrypt(decString, this.keyAndIvBytes, {
      keySize: 256,
      iv: this.keyAndIvBytes,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
