<div nxLayout="grid">
    <div nxRow *ngIf="count!=0">
        <div nxCol="12">
            <table nxTable zebra class="nx-margin-right-5xl" nxSort (sortChange)="sortTable($event)">
                <thead>
                    <tr nxTableRow>
                        <th nxHeaderCell nxSortHeaderCell="zone">
                            Zone
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="state_Name">
                            State Name
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="city_Name">
                            City Name
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="dealerOrWorkshopCode">
                            Dealer/Workshop Code
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="workshop_Name">
                            Dealer/Workshop Name
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="paymentDate">
                            Payment Date
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="totalCount">
                            Total Count
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="totalAmount">
                            Total Amount
                        </th>
                    </tr>
                </thead>
                <tbody style="text-align: center;">
                    <tr nxTableRow *ngFor="let Req of ReportData">
                        <td nxTableCell>{{Req.zone}}</td>
                        <td nxTableCell>{{Req.state_Name}}</td>
                        <td nxTableCell>{{Req.city_Name}}</td>
                        <td nxTableCell>{{Req.dealerOrWorkshopCode}}</td>
                        <td nxTableCell>{{Req.workshop_Name}}</td>
                        <td nxTableCell>{{Req.paymentDate | date:'MM/dd/yyyy'}}</td>
                        <td nxTableCell>{{Req.totalCount}}</td>
                        <td nxTableCell>{{Req.totalAmount}}</td>
                    </tr>
                    <tr nxTableRow *ngIf="count==0">
                        <td nxTableCell colspan="11">
                            <nx-label>No Report data found.</nx-label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div nxRow class="nx-margin-top-2m nx-margin-right-5xl" style="float: right;">
        <div nxCol="6">
            <nx-pagination [nxCount]="count" [nxPage]="pageNumber" [nxPerPage]="pageSize" nxType="advanced"
                (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
            </nx-pagination>
        </div>
    </div>
</div>