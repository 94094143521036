export const environment = {
  production: false,
  apiUrl: "https://b2b2c-api-uat.awpassistance.in",
  payuUrl: "https://sboxcheckout-static.citruspay.com/bolt/run/bolt.min.js",
  nsmCount: 1,
  rsmCount: 5,
  asmCount: 25,
  financeCount: 1,
  gCaptchaKey: "6LcG9f4cAAAAAFskYLlcgIVQPVnLX8Xwf3R0Ecky",
  gCaptchaSecret: "6LcG9f4cAAAAALzlxZqN0AIKGWtMG5zSY7LqPxyk",
  maxvehicleAge: 6,
  onePayuUrl: "https://test.payu.in/_payment",
  onePayuKey: "oZ7oo9",
  onePayuSalt: "UkojH5TS",  
  responseKey:"511536EF-F270-4058-80CA-1C89C192F69A",
};
