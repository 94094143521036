import { Component, Input } from "@angular/core";
import * as moment from "moment";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { SortEvent, SortDirection } from "@aposin/ng-aquila/table/public-api";
import { PolicyStatus } from '../core/policyStatus.model';

@Component({
  selector: 'app-wallet-statement-report',
  templateUrl: './wallet-statement-report.component.html',
  styleUrls: ['./wallet-statement-report.component.css']
})
export class WalletStatementReportComponent  {
  @Input() policyStartDate: moment.Moment;
  @Input() policyEndDate: moment.Moment;
  @Input() dealerCode : string;

  fileName: string;
  ReportData: any;
  exportdata: any = [];
  _apiUrl: string;
  clientId: number;
  userTypeId: number;
  userBaseTypeId: number;
  userId: number;
  policyStatus: typeof PolicyStatus;

  /** sorting starts here */

  sortTable(sort: SortEvent) {
    this.ReportData = this.ReportData.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection
  ) {
    return (a < b ? -1 : 1) * (direction === "asc" ? 1 : -1);
  }
  /** sorting ends here */
  /**Pagination Start */
  count: number = 0;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  prevPage() {
    this.pageNumber--;
    this.getReportData();
  }

  nextPage() {
    this.pageNumber++;
    this.getReportData();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getReportData();
  }
  /**Pagination end */

  constructor(
    private authService: AuthService,
    private appConfig : AppConfig
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.userBaseTypeId = parseInt(atob(localStorage.getItem("USER_BASE_TYPE_ID")));
    this.userTypeId = parseInt(atob(localStorage.getItem("USER_TYPE_ID")));
    this.policyStatus = PolicyStatus;
  }

  loadReport() {
    this.pageNumber =1;
    this.getReportData();
  }

  getReportData() {
    var startdate = this.policyStartDate == undefined ? "" : this.policyStartDate.format('LL');
    var enddate = this.policyEndDate == undefined ? "" : this.policyEndDate.format('LL');
    this.authService.getList(this._apiUrl + `/api/v1/Report/WalletReport?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}
    &searchText=${this.searchTerm}&startDatepicker=${startdate}&endDatepicker=${enddate}&udf1=${this.dealerCode == undefined ? "" : this.dealerCode}`)
    .subscribe(response => {
      if (response) {
        this.ReportData = response.body;
        let pagingProp = JSON.parse(response.headers.get('X-Pagination'));
        this.count = pagingProp.TotalCount;
      }
    }, (error) => {
      console.error(error);
    });
  }

  getExportData() {
    var startdate = this.policyStartDate == undefined ? "" : this.policyStartDate.format('LL');
    var enddate = this.policyEndDate == undefined ? "" : this.policyEndDate.format('LL');
    this.authService.getList(this._apiUrl + `/api/v1/Report/WalletReport?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}
    &searchText=${this.searchTerm}&startDatepicker=${startdate}&endDatepicker=${enddate}&udf1=${this.dealerCode == undefined ? "" : this.dealerCode}`)
    .subscribe(response => {
      if (response) {
        this.exportdata = [];
        response.body.forEach(element => this.setExportData(element));
        this.bindexcel();
      }
    }, (error) => {
      console.error(error);
    });
  }

  setExportData(item: any) {
    var data: any = [];
    data.TransactionId = item.transactionId;
    data.DealerCode = item.dealerCode;
    data.PolicyNo = item.policyNo;
    data.CustomerName = item.customerName;
    data.InvoiceNo = item.invoiceNo;
    data.TransactionDetails = item.transactionDetails;
    data.Paymentdate = new Date(item.date).toLocaleDateString();
    data.UnitPrice = item.unitPrice;
    data.CessAmount = item.cessAmount;
    data.IGST = item.igst;
    data.CGST = item.cgst;
    data.SGST = item.sgst;
    data.Amount = item.amount;
    data.TransactionType = item.transactionType;
    data.OutstandingBalance = item.outstandingBal;
    
    this.exportdata.push(data);
  }

  exporttoexcel(){
    this.pageNumber = -1;
    this.getExportData();
  }

  bindexcel() {
    import('xlsx').then(xlsx => {
      /* json data is passed over here */
      const ws: import('xlsx').WorkSheet = xlsx.utils.json_to_sheet(this.exportdata);
      /* generate workbook and add the worksheet */
      const wb: import('xlsx').WorkBook = xlsx.utils.book_new();

      xlsx.utils.book_append_sheet(wb, ws, "Wallet_Statement_Report");
      /* save to file */
      this.fileName =
        "WalletStatementReport_" +
        new Date(Date.now()).toLocaleString().split(",")[0] +
        ".xlsx";
      xlsx.writeFile(wb, this.fileName);
    });
  }
}

