import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { throwError } from 'rxjs';
import { NxMessageToastService } from '@aposin/ng-aquila/message';

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.css']
})
export class UserPermissionsComponent implements OnInit {


  modalOpen: boolean = false;
  addPermission: boolean = false;
  userPermissionForm: FormGroup;
  _apiUrl: string;
  _userRole: string;
  _userId: number;
  userPermissionList = [];
  isSuccess: boolean = false;
  EDIT_FLAG: boolean;
  userTypeId;
  userTypeDetails = {};
  clientList = [];
  clientId: number;
  userTypeList = [];
  errorMessage: string;
  closed: boolean;

  constructor(private authService: AuthService, private appConfig : AppConfig, private messageToastService: NxMessageToastService) {
    this._apiUrl = appConfig.baseUrl;
    this.userPermissionFormBuilder();
  }

  ngOnInit() {
    this.getClientList();
  }

  userPermissionFormBuilder() {
    this.userPermissionForm = new FormBuilder().group({
      id: 0,
      userTypeId: ['', Validators.required],
      add: false,
      approveReject: false,
      view: true,
      edit: false,
      delete: false,
      acceptWallet: false,
      rejectWallet: false,
      acceptUser: true,
      rejectUser: false,
      isOfflinePayment: false,
      isOnlinePayment: false,
      isPlanManagae : false,
      cancelPolicy : false,
      editPolicy : false,
      cancelPolicyApprove : false,
      editPolicyApprove : false,
      isEmailNotification : false,
      isSMSNotification : false ,
      clientId: this.clientId,
      pageId: 0,
      createdBy: localStorage.getItem('USER_NAME'),
      updatedBy: localStorage.getItem('USER_NAME')
    });
  }

  getUserPermissionList() {
    this.authService.get(this._apiUrl + '/api/v1/UserRights/client/'+ this.clientId).subscribe(response => {
      if (response) {
        this.userPermissionList = response;
      }
    }, (error) => {
      console.error(error);
    });
  }

  toggleModal(EDIT: boolean, id) {
    this.EDIT_FLAG = EDIT;
    this.userTypeId = id;
    this.modalOpen = !this.modalOpen;
    this.isSuccess = false;
    if (this.EDIT_FLAG) {
      this.getUserPermissionDetails(id);
    }
    if (!this.modalOpen) {
      this.userPermissionFormBuilder();
    }
  }

  addUserPermissionMaster() {
    this.userPermissionForm.patchValue({ "clientId": this.clientId });
    this.authService.post(this._apiUrl + '/api/v1/UserRights', this.userPermissionForm.value).subscribe(response => {
      if (response) {
        this.getUserPermissionList();
        this.isSuccess = true;
      }
    }, (error) => {
      console.error(error);
      this.handleError(error);
    });
  }

  getUserPermissionDetails(id) {
    this.authService.get(this._apiUrl + '/api/v1/UserRights/' + id).subscribe(response => {
      if (response) {
        // console.log(response)
        this.userPermissionForm.setValue({
          id: response.id,
          userTypeId: response.userTypeId,
          add: response.add,
          approveReject: response.approveReject,
          view: response.view,
          edit: response.edit,
          delete: response.delete,
          acceptWallet: response.acceptWallet,
          rejectWallet: response.rejectWallet,
          acceptUser: response.acceptUser,
          rejectUser: response.rejectUser,
          isOfflinePayment: response.isOfflinePayment,
          isOnlinePayment: response.isOnlinePayment,
          isPlanManagae : response.isPlanManagae,
          cancelPolicy : response.cancelPolicy,
          editPolicy : response.editPolicy,
          cancelPolicyApprove : response.cancelPolicyApprove,
          editPolicyApprove : response.editPolicyApprove,
          isEmailNotification : response.isEmailNotification,
          isSMSNotification : response.isSMSNotification ,
          clientId: response.clientId,
          pageId: response.pageId,
          createdBy: localStorage.getItem('USER_NAME'),
          updatedBy: localStorage.getItem('USER_NAME')
        })
      }
    }, (error) => {
      console.error(error);
    });
  }
  updateUserPermissionMaster() {
    this.authService.put(this._apiUrl + '/api/v1/UserRights/' + this.userPermissionForm.controls.id.value, this.userPermissionForm.value).subscribe(response => {
      this.getUserPermissionList();
      this.isSuccess = true;
    }, (error) => {
      console.error(error);
    });
  }

  confirmModalClose() {

    this.getUserPermissionList();
    this.userPermissionFormBuilder();
  }

  confirmModal(userTypeDetails, isChecked: boolean) {

    this.userPermissionForm.setValue({
      userTypeId: userTypeDetails.userTypeId,
      userType: userTypeDetails.userType,
      isActive: isChecked ? 1 : 0,
      clientId: userTypeDetails.clientId
    })
  }

  getClientList() {
    this.authService.get(this._apiUrl + '/api/v1/Client').subscribe(response => {
      if (response) {
        this.clientList = response;
      }
    }, (error) => {
      console.error(error);
    });
  }

  onClientChanges(clientid) {
    this.addPermission = true;
    this.clientId = clientid;
    this.getUserTypeList();
    this.getUserPermissionList();
  }

  getUserTypeList() {
    this.authService.get(this._apiUrl + '/api/v1/UserTypes/client/'+ this.clientId).subscribe(response => {
      if (response) {
        this.userTypeList = response;
        // console.log(response)
      }
    }, (error) => {
      console.error(error);
    });
  }

  handleError(error) {
    if (error.error instanceof ErrorEvent) {
      // client-side error
      this.errorMessage = `Error : ${error.error.message}`;
    } else {
      // server-side error
      this.errorMessage = `Error : ${error.error}`;
    }
    this.messageToastService.open(this.errorMessage, { context: 'info', duration: 5000 });
    return throwError(this.errorMessage);
  }
}
