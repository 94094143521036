<div class="color-container">
    <div class="wrapper">
      <div class="nx-margin-s margin-box">
        <nx-card class="card">
    
            <form [formGroup]="workShopForm" (ngSubmit)="onSubmit()">
              <div nxLayout='grid'>
    
                <div nxRow>
                    <div nxCol="12" >
                        <nx-link nxStyle='icon-left'>
                            <a style="color: orangered;"><nx-icon name='exclamation-circle'></nx-icon>
                                Please enter the following details to proceed..</a>
                          </nx-link>
                    </div>
                </div>
                <div nxRow >
                  <div nxCol="12"  style="margin-bottom:1%;">
                    <nx-link>
                      <a >
                        <nx-icon name='user-o'></nx-icon>
                        <h3 nxHeadline="section" class="nx-margin-bottom-0">Personal Details</h3>
                      </a>
                    </nx-link>
                  </div>
    
    
                  <div nxCol="12,6"  >
                    <nx-formfield nxLabel="Dealer Code">
                      <input nxInput formControlName="dealerCode" readonly/>
                      <nx-error nxFormfieldError>
                        <strong>Please note:</strong> Dealer code is required!
                      </nx-error>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,6">
                    <nx-formfield *ngIf="login=='workshop'" nxLabel="Workshop code">
                      <input nxInput formControlName="workshopCode" />
                      <nx-error nxFormfieldError>
                        <div *ngIf="f.workshopCode.errors?.required"><strong>Please note:</strong>Workshop code is required.</div>
                        <div *ngIf="f.workshopCode.errors?.minlength"><strong>Please note:</strong> Workshop code should be 5
                          character.</div>
                        <div *ngIf="f.workshopCode.errors?.maxlength"><strong>Please note:</strong>Workshop code should be 5
                          character.</div>
                        <div *ngIf="f.workshopCode.errors?.pattern"><strong>Please note:</strong>Workshop code should be alpha
                          numeric.</div>
                      </nx-error>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,6"  >
                    <nx-formfield *ngIf="login=='workshop'" nxLabel="Workshop Name">
                      <input nxInput formControlName="workshopName" />
                      <nx-error nxFormfieldError>
                        <div *ngIf="f.workshopName.errors?.required"><strong>Please note:</strong>Workshop name is required.</div>
                      </nx-error>
                    </nx-formfield>
                    <nx-formfield *ngIf="login=='dealer'" nxLabel="Dealer Name">
                      <input nxInput formControlName="workshopName" />
                      <nx-error nxFormfieldError>
                        <strong>Please note:</strong> Dealer name is required!
                      </nx-error>
                    </nx-formfield>
                  </div>
                  
                  <div nxCol="12,6">
                    <nx-formfield *ngIf="login=='workshop'" nxLabel="Workshop Address">
                      <textarea nxInput formControlName="workshopAddress"></textarea>
                      <nx-error nxFormfieldError>
                        <div *ngIf="f.workshopAddress.errors?.required"><strong>Please note:</strong>Workshop address is required.
                        </div>
                      </nx-error>
                    </nx-formfield>
                    <nx-formfield *ngIf="login=='dealer'" nxLabel="Dealer Address">
                      <input nxInput formControlName="workshopAddress" />
                      <nx-error nxFormfieldError>
                        <strong>Please note:</strong> Dealer Address is required!
                      </nx-error>
                    </nx-formfield>
                  </div>
    
    
                  <div nxCol="12,6"  >
                    <nx-formfield nxLabel="Contact Person">
                      <input nxInput formControlName="contactPerson" />
                      <nx-error nxFormfieldError>
                        <div *ngIf="f.contactPerson.errors?.required"><strong>Please note:</strong>Please enter contact person
                          name.</div>
                      </nx-error>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,6" >
                    <nx-formfield nxLabel="Contact Number">
                      <input nxInput formControlName="contactNumber" />
                      <nx-error nxFormfieldError>
                        <div *ngIf="f.contactNumber.errors?.required"><strong>Please note:</strong>Contact Number is required.
                        </div>
                        <div *ngIf="f.contactNumber.errors?.pattern"><strong>Please note:</strong>Invalid mobile number.</div>
                      </nx-error>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,6"  >
                    <div></div>
                    <nx-formfield nxLabel="Service Tax Number" *ngIf="false">
                      <input nxInput formControlName="serviceTaxNumber" />
                    </nx-formfield>
                    <nx-formfield nxLabel="Select Region">
                      <nx-dropdown formControlName="zoneId" (selectionChange)="onzoneChanges($event.value)">
                        <nx-dropdown-item *ngFor="let item of zoneList | isActiveCheck" [nxValue]="item.id">{{item.zone}}
                        </nx-dropdown-item>
                      </nx-dropdown>
                      <nx-icon nxFormfieldAppendix name="info-circle-o" [nxPopoverTriggerFor]="popoverZone"
                        nxPopoverTrigger="hover">
                      </nx-icon>
                      <nx-error nxFormfieldError>
                        <strong>Please note:</strong> this field is required!
                      </nx-error>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,6"  >
                    <nx-formfield nxLabel="Select BD">
                      <nx-dropdown formControlName="superUserId">
                        <nx-dropdown-item *ngFor="let item of asmUsers | isActiveCheck" 
                        [nxValue]="this.requestIntegrity.decryptUsingAES256(item.dealerCode)">
                        {{item.firstName}} {{item.lastName}}
                        </nx-dropdown-item>
                      </nx-dropdown>
                      <nx-error nxFormfieldError>
                        <strong>Please note:</strong> this field is required!
                      </nx-error>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,6"  >
                    <nx-formfield nxLabel="PAN Number">
                      <input nxInput formControlName="panNo" />
                      <nx-error nxFormfieldError>
                        <div *ngIf="f.panNo.errors?.pattern"><strong>Please note:</strong>Invalid PAN Number.</div>
                      </nx-error>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,6"  >
                    <nx-formfield nxLabel="GST Number">
                      <input nxInput formControlName="gstNumber" />
                      <nx-error nxFormfieldError>
                        <div *ngIf="f.gstNumber.errors?.required"><strong>Please note:</strong>GSTIN Number is required.</div>
                        <div *ngIf="f.gstNumber.errors?.pattern"><strong>Please note:</strong>Invalid GSTIN Number.(Use Block Letters only)</div>
                      </nx-error>
                    </nx-formfield>
                  </div>
    
                  <div nxCol="12,6"  >
                    <nx-formfield nxLabel="Primary Email">
                      <input nxInput formControlName="primaryEmailIdContactPerson" readonly/>
                      <nx-icon nxFormfieldAppendix name="info-circle-o" [nxPopoverTriggerFor]="popoverEmail"
                        nxPopoverTrigger="hover">
                      </nx-icon>
    
                      <nx-error nxFormfieldError>
                        <div *ngIf="f.primaryEmailIdContactPerson.errors?.required"><strong>Please note:</strong>Email id is
                          required.</div>
                        <div *ngIf="f.primaryEmailIdContactPerson.errors?.pattern"><strong>Please note:</strong>Invalid email
                          format.</div>
                      </nx-error>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,6" >
                    <nx-formfield nxLabel="Secondary Email">
                      <input nxInput formControlName="contactPersonEmailId" />
                      <nx-error nxFormfieldError>
                        <div *ngIf="f.contactPersonEmailId.errors?.pattern"><strong>Please note:</strong>Invalid email format.
                        </div>
                      </nx-error>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,6"  >
                    <nx-formfield nxLabel="State">
                      <nx-dropdown formControlName="stateId" (selectionChange)="getStateWiseCityList($event.value)">
                        <nx-dropdown-item *ngFor="let item of stateList" [nxValue]="item.id">{{item.stateName}}
                        </nx-dropdown-item>
                      </nx-dropdown>
                      <nx-error nxFormfieldError>
                        <strong>Please note:</strong> this field is required!
                      </nx-error>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,6" >
                    <nx-formfield nxLabel="City">
                      <nx-dropdown formControlName="cityId">
                          <nx-dropdown-item *ngFor="let item of cityList" [nxValue]="item.id">
                              {{item.cityName}}
                          </nx-dropdown-item>
                      </nx-dropdown>
                      <nx-error nxFormfieldError>
                        <strong>Please note:</strong> this field is required!
                      </nx-error>
                  </nx-formfield>
                  </div>
                  <div nxCol="12,6"  >
                    <nx-formfield nxLabel="PIN Code">
                      <input nxInput formControlName="pincode" nxMask="000000" />
                      <nx-error nxFormfieldError>
                          <div *ngIf="f.pincode.errors?.required"><strong>Please note:</strong> This
                              field is
                              required!</div>
                          <div *ngIf="f.pincode.errors?.pattern"><strong>Please note:</strong> Invalid
                              PIN Code</div>
                      </nx-error>
                      <span nxFormfieldHint>
                          eg: 002215
                      </span>
                  </nx-formfield>
                  </div>
                  <div nxCol="12,6" ></div>
    
                  <!-- Bank Details -->
    
                  <div nxCol="12"  style="margin-bottom:1%;margin-top:2%">
                    <nx-link>
                      <a >
                        <nx-icon name='product-bank-monument-info'></nx-icon>
                        <h3 nxHeadline="section" class="nx-margin-bottom-0">Bank Details</h3>
                      </a>
                    </nx-link>
                  </div>
    
                  <div nxCol="12,6"  >
                    <nx-formfield nxLabel="Bank Account Number">
                      <input nxInput formControlName="bankAccountNo" />
                      <nx-error nxFormfieldError>
                        <div *ngIf="f.bankAccountNo.errors?.required"><strong>Please note:</strong>Enter account number.</div>
                        <div *ngIf="f.bankAccountNo.errors?.pattern"><strong>Please note:</strong>Accepts only numeric values.
                        </div>
                      </nx-error>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,6" >
                    <nx-formfield nxLabel="Bank Name">
                      <input nxInput formControlName="bankName" />
                      <nx-error nxFormfieldError>
                        <div *ngIf="f.bankName.errors?.required"><strong>Please note:</strong>Enter bank name.</div>
                      </nx-error>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,6"  >
                    <nx-formfield nxLabel="IFSC Code">
                      <input nxInput formControlName="ifscCode" />
                      <nx-error nxFormfieldError>
                        <div *ngIf="f.ifscCode.errors?.required"><strong>Please note:</strong>Enter IFSC code.</div>
                      </nx-error>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,6"  >
                    <nx-formfield nxLabel="Beneficiary Name">
                      <input nxInput formControlName="beneficiaryName" />
                      <nx-error nxFormfieldError>
                        <div *ngIf="f.beneficiaryName.errors?.required"><strong>Please note:</strong>Enter beneficiary name.</div>
                      </nx-error>
                    </nx-formfield>
                  </div>
                  <div nxCol="12,6"  >
                    <nx-formfield nxLabel="Bank Branch Address">
                      <textarea nxInput formControlName="bankBranchAddress"></textarea>
                      <nx-error nxFormfieldError>
                        <div *ngIf="f.bankBranchAddress.errors?.required"><strong>Please note:</strong>Enter branch address.</div>
                      </nx-error>
                    </nx-formfield>
                  </div>
                </div>
    
                <div nxRow>
                  <div nxCol="12,6"  >
                    <nx-label>Upload Cancel Cheque(*.pdf only)</nx-label>
                    <app-upload (onUploadFinished)="checkUploadFinished($event)" [acceptedfiles]="accpetedTypes"></app-upload>
                  </div>
                  <div nxCol="12,6" >
                    <nx-label>Upload GST Certificate(*.pdf only)</nx-label>
                    <app-upload [acceptedfiles]="accpetedTypes" (onUploadFinished)="gstUploadFinished($event)" ></app-upload>
                  </div>
                </div>
                <div nxRow style="margin-top: 2%;">
                    <div nxCol="12,6"   >
                        <nx-label>Upload Pancard(*.pdf only)</nx-label>
                        <app-upload (onUploadFinished)="panUploadFinished($event)" [acceptedfiles]="accpetedTypes"></app-upload>
                      </div>
                </div>
    
                <div nxRow style="margin-top: 2%;">
                  <div nxCol="12,12,12,6" >
                    <button nxButton="secondary large" type="reset" (click)="logout()"> 
                      <nx-icon size="s" name='back' class="nx-margin-right-s"></nx-icon>Exit
                    </button>
                  </div>
                  <div nxCol="12,12,12,6" >
                  <button nxButton="cta large" type="submit" [disabled]="!workShopForm.valid"> 
                    <nx-icon size="s" name="edit" class="nx-margin-right-s"></nx-icon> Update 
                  </button>
                </div>
                </div>
              </div>
            </form>
        </nx-card>
      </div>
    </div>
    </div>
    
    <nx-popover #popoverEmail>
      <div style="max-width: 200px;">
        This will be your user name for login.
      </div>
    </nx-popover>
    <nx-popover #popoverZone>
      <div style="max-width: 200px;">
        Zone Loaded based on ASM.
      </div>
    </nx-popover>