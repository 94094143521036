export class workshopData {
    workshopId: number;
    dealerCode: string;
    workshopCode: string;
    workshopName: string;
    workshopAddress: string;
    serviceTaxNumber: string;
    panNo: string;
    contactPerson: string;
    contactNumber: string;
    primaryEmailIdContactPerson: string;
    password: string;
    contactPersonEmailId: string;
    apsmName: string;
    rpsmName: string;
    region: string;
    bankAccountNo: string;
    bankName: string;
    ifscCode: string;
    beneficiaryName: string;
    bankBranchAddress: string;
    cancelChequeImage: string;
    panCardImage: string;
    createdBy: string;
    createdDate: Date;
    updatedBy: string;
    updatedDate: Date;
    isActive: number;
    gstCertificateImage: string;
    lastLoginDate: Date;
    attemptCount: number;
    firstlogin: number;
    alternateContactNumber: string;
    state: string;
    clientId: number;
    userType:number;
    superUserId:number;
    zoneId:number;
    cityId: number;
    stateId: string;
    gstNumber: string;
}
