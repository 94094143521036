import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
  selector: 'app-config-encryption',
  templateUrl: './config-encryption.component.html',
  styleUrls: ['./config-encryption.component.css']
})
export class ConfigEncryptionComponent implements OnInit {

  formGroup: FormGroup;

  result : string;
  Value : string;
  Key : string;

  constructor(private clipboard: Clipboard) {
    this.formGroup = new FormBuilder().group({
      value: ['', Validators.required],
      key: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  onEncryptClick() {
    this.result = null;

    this.Value = this.formGroup.get('value').value;
    this.Key = this.formGroup.get('key').value;

    this.result = CryptoJS.AES.encrypt(this.Value, this.Key.trim()).toString();
  }

  onDecryptClick() {
    this.result = null;

    this.Value = this.formGroup.get('value').value;
    this.Key = this.formGroup.get('key').value;

    this.result = CryptoJS.AES.decrypt(this.Value, this.Key.trim()).toString(CryptoJS.enc.Utf8);
  }

  copyInputMessage(){
    this.clipboard.copy(this.result);
  }

}
