import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { UserType } from "../core/userType.model";

@Component({
  selector: "app-page-routing-config",
  templateUrl: "./page-routing-config.component.html",
  styleUrls: ["./page-routing-config.component.css"],
})
export class PageRoutingConfigComponent implements OnInit {
  routeModalOpen: boolean = false;
  confirmModalOpen: boolean = false;
  addNewRoute: boolean = false;
  routeForm: FormGroup;
  _apiUrl: string;
  _userRole: string;
  _userId: number;
  routeList = [];
  isSuccess: boolean = false;
  EDIT_FLAG: boolean;
  routeId;
  routeDetails = {};
  userTypeList = [];
  clientList = [];
  clientId: number;
  userbaseTypeId: number;

  constructor(
    private authService: AuthService,
    private appConfig : AppConfig
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.routeFormBuilder();
    this.userbaseTypeId = parseInt(atob(localStorage.getItem("USER_BASE_TYPE_ID")));
  }

  ngOnInit() {
    this.getClientList();
  }

  routeFormBuilder() {
    this.routeForm = new FormBuilder().group({
      displayOrder: ["", Validators.required],
      displayName: ["", Validators.required],
      route: ["", Validators.required],
      userRole: [""],
      userId: this._userId,
      clientId: this.clientId,
      id: 0,
      isActive: 1,
    });
  }

  getRouteList() {
    var isWorkshopUser  = false;
    //check for workshopuser
    if(this.userbaseTypeId === UserType.WorkShop){
      isWorkshopUser = true;
    }
    let routeDetails = {
      clientId: this.clientId,
      userTypeId: this._userId,
      isWorkshopUser:isWorkshopUser
    }
    let encrypted=btoa(JSON.stringify(routeDetails));
    let first = encrypted.substring(0, 55);
    let last =  encrypted.substring(55);
    encrypted = first+'2'+last;

    this.authService
    .post(
      this._apiUrl + `/api/v1/RouteConfigs/GetRouteDetails`,encrypted.toString()
    ).subscribe(
        (response) => {
          if (response) {
            this.routeList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }


  toggleModal(EDIT: boolean, id) {
    this.EDIT_FLAG = EDIT;
    this.routeId = id;
    this.routeModalOpen = !this.routeModalOpen;
    this.isSuccess = false;
    if (this.EDIT_FLAG) {
      this.getRouteDetails(id);
    }
    if (!this.routeModalOpen) {
      this.routeFormBuilder();
    }
  }

  addRouteMaster() {
    this.routeForm.patchValue({
      clientId: this.clientId,
      userId: this._userId,
    });
    this.authService
      .post(this._apiUrl + "/api/v1/RouteConfigs", this.routeForm.value)
      .subscribe(
        (response) => {
          if (response) {
            this.getRouteList();
            this.isSuccess = true;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getRouteDetails(id) {
    this.authService.get(this._apiUrl + "/api/v1/RouteConfigs/" + id).subscribe(
      (response) => {
        if (response) {
          this.routeForm.setValue({
            displayOrder: response.displayOrder,
            displayName: response.displayName,
            route: response.route,
            userRole: response.userRole,
            userId: response.userId,
            isActive: response.isActive,
            clientId: response.clientId,
            id: response.id,
          });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
  updateRouteMaster() {
    this.authService
      .put(
        this._apiUrl + "/api/v1/RouteConfigs/" + this.routeId,
        this.routeForm.value
      )
      .subscribe(
        (response) => {
          this.getRouteList();
          this.isSuccess = true;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  confirmModalClose() {
    this.confirmModalOpen = false;
    this.getRouteList();
    this.routeFormBuilder();
  }

  confirmModal(routeDetails, isChecked: boolean) {
    this.confirmModalOpen = true;
    this.routeForm.setValue({
      id: routeDetails.id,
      displayOrder: routeDetails.displayOrder,
      displayName: routeDetails.displayName,
      route: routeDetails.route,
      userRole: routeDetails.userRole,
      userId: routeDetails.userId,
      isActive: isChecked ? 1 : 0,
      clientId: routeDetails.clientId,
    });
  }

  changeRouteStatus() {
    this.authService
      .put(
        this._apiUrl + "/api/v1/RouteConfigs/" + this.routeForm.controls.id.value,
        this.routeForm.value
      )
      .subscribe(
        (response) => {
          this.getRouteList();
          this.confirmModalClose();
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getClientList() {
    this.authService.get(this._apiUrl + "/api/v1/Client").subscribe(
      (response) => {
        if (response) {
          this.clientList = response;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  onClientChanges(clientid) {
    this.clientId = clientid;
    this.getUserTypeList();

    if (this._userId != undefined) {
      this.routeList = null;
      this.getRouteList();
    }
  }

  getUserTypeList() {
    this.authService
      .get(this._apiUrl + "/api/v1/UserTypes/client/"+ this.clientId)
      .subscribe(
        (response) => {
          if (response) {
            this.userTypeList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  onUserTypeIdChanges(id) {
    this._userId = id;
    //this._userRole = UserType[id];
    this.addNewRoute = true;
    this.getRouteList();
  }
}
