import { Component, OnInit,Inject} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { policyMaster } from './policyMaster.model'

@Component({
  selector: 'app-policymaster',
  templateUrl: './policymaster.component.html',
  styleUrls: ['./policymaster.component.css']
})
export class PolicymasterComponent implements OnInit {
  policyModalOpen: boolean = false;
  clientId : number;
  policyMasterForm: FormGroup;
  _apiUrl: string;
  planMasterList=[];
  policyMasterData :policyMaster = new policyMaster();
  isSuccess:boolean = false;

  constructor(private authService: AuthService, private appConfig : AppConfig) {

    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this._apiUrl = appConfig.baseUrl;
    this.policyMasterFormBuilder();
  }

  ngOnInit() {
    this.getPolicyMasterList();
  }

  policyMasterFormBuilder(){
    this.policyMasterForm = new FormBuilder().group({
      planName: [this.policyMasterData.planName, Validators.required],
      durationOfPlan: [this.policyMasterData.durationOfPlan, Validators.required],
      totalPrice: [this.policyMasterData.totalPrice, Validators.required],
      awpRiskPrice: [this.policyMasterData.awpRiskPrice, Validators.required],
      marketingFund: [this.policyMasterData.marketingFund, Validators.required],
      customerEngementFund: [this.policyMasterData.customerEngementFund, Validators.required],
      royality: [this.policyMasterData.royality, Validators.required]

    });
  }

  getPolicyMasterList(){
    this.authService.get(this._apiUrl + '/api/v1/Policies/client/' + this.clientId).subscribe(response => {
      if (response) {
        this.planMasterList = response;
        console.log('plan master list',response)
      }
    }, (error) => {
      console.error(error);
    });
  }
  toggleModal() {
    this.policyModalOpen = !this.policyModalOpen;
  }

}
