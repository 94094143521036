export class UserRights {
  add : boolean ;
  approveReject : boolean ;
  view : boolean ;
  edit : boolean ;
  delete : boolean ;
  acceptWallet : boolean ;
  rejectWallet : boolean ;
  acceptUser : boolean ;
  rejectUser : boolean ;
  isOfflinePayment : boolean ;
  isOnlinePayment : boolean ;
  isPlanManagae : boolean ;
  cancelPolicy : boolean ;
  editPolicy : boolean ;
  cancelPolicyApprove : boolean ;
  editPolicyApprove : boolean ;
  isEmailNotification : boolean ;
  isSMSNotification : boolean ;
}
