import { Component, OnInit, Inject } from "@angular/core";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from "src/config/app.config";
import { workshopListData } from "./workshopListData.model";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { SortEvent, SortDirection } from "@aposin/ng-aquila/table";
import { throwError } from "rxjs";
import { NxMessageToastService } from "@aposin/ng-aquila/message";
import { UserType } from "../core/userType.model";
import { MESSAGE_CONSTS } from "../ValidationMessages";
import { parse } from "querystring";
import { saveAs } from "file-saver";
import { CustomErrorHandler } from "../core/custom-error-handler";
import { RequestIntegrity } from "../core/request-integrety";

@Component({
  selector: "app-workshoplist",
  templateUrl: "./workshoplist.component.html",
  styleUrls: ["./workshoplist.component.css"],
})
export class WorkshoplistComponent implements OnInit {
  /**Private variables */
  _apiUrl: string;

  /**public variables */
  userTypeData = [
    {
      name: "Workshop",
      value: UserType.WorkShop,
    },
    {
      name: "Dealer",
      value: UserType.Dealer,
    },
  ];
  workshopList = [];
  workshopListData: workshopListData = new workshopListData();
  workshopId;
  workshopView: boolean = false;
  clientId: number;
  userId: number;
  workshopStatusForm = new FormGroup({
    statusReason: new FormControl(""),
  });
  userConfirmModal: boolean = false;
  workshopStatus: boolean;
  workshopStatusReason: string;
  isSuccess: boolean = false;
  errorMessage: string;
  userTypeId: number;
  asmUsers: any;
  zoneList: any;
  workShopForm: FormGroup;
  dealerView: boolean = false;
  asmchanges: boolean = false;
  userPermission: any;
  IsUpdate: boolean = false;
  stateList: any;
  message: string;
  IsRemoveWorkshop: boolean = false;
  searchuserType: any = " ";

  constructor(
    private authService: AuthService,
    private appConfig: AppConfig,
    private formBuilder: FormBuilder,
    private messageToastService: NxMessageToastService,
    private errorHandler: CustomErrorHandler,
    private requestIntegrity: RequestIntegrity
  ) {
    this._apiUrl = appConfig.baseUrl;
    //this.userName = localStorage.getItem('USER_NAME');
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
  }

  /** sorting starts here */

  sortTable(sort: SortEvent) {
    //Sorts the table data by a certain category.
    this.workshopList = this.workshopList.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection
  ) {
    return (a < b ? -1 : 1) * (direction === "asc" ? 1 : -1);
  }
  /** sorting ends here */

  /**Pagination Start */
  count: number;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  prevPage() {
    this.pageNumber--;
    this.getworkshopList();
  }

  nextPage() {
    this.pageNumber++;
    this.getworkshopList();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getworkshopList();
  }
  /**Pagination end */

  ngOnInit() {
    this.getworkshopList();
    this.getASMUsers();
    this.FormInit();
    this.setUserPermission();
    this.getStateDetails();
    this.onChanges();
  }

  FormInit() {
    this.workShopForm = new FormBuilder().group({
      workshopId: [this.workshopListData.workshopId],
      userType: [this.workshopListData.userType],
      dealerCode: new FormControl(this.workshopListData.dealerCode),
      workshopCode: new FormControl(this.workshopListData.workshopCode),
      workshopName: [this.workshopListData.workshopName],
      workshopAddress: [this.workshopListData.workshopAddress],
      serviceTaxNumber: [""],
      panNo: new FormControl(this.workshopListData.panNo,[Validators.required]),
      gstNumber: new FormControl(this.workshopListData.gstNumber,[Validators.required]),
      contactPerson: [this.workshopListData.contactPerson],
      contactNumber: new FormControl(this.workshopListData.contactNumber),
      primaryEmailIdContactPerson: new FormControl(
        this.workshopListData.primaryEmailIdContactPerson
      ),
      contactPersonEmailId: new FormControl(
        this.workshopListData.contactPersonEmailId
      ),
      bankAccountNo: new FormControl(this.workshopListData.bankAccountNo),
      bankName: [this.workshopListData.bankName],
      ifscCode: [this.workshopListData.ifscCode],
      beneficiaryName: [this.workshopListData.beneficiaryName],
      bankBranchAddress: [this.workshopListData.bankBranchAddress],
      isActive: [this.workshopListData.isActive],
      firstlogin: [this.workshopListData.firstlogin],
      zoneId: new FormControl(this.workshopListData.zoneId, [
        Validators.required,
      ]),
      clientId: this.clientId,
      superUserId: new FormControl(this.workshopListData.superUserId, [
        Validators.required,
      ]),
      statusReason: new FormControl(this.workshopListData.statusReason),
      stateId: new FormControl(this.workshopListData.stateId),
      cityId: new FormControl(this.workshopListData.cityId),
      CancelChequeImage: new FormControl(
        this.workshopListData.cancelChequeImage
      ),
      PanCardImage: new FormControl(this.workshopListData.panCardImage),
      GstCertificateImage: new FormControl(
        this.workshopListData.gstCertificateImage
      ),
      password: new FormControl(this.workshopListData.password),
      minWalletBalance: new FormControl(this.workshopListData.minWalletBalance),
      pincode: new FormControl(this.workshopListData.pincode),
      identifier: new FormControl(this.workshopListData.identifier),
    });
  }

  get f() {
    return this.workShopForm.controls;
  }

  setUserPermission() {
    this.authService.userPermissions().subscribe((response) => {
      if (response) {
        this.userPermission = response;
      }
      // console.log(this.userPermission);
    });
  }
  /**
   * listing all the workshop list
   */
  getworkshopList() {
    this.authService
      .getList(
        this._apiUrl +
          "/api/v1/Workshops" +
          "?pageNumber=" +
          this.pageNumber +
          "&pageSize=" +
          this.pageSize +
          "&searchText=" +
          this.searchTerm +
          "&udf1=" +
          this.searchuserType
      )
      .subscribe(
        (response) => {
          if (response) {
            this.workshopList = response.body;
            if (this.searchuserType != null) {
              this.workshopList = this.workshopList.filter(
                (x) => x.userType == this.searchuserType
              );
              var pagingProp = JSON.parse(response.headers.get("X-Pagination"));
              this.count = pagingProp.TotalCount;
              //this.count = this.workshopList.length;
            } else {
              this.workshopList = [];
            }
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  /**
   * To get worshoplist by id
   * @param id
   */
  getworkshopListByid(id) {
    this.authService
      .get(this._apiUrl + "/api/v1/Workshops/GetworkshopListByid/" + id)
      .subscribe(
        (response) => {
          if (response) {
            this.workshopListData = response;
            //View Protected Data
            this.workshopListData.panNo =
              this.requestIntegrity.decryptUsingAES256(
                this.workshopListData.panNo
              );
            this.workshopListData.statusReason = response.approveRejectReason;

            this.FormInit();
            this.onsuperUserIdChanges(this.workshopListData.superUserId);
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  //get all ASM/BD users for dealer
  getASMUsers() {
    this.authService
      .get(
        this._apiUrl +
          `/api/v1/Users/UserBaseType/${UserType.BD}/client/${this.clientId}`
      )
      .subscribe(
        (response) => {
          if (response) {
            this.asmUsers = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  toggleModal(
    workshopId: number,
    userTypeId: number = 0,
    action: string,
    identifier: string
  ) {
    if (identifier) {
      this.isSuccess = false;
      this.getworkshopListByid(identifier);
    }
    if (userTypeId == UserType.WorkShop) this.workshopView = !this.workshopView;
    else if (userTypeId == UserType.Dealer) this.dealerView = !this.dealerView;

    if (action == "edit") {
      this.IsUpdate = true;
    } else if (action == "confirm") {
      this.IsUpdate = false;
      this.workShopForm
        .get("statusReason")
        .setValidators([Validators.required]);
      this.workShopForm.updateValueAndValidity();
      this.checkWorkshopRemovePermission(workshopId);
    } else {
      this.getworkshopList();
    }
  }

  updateWorkshopstatus(workshopId: number, status: number, Remove: boolean) {
    let workshopstatusUpdate = {
      modifiedByUserId: this.userId,
      userId: workshopId,
      status: status,
      approveRejectMessage: this.workShopForm.value.statusReason,
      updatedDate: new Date(),
      remove: Remove,
    };
    if (this.workShopForm.valid) {
      this.authService
        .post(
          this._apiUrl + "/api/v1/UserStatusLog/WorkshopUserStatus",
          workshopstatusUpdate
        )
        .subscribe(
          (response) => {
            if (response.success) {
              this.isSuccess = true;
              this.FormInit();
              if (status == 1) this.message = "Approval successfull !!";
              else if (status == 0) this.message = "Rejection successfull !!";
              else if (status == 2) this.message = "Removed successfully !!";
              this.getworkshopList();
            } else {
              this.errorHandler.handleCustomError(response.responsemsg);
            }
          },
          (error) => {
            this.errorHandler.handleError(error);
          }
        );
    } else {
      console.warn("form not valid");
    }
  }

  updateWorkshop() {
    this.workShopForm
      .get("minWalletBalance")
      .setValue(parseFloat(this.workShopForm.value.minWalletBalance));
    this.workShopForm.updateValueAndValidity();
    if (this.workShopForm.valid) {
      this.authService
        .put(
          this._apiUrl +
            `/api/v1/Workshops/${this.workShopForm.value.identifier}`,
          this.workShopForm.value
        )
        .subscribe(
          (response) => {
            this.isSuccess = true;
            this.FormInit();
            this.message = "Details updated successfull";
            this.getworkshopList();
          },
          (error) => {
            this.errorHandler.handleCustomError(
              error.error.responsemsg + "  Try again after some time!!"
            );
            this.dealerView = !this.dealerView;
            this.getworkshopList();
          }
        );
    } else {
      console.warn("form not valid");
      return;
    }
  }

  onsuperUserIdChanges(id: any) {
    this.authService
      .get(this._apiUrl + `/api/v1/Zones/GetZoneByUserId/${id}`)
      .subscribe(
        (response) => {
          if (response) {
            this.zoneList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  onChanges(): void {
    this.workShopForm.get("superUserId").valueChanges.subscribe((val) => {
      this.asmchanges = true;
      this.workShopForm.setErrors({ invalid: true });
      this.workShopForm.updateValueAndValidity();
    });
    this.workShopForm.get("zoneId").valueChanges.subscribe((val) => {
      this.asmchanges = true;
    });
  }

  getStateDetails() {
    this.authService
      .get(
        this._apiUrl + "/api/v1/States/GetActiveStates/client/" + this.clientId
      )
      .subscribe(
        (response) => {
          if (response) {
            this.stateList = response;
          }
        },
        (error) => {}
      );
  }

  //If existing policues against a workshop then won't able to remove workshop
  checkWorkshopRemovePermission(workshopId: number) {
    var status: boolean = false;
    this.authService
      .get(this._apiUrl + "/api/v1/Workshops/isRemoveWorkshop/" + workshopId)
      .subscribe(
        (response) => {
          this.IsRemoveWorkshop = response.success;
        },
        (error) => {
          this.IsRemoveWorkshop = false;
        }
      );
  }

  //Approve button incase of user status is not already active
  get IsApproveButton(): boolean {
    if (this.workshopListData.isActive != 1) {
      return true;
    }
    return false;
  }

  get IsRemoveButton(): boolean {
    if (this.userPermission.delete && this.IsRemoveWorkshop) {
      return true;
    }
    return false;
  }

  get IsRejectButton(): boolean {
    if (this.userPermission.rejectUser && this.workshopListData.isActive != 0) {
      return true;
    }
    return false;
  }

  download(path: string) {
    this.authService
      .export(this._apiUrl + `/api/v1/common/stream?filePath=${path}`)
      .subscribe(
        (response) => {
          if (response.size > 0) {
            var filename = path.split(/_(.+)/)[1];
            saveAs(response, filename);
          } else {
            this.errorHandler.handleCustomError(
              "File not found,Contact admin!!"
            );
          }
        },
        (error) => {
          this.errorHandler.handleCustomError(error);
        }
      );
  }
}
