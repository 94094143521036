export class workshopListData {
  workshopId: number;
  dealerCode: string;
  workshopCode: string;
  workshopName: string;
  workshopAddress: string;
  serviceTaxNumber: string;
  panNo: string;
  contactPerson: string;
  contactNumber: string;
  primaryEmailIdContactPerson: string;
  password: string;
  contactPersonEmailId: string;
  apsmName: string;
  rpsmName: string;
  region: string;
  bankAccountNo: string;
  bankName: string;
  ifscCode: string;
  beneficiaryName: string;
  bankBranchAddress: string;
  cancelChequeImage: string;
  panCardImage: string;
  createdBy: string;
  createdDate: Date;
  updatedBy: string;
  updatedDate: Date;
  isActive: number;
  gstCertificateImage: string;
  lastLoginDate: Date;
  attemptCount: number;
  firstlogin: number;
  alternateContactNumber: string;
  state: string;
  clientId: number;
  superUserId: number;
  userType: number;
  zoneId: number;
  statusReason: string;
  gstNumber: string;
  stateId: number;
  minWalletBalance: number;
  cityId: number;
  pincode: string;
  identifier: string;
}
