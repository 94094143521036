export class walletListData {
    id: number;
    workshopId: number;
    requestedAmount: number;
    grantedAmt: number;
    paymentMethod: string;
    requestDate: Date;
    paymentMethodDetails: string;
    uploadedDoc: string;
    remark: string;
    approvedby: string;
    approvedRemarks: string;
    approvedDate: Date;
    paymentDate: Date;
    status: number;
    dealerCode: string;
    dealerName: string;
    clientId: number;
}