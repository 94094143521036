<app-header></app-header>
<app-menu></app-menu>

<div nxLayout="grid">
    <div nxRow style="padding-top: 4%;">
        <div nxCol="5" nxColOffset="1" >
            <div nxRow>
                <div class="container">
                    <div class="tile wide invoice" (click)="GetData('Completed');">
                        <div class="header">
                        <div class="left">
                            <div class="count"><nx-icon name="product-paper-document" size="l"></nx-icon></div>
                        </div>
                        <div class="right">
                            <div class="count">{{completed}}</div>
                            <div class="title">Invoices</div>
                        </div>
                        </div>
                        <div class="body">
                        <div class="title">E - Invoices Are Completed</div>
                        </div>
                    </div>
                </div>
            </div>
            <div nxRow>
                <div class="container">
                    <div class="tile wide resource" (click)="GetData('Pending');">
                        <div class="header">
                        <div class="left">
                            <div class="count"><nx-icon name="product-paper-document" size="l"></nx-icon></div>
                        </div>
                        <div class="right">
                            <div class="count">{{pending}}</div>
                            <div class="title">Invoices</div>
                        </div>
                        </div>
                        <div class="body">
                        <div class="title">E - Invoices Are Pending</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div nxCol="6">
            <nx-card>
                <h2 nxHeadline="subsection-small">E - INVOICES</h2>
                <canvas id="canvas" width="800" height="400" ></canvas>
                
                <nx-radio-toggle [nxStyle]="'small'" name="chartType" [(ngModel)]="chartType" (click)="changeChartType('BTD')">
                    <nx-radio-toggle-button nxValue="1" [nxSelected]='true'>
                      <nx-icon name="product-stock-data-graph"></nx-icon>
                    </nx-radio-toggle-button>
                    <nx-radio-toggle-button nxValue="2">
                      <nx-icon name="product-share-graph-arrow-up"></nx-icon>
                    </nx-radio-toggle-button>
                    <nx-radio-toggle-button nxValue="3">
                        <nx-icon name="in-progress"></nx-icon>
                    </nx-radio-toggle-button>
                  </nx-radio-toggle>
            </nx-card>
        </div>

    </div>
</div>


<app-footer></app-footer>


