<nx-selectable-card appearance="expert">
    <nx-card-header>
        <app-title
        [desktopTitle]="'Download'"
        [padding]="'30px 0px 5px 0px'"
      ></app-title>
      <hr />
    </nx-card-header>
  
    <div nxLayout="grid" class="docs-grid-colored-grid card-content">
        <div nxRow nxRowAlignItems="center" nxRowJustify="start,start,around">
            <div nxCol="12" class="download-bg">
            </div>
            <div nxCol="12" style="text-align: center;" class="nx-margin-top-xs">
                <nx-label>Your Policy created successfully.</nx-label>
            </div>
            <div nxCol="12" style="text-align: center;" class="nx-margin-top-xs">
                <button nxButton="tertiary" type="button" [routerLink]="['/sellpolicy/vehicledetails']">
                    <nx-icon name="product-house" class="nx-margin-right-s"></nx-icon>Go Home
                </button>
            </div>
            <div nxCol="12" style="text-align: center;" class="nx-margin-top-xs">
                <!-- <button nxButton="primary" type="button" (click)="viewPolicy()">
                    <nx-icon name="password-show-o" class="nx-margin-right-s"></nx-icon>View Policy
                </button> -->

                <button nxButton="primary" type="button" (click)="downloadPolicy()">
                    <nx-icon name="pdf" class="nx-margin-right-s"></nx-icon>Download Policy
                </button>
            </div>
        </div>
    </div>
  
  
    <nx-card-footer>
        <nx-link>
          <a >
            <nx-icon name='product-pages-documents'></nx-icon>
            Step 5/5 <span style="color: green;"> &nbsp; Policy Certificate</span> 
          </a>
        </nx-link>
    </nx-card-footer>
</nx-selectable-card>

