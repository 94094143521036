import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ColormanagementComponent } from "../colormanagement/colormanagement.component";
import { ModelmanagementComponent } from "../modelmanagement/modelmanagement.component";
import { PlanComponent } from "../plan/plan.component";
import { UserlistComponent } from "../userlist/userlist.component";
import { MasterComponent } from "../master/master.component";
import { ZonemanagementComponent } from "../zonemanagement/zonemanagement.component";
import { AuthGuardService as AuthGuard } from "../core/auth/auth-guard.service";
import { RoleGuardService as RoleGuard } from "../core/auth/role-guard.service";
import { ManageContentComponent } from "../manage-content/manage-content.component";
import { CitymasterComponent } from '../citymaster/citymaster.component';
import { StatemasterComponent } from '../statemaster/statemaster.component';
import { CesstaxmasterComponent } from '../cesstaxmaster/cesstaxmaster.component';
import { PortaltransactionoverviewComponent } from '../portaltransactionoverview/portaltransactionoverview.component';


const routes: Routes = [
  {
    path: "manageusers",
    component: UserlistComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "masters",
    component: MasterComponent,
    children: [
      { path: "", redirectTo: "manageplans", pathMatch: "full" },
      {
        path: "manageplans",
        component: PlanComponent,
      },
      {
        path: "managecolors",
        component: ColormanagementComponent,
      },
      {
        path: "managemodels",
        component: ModelmanagementComponent,
      },
      {
        path: "managezone",
        component: ZonemanagementComponent,
      },
      {
        path: "managestate",
        component: StatemasterComponent,
      },
      {
        path: "managecity",
        component: CitymasterComponent,
      },
      {
        path: "managecessandtax",
        component: CesstaxmasterComponent,
      },
    ],
    canActivate: [RoleGuard],
  },
  {
    path: "manage-content",
    component: ManageContentComponent,
    canActivate: [RoleGuard],
  },
  {
    path: "transaction-overview",
    component: PortaltransactionoverviewComponent,
    canActivate: [RoleGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
