<nx-selectable-card appearance="expert">
  <!-- <nx-card-header>
    <nx-small-stage class="nx-margin-bottom-m" appearance="expert">
      <nx-small-stage-image nxSmallStageImageEnd src="/assets/illustrations/man_besides_broken_down_car_with_safety_west_and_warning_triangle.png"></nx-small-stage-image>
      <h2 nxHeadline="subsection" class="nx-font-weight-regular">
        Vehicle Details
      </h2>
    </nx-small-stage> -->
  <nx-card-header>
    <app-title
      [desktopTitle]="'Vehicle Details'"
      [padding]="'30px 0px 5px 0px'"
    ></app-title>
    <hr />
  </nx-card-header>

  <div nxLayout="grid">
    <form [formGroup]="vehicleDetailForm">
      <div nxRow nxRowAlignItems="center,center,center">
        <div nxCol="12,12,4,2">
          <figure nxFigure="1dot8by1">
            <img
              src="/assets/illustrations/vehicle-details.png"
              alt="Image Not Found"
            />
          </figure>
        </div>
        <div nxCol="12,12,8">
          <div nxLayout="grid">
            <div
              nxRow
              nxRowJustify="start,start,around"
              nxRowAlignContent="end,end,around,stretch"
            >
              <div nxCol="12,12,6">
                <nx-formfield nxLabel="VIN(Chasis Number)">
                  <nx-formfield-label class="required"
                    >VIN(Chasis Number)</nx-formfield-label
                  >
                  <input
                    nxInput
                    formControlName="vinChassisNo"
                    nxMask="AAAAAAAAAAAAAAAAA"
                    nxConvertTo="upper"
                    (focusout)="Vinchanged($event)"
                  />
                  <nx-error nxFormfieldError>
                    <div
                      *ngIf="
                        VehicleDetailFormControls.vinChassisNo.errors?.pattern
                      "
                    >
                      <strong>Please note:</strong> Enter a 17 character
                      VIN(Chasis Number).
                    </div>
                    <div
                      *ngIf="
                        VehicleDetailFormControls.vinChassisNo.errors?.required
                      "
                    >
                      <strong>Please note:</strong> This field is required!
                    </div>
                  </nx-error>
                  <span nxFormfieldHint>
                    HINT : VIN(Chasis Number) in 17 characters
                  </span>
                </nx-formfield>
              </div>
              <div nxCol="12,12,6">
                <nx-formfield nxLabel="Registration Number">
                  <input
                    #licensePlateNo
                    nxInput
                    formControlName="licensePlateNo"
                    (keyup)="
                      licensePlateNo.value = licensePlateNo.value.toUpperCase()
                    "
                    (focusout)="
                      licensePlateNo.value = licensePlateNo.value.toUpperCase()
                    "
                  />
                  <nx-error nxFormfieldError>
                    <div
                      *ngIf="
                        VehicleDetailFormControls.licensePlateNo.errors?.pattern
                      "
                    >
                      <strong>Please note:</strong> Enter a valid Registration
                      Number
                    </div>
                  </nx-error>
                  <span nxFormfieldHint> eg: KL07CR0007 </span>
                </nx-formfield>
              </div>
            </div>

            <div
              nxRow
              nxRowJustify="start,start,around"
              nxRowAlignContent="end,end,around,stretch"
            >
              <div nxCol="12,12,6">
                <nx-formfield nxLabel="Select Model">
                  <nx-formfield-label class="required"
                    >Select Model</nx-formfield-label
                  >
                  <nx-dropdown
                    #model
                    formControlName="model"
                    (selectionChange)="onModelSelected(model.value)"
                  >
                    <nx-dropdown-item
                      *ngFor="let item of modelList | isActiveCheck"
                      [nxValue]="item.modelName"
                    >
                      {{ item.modelName }}
                    </nx-dropdown-item>
                  </nx-dropdown>
                  <nx-error nxFormfieldError>
                    <div
                      *ngIf="VehicleDetailFormControls.model.errors?.required"
                    >
                      <strong>Please note:</strong> This field is required!
                    </div>
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="12,12,6">
                <nx-formfield nxLabel="Select Color">
                  <nx-formfield-label class="required"
                    >Select Color</nx-formfield-label
                  >
                  <nx-dropdown formControlName="colorName">
                    <nx-dropdown-item
                      *ngFor="let item of colorList | isActiveCheck"
                      [nxValue]="item.colorName"
                    >
                      {{ item.colorName }}
                    </nx-dropdown-item>
                  </nx-dropdown>
                  <nx-error nxFormfieldError>
                    <div
                      *ngIf="
                        VehicleDetailFormControls.colorName.errors?.required
                      "
                    >
                      <strong>Please note:</strong> This field is required!
                    </div>
                  </nx-error>
                </nx-formfield>
              </div>
            </div>

            <div
              nxRow
              nxRowJustify="start,start,around"
              nxRowAlignContent="end,end,around,stretch"
            >
              <div nxCol="12,12,6">
                <nx-formfield nxLabel="Vehicle Purchase Date">
                  <nx-formfield-label class="required"
                    >Vehicle Purchase Date</nx-formfield-label
                  >
                  <input
                    nxDatefield
                    nxInput
                    [nxDatepicker]="startDatepicker"
                    formControlName="vehicleSaleDate"
                    (dateChange)="onVehicleSaleDateChange()"
                    [nxMin]="maxYearsEligible"
                    [nxMax]="maxDate"
                  />
                  <nx-datepicker-toggle
                    [for]="startDatepicker"
                    nxFormfieldSuffix
                  >
                  </nx-datepicker-toggle>
                  <nx-datepicker #startDatepicker></nx-datepicker>
                  <nx-error
                    nxFormfieldError
                    *ngIf="purchaseDate.errors?.nxDatefieldMin"
                  >
                    Can't sell policy to vehicle older than {{ maxDate }} years!
                  </nx-error>
                  <nx-error
                    nxFormfieldError
                    *ngIf="
                      VehicleDetailFormControls.vehicleSaleDate.errors?.required
                    "
                  >
                    <strong>Please note:</strong> This field is required!
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="12,12,6">
                <nx-formfield nxLabel="Age of Vehicle (Years)">
                  <input nxInput formControlName="ageOfVehicle" readonly />
                </nx-formfield>
              </div>
            </div>

            <div
              nxRow
              nxRowJustify="start,start,around"
              nxRowAlignContent="end,end,around,stretch"
            >
              <div nxCol="12,12,6">
                <nx-formfield-label class="required"
                  >Select Brand</nx-formfield-label
                >
                <nx-formfield>
                  <nx-dropdown formControlName="brand">
                    <nx-dropdown-item [nxValue]="clientName"></nx-dropdown-item>
                  </nx-dropdown>
                  <nx-error
                    nxFormfieldError
                    *ngIf="VehicleDetailFormControls.brand.errors?.required"
                  >
                    <strong>Please note:</strong> This field is required!
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="12,12,6">
                <nx-formfield nxLabel="Policy Sale/Invoice Date">
                  <input
                    nxDatefield
                    nxInput
                    [nxDatepicker]="invoiceDatepicker"
                    formControlName="invoiceDate"
                    [nxMax]="maxDate"
                  />
                  <nx-datepicker-toggle
                    [for]="invoiceDatepicker"
                    nxFormfieldSuffix
                  >
                  </nx-datepicker-toggle>
                  <nx-datepicker #invoiceDatepicker></nx-datepicker>
                </nx-formfield>
              </div>
            </div>

            <div
              nxRow
              nxRowJustify="start,start,around"
              nxRowAlignContent="end,end,around,stretch"
            >
              <div nxCol="12,12,6">
                <nx-formfield nxLabel="Variant">
                  <input nxInput formControlName="variant" />
                  <nx-error nxFormfieldError>
                    <div
                      *ngIf="VehicleDetailFormControls.variant.errors?.pattern"
                    >
                      <strong>Please note:</strong> Enter Valid details
                      <br />Allowed charcters are -_,/.&lt;space&gt; and Alpha
                      numerics.
                    </div>
                  </nx-error>
                </nx-formfield>
              </div>
              <div nxCol="12,12,6"></div>
              <div nxCol="12,12,6" hidden>
                <nx-formfield nxLabel="Other Details">
                  <textarea nxInput formControlName="otherDetails"></textarea>
                </nx-formfield>
              </div>
            </div>
          </div>
        </div>
        <div nxCol="12,12,4,2" style="text-align: -webkit-center">
          <button
            class="navigation"
            nxIconButton="tertiary"
            aria-label="next page"
            type="button"
            [disabled]="vehicleDetailForm.invalid"
            (click)="saveVehicleDetails()"
          >
            <ul class="iconlist iconlist__item">
              <nx-icon size="l" name="chevron-right-circle-o"></nx-icon
              ><span style="font-size: small">Next</span>
            </ul>
          </button>
        </div>
      </div>
    </form>
  </div>

  <nx-card-footer>
    <nx-link>
      <a>
        <nx-icon name="play"></nx-icon>
        Step 1/5
      </a>
    </nx-link>
  </nx-card-footer>
</nx-selectable-card>

<ng-template #policySearchModal>
  <div class="centered-content">
    <!-- Extend Policy -->
    <ng-container *ngIf="isExtend; else cancelledVehicle">
      <div nxRow nxRowJustify="center">
        <div nxCol="4" class="policyExtend"></div>
        <h3
          nxHeadline="subsection-medium"
          *ngIf="isExtend"
          class="nx-font-weight-semibold"
        >
          '{{ vinNumber }}' is covered till
          {{ expiryDateofExistingPolicy | date: "MM/dd/yyyy" }}
        </h3>
      </div>
      <div nxRow nxRowJustify="center">
        <button
          nxButton="primary"
          class="nx-margin-left-5xl"
          *ngIf="isExtend"
          (click)="extendPolicy()"
        >
          <nx-icon name="plus-circle-o" class="nx-margin-right-2xs"></nx-icon>
          Extend Policy
        </button>
        <button
          nxButton="emphasis"
          class="nx-margin-left-5xl"
          *ngIf="isExtend"
          (click)="closeModal()"
        >
          Close
        </button>
      </div>
    </ng-container>
    <!-- modal for cancelled vin -->
    <ng-template #cancelledVehicle>
      <ng-container
        *ngIf="isSellCancelledPolicyAgain; else cancellinProgressVehicle"
      >
        <div nxRow nxRowJustify="center">
          <nx-icon
            name="exclamation-triangle"
            style="color: orangered"
            outline="true"
            size="l"
          ></nx-icon>
          <h3 nxHeadline="subsection-medium" class="nx-font-weight-semibold">
            '{{ vinNumber }}' is cancelled on
            {{ lastavailDate | date: "MM/dd/yyyy" }}
          </h3>
        </div>
        <div nxRow nxRowJustify="center">
          <div nxCol="12">
            <nx-label style="text-align: center"
              >Do you want to sell policy again?</nx-label
            >
          </div>
        </div>
        <div
          nxRow
          nxRowJustify="center"
          style="text-align: center"
          class="nx-margin-top-2xs"
        >
          <div nxCol="12">
            <button nxButton="primary" (click)="extendPolicy()">Yes</button>
            <button
              nxButton="emphasis"
              class="nx-margin-top-2s"
              (click)="closeModal()"
            >
              No
            </button>
          </div>
        </div>
      </ng-container>
    </ng-template>
    <!-- modal for cancellation in process -->
    <ng-template #cancellinProgressVehicle>
      <ng-container *ngIf="CancellationApprovalPending; else AgedVehicle">
        <div nxRow nxRowJustify="center">
          <nx-icon
            name="exclamation-triangle"
            style="color: orangered"
            outline="true"
            size="l"
          ></nx-icon>
          <h3
            nxHeadline="subsection-medium"
            class="nx-font-weight-semibold nx-margin-left-xl"
          >
            '{{ vinNumber }}' is requested cancellation
          </h3>
        </div>
        <button
          nxButton="secondary block"
          class="nx-margin-top-m"
          (click)="closeModal()"
        >
          Close
        </button>
      </ng-container>
    </ng-template>
    <!-- Vehicle Age Exceeds -->
    <ng-template #AgedVehicle>
      <ng-container *ngIf="isAged; else maxallowedinsured">
        <div nxRow nxRowJustify="center">
          <nx-icon
            name="exclamation-triangle"
            style="color: orangered"
            outline="true"
            size="l"
          ></nx-icon>
          <h3
            nxHeadline="subsection-medium"
            class="nx-font-weight-semibold"
            class="nx-margin-left-xl"
          >
            Vehicle Aged More than {{ maxvehage }} years, You cannot extend
            Policy!!
          </h3>
        </div>
        <button
          nxButton="secondary block"
          class="nx-margin-top-m"
          (click)="closeModal()"
        >
          Close
        </button>
      </ng-container>
    </ng-template>
    <!-- maximum insurance availed -->
    <ng-template #maxallowedinsured>
      <ng-container *ngIf="maxInsured; else unitySearch">
        <div nxRow nxRowJustify="center">
          <nx-icon
            name="exclamation-triangle-o"
            style="color: orangered"
            outline="true"
            size="l"
          ></nx-icon>
          <h3
            nxHeadline="subsection-medium"
            class="nx-font-weight-semibold"
            class="nx-margin-left-xl"
          >
            This Vehicle Insured for maximum allowed years!
          </h3>
        </div>
        <button
          nxButton="secondary block"
          class="nx-margin-top-m"
          (click)="closeModal()"
        >
          Close
        </button>
      </ng-container>
    </ng-template>
    <!-- Unity Search -->
    <ng-template #unitySearch>
      <ng-container *ngIf="isUnitySearch">
        <div nxRow nxRowJustify="center">
          <div nxCol="12,12,5" class="policySearch"></div>
        </div>
        <button
          (click)="searchUnity()"
          class="nx-margin-left-2xl"
          nxButton="primary"
        >
          <nx-icon name="search" class="nx-margin-right-2xs"></nx-icon> Click to
          auto validate
        </button>

        <button
          nxButton="emphasis"
          class="nx-margin-left-5xl"
          (click)="closeModal()"
        >
          Close
        </button>
      </ng-container>
    </ng-template>
  </div>
</ng-template>
