<nx-selectable-card appearance="expert">
    <nx-card-header>
        <app-title
        [desktopTitle]="'Plan Details'"
        [padding]="'30px 0px 5px 0px'"
      ></app-title>
      <hr />
    </nx-card-header>

    <div nxLayout="grid" class="docs-grid-colored-grid card-content">
        <form [formGroup]="planDetailForm">
            <div nxRow nxRowAlignItems="center,center,center,center">
                <div nxCol="12,12,4,2" >
                    <figure nxFigure="1by1">
                    <img src="/assets/illustrations/illustration_service_plan.png" alt="Image Not Found"/>
                    </figure>
                </div>

                <div nxCol="12,12,4,2" style="text-align: -webkit-center;">
                    <button class="navigation" nxIconButton="tertiary" aria-label="prev page" type="button" (click)="PrevPage()" [routerLink]="['/sellpolicy/customerdetails']">
                        <ul class="iconlist iconlist__item ">
                            <nx-icon size="l" name="chevron-left-circle-o" ></nx-icon><span style="font-size: small;">Prev</span>
                        </ul>
                    </button>
                </div>

                <div  nxCol="12,12,6">
                    <div nxRow nxRowJustify="start,start,around" nxRowAlignContent="center,center">
                        <div nxCol="12,12,6">
                            <nx-formfield nxLabel="Plan Type">
                                <nx-formfield-label class="required">Plan Type</nx-formfield-label>
                                <nx-dropdown formControlName="planId" (selectionChange)="onPlanChanges($event.value)">
                                    <nx-dropdown-item *ngFor="let item of planMasterList | isActiveCheck"
                                        [nxValue]="item.planId">{{item.planName}}</nx-dropdown-item>
                                </nx-dropdown>
                                <nx-error nxFormfieldError>
                                    Please select an option.
                                </nx-error>
                            </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                            <nx-formfield nxLabel="Policy Term (Years)">
                                <input nxInput value="{{PolicyTerm}}" readonly />
                            </nx-formfield>                        
                        </div>
                    </div>

                    <div nxRow nxRowJustify="start,start,around" nxRowAlignContent="center,center">
                        <div nxCol="12,12,6">
                            <nx-formfield nxLabel="Effective Date">
                                <input nxInput value="{{effectiveDate |  date:'MM/dd/yyyy'}}" readonly />
                            </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                            <nx-formfield nxLabel="Expiry Date">
                                <input nxInput value="{{expiryDate |  date:'MM/dd/yyyy'}}" readonly />
                            </nx-formfield>                        
                        </div>
                    </div>

                    <div nxRow nxRowJustify="start,start,around" nxRowAlignContent="center,center">
                        <div nxCol="12,12,6">
                            <nx-formfield nxLabel="Price">
                                <input nxInput formControlName="price" readonly />
                            </nx-formfield>
                        </div>
                        <div nxCol="12,12,6">
                            <nx-formfield nxLabel="GST Price">
                                <input nxInput formControlName="gstPrice" readonly />
                            </nx-formfield>
                            
                        </div>
                    </div>
                    <div nxRow nxRowJustify="start,start,around" nxRowAlignContent="center,start" *ngIf="IsCess">
                        <div nxCol="12,12,6">
                            <h3 nxHeadline="subsection-xsmall" style="color: green; margin-bottom: 0px !important;">Applicable Cess/Tax(es)</h3>
                        </div>
                        <div nxCol="12,12,6" *ngFor="let item of cessList">
                            <nx-label size="small">{{item.cessName}} : {{item.cessPercentage}} %</nx-label>
                        </div>
                    </div>                    
                </div>

                <div nxCol="12,12,4,2" style="text-align: -webkit-center;">
                    <button class="navigation" nxIconButton="tertiary" aria-label="next page" type="button" [disabled]="planDetailForm.invalid" (click)="savePlanDetails()">
                        <ul class="iconlist iconlist__item">
                            <nx-icon size="l" name="chevron-right-circle-o"></nx-icon><span style="font-size: small;">Next</span>
                        </ul>
                    </button>
                </div>
            </div>
        </form>
    </div>


    <nx-card-footer>
        <nx-link>
          <a >
            <nx-icon name='play'></nx-icon>
            Step 3/5
          </a>
        </nx-link>
    </nx-card-footer>
</nx-selectable-card>
