import { Component, OnInit, Input, Inject } from '@angular/core';
import * as moment from 'moment';
import { AuthService } from '../core/auth/auth.service';
import { SortDirection, SortEvent } from '@aposin/ng-aquila/table';
import { AppConfig } from '../../config/app.config';


@Component({
  selector: 'app-salesreport-weekly',
  templateUrl: './salesreport-weekly.component.html',
  styleUrls: ['./salesreport-weekly.component.css']
})
export class SalesreportWeeklyComponent {
  @Input() policyStartDate: moment.Moment;
  @Input() policyEndDate: moment.Moment;

  fileName: string;
  ReportData: any;
  exportdata: any = [];
  _apiUrl: string;
  clientId: number;
  userTypeId: number;
  userBaseTypeId: number;
  userId: number;

  /** sorting starts here */

  sortTable(sort: SortEvent) {
    this.ReportData = this.ReportData.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection) {

    return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
  }
  /** sorting ends here */
  /**Pagination Start */
  count: number = 0;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  prevPage() {
    this.pageNumber--;
    this.getReportData();
  }

  nextPage() {
    this.pageNumber++;
    this.getReportData();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getReportData();
  }
  /**Pagination end */

  constructor(private authService: AuthService,
    private appConfig : AppConfig,) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.userBaseTypeId = parseInt(atob(localStorage.getItem("USER_BASE_TYPE_ID")))
    this.userTypeId = parseInt(atob(localStorage.getItem("USER_TYPE_ID")))
  }


  loadReport() {
    this.getReportData()
  }

  getReportData() {
    var startdate = this.policyStartDate == undefined ? "" : this.policyStartDate.format('LL');
    var enddate = this.policyEndDate == undefined ? "" : this.policyEndDate.format('LL');

    this.authService.getList(this._apiUrl + `/api/v1/Report/GetSalesWeekReportWithPaging?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}&searchText=${this.searchTerm}&startDatepicker=${startdate}&endDatepicker=${enddate}`)
    .subscribe(response => {
      if (response) {
        this.ReportData = response.body;
        let pagingProp = JSON.parse(response.headers.get('X-Pagination'));
        this.count = pagingProp.TotalCount;
        // console.log(this.ReportData);
        // this.ReportData.forEach(element => this.setExportData(element));
      }
    }, (error) => {
      console.error(error);
    });
  }
  exporttoexcel() {
    var startdate = this.policyStartDate == undefined ? "" : this.policyStartDate.format('LL');
    var enddate = this.policyEndDate == undefined ? "" : this.policyEndDate.format('LL');
    this.authService
      .getList(this._apiUrl + `/api/v1/Report/GetSalesWeekReport?startDatepicker=${startdate}&endDatepicker=${enddate}`)
      .subscribe(
        (response) => {
          if (response) {
            this.ReportData = response.body;
            this.ReportData.forEach(element => this.setMonthlySalesReportexportdata(element));
            this.fileName = "SalesWeeklyReport_" + new Date(Date.now()).toLocaleString().split(',')[0] + '.xlsx';
            /* json data is passed over here */

            import('xlsx').then(xlsx => {
              const ws: import('xlsx').WorkSheet = xlsx.utils.json_to_sheet(this.exportdata);
              xlsx.utils.sheet_add_json(ws, this.exportdata, { skipHeader: true, origin: "A3" });
              ws.A1.v = "Zone";
              ws.B1.v = "State";
              ws.C1.v = "City";
              ws.D1.v = "Dealer/WorkShop Code";
              ws.E1.v = "Dealer/WorkShop Name";
              ws.F1.v = "Payment Date";
              ws.G1.v = "Sunday";
              ws.I1.v = "Monday";
              ws.K1.v = "Tuesday";
              ws.M1.v = "Wednesday";
              ws.O1.v = "Thursday";
              ws.Q1.v = "Friday";
              ws.S1.v = "Saturday";
              ws.U1.v = "Total";

              ws.G2.v = "#Count"
              ws.H2.v = "#Amount"
              ws.I2.v = "#Count"
              ws.J2.v = "#Amount"
              ws.K2.v = "#Count"
              ws.L2.v = "#Amount"
              ws.M2.v = "#Count"
              ws.N2.v = "#Amount"
              ws.O2.v = "#Count"
              ws.P2.v = "#Amount"
              ws.Q2.v = "#Count"
              ws.R2.v = "#Amount"
              ws.S2.v = "#Count"
              ws.T2.v = "#Amount"
              ws.U2.v = "#Count"
              ws.V2.v = "#Amount"
              var merge = [{ s: { r: 0, c: 0 }, e: { r: 1, c: 0 } }, { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } }, { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } }, { s: { r: 0, c: 3 }, e: { r: 1, c: 3 } }, { s: { r: 0, c: 4 }, e: { r: 1, c: 4 } }, { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } }, { s: { r: 0, c: 6 }, e: { r: 0, c: 7 } }, { s: { r: 0, c: 8 }, e: { r: 0, c: 9 } }, { s: { r: 0, c: 10 }, e: { r: 0, c: 11 } }, { s: { r: 0, c: 12 }, e: { r: 0, c: 13 } }, { s: { r: 0, c: 14 }, e: { r: 0, c: 15 } }, { s: { r: 0, c: 16 }, e: { r: 0, c: 17 } }, { s: { r: 0, c: 18 }, e: { r: 0, c: 19 } }, { s: { r: 0, c: 20 }, e: { r: 0, c: 21 } }, { s: { r: 0, c: 22 }, e: { r: 0, c: 23 } }, { s: { r: 0, c: 24 }, e: { r: 0, c: 25 } }];
              /* add merges */
              if (!ws['!merges']) ws['!merges'] = [];
              ws['!merges'] = merge;
              /* generate workbook and add the worksheet */
              const wb: import('xlsx').WorkBook = xlsx.utils.book_new();
              xlsx.utils.book_append_sheet(wb, ws, 'WorkshopReport');

              /* save to file */
              xlsx.writeFile(wb, this.fileName);
            });

          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  setMonthlySalesReportexportdata(item) {
    var data: any = [];
    data.zone = item.zone
    data.state_Name = item.state_Name
    data.city_Name = item.city_Name
    data.dealerOrWorkshopCode = item.dealerOrWorkshopCode
    data.workshop_Name = item.workshop_Name
    data.paymentDate = item.paymentDate
    data.sundayCount = item.sundayCount
    data.sundayAmount = item.sundayAmount
    data.mondayCount = item.mondayCount
    data.mondayAmount = item.mondayAmount
    data.tuesdayCount = item.tuesdayCount
    data.tuesdayAmount = item.tuesdayAmount
    data.wednesdayCount = item.wednesdayCount
    data.wednesdayAmount = item.wednesdayAmount
    data.thursdayCount = item.thursdayCount
    data.thursdayAmount = item.thursdayAmount
    data.fridayCount = item.fridayCount
    data.fridayAmount = item.fridayAmount
    data.saturdayCount = item.saturdayCount
    data.saturdayAmount = item.saturdayAmount
    data.totalCount = item.totalCount
    data.totalAmount = item.totalAmount
    this.exportdata.push(data);
  }

}
