<app-header></app-header>
<app-menu></app-menu>
<div nxLayout="grid">
    <div nxRow>
      <div nxCol="10">
        <button (click)="toggleModal()" nxButton="tertiary" type="button" style="float: right;">Add Client</button>
      </div>
    </div>
    <div nxRow>
      <div nxCol="10" nxColOffset="1">
        <table nxTable zebra>
          <thead>
            <tr nxTableRow>
              <th nxHeaderCell>
                Client Name
              </th>
              <th nxHeaderCell>
                Client Url
              </th>
              <th nxHeaderCell>
                Branding Class Name(CSS)
              </th>
              <th nxHeaderCell>
                Status
              </th>
              <th nxHeaderCell>
                Activate/Deactivate
              </th>
              <th nxHeaderCell>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr nxTableRow *ngFor="let item of clientList">
              <td nxTableCell>{{item.clientName}}</td>
              <td nxTableCell>{{item.clientUrl}}</td>
              <td nxTableCell>{{item.brandingClassName}}</td>
              <td nxTableCell>
                <nx-badge vibrant type="positive" *ngIf="item.isActive ==1">Active</nx-badge>
                <nx-badge vibrant type="critical" *ngIf="item.isActive == 0">InActive</nx-badge>
              </td>
              <td nxTableCell *ngIf="item.isActive == 1">
                <nx-switcher [checked]="true" (change)='confirmModal(item,$event.target.checked)'></nx-switcher>
              </td>
              <td nxTableCell *ngIf="item.isActive == 0">
                <nx-switcher [checked]="false" (change)='confirmModal(item,$event.target.checked)'></nx-switcher>
              </td>
              <td nxTableCell><nx-icon name="edit" size="s" (click)="editClient(item.clientId)"></nx-icon></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <nx-modal #addColor [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="clientModalOpen" [nxShowCloseIcon]="false">
    <div *ngIf="!isSuccess">
    <form name='addclient-form' [formGroup]="clientForm">
      <div nxLayout="grid">
        <div nxRow nxRowJustify="start,around">
          <h1 nxHeadline="page" *ngIf="!EDIT_FLAG">Add Client</h1>
          <h1 nxHeadline="page" *ngIf="EDIT_FLAG">Update Client</h1>
        </div>
        <div nxRow>
          <div nxCol="12" nxAlignSelf="center">
            <nx-formfield nxLabel="Client Name">
              <input nxInput formControlName="clientName" />
              <nx-error nxFormfieldError>
                <strong>Please note:</strong> this field is required!
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12" nxAlignSelf="center">
            <nx-formfield nxLabel="Client Url">
              <input nxInput formControlName="clientUrl" />
              <span nxFormfieldPrefix>
                https://
              </span>
              <nx-error nxFormfieldError>
                <strong>Please note:</strong> this field is required!
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12" nxAlignSelf="center">
            <nx-formfield nxLabel="Branding Class Name(CSS)">
              <input nxInput formControlName="brandingClassName" />
              <nx-error nxFormfieldError>
                <strong>Please note:</strong> this field is required!
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12">
            <nx-formfield nxLabel="Balance Deduct From">
              <nx-dropdown [nxValueFormatter]="toTextMulti" [nxIsMultiselect]="true" formControlName="balanceDeductFromArray">
                <nx-dropdown-item
                  *ngFor="let column of planColumn"
                  [nxValue]="column.value"
                >{{column.name}}</nx-dropdown-item>
              </nx-dropdown>
              <nx-error nxFormfieldError>
                    <strong>Please note: </strong>This Feild is required!
                </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow style="margin-top: 5%;" >
          <div nxCol="12">
            <button nxButton="small" type="button" *ngIf="!EDIT_FLAG" (click)="onSave()" style="margin-right: 2%;">Add</button>
            <button nxButton="small" type="button" *ngIf="EDIT_FLAG" (click)="onUpdate()" style="margin-right: 2%;">Update</button>
            <button (click)="toggleModal()" nxButton="small secondary" type="button">Close</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="isSuccess">

    <div nxRow nxRowJustify='start,around'>
      <!-- <div nxCol="12" id="successModalImage" class="success_image">
      </div> -->
    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
      <div nxCol="12">
        <!-- <nx-message nxContext="success">User is added successfully</nx-message> -->
        <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf="!EDIT_FLAG">Client added successfully!</h3>
        <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf="EDIT_FLAG">Client updated successfully!</h3>

      </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
      <div nxCol="12">
        <button (click)="toggleModal()" nxButton="block primary" >
          close
        </button>
      </div>
    </div>

    <!-- <nx-message nxContext="success">
    User is added successfully.
  </nx-message> -->
  </div>

  </nx-modal>

  <!--Modal for confirmation -->
  <nx-modal #confirmModal [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="confirmModalOpen"
    [nxShowCloseIcon]="false">
    <div nxRow nxRowJustify="start,around">
      <h2 nxHeadline="page">Confirmation</h2>

    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
      <div nxCol="12">
        <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">Are you sure you want to do this action?</h3>
      </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
      <div nxCol="6">
        <button (click)="updateStatus()" nxButton="block primary" >
          Yes
        </button>
      </div>
      <div nxCol="6">
        <button (click)="onNoClick()" nxButton="block primary" >
          No
        </button>
      </div>

    </div>
  </nx-modal>
  <app-footer></app-footer>
