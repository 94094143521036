<app-header></app-header>
<app-menu></app-menu>

<div nxLayout="grid">
    <div nxRow nxRowAlignItems="start,start,center" nxRowJustify="center">
        <div nxCol="12" class="nx-margin-top-2m">
            <div class="uploadillstn"></div>
        </div>
        <div nxCol="6" class="nx-margin-top-2m" nxAlignSelf="stretch" style="text-align: center;">
            <button nxIconButton="cta" type="button" style="margin: 0 auto;"
                (click)="downloadExcelTemplate()">
                <nx-icon name="xls"></nx-icon>
            </button>
        </div>
        <div nxCol="12" style="text-align: center;">
            <h3 nxHeadline="subsection-medium" class="nx-font-weight-light">Download Excel template from here
            </h3>
        </div>
    </div>
    <div nxRow nxRowAlignItems="start,start,center" nxRowJustify="center">
        <div nxCol="10" style="height: 300px;" class="nx-margin-top-2m">
            <form [formGroup]="BulkUploadForm">
                <nx-file-uploader #documentUpload formControlName="documents" maxFileSize="{{5 * 1024 * 1024}}"
                    (valueChange)="onChange($event)" (fileDeleted)="onDelete($event)"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                    <nx-label size="small">Upload Bulk Policy Sales</nx-label>
                    <span class="nx-margin-bottom-2s" nxFileUploadHint>Only excel(*.XLSX,*.XLS) files and File size less
                        than 5 MB are
                        accepted</span>
                    <nx-file-uploader-drop-zone class="nx-margin-bottom-m" style="border-radius: 10px;">
                        <span class="nx-margin-right-2xs">Drag your files here or click </span>
                        <button nxIconButton="cta" type="button" nxFileUploadButton>
                            <nx-icon name="plus"></nx-icon>
                        </button>
                    </nx-file-uploader-drop-zone>

                    <nx-error *ngIf="BulkUploadForm.controls['documents'].hasError('NxFileUploadFileTypeNotAccepted')">
                        File
                        {{BulkUploadForm.controls['documents'].getError('NxFileUploadFileTypeNotAccepted').fileName | json}}
                        can not be uploaded. This file type is not supported!
                    </nx-error>
                    <nx-error *ngIf="BulkUploadForm.controls['documents'].hasError('required')">Required!</nx-error>
                    <nx-error *ngIf="BulkUploadForm.controls['documents'].hasError('serverError')">An error occured
                        while
                        uploading.</nx-error>
                    <nx-error *ngIf="BulkUploadForm.controls['documents'].hasError('NxFileUploadMaxFileSize')">
                        File
                        {{BulkUploadForm.controls['documents'].getError('NxFileUploadMaxFileSize').fileName | json}} can
                        not be uploaded. File size exceeds size limit!
                    </nx-error>
                </nx-file-uploader>
                <nx-error *ngIf="showUploadError">
                    {{errormsg}}
                </nx-error>
                <span> <button nxButton="primary small-medium" (click)="uploadpolicies()"
                        [disabled]="!documentUpload.value?.length" type="button" class="nx-margin-bottom-0">
                        Upload
                    </button>
                </span>
            </form>
        </div>
    </div>
</div>