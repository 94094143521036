<nx-modal *ngIf="firstlogin || forcechangepwd" [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" nxSize="fixed"
    [nxShowCloseIcon]="false">
    <div nxLayout='grid'>
        <form [formGroup]="changepassform">
            <nx-small-stage class="nx-margin-bottom-m" appearance="expert" narrow style="border-radius: 10px;">
                <nx-small-stage-image
                    nxSmallStageImageEnd
                    src="assets/illustrations/man_and_woman_with_tablet_smartphone_monitor.png"
                ></nx-small-stage-image>
                <h2 nxHeadline="subsection-large" class="nx-font-weight-regular">
                    Change<br />
                    Password
                </h2>
            </nx-small-stage>
            <div nxRow>
                <div nxCol="12">
                    <nx-formfield nxLabel="Current Password">
                        <input required nxInput #currentpass type="password" formControlName="currentpass" />
                        <nx-password-toggle [control]="currentpass" ariaLabel="Your custom show password label"
                            nxFormfieldSuffix></nx-password-toggle>
                        <nx-error nxFormfieldError>
                            <b>Please note: </b> you have to fill in your password to continue.
                        </nx-error>
                        <span nxFormfieldSuffix aria-hidden="true" class="nx-margin-left-s"
                            [nxPopoverTriggerFor]="popoverpassinfo" nxPopoverTrigger="hover">
                            <nx-icon name="info-circle-o" size="s"></nx-icon>
                        </span>
                    </nx-formfield>
                </div>
            </div>
            <div nxRow>
                <div nxCol="12">
                    <nx-formfield nxLabel="New Password">
                        <input required nxInput #newpass type="password" formControlName="newpass" />
                        <nx-password-toggle [control]="newpass" ariaLabel="Your custom show password label"
                            nxFormfieldSuffix></nx-password-toggle>
                            <nx-error nxFormfieldError>
                                <div *ngIf="f.newpass.errors?.pattern"><strong>Please note: </strong>Should have 1
                                    Upper,Lower,Numeric,Special characters</div>
                                <div *ngIf="f.newpass.errors?.required"><strong>Please note: </strong>you have to fill in
                                    your password to continue.</div>
                            </nx-error>
                        <!-- <span nxFormfieldSuffix aria-hidden="true" [nxPopoverTriggerFor]="popoverpassinfo"
            nxPopoverTrigger="hover">
                <nx-icon name="info-circle-o" size="s"></nx-icon>
            </span> -->
                    </nx-formfield>
                </div>
            </div>
            <div nxRow>
                <div nxCol="12">
                    <nx-formfield nxLabel="Confirm New Password">
                        <input required nxInput #confirmpass type="password" formControlName="confirmpass" />
                        <nx-password-toggle [control]="confirmpass" ariaLabel="Your custom show password label"
                            nxFormfieldSuffix></nx-password-toggle>
                        <nx-error nxFormfieldError>
                            <div *ngIf="f.confirmpass.errors?.required"><b>Please note: </b> you have to fill in
                                your password to continue.</div>
                            <div *ngIf="f.confirmpass.errors?.mustMatch"><b>Please note: </b> New password must match
                                Confirmed New password.</div>
                        </nx-error>
                        <!-- <span nxFormfieldSuffix aria-hidden="true" [nxPopoverTriggerFor]="popoverpassinfo"
            nxPopoverTrigger="hover">
                <nx-icon name="info-circle-o" size="s"></nx-icon>
            </span> -->
                    </nx-formfield>
                </div>
            </div>
            <div nxRow class="nx-margin-top-xs" nxRowJustify="start,around">
                <div nxCol="5">
                    <button (click)="changepassword()" [disabled]='changepassform.invalid' nxButton="block primary">
                        submit
                    </button>
                </div>
                <div nxCol="5" *ngIf="forcechangepwd">
                    <button routerLink='/dashboard' nxButton="block secondary">
                        close
                    </button>
                </div>
            </div>
            <p nxCopytext class="nx-margin-top-m" style="color: orangered;">* You will be redirected to login page after password change.</p>
        </form>
    </div>
</nx-modal>

<nx-popover #popoverpassinfo>
    <div style="max-width: 200px;">
        Enter your current password!
    </div>
</nx-popover>