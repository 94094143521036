<table nxTable condensed nxSort (sortChange)="sortTable($event)">
    <thead>
      <tr nxTableRow>
        <th nxHeaderCell nxSortHeaderCell="id">
          Request Id
        </th>
        <th nxHeaderCell  nxSortHeaderCell="dealerCode">
          Dealer Code
        </th>
        <th nxHeaderCell  nxSortHeaderCell="dealerName">
          Dealer Name
        </th>
        <th nxHeaderCell nxSortHeaderCell="requestDate">
          Request Date
        </th>
        <th nxHeaderCell  nxSortHeaderCell="approvedDate">
          Approved Date
        </th>
        <th nxHeaderCell nxSortHeaderCell="paymentMethod">
          Payment Method
        </th>
        <th nxHeaderCell nxSortHeaderCell="approvedRemarks">
          Remark
        </th>
        <th nxHeaderCell nxSortHeaderCell="requestedAmount">
          Amount
        </th>
        <th nxHeaderCell nxSortHeaderCell="status">
          Status
        </th>
      </tr>
    </thead>
    <tbody style="text-align: center;">
      <tr nxTableRow *ngFor="let Req of ReportData">
        <td nxTableCell>{{Req.id}}</td>
        <td nxTableCell >{{Req.dealerCode}}</td>
        <td nxTableCell >{{Req.dealerName}}</td>
        <td nxTableCell>{{Req.requestDate | date:'MM/dd/yyyy'}}</td>
        <td nxTableCell >{{Req.approvedDate | date:'MM/dd/yyyy'}}</td>
        <td nxTableCell>{{Req.paymentMethod}}</td>
        <td nxTableCell>{{Req.approvedRemarks}}</td>
        <td nxTableCell>{{Req.requestedAmount}}</td>
        <td nxTableCell *ngIf="Req.status == WalletStatuses.Credited else pending">
          <nx-badge type="positive">Credited</nx-badge>
        </td>
        <ng-template #pending>
          <td nxTableCell *ngIf="Req.status == WalletStatuses.Requested else rejected">
            <nx-badge type="negative">Requested</nx-badge>
          </td>
        </ng-template>
        <ng-template #rejected>
          <td nxTableCell *ngIf="Req.status == WalletStatuses.Rejected else failed">
            <nx-badge type="critical">Rejected</nx-badge>
          </td>
        </ng-template>
        <ng-template #failed>
          <td nxTableCell>
            <nx-badge type="critical">Failed</nx-badge>
          </td>
        </ng-template>
      </tr>
      <tr nxTableRow *ngIf="count==0">
        <td nxTableCell colspan="8">
          <ng-container>
              <div nxRow nxRowJustify="center">
                <div nxCol="12" class="nosearchresult"></div>
                <h3 nxHeadline="subsection-medium" class="nx-font-weight-semibold">No Records found !!!</h3>
              </div>
            </ng-container>
        </td>
      </tr>
    </tbody>
  </table>

  <div nxRow class="nx-margin-top-2m nx-margin-right-xl" style="float: right;">
    <div nxCol="6">
        <nx-pagination [nxCount]="count" [nxPage]="pageNumber" [nxPerPage]="pageSize" nxType="advanced"
            (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
        </nx-pagination>
    </div>
  </div>