import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserType } from "../core/userType.model";

@Component({
  selector: "app-master",
  templateUrl: "./master.component.html",
  styleUrls: ["./master.component.css"],
})
export class MasterComponent implements OnInit {
  public userRole;

  constructor(private route : ActivatedRoute) {
    this.userRole = atob(localStorage.getItem("userRole"));
  }

  ngOnInit() {
    this.makeLinks();
    var activelink = this.links.find(e => e.path === window.location.pathname);
    this.setActiveLink(activelink);
  }

  links = [
    {
      label: "Plan Master",
      path: "/masters/manageplans",
      disabled: false,
    },
    {
      label: "Color Master",
      path: "/masters/managecolors",
      disabled: false,
    },
    {
      label: "Vehicle Master",
      path: "/masters/managemodels",
      disabled: false,
    },
    {
      label: "Region Master",
      path: "/masters/managezone",
      disabled: false,
    },
    {
      label: "State Master",
      path: "/masters/managestate",
      disabled: false,
    },
    {
      label: "City Master",
      path: "/masters/managecity",
      disabled: false,
    },
  ];

  makeLinks(){
    if (this.userRole==UserType[8]) {
      this.links.push({
        label: "Cess & Tax Master",
        path: "/masters/managecessandtax",
        disabled: false,
      });
    }
  }

 
  currentLink = this.links[0];

  setActiveLink(link) {
    if (!link.disabled) {
      this.currentLink = link;
    }
  }
}
