<div class="row" style="margin-bottom:15px;">
  <div class="col-md-3">
  </div>
  <div class="col-md-4">
    <form [formGroup]="testForm">
      <nx-file-uploader #documentUpload (filesSelected)="upload()" formControlName="documents"  maxFileSize="{{5 * 1024 * 1024}}">
        <nx-label size="small" class="nx-margin-bottom-3xs">Select a file to upload</nx-label>
        <span class="nx-margin-bottom-s" nxFileUploadHint>maximum file size 5MB</span>
        <nx-file-uploader-drop-zone class="nx-margin-bottom-m" style="border-radius: 10px;">
          <span class="nx-margin-right-2xs">Drag your files here or click </span>
          <button nxButton="primary small-medium" type="button" class="nx-margin-bottom-s" nxFileUploadButton>
            <nx-icon name="plus" class="nx-margin-right-2xs"></nx-icon>
            Add File
          </button>
        </nx-file-uploader-drop-zone>
        <nx-error *ngIf="testForm.controls['documents'].hasError('NxFileUploadFileTypeNotAccepted')">
          File {{testForm.controls['documents'].getError('NxFileUploadFileTypeNotAccepted').fileName | json}}
          can not be uploaded. This file type is not supported!
        </nx-error>
        <nx-error *ngIf="testForm.controls['documents'].hasError('NxFileUploadMaxFileSize')">
          File {{testForm.controls['documents'].getError('NxFileUploadMaxFileSize').fileName | json}} can not be
          uploaded. File size exceeds size limit!
        </nx-error>
      </nx-file-uploader>
    </form>
    <nx-error *ngIf="showUploadError">
      {{errormsg}}
    </nx-error>
  </div>
</div>