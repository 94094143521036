<app-header></app-header>
<app-menu></app-menu>
<div nxLayout="grid" style="margin: 2%;">
  <div nxRow>
   
    <div nxCol="2" nxColOffset="1">
        <nx-formfield nxLabel="SelectUser Role">
            <nx-dropdown (selectionChange)="onUserTypeChanges($event.value)">
              <nx-dropdown-item *ngFor="let item of userTypeList | isActiveCheck" [nxValue]="item.userTypeId">
                {{item.userType}}
              </nx-dropdown-item>
            </nx-dropdown>
            <nx-error nxFormfieldError>
              <strong>Please note:</strong> this field is required!
            </nx-error>
          </nx-formfield>
    </div>

    <div nxCol="6">
      <button *ngIf="addPermission" (click)="toggleModal(false,0)" nxButton="secondary small-medium" type="button"
        style="float: right;">Add User Report</button>
    </div>
  </div>

  <div nxRow>
    <div nxCol="10" nxColOffset="1">
      <table nxTable zebra>
        <thead>
          <tr nxTableRow>
            <th nxHeaderCell>
              UserType
            </th>
            <th nxHeaderCell>
             Report Name
            </th>
            
            <th nxHeaderCell>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr nxTableRow *ngFor="let item of userReports">
            <td nxTableCell>{{item.userRole}}</td>
            <td nxTableCell>{{item.report.reportName}}</td>
            <td nxTableCell>
              <button nxButton="tertiary medium">
                <nx-icon name="trash" style="color: red;" size="s" (click)="confirmModal(item.id)"></nx-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<app-footer></app-footer>


<nx-modal [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="modalOpen" [nxShowCloseIcon]="false">
  <div *ngIf="!isSuccess">
    <form name='add-form' [formGroup]="userReportsForm">

      <div nxLayout="grid">
        <div nxRow nxRowJustify="start,around">
          <h1 nxHeadline="page">Add New Report</h1>
        </div>

        <div nxRow>
          <div nxCol="12" nxAlignSelf="center">
            <nx-formfield nxLabel="Select Report">
              <nx-dropdown formControlName="reportId">
                <nx-dropdown-item *ngFor="let item of reportList" [nxValue]="item.reportId">
                  {{item.reportName}}
                </nx-dropdown-item>
              </nx-dropdown>
              <nx-error nxFormfieldError>
                <strong>Please note:</strong> this field is required!
              </nx-error>
            </nx-formfield>
          </div>
        </div>

        <div nxRow style="margin-top: 5%;">
          <div nxCol="12">
            <button (click)="addUserReportMaster()" [disabled]="userReportsForm.invalid" nxButton="primary"
              type="button">Add</button>
           
            <button (click)="toggleModal(false,0)" class="nx-margin-left-2xs" nxButton="secondary" type="button">Close</button>
          </div>
        </div>

      </div>
    </form>
  </div>
  <div *ngIf="isSuccess">
    <div nxRow nxRowJustify='start,around'>
      <div nxCol="12" id="successModalImage" class="success_image">
      </div>
    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
      <div nxCol="12">
        <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf="!EDIT_FLAG">Report added successfully</h3>
      </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
      <div nxCol="12">
        <button (click)="toggleModal(false,0)" nxButton="block primary" id="returnButton">
          close
        </button>
      </div>
    </div>
  </div>
</nx-modal>

<nx-modal #confirmModal [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="confirmModalOpen"
    [nxShowCloseIcon]="false">
    <div nxRow nxRowJustify="start,around">
      <h2 nxHeadline="page">Confirmation</h2>

    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
      <div nxCol="12">
        <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">Are you sure you want to do this action?</h3>
      </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
      <div nxCol="6">

        <button (click)="delete()" nxButton="block primary" >
          Yes
        </button>
      </div>
      <div nxCol="6">
        <button (click)="confirmModalClose()" nxButton="block primary" >
          No
        </button>
      </div>
    </div>
  </nx-modal>