import {
  Component,
  AfterViewInit,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { sellPolicyData } from "../addpolicy/sellpolicyData.model";
import * as moment from "moment";
import { NxMessageToastService } from "@aposin/ng-aquila/message";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from "src/config/app.config";
import { AciveDealerReportComponent } from "../acive-dealer-report/acive-dealer-report.component";
import { WalletUsageReportComponent } from "../wallet-usage-report/wallet-usage-report.component";
import { PolicySaleReportComponent } from "../policy-sale-report/policy-sale-report.component";
import { WalletStatementReportComponent } from "../wallet-statement-report/wallet-statement-report.component";
import { SalesreportmonthlyComponent } from "../salesreportmonthly/salesreportmonthly.component";
import { SalesreportWeeklyComponent } from "../salesreport-weekly/salesreport-weekly.component";
import { OfflineWalletReportComponent } from "../offline-wallet-report/offline-wallet-report.component";
import { CustomErrorHandler } from "../core/custom-error-handler";
import { PolicySaleDetailedReportComponent } from "../policy-sale-detailed-report/policy-sale-detailed-report.component";
import { PolicyStatus } from "../core/policyStatus.model";
import { PolicyReportWithPriceSplitupComponent } from "../policy-report-with-price-splitup/policy-report-with-price-splitup.component";
import { WalletStatus } from "../core/status.model";
import { DealerCommissionInvoiceComponent } from "../dealer-commission-invoice/dealer-commission-invoice.component";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { UploadInvoiceComponent } from "../upload-invoice/upload-invoice.component";
import { DownloadDealerInvoiceComponent } from "../download-dealer-invoice/download-dealer-invoice.component";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.css"],
})
export class ReportComponent implements AfterViewInit {
  @ViewChildren("dealerReport")
  dealerReportComponents: QueryList<AciveDealerReportComponent>;
  dealerReportComponent: AciveDealerReportComponent;

  @ViewChildren("walletUsageReport")
  walletUsageReportComponents: QueryList<WalletUsageReportComponent>;
  walletUsageReportComponent: WalletUsageReportComponent;

  @ViewChildren("policySaleReport")
  policySaleReportComponents: QueryList<PolicySaleReportComponent>;
  policySaleReportComponent: PolicySaleReportComponent;

  @ViewChildren("walletstmntReport")
  walletStatementReportComponents: QueryList<WalletStatementReportComponent>;
  walletStatementReportComponent: WalletStatementReportComponent;

  @ViewChildren("salesReportMonth")
  salesReportMonthComponents: QueryList<SalesreportmonthlyComponent>;
  salesReportMonthComponent: SalesreportmonthlyComponent;

  @ViewChildren("salesReportWeek")
  salesReportWeekComponents: QueryList<SalesreportWeeklyComponent>;
  salesReportWeekComponent: SalesreportWeeklyComponent;

  @ViewChildren("offlineWalletReport")
  offlineWalletReportComponents: QueryList<OfflineWalletReportComponent>;
  offlineWalletReportComponent: OfflineWalletReportComponent;

  @ViewChildren("policySaleDetailedReport")
  policySaleDetailedReportComponents: QueryList<PolicySaleDetailedReportComponent>;
  policySaleDetailedReportComponent: PolicySaleDetailedReportComponent;

  @ViewChildren("policyReportWithPriceSplitUp")
  policyReportWithPriceSplitUpComponents: QueryList<PolicyReportWithPriceSplitupComponent>;
  policyReportWithPriceSplitUpComponent: PolicyReportWithPriceSplitupComponent;

  @ViewChildren("dealerCommissionInvoice")
  dealerCommissionInvoiceComponents: QueryList<DealerCommissionInvoiceComponent>;
  dealerCommissionInvoiceComponent: DealerCommissionInvoiceComponent;

  _apiUrl: string;
  clientId: number;
  policyList: any = [];
  policyEditModalOpen: boolean = false;
  id: number;

  confirmModalOpen: boolean = false;
  policyStatusDetails = {};
  policyDetails = {};
  sellPolicyData: sellPolicyData = new sellPolicyData();
  userPermission = {};
  _edit: boolean = false;
  userTypeId: number;
  userId: number;
  policyRequestList: any[];
  walletStatementList: any[];
  exportdata: any = [];
  fileName: string;
  userBaseTypeId: number;
  walletUsageStatementList: any;
  requestType: number;
  isFilterVisible: boolean = true;
  reportTypeData: any;
  errorMessage: string;
  isAdvancedSearch: boolean = false;
  isAdvancedSearchFirstRow: boolean = true;
  isAdvancedSearchSecondRow: boolean = true;
  isAdvancedSearchThirdRow: boolean = true;
  isAdvancedSearchFourthRow: boolean = true;

  policyStartDate: moment.Moment;
  policyEndDate: moment.Moment;
  //AdvancedSearchFields
  policyNo: string;
  policyType: Array<number>;
  policyStatus: typeof PolicyStatus;
  VehicleAge: number;
  model: string;
  Models: any;
  SelectedWalletRequestStatus: typeof WalletStatus;
  walletStatus: Array<string>;
  walletStatuses: typeof WalletStatus;
  dealerCode: string;
  month: number = 0;
  year: number = 0;
  months: any = [];
  years: any = [];
  currentMonth: number;
  currentYear: number = new Date().getFullYear();
  Admin: boolean = false;
  dealercode: number = 0;
  workshopList: [];
  dealerInvoice: boolean = false;

  uploadText: string = "Upload";
  dsDialogRef!: NxModalRef<UploadInvoiceComponent>;
  dwDialogRef!: NxModalRef<DownloadDealerInvoiceComponent>;
 
  constructor(
    private authService: AuthService,
    private appConfig: AppConfig,
    private errorHandler: CustomErrorHandler,
    public dialogService: NxDialogService,
  ) {
    
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.userBaseTypeId = parseInt(
      atob(localStorage.getItem("USER_BASE_TYPE_ID"))
    );
    this.userTypeId = parseInt(atob(localStorage.getItem("USER_TYPE_ID")));
    this.policyStatus = PolicyStatus;
    this.getReportTypes();
    this.walletStatuses = WalletStatus;
    this.getYearList();
    if (this.userBaseTypeId == 8) this.getworkshopList();
  }

  getReportTypes() {
    this.authService
      .get(this._apiUrl + "/api/v1/UserReports/GetUserReportsByUserType")
      .subscribe(
        (response) => {
          if (response) {
            this.reportTypeData = response;
          }
        },
        (error) => {
          this.errorHandler.handleError(error);
        }
      );
  }

  ngAfterViewInit(): void {
    this.dealerReportComponents.changes.subscribe(
      (comps: QueryList<AciveDealerReportComponent>) => {
        this.dealerReportComponent = comps.first;
      }
    );

    this.walletUsageReportComponents.changes.subscribe(
      (comps: QueryList<WalletUsageReportComponent>) => {
        this.walletUsageReportComponent = comps.first;
      }
    );

    this.policySaleReportComponents.changes.subscribe(
      (comps: QueryList<PolicySaleReportComponent>) => {
        this.policySaleReportComponent = comps.first;
      }
    );

    this.walletStatementReportComponents.changes.subscribe(
      (comps: QueryList<WalletStatementReportComponent>) => {
        this.walletStatementReportComponent = comps.first;
      }
    );

    this.salesReportMonthComponents.changes.subscribe(
      (comps: QueryList<SalesreportmonthlyComponent>) => {
        this.salesReportMonthComponent = comps.first;
      }
    );
    this.salesReportWeekComponents.changes.subscribe(
      (comps: QueryList<SalesreportWeeklyComponent>) => {
        this.salesReportWeekComponent = comps.first;
      }
    );
    this.offlineWalletReportComponents.changes.subscribe(
      (comps: QueryList<OfflineWalletReportComponent>) => {
        this.offlineWalletReportComponent = comps.first;
      }
    );
    this.policySaleDetailedReportComponents.changes.subscribe(
      (comps: QueryList<PolicySaleDetailedReportComponent>) => {
        this.policySaleDetailedReportComponent = comps.first;
      }
    );
    this.policyReportWithPriceSplitUpComponents.changes.subscribe(
      (comps: QueryList<PolicyReportWithPriceSplitupComponent>) => {
        this.policyReportWithPriceSplitUpComponent = comps.first;
      }
    );
    this.dealerCommissionInvoiceComponents.changes.subscribe(
      (comps: QueryList<DealerCommissionInvoiceComponent>) => {
        this.dealerCommissionInvoiceComponent = comps.first;
      }
    );
  }
  ngOnInit() {

    this.walletStatus = Object.keys(WalletStatus).filter((key) => isNaN(+key));
  }

  onReportTypeChange() {
    this.isAdvancedSearch = false;
    this.isFilterVisible = true;
    if (
      this.requestType == 4 ||
      this.requestType == 5 ||
      this.requestType == 6
    ) {
      this.isFilterVisible = false;
    }
    //Advanced Search
    if (this.requestType == 8 || this.requestType == 9) {
      this.getModelsList();
      this.isAdvancedSearch = true;
      this.isAdvancedSearchThirdRow = false;
      this.isAdvancedSearchFirstRow = true;
      this.isAdvancedSearchSecondRow = true;
      this.isAdvancedSearchFourthRow = false;
    }
    if (this.requestType == 7) {
      this.isAdvancedSearch = true;
      this.isAdvancedSearchFirstRow = false;
      this.isAdvancedSearchSecondRow = false;
      this.isAdvancedSearchFourthRow = false;
    }
    if (
      this.requestType == 2 &&
      this.userBaseTypeId != 4 &&
      this.userBaseTypeId != 5
    ) {
      this.isAdvancedSearch = true;
      this.isAdvancedSearchThirdRow = false;
      this.isAdvancedSearchFirstRow = false;
      this.isAdvancedSearchSecondRow = false;
      this.isAdvancedSearchFourthRow = true;
    }
    if (this.requestType == 10) {
      this.isFilterVisible = false;
      this.isAdvancedSearch = false;
      this.dealerInvoice = true;
     
    }
  }

  getdetails(type: number) {
    this.requestType = type;
    if (type == 1) {
      this.policySaleReportComponent.loadReport();
    } else if (type == 2) {
      this.walletStatementReportComponent.loadReport();
    } else if (type == 3) {
      this.walletUsageReportComponent.loadReport();
    } else if (type == 4) {
      this.dealerReportComponent.loadReport();
    } else if (type == 5) {
      this.salesReportMonthComponent.loadReport();
    } else if (type == 6) {
      this.salesReportWeekComponent.loadReport();
    } else if (type == 7) {
      this.offlineWalletReportComponent.loadReport();
    } else if (type == 8) {
      this.policySaleDetailedReportComponent.loadReport();
    } else if (type == 9) {
      this.policyReportWithPriceSplitUpComponent.loadReport();
    } else if (type == 10) {
      this.dealerCommissionInvoiceComponent.loadReport();
    }
  }

  exporttoexcel(): void {
    if (this.requestType == 1) {
      this.policySaleReportComponent.exporttoexcel();
    } else if (this.requestType == 2) {
      this.walletStatementReportComponent.exporttoexcel();
    } else if (this.requestType == 3) {
      this.walletUsageReportComponent.exporttoexcel();
    } else if (this.requestType == 4) {
      this.dealerReportComponent.exporttoexcel();
    } else if (this.requestType == 5) {
      this.salesReportMonthComponent.exporttoexcel();
    } else if (this.requestType == 6) {
      this.salesReportWeekComponent.exporttoexcel();
    } else if (this.requestType == 7) {
      this.offlineWalletReportComponent.exporttoexcel();
    } else if (this.requestType == 8) {
      this.policySaleDetailedReportComponent.exporttoexcel();
    } else if (this.requestType == 9) {
      this.policyReportWithPriceSplitUpComponent.exporttoexcel();
    }
  }
 
  // Download System generated / Custom Invoice

  getModelsList() {
    this.authService.get(this._apiUrl + "/api/v1/Models").subscribe(
      (response) => {
        if (response) {
          this.Models = response;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
  reset() {
    this.policyStartDate = null;
    this.policyEndDate = null;
    this.policyNo = null;
    this.model = null;
    this.policyType = null;
    this.VehicleAge = null;
    this.SelectedWalletRequestStatus = null;
    this.dealerCode = null;
  }
  //Upload Invoice Modal
  uploadInvoiceModalOpen() {
    if (this.year == 0) {
      this.errorHandler.handleCustomError("Please select year.");
      this.dialogService.openModals[0].close();
    } else if (this.month == 0) {
      this.errorHandler.handleCustomError("Please select month.");
      this.dialogService.openModals[0].close();
    } else if (this.userBaseTypeId == 8 && this.dealercode == 0) {
      this.errorHandler.handleCustomError("Please select a dealer.");
      this.dialogService.openModals[0].close();
    } else {
      this.dsDialogRef = this.dialogService.open(UploadInvoiceComponent, {
        ariaLabel: "Upload Invoice",
        showCloseIcon: true,
        data: { month: this.month, year: this.year, dealer: this.dealercode },
      });
    }
  }

  //Download Invoice Modal
  downloadInvoiceModalOpen() {
    if (this.userBaseTypeId == 8 && this.dealercode == 0) {
      this.errorHandler.handleCustomError("Please select a dealer.");
    }
    else if (this.year == 0) {
      this.errorHandler.handleCustomError("Please select year.");
    } else if (this.month == 0) {
      this.errorHandler.handleCustomError("Please select month.");
    } else {
      this.dwDialogRef = this.dialogService.open(DownloadDealerInvoiceComponent, {
        ariaLabel: "Download Invoice",
        showCloseIcon: true,
        data: { month: this.month, year: this.year, dealer: this.dealercode },
      });
    }
  }

  //Get Month list year wise
  getMonthList() {
    this.months = [];
    if (this.year == this.currentYear)
      this.currentMonth = new Date().getMonth() + 1;
    else this.currentMonth = 12;

    for (let i = 1; i <= this.currentMonth; i++) {
      if (i == 1) {
        let eachmonth = { value: i, month: "January" };
        this.months.push(eachmonth);
      }
      if (i == 2) {
        let eachmonth = { value: i, month: "February" };
        this.months.push(eachmonth);
      }
      if (i == 3) {
        let eachmonth = { value: i, month: "March" };
        this.months.push(eachmonth);
      }
      if (i == 4) {
        let eachmonth = { value: i, month: "April" };
        this.months.push(eachmonth);
      }
      if (i == 5) {
        let eachmonth = { value: i, month: "May" };
        this.months.push(eachmonth);
      }
      if (i == 6) {
        let eachmonth = { value: i, month: "June" };
        this.months.push(eachmonth);
      }
      if (i == 7) {
        let eachmonth = { value: i, month: "July" };
        this.months.push(eachmonth);
      }
      if (i == 8) {
        let eachmonth = { value: i, month: "August" };
        this.months.push(eachmonth);
      }
      if (i == 9) {
        let eachmonth = { value: i, month: "September" };
        this.months.push(eachmonth);
      }
      if (i == 10) {
        let eachmonth = { value: i, month: "October" };
        this.months.push(eachmonth);
      }
      if (i == 11) {
        let eachmonth = { value: i, month: "November" };
        this.months.push(eachmonth);
      }
      if (i == 12) {
        let eachmonth = { value: i, month: "December" };
        this.months.push(eachmonth);
      }
    }
  }
  getYearList() {
    this.years = [
      { value: this.currentYear - 1, year: this.currentYear - 1 },
      { value: this.currentYear, year: this.currentYear },
    ];
  }
  //Get all dealers
  getworkshopList() {
    this.authService
      .get(this._apiUrl + "/api/v1/workshops/GetallDealers?pageNumber=-1")
      .subscribe(
        (response) => {
          if (response) {
            this.workshopList = response;
          }
        },
        (error) => {
          this.errorHandler.handleError(error);
        }
      );
  }
}
