<table nxTable condensed nxSort (sortChange)="sortTable($event)">
  <thead>
      <tr nxTableRow>
          <th nxHeaderCell nxSortHeaderCell="dealerCode">
              Dealer/Workshop Code
          </th>
          <th nxHeaderCell nxSortHeaderCell="dealerName">
              Dealer/Workshop Name
          </th>
          <th nxHeaderCell nxSortHeaderCell="region">
              Region
          </th>
          <th nxHeaderCell nxSortHeaderCell="state">
              State
          </th>
          <th nxHeaderCell nxSortHeaderCell="city">
              City
          </th>
          <th nxHeaderCell nxSortHeaderCell="userRole">
              User Role
          </th>
          <th nxHeaderCell nxSortHeaderCell="walletBalance">
            Wallet Balance
         </th>
          <th nxHeaderCell nxSortHeaderCell="isActive">
              Status
          </th>
      </tr>
  </thead>
  <tbody style="text-align: center;">
      <tr nxTableRow *ngFor="let item of ReportData">
          <td nxTableCell>{{item.dealerCode}}</td>
          <td nxTableCell>{{item.dealerName}}</td>
          <td nxTableCell>{{item.region}}</td>
          <td nxTableCell>{{item.state}}</td>
          <td nxTableCell>{{item.city}}</td>
          <td nxTableCell>{{item.userRole}}</td>
          <td nxTableCell>{{item.walletBalance}}</td>
          <td nxTableCell>
            <nx-badge vibrant  *ngIf="item.isActive == -1" type='active'>Requested</nx-badge>
            <nx-badge vibrant  *ngIf="item.isActive == 1" type='positive'>Active</nx-badge>
            <nx-badge   *ngIf="item.isActive == 0" type='critical'>Rejected</nx-badge>  
            <nx-badge vibrant  *ngIf="item.isActive == 2" type='critical'>Removed</nx-badge>  
          </td>
      </tr>
      <tr nxTableRow *ngIf="count==0">
        <td nxTableCell colspan="8">
          <ng-container>
              <div nxRow nxRowJustify="center">
                <div nxCol="12" class="nosearchresult"></div>
                <h3 nxHeadline="subsection-medium" class="nx-font-weight-semibold">No Records found !!!</h3>
              </div>
            </ng-container>
            </td>
        </tr>
  </tbody>
</table>
<div nxRow class="nx-margin-top-2m nx-margin-right-5xl" style="float: right;">
  <div nxCol="6">
      <nx-pagination [nxCount]="count" [nxPage]="pageNumber" [nxPerPage]="pageSize" nxType="advanced"
          (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
      </nx-pagination>
  </div>
</div>
