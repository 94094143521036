import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-payu-response",
  templateUrl: "./payu-response.component.html",
  styleUrls: ["./payu-response.component.css"],
})
export class PayuResponseComponent implements OnInit {
  transactionStatus: boolean = false;
  transactionId: string;
  responsemessage: any;

  constructor(private _activatedroute: ActivatedRoute) {
    this.transactionStatus = (this._activatedroute.snapshot.paramMap.get("txnstatus")).toLowerCase()=='success'?true:false;
    this.responsemessage = (this._activatedroute.snapshot.paramMap.get("txnstatus")).toLowerCase()=='success'?"Payment Completed Successfuly!":this._activatedroute.snapshot.paramMap.get("txnstatus");
    this.transactionId = this._activatedroute.snapshot.paramMap.get("txnid");
  }

  ngOnInit(): void {}
}
