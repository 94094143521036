import { Component, OnInit, Inject } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { Subject } from "rxjs";
import { AppConfig } from "src/config/app.config";
import { CustomErrorHandler } from "../core/custom-error-handler";
import { NxDialogService, NX_MODAL_DATA } from "@aposin/ng-aquila/modal";
import { AuthService } from "../core/auth/auth.service";
import { MESSAGE_CONSTS } from "../ValidationMessages";
import { LoaderService } from "../loader.service";
import { invoiceData } from "./invoiceData.model";
@Component({
  selector: "app-download-dealer-invoice",
  templateUrl: "./download-dealer-invoice.component.html",
  styleUrls: ["./download-dealer-invoice.component.css"],
})
export class DownloadDealerInvoiceComponent implements OnInit {
  _apiUrl: string;
  clientId: number;
  userBaseTypeId: number;
  userTypeId: number;
  signature: boolean = false;
  downloadInvoiceOpen: boolean = true;
  checked: boolean = false;
  downloadType: string = "S";
  signatureView: boolean = true;
  month: number;
  year: number;
  dealercode: number;
  downloadInvoiceForm: FormGroup;
  Messages: any;
  invoiceData: invoiceData = new invoiceData();
  invoiceExists: boolean = false;
  public downloadTypeChange(value: string) {
    if (value == "C") {
      this.signatureView = false;
    } else if (value == "S" && this.userBaseTypeId != 8)
      this.signatureView = true;
  }

  private readonly _destroyed = new Subject<void>();
  constructor(
    private authService: AuthService,
    private appConfig: AppConfig,
    private errorHandler: CustomErrorHandler,
    public dialogService: NxDialogService,
    private fb: FormBuilder,
    private loaderService: LoaderService,
    @Inject(NX_MODAL_DATA) public data: any
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.userBaseTypeId = parseInt(
      atob(localStorage.getItem("USER_BASE_TYPE_ID"))
    );
    this.userTypeId = parseInt(atob(localStorage.getItem("USER_TYPE_ID")));
    this.Messages = MESSAGE_CONSTS;
    if (this.userBaseTypeId == 8) {
      this.signatureView = false;
    }
  }
  downloadFormBuilder() {
    this.downloadInvoiceForm = this.fb.group({
      invoiceNumber: [
        "",
        [Validators.required, Validators.pattern(MESSAGE_CONSTS.INVOICE_REGEX)],
      ],
      types: [""],
      digitalSign: [""],
    });
    this.downloadInvoiceForm.controls.types.valueChanges.subscribe((value) => {
      if (value === "C") {
        this.downloadInvoiceForm.get("invoiceNumber").disable();
      } else {
        this.downloadInvoiceForm.get("invoiceNumber").enable();
      }
    });
  }
  get f() {
    return this.downloadInvoiceForm.controls;
  }
  ngOnInit(): void {
    this.downloadFormBuilder();
    if (typeof this.data !== "undefined") {
      this.month = this.data.month;
      this.year = this.data.year;
      this.dealercode = this.data.dealer;
    }
    this.getInvoiceNumber();
    if (this.userBaseTypeId == 8) this.signatureView = false;
    else this.signatureView = true;
  }
  //Dealer Invoice number show
  getInvoiceNumber() {
    this.authService
      .get(
        this._apiUrl +
          "/api/v1/DownLoadPage/GetInvoiceNumber/" +
          this.month +
          "/" +
          this.year +
          "/" +
          this.dealercode
      )
      .subscribe(
        (response) => {
          if (response) {
            this.invoiceData = response;
            this.downloadInvoiceForm.controls["invoiceNumber"].setValue(
              this.invoiceData.invoiceNumber
            );
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
  invoiceNumberCheck() {
    if (this.downloadType =="C")
    this.downloadInvoice();
    else
    {
    var invoicenumber =
      this.downloadInvoiceForm.controls["invoiceNumber"].value;
    this.authService
      .get(
        this._apiUrl +
          "/api/v1/DownLoadPage/InvoiceNumberCheck/" +
          this.month +
          "/" +
          this.year +
          "/" +
          this.dealercode +
          "/" +
          invoicenumber
      )
      .subscribe(
        (response) => {
          if (response.code == 200) {
            this.errorHandler.handleCustomError(
              "Invoice number already exists for other month."
            );
          } else {
            this.downloadInvoice();
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }
  //Dealer Invoice download (System and Custom)
  downloadInvoice() {
    this.loaderService.show();
    if (this.checked == true) this.signature = true;
    else this.signature = false;
    var downloadtype = this.downloadType;
    var invoicenumber = this.downloadInvoiceForm.controls["invoiceNumber"].value;
    this.closeModal();
    this.authService
      .export(
        this._apiUrl +
          "/api/v1/DownLoadPage/dealerinvoice/" +
          this.month +
          "/" +
          this.year +
          "/" +
          this.signature +
          "/" +
          this.dealercode +
          "/" +
          downloadtype +
          "/" +
          invoicenumber
      )
      .subscribe(
        (data) => {
          saveAs(
            data,
            "Dealer_Invoice_" + this.month + "_" + this.year + `_report.pdf`
          );
          this.loaderService.hide();
        },
        (error) => {
          if (downloadtype == "C")
            this.errorHandler.handleCustomError(
              "Custom Invoice not found for the selected month. Please upload."
            );
          else
            this.errorHandler.handleCustomError(
              "System Invoice download failed."
            );
        }
      );
  }
  closeModal() {
    this.dialogService.openModals[0].close();
  }
  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
