<app-header></app-header>
<app-menu></app-menu>
<div nxLayout="grid" style="margin: 2%">
  <div nxRow>
    <div nxCol="2" nxColOffset="1">
      <nx-formfield nxLabel="SelectUser Role">
        <nx-dropdown (selectionChange)="onUserTypeChanges($event.value)">
          <nx-dropdown-item
            *ngFor="let item of userTypeList | isActiveCheck"
            [nxValue]="item.userTypeId"
          >
            {{ item.userType }}
          </nx-dropdown-item>
        </nx-dropdown>
        <nx-error nxFormfieldError>
          <strong>Please note:</strong> this field is required!
        </nx-error>
      </nx-formfield>
    </div>
    <div nxCol="8">
      <button
        (click)="toggleModal(false, 0)"
        nxButton="secondary small-medium"
        type="button"
        style="float: right"
      >
        Add Email Address
      </button>
    </div>
  </div>

  <div nxRow>
    <div nxCol="10" nxColOffset="1">
      <table nxTable zebra>
        <thead>
          <tr nxTableRow>
            <th nxHeaderCell>User Role</th>
            <th nxHeaderCell>Email</th>

            <th nxHeaderCell>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr nxTableRow *ngFor="let item of emailList">
            <td nxTableCell>{{ item.userRole }}</td>
            <td nxTableCell>{{ item.userEmail.email }}</td>
            <td nxTableCell>
              <button nxButton="tertiary medium">
                <nx-icon
                  name="trash"
                  style="color: red"
                  size="s"
                  (click)="confirmModal(item.userEmail.id)"
                ></nx-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<app-footer></app-footer>

<nx-modal
  [nxHideOnClickOutside]="false"
  [nxHideOnEsc]="false"
  *ngIf="modalOpen"
  [nxShowCloseIcon]="false"
>
  <div *ngIf="!isSuccess">
    <form name="add-form" [formGroup]="userEmailsForm">
      <div nxLayout="grid">
        <div nxRow nxRowJustify="start,around">
          <h1 nxHeadline="page">Add Email</h1>
        </div>

        <div nxRow>
          <div nxCol="12" nxAlignSelf="center">
            <nx-formfield nxLabel="Email">
              <nx-formfield-label class="required">Email</nx-formfield-label>
              <input nxInput formControlName="email" />
              <span nxFormfieldHint>
                <span style="color: rgb(51, 255, 0)">Note: </span> Add cc email
                addresses for sending notifications.
              </span>
              <nx-error nxFormfieldError>
                <div *ngIf="f.email.errors?.required">
                  <strong>Please note:</strong> This field is required!
                </div>
                <div *ngIf="f.email.errors?.email">
                  <strong>Please note:</strong> Invalid email format!
                </div>
                <div *ngIf="userRoleValid">
                  <strong>Please note:</strong> Select User Role !
                </div>
              </nx-error>
            </nx-formfield>
            <nx-error *ngIf="userRoleValid">
              <div><strong>Please note:</strong> Select User Role !</div>
            </nx-error>
          </div>
        </div>

        <div nxRow style="margin-top: 5%">
          <div nxCol="12">
            <button
              (click)="addUserEmail()"
              [disabled]="userEmailsForm.invalid"
              nxButton="primary"
              type="button"
            >
              Add
            </button>
            <button
              (click)="toggleModal(false, 0)"
              class="nx-margin-left-2xs"
              nxButton="secondary"
              type="button"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="isSuccess">
    <div nxRow nxRowJustify="start,around">
      <div nxCol="12" id="successModalImage" class="success_image"></div>
    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
      <div nxCol="12">
        <h3
          class="u-text-align-center"
          nxHeadline="subsection-xsmall"
          *ngIf="!EDIT_FLAG"
        >
          Email added successfully
        </h3>
      </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
      <div nxCol="12">
        <button
          (click)="toggleModal(false, 0)"
          nxButton="block primary"
          id="returnButton"
        >
          close
        </button>
      </div>
    </div>
  </div>
</nx-modal>

<nx-modal
  #confirmModal
  [nxHideOnClickOutside]="false"
  [nxHideOnEsc]="false"
  *ngIf="confirmModalOpen"
  [nxShowCloseIcon]="false"
>
  <div nxRow nxRowJustify="start,around">
    <h2 nxHeadline="page">Confirmation</h2>
  </div>
  <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
    <div nxCol="12">
      <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">
        Are you sure you want to do this action?
      </h3>
    </div>
  </div>
  <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
    <div nxCol="6">
      <button (click)="delete()" nxButton="block primary">Yes</button>
    </div>
    <div nxCol="6">
      <button (click)="confirmModalClose()" nxButton="block primary">No</button>
    </div>
  </div>
</nx-modal>
