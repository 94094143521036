<app-header></app-header>
<app-menu></app-menu>
<div nxLayout="grid" style="margin: 2%;">

    <div nxRow>
        <div nxCol="2" nxColOffset="1">
            <nx-formfield nxLabel="Select Client">
                <nx-dropdown  #selectedClient (selectionChange)="onClientChanges($event.value)">
                  <nx-dropdown-item *ngFor="let item of clientList  | isActiveCheck" [nxValue]="item.clientId">{{item.clientName}}
                  </nx-dropdown-item>
                </nx-dropdown>

            </nx-formfield>
        </div>

      <div nxCol="6">
        <button  *ngIf="addUserType" (click)="toggleModal(false,0)" nxButton="secondary small-medium" type="button" style="float: right;">Add User Role</button>
      </div>
    </div>

    <div nxRow>
      <div nxCol="10" nxColOffset="1">
        <table nxTable zebra>
          <thead>
            <tr nxTableRow>
              <th nxHeaderCell>
                UserType
              </th>
              <th nxHeaderCell>
                User Role
              </th>
              <th nxHeaderCell>
                Status
              </th>
              <th nxHeaderCell>
                Edit
              </th>
              <th nxHeaderCell>
                Activate/Deactivate
              </th>
            </tr>
          </thead>
          <tbody>
            <tr nxTableRow *ngFor="let item of userTypeList">
              <td nxTableCell>{{item.userTypeId}}</td>
              <td nxTableCell>{{item.userType}}</td>
              <td nxTableCell *ngIf="item.isActive =='1' else inActive">
                <nx-badge vibrant type="positive">Active</nx-badge>
              </td>
              <ng-template #inActive>
                <td nxTableCell>
                  <nx-badge vibrant type="critical">In Active</nx-badge>
                </td>
              </ng-template>
              <td nxTableCell>
                <button nxButton="tertiary medium">
                  <nx-icon name="edit" size="s" (click)="toggleModal(true,item.userTypeId)"></nx-icon>
                </button>
              </td>
              <td nxTableCell *ngIf="item.isActive =='1'">
                <nx-switcher [checked]="true" (change)='confirmModal(item,$event.target.checked)'></nx-switcher>
              </td>
              <td nxTableCell *ngIf="item.isActive =='0'">
                <nx-switcher [checked]="false" (change)='confirmModal(item,$event.target.checked)'></nx-switcher>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
<app-footer></app-footer>


  <nx-modal [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="modalOpen"
    [nxShowCloseIcon]="false">
    <div *ngIf="!isSuccess">
      <form name='add-form' [formGroup]="userTypeForm">

        <div nxLayout="grid">
          <div nxRow nxRowJustify="start,around">
            <h1 nxHeadline="page" *ngIf="!EDIT_FLAG">Add User Role</h1>
            <h1 nxHeadline="page" *ngIf="EDIT_FLAG">Edit User Role</h1>
          </div>

          <div nxRow>
            <div nxCol="12" nxAlignSelf="center">
                <nx-formfield nxLabel="User Role">
                  <input nxInput formControlName="userType" />
                  <nx-error nxFormfieldError>
                    <strong>Please note:</strong> this field is required!
                  </nx-error>
                </nx-formfield>
            </div>

          </div>

          <div nxRow>
            <div nxCol="12" nxAlignSelf="center">

              <nx-formfield nxLabel="Select Base Type">
                <nx-dropdown formControlName="baseType">
                  <nx-dropdown-item *ngFor="let item of baseUserTypes  | isActiveCheck" [nxValue]="item.id">{{item.userType}}
                  </nx-dropdown-item>
                </nx-dropdown>
                <nx-error nxFormfieldError>
                  Please select an option.
                </nx-error>
              </nx-formfield>
            </div>

          </div>

          <div nxRow style="margin-top: 5%;">
            <div nxCol="12">
              <button (click)="addUserTypeMaster()" [disabled]="userTypeForm.invalid" nxButton="primary" type="button" *ngIf="!EDIT_FLAG">Add</button>
              <button (click)="updateUserTypeMaster()"[disabled]="userTypeForm.invalid"  nxButton="primary" type="button" *ngIf="EDIT_FLAG">Update</button>
              <button (click)="toggleModal(false,0)" nxButton="secondary" type="button">Close</button>
            </div>
          </div>

          <div nxRow>
            <nx-label size="small" style="color:orangered;">Warning : Adding new user role will require code changes..</nx-label>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="isSuccess">
      <div nxRow nxRowJustify='start,around'>
        <div nxCol="12" id="successModalImage" class="success_image">
        </div>
      </div>
      <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
        <div nxCol="12">
          <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf="!EDIT_FLAG">Role added successfully</h3>
          <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf="EDIT_FLAG">Role updated successfully</h3>
        </div>
      </div>
      <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
        <div nxCol="12">
          <button (click)="toggleModal(false,0)" nxButton="block primary" id="returnButton">
            close
          </button>
        </div>
      </div>
    </div>
  </nx-modal>

  <nx-modal #confirmModal [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="confirmModalOpen"
    [nxShowCloseIcon]="false">
    <div nxRow nxRowJustify="start,around">
      <h2 nxHeadline="page">Confirmation</h2>

    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
      <div nxCol="12">
        <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">Are you sure you want to do this action?</h3>
      </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
      <div nxCol="6">

        <button (click)="changeUserTypeStatus()" nxButton="block primary" >
          Yes
        </button>
      </div>
      <div nxCol="6">
        <button (click)="confirmModalClose()" nxButton="block primary" >
          No
        </button>
      </div>
    </div>
  </nx-modal>
