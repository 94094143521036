import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { AuthService } from "./auth.service";
//import decode from "jwt-decode";
@Injectable()
export class RoleGuardService implements CanActivate {

  public routeData = [];
  public configuredRoute = [];

  constructor(public auth: AuthService, public router: Router) {}
  
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if(!this.auth.isAuthenticated()) {
      this.router.navigate(['']);
      return of(false);
    }

    return this.auth.canActivate(route.routeConfig.path);
  }

  setRouteData(data: any){
    this.routeData = data;
    this.routeData.forEach(item => {
      this.configuredRoute.push(item["route"].substring(1));
    });
  }
}
