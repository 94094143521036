<form [formGroup]="dsForm">
  <nx-small-stage
    class="nx-margin-bottom-m border-radius"
    appearance="expert"
    narrow
  >
    <nx-small-stage-image
      nxSmallStageImageEnd
      src="assets/illustrations/man_with_laptop.png"
    ></nx-small-stage-image>
    <h2 nxHeadline="subsection-large" class="nx-font-weight-regular">
      Update Signature
    </h2>
  </nx-small-stage>
  <div nxRow nxRowJustify="start,around">
    <div nxCol="12">
      <p nxCopytext class="font-bold"><b>Existing Signature</b></p>
      <div class="nx-margin-top-2m box">
        <img [src]="thumbnail" class="nx-margin-top-l" />
      </div>
    </div>
  </div>

  <div nxRow nxRowJustify="start,around">
    <div nxCol="12">
      <nx-file-uploader
        #documentUpload
        formControlName="documents"
        maxFileSize="{{ 1 * 500 * 500 }}"
        accept="image/jpeg, image/x-png, image/png"
        required
      >
        <nx-label size="small" class="nx-margin-bottom-3xs"
          >Select a file to upload</nx-label
        >
        <span class="nx-margin-bottom-s" nxFileUploadHint
          >Maximum file size 500 KB with Width and Height must not exceed 300*50 pixels.</span
        >
        <nx-file-uploader-drop-zone
          class="nx-margin-bottom-m border-radius"
        >
          <span class="nx-margin-right-2xs"
            >Drag your files here or click
          </span>
          <button
            nxButton="primary small-medium"
            type="button"
            class="nx-margin-bottom-s"
            nxFileUploadButton
          >
            <nx-icon name="plus" class="nx-margin-right-2xs"></nx-icon>
            Add File
          </button>
        </nx-file-uploader-drop-zone>
        <nx-error
          *ngIf="
            dsForm.controls['documents'].hasError(
              'NxFileUploadFileTypeNotAccepted'
            )
          "
        >
          File
          {{
            dsForm.controls["documents"].getError(
              "NxFileUploadFileTypeNotAccepted"
            ).fileName | json
          }}
          can not be uploaded. This file type is not supported!
        </nx-error>
        <nx-error
          *ngIf="
            dsForm.controls['documents'].hasError('NxFileUploadMaxFileSize')
          "
        >
          File
          {{
            dsForm.controls["documents"].getError("NxFileUploadMaxFileSize")
              .fileName | json
          }}
          can not be uploaded. File size exceeds size limit!
        </nx-error>
      </nx-file-uploader>
    </div>
  </div>

  <div nxRow nxRowJustify="start,around">
    <div nxCol="5" class="align-center">
      <button
        nxButton="secondary small"
        type="button"
        [disabled]="documentUpload.value && !documentUpload.value.length"
        (click)="upload()"
      >
        SUBMIT
      </button>
      <button
        type="button"
        class="nx-margin-left-2xs"
        nxButton="secondary small"
        (click)="closeModal()"
      >
        close
      </button>
    </div>
  </div>
</form>
