import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit } from "@angular/core";
import { Chart } from "chart.js";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { ChartType } from '../core/chart.model';
import { UserType } from "../core/userType.model";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  btdchart: Chart;
  mtdchart: Chart;
  ytdchart: Chart;
  btdChartType: ChartType = ChartType.bar;
  mtdChartType: ChartType = ChartType.bar;
  ytdChartType: ChartType = ChartType.bar;
  _apiUrl: string;
  ChartCal: any;
  cdr: any;
  _editWorkshop : boolean;
  userbaseTypeId: number;


  constructor(
    private authService: AuthService,
    private appConfig : AppConfig,
    private _cdr: ChangeDetectorRef) {
    this._apiUrl = appConfig.baseUrl;
    this.cdr = _cdr;
    this.userbaseTypeId = parseInt(atob(localStorage.getItem("USER_BASE_TYPE_ID")));
  }
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.getDashBoard();
    this.getChartCalculations();
    if(this.userbaseTypeId == UserType.Dealer ||this.userbaseTypeId == UserType.WorkShop ){
    this.ValidateWorkshopRequiredData();
    }
  }
 
  getDashBoard() {
    this.authService
      .get(
        this._apiUrl + "/api/v1/Dashboard/chart")
      .subscribe(
        (response) => {
          if (response) {
            this.BindBTDChart(response.labelBTD, response.dataBTD, "canvas");
            this.BindMTDChart(response.labelMTD, response.dataMTD, "canvas1");
            this.BindYTDChart(response.labelYTD, response.dataYTD, "canvas2");
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getChartCalculations() {
    this.authService
      .get(
        this._apiUrl +"/api/v1/Dashboard/calculations")
      .subscribe(
        (response) => {
          if (response) {
            this.ChartCal = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  changeChartType(type: string) {
    if (type == "BTD") {
      this.btdchart.config.type = ChartType[this.btdChartType];
      this.btdchart.update();
    }
    if (type == "MTD") {
      this.mtdchart.config.type = ChartType[this.mtdChartType];
      this.mtdchart.update();
    }
    if (type == "YTD") {
      this.ytdchart.config.type = ChartType[this.ytdChartType];
      this.ytdchart.update();
    }
  }

  BindBTDChart(label: any, data: any, canvasId: string) {
    this.btdchart = new Chart(canvasId, {
      type: "bar",
      data: {
        labels: label,
        datasets: [
          {
            data: data,
            borderColor: "#3cba9f",
            backgroundColor: this.poolColors(data.length),
            fill: true,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              fontColor: "#C7C7CC",
              fontSize: 11,
            },
          ],
          yAxes: [
            {
              display: true,
            },
          ],
        },
      },
    });
  }

  BindMTDChart(label: any, data: any, canvasId: string) {
    this.mtdchart = new Chart(canvasId, {
      type: "bar",
      data: {
        labels: label,
        datasets: [
          {
            data: data,
            borderColor: "#3cba9f",
            backgroundColor: this.poolColors(data.length),
            fill: true,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              fontColor: "#C7C7CC",
              fontSize: 11,
            },
          ],
          yAxes: [
            {
              display: true,
            },
          ],
        },
      },
    });
  }

  BindYTDChart(label: any, data: any, canvasId: string) {
    this.ytdchart = new Chart(canvasId, {
      type: "bar",
      data: {
        labels: label,
        datasets: [
          {
            data: data,
            borderColor: "#3cba9f",
            backgroundColor: this.poolColors(data.length),
            fill: true,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              fontColor: "#C7C7CC",
              fontSize: 11,
            },
          ],
          yAxes: [
            {
              display: true,
            },
          ],
        },
      },
    });
  }

  poolColors(length) {
    var pool = [];
    for (var i = 0; i < length; i++) {
      pool.push(this.dynamicColors());
    }
    return pool;
  }

  dynamicColors() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgba(" + r + "," + g + "," + b + ", 0.5)";
  }

  //Workshop Edit
  public get isShowWorkshopEdit() : boolean {
    return this._editWorkshop;
  }
  
  ValidateWorkshopRequiredData() {
    this.authService
      .get(
        this._apiUrl +"/api/v1/Workshops/ValidateWorkshopRequiredData")
      .subscribe(
        (response) => {
            this._editWorkshop = !response;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  onCloseModal(arg : boolean){
    this._editWorkshop = arg;
  }
  
}

