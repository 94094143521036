import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "../login/login.component";
import { ForgotpasswordComponent } from "../forgotpassword/forgotpassword.component";
import { LoginResolve } from "./login.resolve";

const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
    resolve: {
      branding: LoginResolve,
    },
  },
  { 
    path: "forgotpwd", 
    component: ForgotpasswordComponent 
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class loginRoutingModule {}
