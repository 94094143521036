import {
  trigger,
  transition,
  style,
  query,
  animateChild,
  group,
  animate,
} from "@angular/animations";

export const slider = trigger("routeAnimations", [
  transition("* => isLeft", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ left: "-100%" })]),
    group([
      query(":leave", [animate("600ms ease", style({ left: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("600ms ease", style({ left: "0%" }))]),
    ]),
  ]),
  transition("* => isRight", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ right: "-100%" })]),
    group([
      query(":leave", [animate("600ms ease", style({ right: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("600ms ease", style({ right: "0%" }))]),
    ]),
  ]),
  transition("isRight => *", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ left: "-100%" })]),
    group([
      query(":leave", [animate("600ms ease", style({ left: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("600ms ease", style({ left: "0%" }))]),
    ]),
  ]),
  transition("isLeft => *", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ right: "-100%" })]),
    group([
      query(":leave", [animate("600ms ease", style({ right: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("600ms ease", style({ right: "0%" }))]),
    ]),
  ]),
  transition("vehicle => customer", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ right: "-100%" })]),
    group([
      query(":leave", [animate("600ms ease", style({ right: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("600ms ease", style({ right: "0%" }))]),
    ]),
  ]),
  transition("customer => vehicle", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ left: "-100%" })]),
    group([
      query(":leave", [animate("600ms ease", style({ left: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("600ms ease", style({ left: "0%" }))]),
    ]),
  ]),
  transition("customer => plan", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ right: "-100%" })]),
    group([
      query(":leave", [animate("600ms ease", style({ right: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("600ms ease", style({ right: "0%" }))]),
    ]),
  ]),
  transition("plan => customer", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ left: "-100%" })]),
    group([
      query(":leave", [animate("600ms ease", style({ left: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("600ms ease", style({ left: "0%" }))]),
    ]),
  ]),
  transition("plan => payment", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ right: "-100%" })]),
    group([
      query(":leave", [animate("600ms ease", style({ right: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("600ms ease", style({ right: "0%" }))]),
    ]),
  ]),
  transition("payment => plan", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ left: "-100%" })]),
    group([
      query(":leave", [animate("600ms ease", style({ left: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("600ms ease", style({ left: "0%" }))]),
    ]),
  ]),
  transition("payment => download", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ right: "-100%" })]),
    group([
      query(":leave", [animate("600ms ease", style({ right: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("600ms ease", style({ right: "0%" }))]),
    ]),
  ]),
  //b2c integration

  transition("vehicledet => customerdet", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ right: "-100%" })]),
    group([
      query(":leave", [animate("600ms ease", style({ right: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("600ms ease", style({ right: "0%" }))]),
    ]),
  ]),
  transition("customerdet => vehicledet", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ left: "-100%" })]),
    group([
      query(":leave", [animate("600ms ease", style({ left: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("600ms ease", style({ left: "0%" }))]),
    ]),
  ]),
  transition("customerdet => paymentdet", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ right: "-100%" })]),
    group([
      query(":leave", [animate("600ms ease", style({ right: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("600ms ease", style({ right: "0%" }))]),
    ]),
  ]),
  transition("paymentdet => customerdet", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ left: "-100%" })]),
    group([
      query(":leave", [animate("600ms ease", style({ left: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("600ms ease", style({ left: "0%" }))]),
    ]),
  ]),
  transition("paymentdet => policydet", [
    style({ position: "relative" }),
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
        }),
      ],
      { optional: true }
    ),
    query(":enter", [style({ right: "-100%" })]),
    group([
      query(":leave", [animate("600ms ease", style({ right: "100%" }))], {
        optional: true,
      }),
      query(":enter", [animate("600ms ease", style({ right: "0%" }))]),
    ]),
  ]),
]);

// function slideTo(direction) {
//   const optional = { optional: true };
//   return [
//     style({ position: 'relative' }),
//     query(':enter, :leave', [
//       style({
//         position: 'absolute',
//         top: 0,
//         [direction]: 0,
//         width: '100%'
//       })
//     ], optional),
//     query(':enter', [
//       style({ [direction]: '-100%'})
//     ]),
//     group([
//       query(':leave', [
//         animate('600ms ease', style({ [direction]: '100%'}))
//       ], optional),
//       query(':enter', [
//         animate('600ms ease', style({ [direction]: '0%'}))
//       ])
//     ]),

//     // Required only if you have child animations on the page
//     // query(':leave', animateChild()),
//     // query(':enter', animateChild()),
//   ];
// }
