import { Component, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TitleComponent {

  @Input() mobileTitle = '';
  @Input() desktopTitle = '';
  @Input() description = '';
 // @Input() backgroundColor = '#CFE9EE';
  @Input() backgroundColor = '#007D8C'

  //@Input() titleColor = '#363636';
  @Input() titleColor = '#fff';

  @Input() subTitleColor = '#363636';
  @Input() id: string ;
  @Input() subId: string;

  @Input() padding: string;

  constructor() { }

}
