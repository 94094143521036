import { Injectable, OnInit } from '@angular/core';
import { Subject, BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class WalletRefreshService {
    
    private walletRefreshSubject: Subject<boolean> = new BehaviorSubject<boolean>(null);
    walletBalanceListner = this.walletRefreshSubject.asObservable();

    walletRefresh(refresh: boolean): void {
        this.walletRefreshSubject.next(refresh);
    }


   

}