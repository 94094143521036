<table nxTable condensed nxSort (sortChange)="sortTable($event)">
  <thead>
      <tr nxTableRow>
          <th nxHeaderCell nxSortHeaderCell="policyNo">
              Policy Number
          </th>
          <th nxHeaderCell nxSortHeaderCell="vinChassisNo">
              Vin Number
          </th>
          <th nxHeaderCell nxSortHeaderCell="customerFirstName">
              Customer Name
          </th>
          <th nxHeaderCell nxSortHeaderCell="invoiceDate">
              Policy Sale Date
          </th>
          <th nxHeaderCell nxSortHeaderCell="effectiveDate">
              Policy Start Date
          </th>
          <th nxHeaderCell nxSortHeaderCell="policyStatusId">
              Status
          </th>
          <th nxHeaderCell nxSortHeaderCell="price">
              Total Amount
          </th>
      </tr>
  </thead>
  <tbody style="text-align: center;">
      <tr nxTableRow *ngFor="let item of ReportData">
          <td nxTableCell>{{item.policyNo}}</td>
          <td nxTableCell>{{item.vinChassisNo}}</td>
          <td nxTableCell>{{item.customerFirstName}} {{item.customerLastName}}</td>
          <td nxTableCell>{{item.invoiceDate | date:'MM/dd/yyyy'}}</td>
          <td nxTableCell>{{item.effectiveDate | date:'MM/dd/yyyy'}}</td>
          <td nxTableCell *ngIf="item.policyStatusId == policyStatus.Active">
              <nx-badge  type="positive">Active</nx-badge>
          </td>
          <td nxTableCell *ngIf="item.policyStatusId == policyStatus.Cancel">
              <nx-badge   type="critical">Cancel</nx-badge>
          </td>
          <td nxTableCell *ngIf="item.policyStatusId == policyStatus.Modified">
              <nx-badge   type="active">Modified</nx-badge>
          </td>
          <td nxTableCell *ngIf="item.policyStatusId == policyStatus.CancelationApprovalPending">
              <nx-badge type="critical" [nxTooltipPosition]="'top'"
                  [nxTooltip]="'Cancelation Approval Pending'">Pending</nx-badge>
          </td>
          <td nxTableCell *ngIf="item.policyStatusId == policyStatus.ModificationApprovalPending">
              <nx-badge   type="negative" [nxTooltipPosition]="'top'"
                  [nxTooltip]="'Modification Approval Pending'">Pending</nx-badge>
          </td>
          <td nxTableCell>{{item.price}}</td>
      </tr>
      <tr nxTableRow *ngIf="count==0">
        <td nxTableCell colspan="11">
          <ng-container>
              <div nxRow nxRowJustify="center">
                <div nxCol="12" class="nosearchresult"></div>
                <h3 nxHeadline="subsection-medium" class="nx-font-weight-semibold">No Records found !!!</h3>
              </div>
            </ng-container>
        </td>
    </tr>
  </tbody>
</table>

<div nxRow class="nx-margin-top-2m nx-margin-right-5xl" style="float: right;">
  <div nxCol="6">
      <nx-pagination [nxCount]="count" [nxPage]="pageNumber" [nxPerPage]="pageSize" nxType="advanced"
          (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
      </nx-pagination>
  </div>
</div>

