import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { SortDirection, SortEvent } from '@aposin/ng-aquila/table';

@Component({
  selector: 'app-zonemanagement',
  templateUrl: './zonemanagement.component.html',
  styleUrls: ['./zonemanagement.component.css']
})
export class ZonemanagementComponent implements OnInit {

  _apiUrl: string;
  clientId: number;
  zoneList: any;
  zoneModalOpen: boolean = false;
  zoneForm: FormGroup;
  EDIT_FLAG: boolean = false;
  confirmModalOpen: boolean = false;
  isSuccess: boolean = false;
  zoneData: any = {};
  Id: number = 0;

  toggleModal() {
    this.EDIT_FLAG = false;
    this.useFormBuilder();
    this.isSuccess = false;
    this.zoneModalOpen = !this.zoneModalOpen;
  }

   /** sorting starts here */
sortTable(sort: SortEvent) {//Sorts the table data by a certain category.
  this.zoneList = this.zoneList.sort((a, b) => {
    if (sort.active in a && sort.active in b) {
      return this.compare(a[sort.active], b[sort.active], sort.direction);
    }
    return 0;
  });
}

compare(
  a: number | string | Date,
  b: number | string | Date,
  direction: SortDirection) {

  return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
}
/** sorting ends here */

/**Pagination Start */
count: number;
pageNumber: number = 1;
pageSize: number = 10;
searchTerm: any = "";
prevPage() {
  this.pageNumber--;
  this.getZones();
}

nextPage() {
  this.pageNumber++;
  this.getZones();
}

goToPage(n: number) {
  this.pageNumber = n;
    this.getZones();
}
/**Pagination end */
  constructor(private authService: AuthService, private appConfig : AppConfig) {

    this._apiUrl = appConfig.baseUrl;
    this.useFormBuilder();
  }

  ngOnInit() {
    this.getZones();
  }

  useFormBuilder() {
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.zoneForm = new FormBuilder().group({
      Id: 0,
      Zone: ['', Validators.required],
      createdBy: localStorage.getItem('USER_NAME'),
      createdDate: new Date(),
      updatedBy: localStorage.getItem('USER_NAME'),
      updatedDate: new Date(),
      isActive: 1,
      clientId: this.clientId
    });
  }
  //get all zones
  getZones() {
     this.authService.getList(this._apiUrl + "/api/v1/Zones/client/" + this.clientId + "?pageNumber=" + this.pageNumber + "&pageSize=" + this.pageSize + "&searchText=" + this.searchTerm).subscribe(response => {
      if (response) {
        this.zoneList = response.body;
        var pagingProp = JSON.parse(response.headers.get('X-Pagination'));
        this.count = pagingProp.TotalCount;
      }
    }, (error) => {
      console.error(error);
    });
  }
//add zone
  onSave() {
    this.authService.post(this._apiUrl + '/api/v1/Zones', this.zoneForm.value).subscribe(response => {
      if (response) {
        this.getZones();
        this.isSuccess = true;
      }
    }, (error) => {
      console.error(error);
    });
  }
//update Zone
  onUpdate() {
    this.authService.put(this._apiUrl + '/api/v1/Zones/' + this.Id, this.zoneForm.value).subscribe(response => {
      this.getZones();
      this.isSuccess = true;
    }, (error) => {
      console.error(error);
    });
  }
  //get data for editing the zone
  editZone(id: number) {
    this.Id = id;
    this.authService.get(this._apiUrl + '/api/v1/Zones/' + this.Id).subscribe(response => {
      if (response) {
        this.zoneForm.patchValue({
          Id: response.id,
          Zone: response.zone,
          createdBy: response.createdBy,
          createdDate: response.createdDate,
          updatedBy: response.updatedBy,
          updatedDate: response.updatedDate,
          isActive: response.isActive,
          clientId: this.clientId
        });
        this.zoneModalOpen = true;
        this.EDIT_FLAG = true;
      }
    }, (error) => {
      console.error(error);
    });
  }
  //activate deactivate zones
  updateStatus() {
    this.confirmModalOpen = false;
    this.authService.put
      (this._apiUrl + '/api/v1/Zones/' + this.zoneData.id, this.zoneData).subscribe(response => {
        if (response) {
          this.getZones();
        }
      }, (error) => {
        console.error(error);
      });

  }
  confirmModal(data: any, isChecked: boolean) { //modal yes button click

    this.confirmModalOpen = true;
    data.isActive = isChecked ? 1: 0; //set the current status
    data.updatedBy = localStorage.getItem('USER_NAME');
    this.zoneData = data;

  }
  onNoClick() { //modal no button click
    this.confirmModalOpen = false;
    this.zoneData = {};
    this.getZones();
  }

}
