import { Pipe, PipeTransform } from '@angular/core';
import { UserType } from '../core/userType.model';

@Pipe({
  name: 'filterUserRole'
})
export class CheckUserRolePipe implements PipeTransform {
  BaseType: number = parseInt(atob(localStorage.getItem("USER_BASE_TYPE_ID")));
  transform(value: any): any {
    switch (this.BaseType) {
      case UserType.SysAdmin: {
        return value;
      }
      case UserType.Admin: {
        let result = value.filter(t => (t.userBaseTypeId != UserType.Admin && t.userBaseTypeId != UserType.SysAdmin && t.userBaseTypeId != UserType.AzPAdmin));
        // console.log(result);
        return result;
      }
      case UserType.AzPAdmin: {
        let result = value.filter(t => (t.userBaseTypeId != UserType.SysAdmin));
        // console.log(result);
        return result;
      }
      case UserType.NSM: {
        let result = value.filter(t => (t.userBaseTypeId != UserType.Admin && t.userBaseTypeId != UserType.SysAdmin && t.userBaseTypeId != UserType.AzPAdmin && t.userBaseTypeId != UserType.NSM));
        // console.log(result);
        return result;
      }
      case UserType.RSM: {
        let result = value.filter(t => (t.userBaseTypeId != UserType.Admin && t.userBaseTypeId != UserType.SysAdmin && t.userBaseTypeId != UserType.AzPAdmin && t.userBaseTypeId != UserType.NSM && t.userBaseTypeId != UserType.RSM));
        // console.log(result);
        return result;
      }
      case UserType.BD: {
        let result = value.filter(t => (t.userBaseTypeId != UserType.Admin && t.userBaseTypeId != UserType.SysAdmin && t.userBaseTypeId != UserType.AzPAdmin && t.userBaseTypeId != UserType.NSM && t.userBaseTypeId != UserType.RSM && t.userBaseTypeId != UserType.BD));
        // console.log(result);
        return result;
      }
      default: {
        return value;
      }
    }
  }

}
