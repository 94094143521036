import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import {SharedModule } from '../shared/shared.module';
import { ManageusersComponent } from '../manageusers/manageusers.component';  
import { UserlistComponent } from '../userlist/userlist.component';  
import { ColormanagementComponent } from '../colormanagement/colormanagement.component';
import { ModelmanagementComponent } from '../modelmanagement/modelmanagement.component';  
import { PlanComponent } from '../plan/plan.component';  
import { MasterComponent } from '../master/master.component';
import { PolicymasterComponent } from '../policymaster/policymaster.component';


import { NxCardModule } from '@aposin/ng-aquila/card'; 
import { NxLinkModule } from '@aposin/ng-aquila/link';
import { NxGridModule } from '@aposin/ng-aquila/grid';  
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield'; 
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';  
import { NxPopoverModule } from '@aposin/ng-aquila/popover';  
import { NxIconModule } from '@aposin/ng-aquila/icon';  
import { NxLabelModule, NxErrorModule } from '@aposin/ng-aquila/base'; 
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxTabsModule } from '@aposin/ng-aquila/tabs';
import { NxTableModule } from '@aposin/ng-aquila/table';  
import { NxBadgeModule } from '@aposin/ng-aquila/badge';  
import { NxSwitcherModule } from '@aposin/ng-aquila/switcher';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxDatefieldModule } from '@aposin/ng-aquila/datefield';
import { NxNativeDateModule } from '@aposin/ng-aquila/datefield';
import { NxMomentDateModule } from '@aposin/ng-aquila/moment-date-adapter';
import { NxSpinnerModule } from '@aposin/ng-aquila/spinner'; 
import { NxPaginationModule } from '@aposin/ng-aquila/pagination'; 
import { NxMessageModule } from '@aposin/ng-aquila/message'; 
import { NxCopytextModule } from '@aposin/ng-aquila/copytext'; 
import { ZonemanagementComponent } from '../zonemanagement/zonemanagement.component';
import { ClientmanagementComponent } from '../clientmanagement/clientmanagement.component';
import { StatemasterComponent } from '../statemaster/statemaster.component';
import { CitymasterComponent } from '../citymaster/citymaster.component';
import { PageRoutingConfigComponent } from '../page-routing-config/page-routing-config.component';
import { TitleModule } from '../title/title.module';
import { NxCheckboxModule } from '@aposin/ng-aquila/checkbox';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxContextMenuModule } from '@aposin/ng-aquila/context-menu';
import { ManageContentComponent } from '../manage-content/manage-content.component';
import { NxTooltipModule } from '@aposin/ng-aquila/tooltip';
import { CesstaxmasterComponent } from '../cesstaxmaster/cesstaxmaster.component';
import { NxMaskModule } from '@aposin/ng-aquila/mask';
import { NxFileUploaderModule } from '@aposin/ng-aquila/file-uploader';
import { ConfigEncryptionComponent } from '../config-encryption/config-encryption.component';
import { PortaltransactionoverviewComponent } from '../portaltransactionoverview/portaltransactionoverview.component';
import { NxRadioModule } from '@aposin/ng-aquila/radio-button';
import { NxAccordionModule } from '@aposin/ng-aquila/accordion';

@NgModule({
  declarations: [
    ManageusersComponent, 
    UserlistComponent, 
    ColormanagementComponent, 
    ModelmanagementComponent,
    PlanComponent,
    MasterComponent,
    PolicymasterComponent,
    ZonemanagementComponent,
    ClientmanagementComponent,
    StatemasterComponent,
    CitymasterComponent,
    PageRoutingConfigComponent,
    ManageContentComponent,
    CesstaxmasterComponent,
    ConfigEncryptionComponent,
    PortaltransactionoverviewComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    NxCardModule,
    NxLinkModule,
    NxGridModule,
    NxFormfieldModule,
    NxInputModule,
    NxDropdownModule,
    NxPopoverModule,
    NxIconModule,
    NxLabelModule,
    NxErrorModule,
    FormsModule,
    ReactiveFormsModule,
    NxButtonModule,
    NxTabsModule,
    NxTableModule,
    NxBadgeModule,
    NxSwitcherModule,
    NxModalModule,
    NxModalModule.forRoot(),
    NxDatefieldModule,
    NxNativeDateModule,
    NxMomentDateModule,
    NxSpinnerModule,
    NxPaginationModule,
    NxMessageModule,
    NxCopytextModule,
    TitleModule,
    NxCheckboxModule,
    NxHeadlineModule,
    NxContextMenuModule,
    NxTooltipModule,
    NxMaskModule,
    NxFileUploaderModule,   
    NxRadioModule,
    NxAccordionModule,
    NxSwitcherModule
  ],

  exports:[ManageusersComponent,UserlistComponent,ColormanagementComponent,ModelmanagementComponent,PlanComponent,MasterComponent,PolicymasterComponent]
})
export class AdminModule { }
