import { FileItem, NxFileUploaderComponent } from '@aposin/ng-aquila/file-uploader';
import { NxMessageToastConfig, NxMessageToastService } from '@aposin/ng-aquila/message';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { AppConfig } from 'src/config/app.config';
import { WalletRefreshService } from '../walletbalance/walletbalance-service';

const myCustomConfig: NxMessageToastConfig = {
  duration: 3000,
  context: 'success',
  announcementMessage: 'Bulk policy sale was uploaded successfully!'
};
@Component({
  selector: 'app-bulkupload',
  templateUrl: './bulkupload.component.html',
  styleUrls: ['./bulkupload.component.css']
})
export class BulkuploadComponent implements OnInit {
  BulkuploadForm: FormGroup;
  private _apiUrl: string;
  BulkUploadForm: FormGroup;
  @ViewChild('documentUpload', { static: false }) documentUpload: NxFileUploaderComponent;
  errormsg: any;
  public showUploadError: boolean = false;
  private _base_url: any;

  constructor(private messageToastService: NxMessageToastService, private http: HttpClient, private appConfig: AppConfig, private fb: FormBuilder,
    private walletRefreshService: WalletRefreshService, @Inject('BASE_URL') baseURL
  ) {
    this._apiUrl = appConfig.baseUrl;
    this._base_url=baseURL
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.BulkUploadForm = this.fb.group({
      documents: [[], Validators.required]
    });
  }

  onChange($event: FileItem[]) {
    this.showUploadError = false;
  }

  onDelete($event: FileItem) {
    this.showUploadError = false;
  }
  uploadpolicies() {
    var url: string = this._apiUrl + '/api/v1/BulkUpload';
    this.showUploadError = false;
    if (!this.documentUpload.value) {
      return;
    }
    const formData = new FormData();

    this.documentUpload.value.forEach((fileItem: FileItem) => {
      if (!fileItem.isUploaded) {
        fileItem.setUploadingState();
        formData.append('uploads[]', fileItem.file, fileItem.name);
      }
    });


    this.http.post(url, formData, { reportProgress: true }).subscribe(
      data => {
        var response: any = data;
        console.log(response);
        if (response.code == 200) {
          this.documentUpload.value.forEach((fileItem: FileItem) => {
            fileItem.setUploadedState();
          });
          this.messageToastService.open('Policies were uploaded successfully!', myCustomConfig);
          this.walletRefreshService.walletRefresh(true);
        }
        else {
          this.documentUpload.value.forEach((fileItem: FileItem) => {
            if (!fileItem.isUploaded) {
              fileItem.setErrorState();
            }
          });
          this.errormsg = response.message;
          this.showUploadError = true;
        }
      },
      error => {
        if (error.status == 200) {
          var blob = new Blob([error.error.text], { type: "application/vnd.ms-excel;charset=utf-8" });
          FileSaver.saveAs(blob, "ERRORREPORT.xls");
          this.walletRefreshService.walletRefresh(true);
        }
        this.documentUpload.value.forEach((fileItem: FileItem) => {
          if (!fileItem.isUploaded) {
            fileItem.setErrorState();
          }
        });
        this.errormsg = 'Error occured while uploading!. Check downloaded file for more details.';
        this.showUploadError = true;
      }
    );
  }

  downloadExcelTemplate() {
    var excelurl: string = this._base_url + "assets/ExcelTemplate/BulkUploadFormat.xls";
    window.open(excelurl);
  }
}
