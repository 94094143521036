import { NxSortHeaderIntl, SortDirection, SortEvent } from '@aposin/ng-aquila/table';
import { Component, Injectable, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppConfig } from 'src/config/app.config';
import { AuthService } from '../core/auth/auth.service';
import { UserType } from '../core/userType.model';

    @Injectable()
export class MyIntl extends NxSortHeaderIntl {
  sortAscendingAriaLabel = 'sort ascending by';
  sortDescendingAriaLabel = 'sort descending by';
}

/**
* @title Advanced example for sorting, filtering and pagination
*/
@Component({
  selector: 'app-portaltransactionoverview',
  templateUrl: './portaltransactionoverview.component.html',
  styleUrls: ['./portaltransactionoverview.component.css'],
  providers: [
    { provide: NxSortHeaderIntl, useClass: MyIntl },
  ],
})

export class PortaltransactionoverviewComponent {
  tableElements: any = [];

  currentlyShownPageElements: any;
  currentlyAvailableElements: any;
  policyStartDate: moment.Moment;
  policyEndDate: moment.Moment;

  private _apiUrl: any;
  page: number = 1;
  filterValue: string ="";
  elementsPerPage: number = 10;
  nooverview: boolean;
  isCorrect: string;
  count: number = 0;

  /**
   * Sorts the table data by a certain category.
   */
  sortTable(sort: SortEvent) {
    this.currentlyAvailableElements = this.currentlyAvailableElements.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });

    this.updatePage();
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection) {

    return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
  }

  constructor(private _appConfig : AppConfig,private authService: AuthService) {
    this._apiUrl = _appConfig.baseUrl;

    
    // init first page on page load
    this.getdetails()
  }

  onFilterValueChange(value) {
    this.getdetails()
  }

  filterData(filterValue: string) {
    this.currentlyAvailableElements = this.tableElements.filter(tableRowObject => {
      return Object.values(tableRowObject).some(propertyValue => new RegExp(filterValue, 'i').test(String(propertyValue)));
    });

  }

  updatePage() {
    const indexMin = (this.page - 1) * this.elementsPerPage;
    const indexMax = indexMin + this.elementsPerPage;
    this.currentlyShownPageElements = this.currentlyAvailableElements.filter(
      (x, index) => index >= indexMin && index < indexMax
    );
  }

  prevPage() {
    this.page--;
    this.getdetails()
  }

  nextPage() {
    this.page++;
    this.getdetails()
  }

  goToPage(n: number) {
    this.page = n;
    this.getdetails()
  }
  getdetails() {
    var policyStartDate = this.policyStartDate == undefined ? "" : this.policyStartDate.format('LL');
    var policyEndDate = this.policyEndDate == undefined ? "" : this.policyEndDate.format('LL');
    this.authService.getList(this._apiUrl + `/api/v1/Report/overview?pageNumber=${this.page} &pageSize=${this.elementsPerPage} 
    +&searchText=${this.filterValue}&startDatepicker=${policyStartDate}&endDatepicker=${policyEndDate}`).
    subscribe(response => {
      this.nooverview=false;
      this.isCorrect ="positive";
      if(response.body.success)
      {
        this.tableElements = response.body.data;
        this.currentlyShownPageElements = this.tableElements;
        var pagingProp = JSON.parse(response.headers.get('X-Pagination'));
        this.count = pagingProp.TotalCount;
      }
      else{
        this.nooverview=true;
      }
    }, (error) => {
      console.error(error);
    });
  }
}



