<app-header></app-header>
<app-menu></app-menu>

<nx-multi-stepper [linear]="true" currentStepLabel="Step" class="nx-margin-top-m" >
    <ng-container *ngFor="let step of multiSteps">
      <nx-step [label]="step.name" [completed]="step.completed">
        
      </nx-step>
    </ng-container>
  </nx-multi-stepper>

<div [@routeAnimations]="prepareRoute(outlet)" class="content">
    <router-outlet #outlet="outlet"></router-outlet>
</div>

<app-footer class="footer"></app-footer>