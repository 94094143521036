import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';

@Component({
  selector: "app-user-type",
  templateUrl: "./user-type.component.html",
  styleUrls: ["./user-type.component.css"],
})
export class UserTypeComponent implements OnInit {
  modalOpen: boolean = false;
  confirmModalOpen: boolean = false;
  addUserType: boolean = false;
  userTypeForm: FormGroup;
  _apiUrl: string;
  _userRole: string;
  _userId: number;
  userTypeList = [];
  isSuccess: boolean = false;
  EDIT_FLAG: boolean;
  userTypeId;
  userTypeDetails = {};
  clientList = [];
  clientId: number;
  baseUserTypes: any;

  constructor(
    private authService: AuthService,
    private appConfig : AppConfig
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.userTypeFormBuilder();
  }

  ngOnInit() {
    this.getClientList();
    this.getBaseUserTypeList();
  }

  userTypeFormBuilder() {
    this.userTypeForm = new FormBuilder().group({
      userTypeId: 0,
      userType: ["", Validators.required],
      baseType: ["", Validators.required],
      clientId: this.clientId,
      isActive: 1,
    });
  }

  getUserTypeList() {
    this.authService
    .get(this._apiUrl + `/api/v1/UserTypes/client/${this.clientId}`)
      .subscribe(
        (response) => {
          if (response) {
            this.userTypeList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  toggleModal(EDIT: boolean, id) {
    this.EDIT_FLAG = EDIT;
    this.userTypeId = id;
    this.modalOpen = !this.modalOpen;
    this.isSuccess = false;
    if (this.EDIT_FLAG) {
      this.getUserTypeDetails(id);
    }
    if (!this.modalOpen) {
      this.userTypeFormBuilder();
    }
  }

  addUserTypeMaster() {
    this.userTypeForm.patchValue({ clientId: this.clientId });
    this.authService
      .post(this._apiUrl + "/api/v1/UserTypes", this.userTypeForm.value)
      .subscribe(
        (response) => {
          if (response) {
            this.getUserTypeList();
            this.isSuccess = true;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getUserTypeDetails(id) {
    this.authService.get(this._apiUrl + "/api/v1/UserTypes/" + id).subscribe(
      (response) => {
        if (response) {
          this.userTypeForm.setValue({
            userType: response.userType,
            isActive: response.isActive,
            clientId: response.clientId,
            userTypeId: response.userTypeId,
            baseType : response.baseType
          });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
  updateUserTypeMaster() {
    this.authService
      .put(
        this._apiUrl + "/api/v1/UserTypes/" + this.userTypeId,
        this.userTypeForm.value
      )
      .subscribe(
        (response) => {
          this.getUserTypeList();
          this.isSuccess = true;
        },
        (error) => {
          console.error(error);
        }
      );
  }

  confirmModalClose() {
    this.confirmModalOpen = false;
    this.getUserTypeList();
    this.userTypeFormBuilder();
  }

  confirmModal(userTypeDetails, isChecked: boolean) {
    this.confirmModalOpen = true;
    this.userTypeForm.setValue({
      userTypeId: userTypeDetails.userTypeId,
      userType: userTypeDetails.userType,
      isActive: isChecked ? 1 : 0,
      clientId: userTypeDetails.clientId,
      baseType: userTypeDetails.baseType
    });
  }

  changeUserTypeStatus() {
    this.authService
      .put(
        this._apiUrl +
          "/api/v1/UserTypes/" +
          this.userTypeForm.controls.userTypeId.value,
        this.userTypeForm.value
      )
      .subscribe(
        (response) => {
          this.getUserTypeList();
          this.confirmModalClose();
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getClientList() {
    this.authService.get(this._apiUrl + "/api/v1/Client").subscribe(
      (response) => {
        if (response) {
          this.clientList = response;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  onClientChanges(clientid) {
    this.addUserType = true;
    this.clientId = clientid;
    this.getUserTypeList();
  }

  getBaseUserTypeList() {
    this.authService.get(this._apiUrl + "/api/v1/BaseUserTypes").subscribe(
      (response) => {
        if (response) {
          this.baseUserTypes = response;
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
