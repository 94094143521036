import { NxMessageToastService } from '@aposin/ng-aquila/message';
import { SortDirection, SortEvent } from '@aposin/ng-aquila/table';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from 'src/config/app.config';
import { AuthService } from '../core/auth/auth.service';

@Component({
  selector: 'app-e-invoice',
  templateUrl: './e-invoice.component.html',
  styleUrls: ['./e-invoice.component.css']
})
export class EInvoiceComponent implements OnInit {
  
  /**Pagination Start */
  count: number;
  pageNumber: number = 1;
  pageSize: number = 10;
  response: any;
  checkUploadPath: any;
  policyNumber: any;
  einvoice: { policyNo: any; filePath: any; };
  
  prevPage() {
    this.pageNumber--;
    this.getEInvoiceList();
  }

  nextPage() {
    this.pageNumber++;
    this.getEInvoiceList();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getEInvoiceList();
  }
  
  /**Pagination end */
  /** sorting starts here */

   sortTable(sort: SortEvent) {//Sorts the table data by a certain category.
    this.policyList = this.policyList.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection) {

    return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
  }
  /** sorting ends here */

  searchTerm: any = "";
  private _apiUrl: string;
  clientId: number;
  userTypeId: number;
  policyList: any;
  UploadInvoiceModal: boolean = false;
  invoiceStatus: string = 'Pending';
  accpetedTypes:string ="application/pdf";
  uploadPath:string ="/api/v1/Upload/EInvoice";

  constructor(private authService: AuthService,
    private appConfig : AppConfig,
    private messageToastService: NxMessageToastService,
    private router: ActivatedRoute,) { 
      this._apiUrl = appConfig.baseUrl;
      this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
      this.userTypeId = parseInt(atob(localStorage.getItem("USER_TYPE_ID")))
    }

  ngOnInit(): void {

    this.router.queryParams.subscribe(params => {
      if(params.InvoiceStatus)
       this.invoiceStatus = params.InvoiceStatus;
    });


    this.getEInvoiceList();
  }

  onStatusChanges() {
    this.getEInvoiceList();
  }

  getEInvoiceList() {
    this.authService
      .getList(this._apiUrl + `/api/v1/PolicyDetails/einvocelist/${this.invoiceStatus}` + "?pageNumber=" + this.pageNumber + "&pageSize=" + this.pageSize 
                    + "&searchText=" + this.searchTerm)
      .subscribe(
        (response) => {
          if (response) {
            this.policyList = response.body;
            var pagingProp = JSON.parse(response.headers.get('X-Pagination'));
            this.count = pagingProp.TotalCount;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  download() {
    this.authService
      .export(
        this._apiUrl +
        "/api/v1/DownLoadPage/finance/einvoice/" + this.policyNumber
      )
      .subscribe((data) => saveAs(data, this.policyNumber + `_invoiceFormat.xlsx`));
  }

  upload($event, policyNo) {
    this.policyNumber = policyNo;
    this.UploadInvoiceModal = true;
  }

  public checkUploadFinished = (event) => {
    this.response = event;
    this.checkUploadPath = this.response.dbPath;
    this.saveInvoicePath(this.checkUploadPath);
  }

  saveInvoicePath(checkUploadPath: any) {
    this.einvoice = {
      policyNo: this.policyNumber,
      filePath: checkUploadPath,
    };

    this.authService
      .post(this._apiUrl + `/api/v1/PolicyDetails/einvoicepath`, this.einvoice)
      .subscribe(
        (response) => {
          this.getEInvoiceList();
        },
        (error) => {
          console.error(error);
        }
      );
  }


  modalClose() {
    this.UploadInvoiceModal = false;
    this.getEInvoiceList();
  }
}
