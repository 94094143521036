import { HttpClient, HttpParams } from "@angular/common/http";
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  EventEmitter,
  Input,
  Output,
  Inject,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import {
  FileItem,
  NxFileUploaderComponent,
} from "@aposin/ng-aquila/file-uploader";
import { AppConfig } from "src/config/app.config";
import {
  NxFileUploadConfig,
  NxFileUploader,
} from "@aposin/ng-aquila/file-uploader";
import { Subject } from "rxjs";
import { CustomErrorHandler } from "../core/custom-error-handler";
import { NxDialogService, NX_MODAL_DATA } from "@aposin/ng-aquila/modal";
import { AuthService } from "../core/auth/auth.service";
@Component({
  selector: "app-upload-invoice",
  templateUrl: "./upload-invoice.component.html",
  styleUrls: ["./upload-invoice.component.css"],
})
export class UploadInvoiceComponent implements OnInit, OnDestroy {
  public progress: number;
  public message: string;
  @Input() acceptedfiles: string;
  @Input() uploadPath: string;
  @Output() public onUploadFinished = new EventEmitter();
  private _apiUrl: string;
  @ViewChild("documentUpload", { static: false })
  documentUpload: NxFileUploaderComponent;
  public showUploadError: boolean = false;
  errormsg: any;
  uploadedInvoiceForm: FormGroup;
  modalOpen: boolean;
  uploadedInvoices: boolean;
  uploadType: string = "S";
  userBaseTypeId: number;
  month: number;
  year: number;
  dealercode: number;
  invoiceList: [];
  uploadConfig: NxFileUploadConfig = {
    requestUrl: "file-upload",
    options: {
      params: new HttpParams(),
      reportProgress: true,
    },
  };

  uploader = new NxFileUploader(this.uploadConfig, this.http);

  uploadInvoiceForm = new FormGroup({
    documents: new FormControl([], Validators.required),
  });

  private readonly _destroyed = new Subject<void>();

  constructor(
    private http: HttpClient,
    private appConfig: AppConfig,
    private fb: FormBuilder,
    private errorHandler: CustomErrorHandler,
    private authService: AuthService,
    public dialogService: NxDialogService,
    @Inject(NX_MODAL_DATA) public data: any
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.userBaseTypeId = parseInt(
      atob(localStorage.getItem("USER_BASE_TYPE_ID"))
    );
  }

  ngOnInit() {
    this.uploadInvoiceForm = this.fb.group({
      documents: [[]],
      types: [""],
    });
    if (typeof this.data !== "undefined") {
      this.month = this.data.month;
      this.year = this.data.year;
      this.dealercode = this.data.dealer;
      
    }
    if (this.userBaseTypeId == 8) {
      this.uploadedInvoices = true;
      this.modalOpen = false;
      this.getUploadedInvoice();
    } else {
      this.modalOpen = true;
      this.uploadedInvoices = false;
    }
  }
  //Upload Invoice
  upload() {
    var uploadtype = this.uploadType;
    var url: string =
      this._apiUrl +
      "/api/v1/Upload/DealerInvoice/" +
      this.month +
      "/" +
      this.year +
      "/" +
      uploadtype;

    if (this.uploadPath != null || this.uploadPath != undefined) {
      url = this._apiUrl + this.uploadPath;
    }

    this.showUploadError = false;
    if (!this.documentUpload.value) {
      return;
    }
    const formData = new FormData();
    this.documentUpload.value.forEach((fileItem: FileItem) => {
      if (!fileItem.isUploaded) {
        fileItem.setUploadingState();
        formData.append("uploads[]", fileItem.file, fileItem.name);
      }
    });
    if (
      !this.uploadInvoiceForm.controls["documents"].hasError(
        "NxFileUploadFileTypeNotAccepted"
      ) &&
      !this.uploadInvoiceForm.controls["documents"].hasError(
        "NxFileUploadMaxFileSize"
      )
    ) {
      this.http.post(url, formData, { reportProgress: true }).subscribe(
        (data) => {
          var response: any = data;
          if (response.code == 200) {
            this.documentUpload.value.forEach((fileItem: FileItem) => {
              fileItem.setUploadedState();
            });
            this.onUploadFinished.emit(response);
            this.closeModal();
            this.errorHandler.handleCustomSuccess(
              "Dealer Invoice Uploaded successfully!"
            );
          } else {
            this.documentUpload.value.forEach((fileItem: FileItem) => {
              if (!fileItem.isUploaded) {
                fileItem.setErrorState();
              }
            });
            this.errormsg = response.message;
            this.showUploadError = true;
          }
        },
        (error) => {
          this.documentUpload.value.forEach((fileItem: FileItem) => {
            if (!fileItem.isUploaded) {
              fileItem.setErrorState();
            }
          });
          this.errormsg = "error occured while uploading.";
          this.showUploadError = true;
        }
      );
    }
  }
  //Download each Invoices
  download(items?: any) {
    this.authService
      .export(this._apiUrl + "/api/v1/DownLoadPage/uploadedInvoice/" + items.id)
      .subscribe((data) => saveAs(data, items.id + `_report.pdf`));
  }
  //Get all uploaded Invoices
  getUploadedInvoice() {
    this.authService
      .get(
        this._apiUrl +
          "/api/v1/upload/UploadedInvoices" +
          "?dealercode=" +
          this.dealercode +
          "&month=" +
          this.month +
          "&year=" +
          this.year +
          "&pageNumber=-1"
      )
      .subscribe(
        (response) => {
          if (response) {
            this.invoiceList = response;
          }
        },
        (error) => {
          this.errorHandler.handleError(error);
        }
      );
    
  }
  closeModal() {
    this.dialogService.openModals[0].close();
  }
  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
