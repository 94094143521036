import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'src/config/app.config';
import { FileItem, NxFileUploaderComponent } from '@aposin/ng-aquila/file-uploader';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  public progress: number;
  public message: string;
  @Input() acceptedfiles: string;
  @Input() uploadPath: string;
  @Output() public onUploadFinished = new EventEmitter();
  private _apiUrl: string;
  @ViewChild('documentUpload', { static: false }) documentUpload: NxFileUploaderComponent;
  public showUploadError: boolean = false;
  errormsg: any;
  testForm: FormGroup;
  constructor(private http: HttpClient, private appConfig: AppConfig, private fb: FormBuilder,) {
    this._apiUrl = appConfig.baseUrl;
  }

  ngOnInit() {
    this.testForm = this.fb.group({
      documents: [[]]
    });

  }

  ngAfterViewInit(): void {
    if (this.acceptedfiles == 'undefined')
      this.acceptedfiles = 'application/pdf,image/*'
    this.documentUpload.accept = this.acceptedfiles;
  }

  upload() {
    var url: string = this._apiUrl + '/api/v1/Upload';

    if(this.uploadPath != null || this.uploadPath != undefined){
      url = this._apiUrl + this.uploadPath;
    }

    this.showUploadError = false;
    if (!this.documentUpload.value) {
      return;
    }
    const formData = new FormData();
    this.documentUpload.value.forEach((fileItem: FileItem) => {
      if (!fileItem.isUploaded) {
        fileItem.setUploadingState();
        formData.append('uploads[]', fileItem.file, fileItem.name);
      }
    });
    if (!this.testForm.controls['documents'].hasError('NxFileUploadFileTypeNotAccepted') && !this.testForm.controls['documents'].hasError('NxFileUploadMaxFileSize')) {
      this.http.post(url, formData, { reportProgress: true }).subscribe(
        data => {
          var response: any = data;
          if (response.code == 200) {
            this.documentUpload.value.forEach((fileItem: FileItem) => {
              fileItem.setUploadedState();
            });
            this.onUploadFinished.emit(response);
          }
          else {
            this.documentUpload.value.forEach((fileItem: FileItem) => {
              if (!fileItem.isUploaded) {
                fileItem.setErrorState();
              }
            });
            this.errormsg = response.message;
            this.showUploadError = true;
          }
        },
        error => {
          this.documentUpload.value.forEach((fileItem: FileItem) => {
            if (!fileItem.isUploaded) {
              fileItem.setErrorState();
            }
          });
          this.errormsg = 'error occured while uploading.';
          this.showUploadError = true;
        }
      );
    }

  }

}