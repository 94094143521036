<app-header></app-header>
<app-menu></app-menu>
<div nxLayout="grid">
  <div nxRow>
    <div nxCol="12,12,6,2" nxColOffset="0,0,0,1" class="nx-margin-top-2m">
      <nx-formfield nxLabel="Select Report Type">
        <nx-dropdown
          #reportType
          [(ngModel)]="requestType"
          (selectionChange)="onReportTypeChange()"
        >
          <nx-dropdown-item
            *ngFor="let item of reportTypeData"
            [nxValue]="item.reportId"
          >
            {{ item.reportName }}
          </nx-dropdown-item>
        </nx-dropdown>
        <nx-error nxFormfieldError> Please select an option. </nx-error>
      </nx-formfield>
    </div>
    <div
      nxCol="12,12,6,2"
      class="nx-margin-top-2m"
      *ngIf="userBaseTypeId == 8 && !isFilterVisible"
    >
      <nx-formfield nxLabel="Select Dealer">
        <nx-dropdown [(ngModel)]="dealercode">
          <nx-dropdown-item
            *ngFor="let item of workshopList"
            [nxValue]="item.dealerCode"
          >
            {{ item.workshopName }}
          </nx-dropdown-item>
        </nx-dropdown>
        <nx-error nxFormfieldError>
          <strong>Please note:</strong> this field is required!
        </nx-error>
      </nx-formfield>
    </div>

    <div nxCol="12,12,6,2" class="nx-margin-top-2m">
      <nx-formfield nxLabel="Start date" *ngIf="isFilterVisible">
        <input
          nxDatefield
          nxInput
          [nxDatepicker]="startDatepicker"
          [(ngModel)]="policyStartDate"
        />
        <nx-datepicker-toggle
          [for]="startDatepicker"
          nxFormfieldSuffix
        ></nx-datepicker-toggle>
        <nx-datepicker #startDatepicker></nx-datepicker>
        <nx-error nxFormfieldError>
          <strong>Please note:</strong> this field is required!
        </nx-error>
      </nx-formfield>

      <nx-formfield
        nxLabel="Select Year"
        *ngIf="!isFilterVisible && dealerInvoice"
      >
        <nx-dropdown [(ngModel)]="year" (selectionChange)="getMonthList()">
          <nx-dropdown-item *ngFor="let item of years" [nxValue]="item.value">
            {{ item.year }}
          </nx-dropdown-item>
        </nx-dropdown>
        <nx-error nxFormfieldError>
          <strong>Please note:</strong> this field is required!
        </nx-error>
      </nx-formfield>
    </div>
    <div nxCol="12,12,6,2" class="nx-margin-top-2m">
      <nx-formfield nxLabel="End date" *ngIf="isFilterVisible">
        <input
          nxDatefield
          nxInput
          [nxDatepicker]="endDatepicker"
          [(ngModel)]="policyEndDate"
        />
        <nx-datepicker-toggle
          [for]="endDatepicker"
          nxFormfieldSuffix
        ></nx-datepicker-toggle>
        <nx-datepicker #endDatepicker></nx-datepicker>
        <nx-error nxFormfieldError>
          <strong>Please note:</strong> this field is required!
        </nx-error>
      </nx-formfield>
      <nx-formfield
        nxLabel="Select Month"
        *ngIf="!isFilterVisible && dealerInvoice"
      >
        <nx-dropdown [(ngModel)]="month">
          <nx-dropdown-item *ngFor="let item of months" [nxValue]="item.value">
            {{ item.month }}
          </nx-dropdown-item>
        </nx-dropdown>

        <nx-error nxFormfieldError>
          <strong>Please note:</strong> this field is required!
        </nx-error>
      </nx-formfield>
    </div>
    <div nxCol="12,12,6,6,4" nxColOffset="0,0,0,1" class="nx-margin-top-2m">
      <button
      nxButton="primary small-medium"
      type="button"
      class="nx-margin-top-3xs"
      (click)="getdetails(requestType)"
    >
      GET DETAILS
    </button>
      <button
        nxButton="secondary small-medium"
        class="nx-margin-left-2xs nx-margin-top-3xs"
        type="button"
        *ngIf="isFilterVisible"
        (click)="reset()"
      >
        Reset
      </button>
      <button
        nxButton="secondary small-medium"
        type="button"
        class="nx-margin-left-2m nx-margin-top-3xs"
        *ngIf="isFilterVisible"
        (click)="exporttoexcel()"
      >
        <nx-icon
          name="xls"
          class="nx-margin-right-2xs color-green"
        ></nx-icon
        >Export
      </button>
      <button
        nxButton="secondary small-medium"
        type="button"
        *ngIf="dealerInvoice"
        class="nx-margin-left-2xs nx-margin-top-3xs w-150"
        (click)="downloadInvoiceModalOpen()"
      >
        <nx-icon
          name="pdf"
          class="nx-margin-right-3xs color-red"
        ></nx-icon>
        Download
      </button>
      <button
        nxButton="secondary small-medium"
        type="button"
        *ngIf="dealerInvoice && userBaseTypeId !== 8"
        class="nx-margin-left-2xs nx-margin-top-3xs w-125"
        (click)="uploadInvoiceModalOpen()"
      >
        <nx-icon
          name="pdf"
          class="nx-margin-right-3xs color-red"
        ></nx-icon
        >{{ uploadText }}
      </button>
    </div>
  </div>

  <!-- Advanced Search -->
  <div nxRow *ngIf="isAdvancedSearch">
    <div nxCol="12,10" nxAlignSelf="center" nxColOffset="1">
      <nx-accordion>
        <nx-expansion-panel>
          <nx-expansion-panel-header>
            <nx-expansion-panel-title>
              Advanced Filter</nx-expansion-panel-title
            >
          </nx-expansion-panel-header>
          <div nxRow *ngIf="isAdvancedSearchFirstRow">
            <div nxCol="12,6" nxAlignSelf="center">
              <nx-formfield nxLabel="Policy No">
                <input nxInput [(ngModel)]="policyNo" type="text" />
              </nx-formfield>
            </div>
            <div nxCol="12,6" nxAlignSelf="center">
              <nx-formfield nxLabel="Policy type">
                <nx-dropdown [(ngModel)]="policyType" [nxIsMultiselect]="true">
                  <nx-dropdown-item nxValue="{{ policyStatus.Active }}"
                    >Issued Policy
                  </nx-dropdown-item>
                  <nx-dropdown-item nxValue="{{ policyStatus.Cancel }}"
                    >Cancelled Policy
                  </nx-dropdown-item>
                  <nx-dropdown-item nxValue="{{ policyStatus.Modified }}"
                    >Modified Policy
                  </nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <div nxRow *ngIf="isAdvancedSearchSecondRow">
            <div nxCol="12,6" nxAlignSelf="center">
              <nx-formfield nxLabel="Age of Vehicle (In Years)">
                <nx-dropdown [(ngModel)]="VehicleAge">
                  <nx-dropdown-item
                    *ngFor="let index of [0, 1, 2, 3, 4, 5, 6, 7]"
                    [nxValue]="index"
                  >
                  </nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
            <div nxCol="12,6" nxAlignSelf="center">
              <nx-formfield nxLabel="Model">
                <nx-dropdown [(ngModel)]="model">
                  <nx-dropdown-item
                    *ngFor="let Model of Models"
                    [nxValue]="Model.modelName"
                  >
                  </nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <div nxRow *ngIf="isAdvancedSearchThirdRow">
            <div nxCol="12,6,4" nxAlignSelf="center">
              <nx-formfield nxLabel="Wallet Status">
                <nx-dropdown
                  [(ngModel)]="SelectedWalletRequestStatus"
                  [nxIsMultiselect]="true"
                >
                  <nx-dropdown-item
                    *ngFor="let type of walletStatus | keyvalue"
                    [nxValue]="walletStatuses[type.value]"
                  >
                    {{ type.value }}
                  </nx-dropdown-item>
                </nx-dropdown>
              </nx-formfield>
            </div>
          </div>
          <div nxRow *ngIf="isAdvancedSearchFourthRow">
            <div nxCol="12,6,4" nxAlignSelf="center">
              <nx-formfield nxLabel="Dealer code">
                <input nxInput [(ngModel)]="dealerCode" type="text" />
              </nx-formfield>
            </div>
          </div>
        </nx-expansion-panel>
      </nx-accordion>
    </div>
  </div>
  <div
    nxRow
    nxRowJustify="start,start,around"
    nxRowAlignContent="end,around,stretch"
    *ngIf="reportType.value == undefined"
    class="nx-margin-top-2xl"
  >
    <ng-container>
      <div nxRow nxRowJustify="center">
        <div nxCol="12" class="selectreport"></div>
        <h3 nxHeadline="subsection-medium" class="nx-font-weight-semibold">
          Select a report type!!!
        </h3>
      </div>
    </ng-container>
  </div>
  <!--------------------------------------------------------------------------------------- Reports ------------------------------------------------------------------->
  <!-- Policy Sale Report -->
  <div nxRow *ngIf="requestType == 1">
    <div nxCol="10" nxColOffset="1">
      <app-policy-sale-report
        #policySaleReport
        [policyStartDate]="policyStartDate"
        [policyEndDate]="policyEndDate"
      >
      </app-policy-sale-report>
    </div>
  </div>
  <!-- Wallet Statement Report -->
  <div nxRow *ngIf="requestType == 2">
    <div nxCol="10" nxColOffset="1">
      <app-wallet-statement-report
        #walletstmntReport
        [policyStartDate]="policyStartDate"
        [policyEndDate]="policyEndDate"
        [dealerCode]="dealerCode"
      >
      </app-wallet-statement-report>
    </div>
  </div>
  <!-- Wallet Usage Report -->
  <div nxRow *ngIf="requestType == 3">
    <div nxCol="10" nxColOffset="1">
      <app-wallet-usage-report
        #walletUsageReport
        [policyStartDate]="policyStartDate"
        [policyEndDate]="policyEndDate"
      >
      </app-wallet-usage-report>
    </div>
  </div>
  <!-- Active Dealer Report -->
  <div nxRow *ngIf="requestType == 4">
    <div nxCol="10" nxColOffset="1">
      <app-acive-dealer-report
        #dealerReport
        [policyStartDate]="policyStartDate"
        [policyEndDate]="policyEndDate"
      >
      </app-acive-dealer-report>
    </div>
  </div>
  <!-- Sales Report Monthly-->
  <div nxRow *ngIf="requestType == 5">
    <div nxCol="10" nxColOffset="1">
      <app-salesreportmonthly
        #salesReportMonth
        [policyStartDate]="policyStartDate"
        [policyEndDate]="policyEndDate"
      >
      </app-salesreportmonthly>
    </div>
  </div>
  <!-- Sales Report Weekly -->
  <div nxRow *ngIf="requestType == 6">
    <div nxCol="10" nxColOffset="1">
      <app-salesreport-weekly
        #salesReportWeek
        [policyStartDate]="policyStartDate"
        [policyEndDate]="policyEndDate"
      >
      </app-salesreport-weekly>
    </div>
  </div>
  <!-- Offline Wallet Report-->
  <div nxRow *ngIf="requestType == 7">
    <div nxCol="10" nxColOffset="1">
      <app-offline-wallet-report
        #offlineWalletReport
        [policyStartDate]="policyStartDate"
        [policyEndDate]="policyEndDate"
        [SelectedWalletRequestStatus]="SelectedWalletRequestStatus"
      >
      </app-offline-wallet-report>
    </div>
  </div>
  <!-- Policy Sale Detailed Report-->
  <div nxRow *ngIf="requestType == 8">
    <div nxCol="10" nxColOffset="1">
      <app-policy-sale-detailed-report
        #policySaleDetailedReport
        [policyStartDate]="policyStartDate"
        [policyEndDate]="policyEndDate"
        [policyNo]="policyNo"
        [policyType]="policyType"
        [policyStatus]="policyStatus"
        [VehicleAge]="VehicleAge"
        [model]="model"
      >
      </app-policy-sale-detailed-report>
    </div>
  </div>
  <!-- Policy Report With Price SplitUp-->
  <div nxRow *ngIf="requestType == 9">
    <div nxCol="10" nxColOffset="1">
      <app-policy-report-with-price-splitup
        #policyReportWithPriceSplitUp
        [policyStartDate]="policyStartDate"
        [policyEndDate]="policyEndDate"
        [policyNo]="policyNo"
        [policyType]="policyType"
        [policyStatus]="policyStatus"
        [VehicleAge]="VehicleAge"
        [model]="model"
      >
      </app-policy-report-with-price-splitup>
    </div>
  </div>
    <!-- Dealer Commission Bill Invoice-->
  <div nxRow *ngIf="requestType == 10">
    <div nxCol="10" nxColOffset="1">
      <app-dealer-commission-invoice
        #dealerCommissionInvoice
        [policyMonth]="month"
        [policyYear]="year"
        [dealerCode]="dealercode"
      >
      </app-dealer-commission-invoice>
    </div>
  </div>
</div>



<app-footer></app-footer>
