import { Injectable } from '@angular/core';
import { NxSortHeaderIntl } from '@aposin/ng-aquila/table';

@Injectable({
  providedIn: 'root'
})
export class SortingService extends NxSortHeaderIntl {
  // constructor() { }

  sortAscendingAriaLabel = 'sort ascending by';
  sortDescendingAriaLabel = 'sort descending by';
}
