<app-header></app-header>
<app-menu></app-menu>

<div nxLayout="grid" class="nx-margin-top-2m">
  <div nxRow>
    <div nxCol="3" class="nx-margin-top-2xs" nxColOffset="1">
      <nx-formfield nxLabel="Select user Type">
        <nx-dropdown
          [(ngModel)]="searchuserType"
          (selectionChange)="getworkshopList()"
        >
          <nx-dropdown-item
            *ngFor="let item of userTypeData"
            [nxValue]="item.value"
          >
            {{ item.name }}
          </nx-dropdown-item>
        </nx-dropdown>
        <nx-error nxFormfieldError> Please select an option. </nx-error>
      </nx-formfield>
    </div>
    <div nxCol="4" class="nx-margin-top-2xs">
      <nx-formfield>
        <input
          nxInput
          placeholder="Enter Keyword"
          [(ngModel)]="searchTerm"
          type="search"
        />
        <span nxFormfieldPrefix>
          <nx-icon name="search" size="s"></nx-icon>
        </span>
        <span
          nxFormfieldSuffix
          *ngIf="searchTerm"
          aria-hidden="true"
          (click)="searchTerm = ''; getworkshopList()"
        >
          <nx-icon name="close" size="s"></nx-icon>
        </span>
      </nx-formfield>
    </div>
    <div nxCol="2" class="nx-margin-top-2xs">
      <button
        nxButton="primary small-medium"
        type="button"
        (click)="getworkshopList()"
      >
        Search
      </button>
    </div>
  </div>
  <div nxRow>
    <div nxCol="10" nxColOffset="1" *ngIf="workshopList.length != 0">
      <table nxTable condensed nxSort (sortChange)="sortTable($event)">
        <thead>
          <tr nxTableRow>
            <th nxHeaderCell nxSortHeaderCell="workshopId">Reg No:</th>
            <th nxHeaderCell nxSortHeaderCell="workshopCode">Code</th>
            <th nxHeaderCell nxSortHeaderCell="workshopName">Name</th>
            <th nxHeaderCell nxSortHeaderCell="contactPerson">
              Contact Person
            </th>
            <th nxHeaderCell nxSortHeaderCell="panNo">PAN Number</th>
            <th nxHeaderCell nxSortHeaderCell="isActive">Status</th>
            <th nxHeaderCell>Actions</th>
          </tr>
        </thead>
        <tbody style="text-align: center">
          <tr nxTableRow *ngFor="let item of workshopList">
            <td nxTableCell>{{ item.workshopId }}</td>
            <td nxTableCell *ngIf="item.workshopCode; else dealercode">
              {{ item.workshopCode }}
            </td>
            <ng-template #dealercode>
              <td nxTableCell *ngIf="item.dealerCode">{{ item.dealerCode }}</td>
            </ng-template>
            <td nxTableCell>{{ item.workshopName }}</td>
            <td nxTableCell>{{ item.contactPerson }}</td>
            <td nxTableCell>{{ item.panNo | viewProtected }}</td>

            <td nxTableCell *ngIf="item.isActive == '1'; else inActive">
              <nx-badge vibrant type="positive">Accepted</nx-badge>
            </td>
            <ng-template #inActive>
              <td nxTableCell *ngIf="item.isActive == '0'; else pending">
                <nx-badge vibrant type="critical">Rejected</nx-badge>
              </td>
            </ng-template>
            <ng-template #pending>
              <td nxTableCell *ngIf="item.isActive == '-1'; else deleted">
                <nx-badge vibrant type="negative">Requested</nx-badge>
              </td>
            </ng-template>
            <ng-template #deleted>
              <td nxTableCell *ngIf="item.isActive == '2'">
                <nx-badge vibrant type="critical">Deleted</nx-badge>
              </td>
            </ng-template>
            <td nxTableCell>
              <nx-context-menu #menu="nxContextMenu">
                <ng-template nxContextMenuContent>
                  <button
                    *ngIf="userPermission.edit && item.isActive == '1'"
                    nxContextMenuItem
                    type="button"
                    (click)="
                      toggleModal(
                        item.workshopId,
                        item.userType,
                        'edit',
                        item.identifier
                      )
                    "
                  >
                    Edit
                  </button>
                  <button
                    *ngIf="userPermission.approveReject"
                    (click)="
                      toggleModal(
                        item.workshopId,
                        item.userType,
                        'confirm',
                        item.identifier
                      )
                    "
                    nxContextMenuItem
                    type="button"
                  >
                    Accept/Reject
                  </button>
                </ng-template>
              </nx-context-menu>
              <button
                nxIconButton="tertiary medium"
                type="button"
                [nxContextMenuTriggerFor]="menu"
                aria-label="Open menu"
                [nxTooltipPosition]="'top'"
                [nxTooltip]="'Please click here'"
              >
                <nx-icon aria-hidden="true" name="ellipsis-h"></nx-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div nxCol="12,10" class="nx-margin-top-2xl" *ngIf="count == 0">
      <ng-container>
        <div nxRow nxRowJustify="center">
          <div nxCol="12" class="nosearchresult"></div>
          <h3 nxHeadline="subsection-medium" class="nx-font-weight-semibold">
            No Records found !!!
          </h3>
        </div>
      </ng-container>
    </div>
  </div>
  <div nxRow class="nx-margin-top-2m nx-margin-right-5xl" style="float: right">
    <div nxCol="6">
      <nx-pagination
        [nxCount]="count"
        [nxPage]="pageNumber"
        [nxPerPage]="pageSize"
        nxType="advanced"
        (nxGoPrev)="prevPage()"
        (nxGoNext)="nextPage()"
        (nxGoPage)="goToPage($event)"
      >
      </nx-pagination>
    </div>
  </div>
</div>
<app-footer></app-footer>

<!-- Modal -->
<nx-modal
  [nxHideOnClickOutside]="false"
  [nxHideOnEsc]="false"
  *ngIf="false"
  [nxShowCloseIcon]="true"
  (nxClose)="toggleModal(0, 0, '', '')"
>
  <div nxLayout="grid" *ngIf="!isSuccess" style="margin-top: 2%">
    <div nxRow nxRowJustify="start,around">
      <h1 nxHeadline="subsection">WorkShop Details</h1>
    </div>
    <div nxRow>
      <div nxCol="6">
        <nx-formfield nxLabel="Workshop Registration Number">
          <input nxInput value="{{ workshopListData.workshopId }}" readonly />
        </nx-formfield>
      </div>
      <div nxCol="6">
        <nx-formfield nxLabel="Workshop code">
          <input nxInput value="{{ workshopListData.workshopCode }}" readonly />
        </nx-formfield>
      </div>
      <div nxCol="6">
        <nx-formfield nxLabel="Workshop Name">
          <input nxInput value="{{ workshopListData.workshopName }}" readonly />
        </nx-formfield>
      </div>
      <div nxCol="6">
        <nx-formfield nxLabel="Dealer Name">
          <input
            nxInput
            value="{{ workshopListData.contactPerson }}"
            readonly
          />
        </nx-formfield>
      </div>
      <div nxCol="6">
        <nx-formfield nxLabel="Area Sales Manager">
          <nx-dropdown formControlName="superUserId">
            <nx-dropdown-item *ngFor="let item of asmUsers" [nxValue]="item.id"
              >{{ item.name }}
            </nx-dropdown-item>
          </nx-dropdown>
          <nx-error nxFormfieldError>
            <strong>Please note:</strong> this field is required!
          </nx-error>
        </nx-formfield>

        <!-- <nx-formfield nxLabel="Area Sales Manager">
          <input nxInput value={{workshopListData.workshopId}} />

        </nx-formfield> -->
      </div>
      <div nxCol="6">
        <nx-formfield nxLabel="Dealer Address">
          <textarea
            nxInput
            value="{{ workshopListData.workshopAddress }}"
            readonly
          ></textarea>
        </nx-formfield>
      </div>
      <div nxCol="6">
        <nx-formfield nxLabel="Service Tax Number">
          <input
            nxInput
            value="{{ workshopListData.serviceTaxNumber }}"
            readonly
          />
        </nx-formfield>
      </div>
      <div nxCol="6">
        <nx-formfield nxLabel="PAN Number">
          <input nxInput value="{{ workshopListData.panNo }}" readonly />
        </nx-formfield>
      </div>
      <div nxCol="6">
        <nx-formfield nxLabel="Contact Person">
          <input
            nxInput
            value="{{ workshopListData.contactPerson }}"
            readonly
          />
        </nx-formfield>
      </div>
      <div nxCol="4">
        <nx-formfield nxLabel="Contact Number">
          <input
            nxInput
            value="{{ workshopListData.contactNumber }}"
            readonly
          />
        </nx-formfield>
      </div>
      <div nxCol="6">
        <nx-formfield nxLabel="Primary Email">
          <input
            nxInput
            value="{{ workshopListData.primaryEmailIdContactPerson }}"
            readonly
          />
        </nx-formfield>
      </div>
      <div nxCol="4">
        <nx-formfield nxLabel="Secondary Email">
          <input
            nxInput
            value="{{ workshopListData.contactPersonEmailId }}"
            readonly
          />
        </nx-formfield>
      </div>
      <div nxCol="6">
        <nx-formfield nxLabel="Bank Account Number">
          <input
            nxInput
            value="{{ workshopListData.bankAccountNo }}"
            readonly
          />
        </nx-formfield>
      </div>
      <div nxCol="6">
        <nx-formfield nxLabel="Bank Name">
          <input nxInput value="{{ workshopListData.bankName }}" readonly />
        </nx-formfield>
      </div>
      <div nxCol="6">
        <nx-formfield nxLabel="IFSC Code">
          <input nxInput value="{{ workshopListData.ifscCode }}" readonly />
        </nx-formfield>
      </div>
      <div nxCol="6">
        <nx-formfield nxLabel="Beneficiary Name">
          <input
            nxInput
            value="{{ workshopListData.beneficiaryName }}"
            readonly
          />
        </nx-formfield>
      </div>
      <div nxCol="6">
        <nx-formfield nxLabel="Bank Branch Address">
          <input
            nxInput
            value="{{ workshopListData.bankBranchAddress }}"
            readonly
          />
        </nx-formfield>
      </div>
    </div>
    <div nxRow>
      <div nxCol="4">
        <nx-label size="small">PAN Card Image </nx-label>
      </div>
      <div nxCol="2">
        <button
          *ngIf="workshopListData.panCardImage"
          nxIconButton="tertiary small"
          aria-label="open image"
          type="button"
          (click)="download(workshopListData.panCardImage)"
        >
          <nx-icon name="launch"></nx-icon>
        </button>
        <p *ngIf="!workshopListData.panCardImage">No attachment found</p>
      </div>
      <div nxCol="4">
        <nx-label size="small">Cancel Cheque Image</nx-label>
      </div>
      <div nxCol="2">
        <button
          *ngIf="workshopListData.cancelChequeImage"
          nxIconButton="tertiary small"
          aria-label="open image"
          type="button"
          (click)="download(workshopListData.cancelChequeImage)"
        >
          <nx-icon name="launch"></nx-icon>
        </button>
        <p *ngIf="!workshopListData.cancelChequeImage">No attachment found</p>
      </div>
      <div nxCol="4">
        <nx-label size="small">GST Certificate Image</nx-label>
      </div>
      <div nxCol="2">
        <button
          *ngIf="workshopListData.gstCertificateImage"
          nxIconButton="tertiary small"
          aria-label="open image"
          type="button"
          (click)="download(workshopListData.gstCertificateImage)"
        >
          <nx-icon name="launch"></nx-icon>
        </button>
        <p *ngIf="!workshopListData.gstCertificateImage">No attachment found</p>
      </div>
    </div>
    <form [formGroup]="workshopStatusForm">
      <div nxRow>
        <div nxCol="12" class="nx-margin-x-s margin-box" style="margin-top: 5%">
          <div nxRow>
            <nx-formfield nxLabel="Reason">
              <textarea
                nxInput
                formControlName="statusReason"
                rows="2"
                cols="65"
                type="text"
                required
              ></textarea>
              <nx-error nxFormfieldError> Your reason is missing. </nx-error>
            </nx-formfield>
          </div>
        </div>
      </div>
      <div nxRow nxRowAlignItems="center">
        <div nxCol="12" style="text-align: center">
          <button
            nxButton="primary "
            type="button"
            [disabled]="workshopListData.isActive"
            (click)="
              updateWorkshopstatus(workshopListData.workshopId, 1, false)
            "
          >
            Accept
          </button>
          <button
            nxButton="Secondary "
            type="button"
            [disabled]="!workshopListData.isActive"
            (click)="
              updateWorkshopstatus(workshopListData.workshopId, 0, false)
            "
          >
            Reject
          </button>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="isSuccess">
    <div nxRow nxRowJustify="start,around"></div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
      <div nxCol="12">
        <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">
          Status updated successfully
        </h3>
      </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
      <div nxCol="12">
        <button (click)="toggleModal(0, 0, '', '')" nxButton="block primary">
          close
        </button>
      </div>
    </div>
  </div>
</nx-modal>

<!-- workshopView Modal -->
<nx-modal
  [nxHideOnClickOutside]="false"
  [nxHideOnEsc]="false"
  *ngIf="workshopView"
  [nxShowCloseIcon]="true"
  (nxClose)="toggleModal(0, workshopListData.userType, '', '')"
>
  <div nxLayout="grid" *ngIf="!isSuccess" style="margin-top: 2%">
    <div nxRow nxRowJustify="start,around">
      <h1 nxHeadline="subsection">Workshop Details</h1>
    </div>
    <form [formGroup]="workShopForm">
      <div nxRow>
        <div nxCol="6">
          <nx-formfield nxLabel="Workshop Registration Number">
            <input nxInput formControlName="workshopId" readonly />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Dealer code">
            <input nxInput formControlName="dealerCode" readonly />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Workshop code">
            <input nxInput formControlName="workshopCode" readonly />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="workshop Name">
            <input
              nxInput
              formControlName="workshopName"
              [readonly]="!IsUpdate"
            />
          </nx-formfield>
        </div>
        <div nxCol="6" [ngClass]="{ disabledbutton: !IsUpdate }">
          <nx-formfield nxLabel="Area Sales Manager">
            <nx-dropdown
              formControlName="superUserId"
              (selectionChange)="onsuperUserIdChanges($event.value)"
            >
              <nx-dropdown-item
                *ngFor="let item of asmUsers"
                [nxValue]="item.id"
                >{{ item.name }}
              </nx-dropdown-item>
            </nx-dropdown>
            <nx-error nxFormfieldError>
              <strong>Please note:</strong> this field is required!
            </nx-error>
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Dealer Address">
            <textarea
              nxInput
              formControlName="workshopAddress"
              [readonly]="!IsUpdate"
            ></textarea>
          </nx-formfield>
        </div>
        <div nxCol="6" [ngClass]="{ disabledbutton: !IsUpdate }">
          <nx-formfield nxLabel="Zone">
            <nx-dropdown formControlName="zoneId">
              <nx-dropdown-item
                *ngFor="let item of zoneList"
                [nxValue]="item.id"
                >{{ item.zone }}
              </nx-dropdown-item>
            </nx-dropdown>
            <nx-error nxFormfieldError>
              <strong>Please note:</strong> this field is required!
            </nx-error>
          </nx-formfield>
        </div>
        <div nxCol="6" class="disabledbutton">
          <nx-formfield nxLabel="State">
            <nx-dropdown formControlName="stateId">
              <nx-dropdown-item
                *ngFor="let item of stateList"
                [nxValue]="item.id"
                >{{ item.stateName }}
              </nx-dropdown-item>
            </nx-dropdown>
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="PAN Number">
            <input nxInput formControlName="panNo" [readonly]="!IsUpdate" />
            <nx-error nxFormfieldError>
              <strong>Please note:</strong> PAN Number is required.
          </nx-error>
          </nx-formfield>      
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="GSTIN">
            <input nxInput formControlName="gstNumber" [readonly]="!IsUpdate" />
            <nx-error nxFormfieldError>
              <strong>Please note:</strong> GST Number is required.
          </nx-error>
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Contact Person">
            <input
              nxInput
              formControlName="contactPerson"
              [readonly]="!IsUpdate"
            />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Contact Number">
            <input
              nxInput
              formControlName="contactNumber"
              [readonly]="!IsUpdate"
            />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Primary Email">
            <input
              nxInput
              formControlName="primaryEmailIdContactPerson"
              [readonly]="!IsUpdate"
            />
            <nx-icon
              style="color: orangered"
              aria-label="trigger by click"
              name="info-circle-o"
              size="s"
              [nxPopoverTriggerFor]="popoverClick"
              nxPopoverTrigger="hover"
              tabindex="0"
              role="button"
              class="nx-margin-left-s margin-box"
            ></nx-icon>
            <nx-popover #popoverClick>
              Changing the primary email will reset the login credentials and
              the updated credentials will send to the new email id!
            </nx-popover>
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Secondary Email">
            <input
              nxInput
              formControlName="contactPersonEmailId"
              [readonly]="!IsUpdate"
            />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Bank Account Number">
            <input
              nxInput
              formControlName="bankAccountNo"
              [readonly]="!IsUpdate"
            />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Bank Name">
            <input nxInput formControlName="bankName" [readonly]="!IsUpdate" />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="IFSC Code">
            <input nxInput formControlName="ifscCode" [readonly]="!IsUpdate" />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Beneficiary Name">
            <input
              nxInput
              formControlName="beneficiaryName"
              [readonly]="!IsUpdate"
            />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Bank Branch Address">
            <input
              nxInput
              formControlName="bankBranchAddress"
              [readonly]="!IsUpdate"
            />
          </nx-formfield>
        </div>
        <div nxCol="6" *ngIf="false">
          <nx-formfield nxLabel="Minimum wallet balance">
            <input nxInput formControlName="minWalletBalance" />
          </nx-formfield>
        </div>
      </div>
      <div nxRow>
        <div nxCol="4">
          <nx-label size="small">PAN Card Image </nx-label>
        </div>
        <div nxCol="2">
          <button
            *ngIf="workshopListData.panCardImage"
            nxIconButton="tertiary small"
            aria-label="open image"
            type="button"
            (click)="download(workshopListData.panCardImage)"
          >
            <nx-icon name="launch"></nx-icon>
          </button>
          <p *ngIf="!workshopListData.panCardImage">No attachment found</p>
        </div>
        <div nxCol="4">
          <nx-label size="small">Cancel Cheque Image</nx-label>
        </div>
        <div nxCol="2">
          <button
            *ngIf="workshopListData.cancelChequeImage"
            nxIconButton="tertiary small"
            aria-label="open image"
            type="button"
            (click)="download(workshopListData.cancelChequeImage)"
          >
            <nx-icon name="launch"></nx-icon>
          </button>
          <p *ngIf="!workshopListData.cancelChequeImage">No attachment found</p>
        </div>
        <div nxCol="4">
          <nx-label size="small">GST Certificate Image</nx-label>
        </div>
        <div nxCol="2">
          <button
            *ngIf="workshopListData.gstCertificateImage"
            nxIconButton="tertiary small"
            aria-label="open image"
            type="button"
            (click)="download(workshopListData.gstCertificateImage)"
          >
            <nx-icon name="launch"></nx-icon>
          </button>
          <p *ngIf="!workshopListData.gstCertificateImage">
            No attachment found
          </p>
        </div>
      </div>
      <div nxRow>
        <div
          nxCol="12"
          class="nx-margin-x-s margin-box"
          style="margin-top: 5%"
          *ngIf="!IsUpdate"
        >
          <div nxRow>
            <nx-formfield nxLabel="Reason">
              <textarea
                nxInput
                formControlName="statusReason"
                rows="2"
                cols="65"
                type="text"
                required
              ></textarea>
              <nx-error nxFormfieldError> Your reason is missing. </nx-error>
            </nx-formfield>
          </div>
        </div>
      </div>
      <div nxRow nxRowAlignItems="center">
        <div nxCol="12" style="text-align: center" *ngIf="!IsUpdate">
          <button
            nxButton="primary "
            type="button"
            *ngIf="IsApproveButton"
            [disabled]="workShopForm.invalid"
            (click)="
              updateWorkshopstatus(workshopListData.workshopId, 1, false)
            "
          >
            Approve
          </button>

          <button
            nxButton="emphasis"
            type="button"
            *ngIf="IsRemoveButton"
            [disabled]="workShopForm.invalid"
            (click)="updateWorkshopstatus(workshopListData.workshopId, 2, true)"
          >
            Remove
          </button>

          <button
            nxButton="secondary"
            type="button"
            *ngIf="IsRejectButton"
            [disabled]="workShopForm.invalid"
            (click)="
              updateWorkshopstatus(workshopListData.workshopId, 0, false)
            "
          >
            Reject
          </button>

          <button
            nxButton="block secondary"
            type="button"
            (click)="toggleModal(0, workshopListData.userType, '', '')"
          >
            Close
          </button>
        </div>

        <div nxCol="12" style="text-align: center" *ngIf="IsUpdate">
          <button
            nxButton="Secondary "
            type="button"
            [disabled]="workShopForm.invalid"
            (click)="updateWorkshop()"
          >
            Update
          </button>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="isSuccess">
    <div nxRow nxRowJustify="start,around">
      <div nxCol="12" style="text-align: center">
        <nx-icon name="check-circle" size="m" style="color: green"></nx-icon>
      </div>
    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
      <div nxCol="12">
        <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">
          {{ message }}
        </h3>
      </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
      <div nxCol="12">
        <button
          (click)="toggleModal(0, workshopListData.userType, '', '')"
          nxButton="block primary"
        >
          close
        </button>
      </div>
    </div>
  </div>
</nx-modal>

<!-- DealerView Modal -->
<nx-modal
  [nxHideOnClickOutside]="false"
  [nxHideOnEsc]="false"
  *ngIf="dealerView"
  [nxShowCloseIcon]="true"
  (nxClose)="toggleModal(0, workshopListData.userType, '', '')"
>
  <div nxLayout="grid" *ngIf="!isSuccess" style="margin-top: 2%">
    <div nxRow nxRowJustify="start,around">
      <h1 nxHeadline="subsection">Dealer Details</h1>
    </div>
    <form [formGroup]="workShopForm">
      <div nxRow>
        <div nxCol="6">
          <nx-formfield nxLabel="Dealer Registration Number">
            <input nxInput formControlName="workshopId" readonly />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Dealer code">
            <input nxInput formControlName="dealerCode" readonly />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Dealer Name">
            <input nxInput formControlName="workshopName" readonly />
          </nx-formfield>
        </div>
        <div nxCol="6" [ngClass]="{ disabledbutton: !IsUpdate }">
          <nx-formfield nxLabel="Area Sales Manager">
            <nx-dropdown
              formControlName="superUserId"
              (selectionChange)="onsuperUserIdChanges($event.value)"
            >
              <nx-dropdown-item
                *ngFor="let item of asmUsers"
                [nxValue]="item.id"
                >{{ item.name }}
              </nx-dropdown-item>
            </nx-dropdown>
            <nx-error nxFormfieldError>
              <strong>Please note:</strong> this field is required!
            </nx-error>
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Dealer Address">
            <textarea
              nxInput
              formControlName="workshopAddress"
              [readonly]="!IsUpdate"
            ></textarea>
          </nx-formfield>
        </div>
        <div nxCol="6" [ngClass]="{ disabledbutton: !IsUpdate }">
          <nx-formfield nxLabel="Zone">
            <nx-dropdown formControlName="zoneId">
              <nx-dropdown-item
                *ngFor="let item of zoneList"
                [nxValue]="item.id"
                >{{ item.zone }}
              </nx-dropdown-item>
            </nx-dropdown>
            <nx-error nxFormfieldError>
              <strong>Please note:</strong> this field is required!
            </nx-error>
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="State">
            <nx-dropdown formControlName="stateId">
              <nx-dropdown-item
                *ngFor="let item of stateList"
                [nxValue]="item.id"
                >{{ item.stateName }}
              </nx-dropdown-item>
            </nx-dropdown>
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="PAN Number">
            <input nxInput formControlName="panNo" [readonly]="!IsUpdate" />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="GSTIN">
            <input nxInput formControlName="gstNumber" [readonly]="!IsUpdate" />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Contact Person">
            <input
              nxInput
              formControlName="contactPerson"
              [readonly]="!IsUpdate"
            />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Contact Number">
            <input
              nxInput
              formControlName="contactNumber"
              [readonly]="!IsUpdate"
            />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Primary Email">
            <input
              nxInput
              formControlName="primaryEmailIdContactPerson"
              [readonly]="!IsUpdate"
            />
            <nx-icon
              style="color: orangered"
              aria-label="trigger by click"
              name="info-circle-o"
              size="s"
              [nxPopoverTriggerFor]="popoverClick"
              nxPopoverTrigger="hover"
              tabindex="0"
              role="button"
              class="nx-margin-left-s margin-box"
            ></nx-icon>
            <nx-popover #popoverClick>
              Changing the primary email will reset the login credentials and
              the updated credentials will send to the new email id!
            </nx-popover>
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Secondary Email">
            <input
              nxInput
              formControlName="contactPersonEmailId"
              [readonly]="!IsUpdate"
            />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Bank Account Number">
            <input
              nxInput
              formControlName="bankAccountNo"
              [readonly]="!IsUpdate"
            />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Bank Name">
            <input nxInput formControlName="bankName" [readonly]="!IsUpdate" />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="IFSC Code">
            <input nxInput formControlName="ifscCode" [readonly]="!IsUpdate" />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Beneficiary Name">
            <input
              nxInput
              formControlName="beneficiaryName"
              [readonly]="!IsUpdate"
            />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Bank Branch Address">
            <input
              nxInput
              formControlName="bankBranchAddress"
              [readonly]="!IsUpdate"
            />
          </nx-formfield>
        </div>
        <div nxCol="6">
          <nx-formfield nxLabel="Password" *ngIf="IsUpdate">
            <input
              nxInput
              #password
              formControlName="password"
              type="password"
            />
            <nx-password-toggle
              [control]="password"
              nxFormfieldSuffix
            ></nx-password-toggle>
            <!-- <nx-icon style="color: orangered;" aria-label="trigger by click" name="info-circle-o" size="s"
              [nxPopoverTriggerFor]="popoverpassword" nxPopoverTrigger="hover" tabindex="0" role="button"
              class="nx-margin-left-s margin-box"></nx-icon>
            <nx-popover #popoverpassword>
              Changing the credentials will send to the
              primary email id!
            </nx-popover>

            <nx-error nxFormfieldError>
              <div *ngIf="f.password.errors?.required">
                Password is required!
              </div>
              <div *ngIf="f.password.errors?.pattern">
                Password Should have 1
                Upper,Lower,Numeric,Special characters
              </div>
            </nx-error> -->
          </nx-formfield>
        </div>
        <div nxCol="6" *ngIf="false">
          <nx-formfield nxLabel="Minimum wallet balance">
            <input nxInput formControlName="minWalletBalance" />
          </nx-formfield>
        </div>
      </div>
      <div nxRow>
        <div nxCol="4">
          <nx-label size="small">PAN Card Image </nx-label>
        </div>
        <div nxCol="2">
          <button
            *ngIf="workshopListData.panCardImage"
            nxIconButton="tertiary small"
            aria-label="open image"
            type="button"
            (click)="download(workshopListData.panCardImage)"
          >
            <nx-icon name="launch"></nx-icon>
          </button>
          <p *ngIf="!workshopListData.panCardImage">No attachment found</p>
        </div>
        <div nxCol="4">
          <nx-label size="small">Cancel Cheque Image</nx-label>
        </div>
        <div nxCol="2">
          <button
            *ngIf="workshopListData.cancelChequeImage"
            nxIconButton="tertiary small"
            aria-label="open image"
            type="button"
            (click)="download(workshopListData.cancelChequeImage)"
          >
            <nx-icon name="launch"></nx-icon>
          </button>
          <p *ngIf="!workshopListData.cancelChequeImage">No attachment found</p>
        </div>
        <div nxCol="4">
          <nx-label size="small">GST Certificate Image</nx-label>
        </div>
        <div nxCol="2">
          <button
            *ngIf="workshopListData.gstCertificateImage"
            nxIconButton="tertiary small"
            aria-label="open image"
            type="button"
            (click)="download(workshopListData.gstCertificateImage)"
          >
            <nx-icon name="launch"></nx-icon>
          </button>
          <p *ngIf="!workshopListData.gstCertificateImage">
            No attachment found
          </p>
        </div>
      </div>
      <div nxRow>
        <div
          nxCol="12"
          class="nx-margin-x-s margin-box"
          style="margin-top: 5%"
          *ngIf="!IsUpdate"
        >
          <div nxRow>
            <nx-formfield nxLabel="Reason">
              <textarea
                nxInput
                formControlName="statusReason"
                rows="2"
                cols="65"
                type="text"
                required
              ></textarea>
              <nx-error nxFormfieldError> Your reason is missing. </nx-error>
            </nx-formfield>
          </div>
        </div>
      </div>

      <div nxRow nxRowAlignItems="center">
        <div nxCol="12" style="text-align: center" *ngIf="!IsUpdate">
          <button
            nxButton="cta primary "
            type="button"
            *ngIf="IsApproveButton"
            [disabled]="workShopForm.invalid"
            (click)="
              updateWorkshopstatus(workshopListData.workshopId, 1, false)
            "
          >
            Approve
          </button>

          <button
            nxButton="emphasis"
            type="button"
            *ngIf="IsRemoveButton"
            [disabled]="workShopForm.invalid"
            (click)="updateWorkshopstatus(workshopListData.workshopId, 2, true)"
          >
            Remove
          </button>

          <button
            nxButton="secondary"
            type="button"
            *ngIf="IsRejectButton"
            [disabled]="workShopForm.invalid"
            (click)="
              updateWorkshopstatus(workshopListData.workshopId, 0, false)
            "
          >
            Reject
          </button>

          <button
            nxButton="block secondary"
            type="button"
            (click)="toggleModal(0, workshopListData.userType, '', '')"
          >
            Close
          </button>
        </div>
        <div nxCol="12" style="text-align: center" *ngIf="IsUpdate">
          <button
            nxButton="Secondary "
            type="button"
            [disabled]="workShopForm.invalid"
            (click)="updateWorkshop()"
          >
            Update
          </button>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="isSuccess">
    <div nxRow nxRowJustify="start,around">
      <div nxCol="12" style="text-align: center">
        <nx-icon name="check-circle" size="m" style="color: green"></nx-icon>
      </div>
    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
      <div nxCol="12">
        <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">
          {{ message }}
        </h3>
      </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
      <div nxCol="12">
        <button
          (click)="toggleModal(0, workshopListData.userType, '', '')"
          nxButton="block primary"
        >
          close
        </button>
      </div>
    </div>
  </div>
</nx-modal>
