<table nxTable zebra condensed nxSort (sortChange)="sortTable($event)">
  <thead class="align-center">
    <tr nxTableRow>
      <th nxHeaderCell nxSortHeaderCell="policyNo">Policy Number</th>
      <th nxHeaderCell nxSortHeaderCell="policyName">Policy Name</th>
      <th nxHeaderCell nxSortHeaderCell="customerFirstName">Customer Name</th>
      <th nxHeaderCell nxSortHeaderCell="customerMobileNo">Mobile Number</th>
      <th nxHeaderCell nxSortHeaderCell="customerEmail">Email</th>
      <th nxHeaderCell nxSortHeaderCell="paymentDate">Created Date</th>
      <th nxHeaderCell nxSortHeaderCell="policyStatusId">Status</th>
    </tr>
  </thead>
  <tbody class="align-center">
    <tr nxTableRow *ngFor="let item of policyMasterList">
      <td nxTableCell>{{ item.policyNo }}</td>
      <td nxTableCell>{{ item.policyName }}</td>
      <td nxTableCell>
        {{ item.customerFirstName }} {{ item.customerLastName }}
      </td>
      <td nxTableCell>{{ item.customerMobileNo }}</td>
      <td nxTableCell>{{ item.customerEmail }}</td>
      <td nxTableCell>{{ item.paymentDate | date: "MM/dd/yyyy" }}</td>
      <td nxTableCell *ngIf="item.policyStatusId == policyStatus.Active">
        <nx-badge vibrant type="positive">Active</nx-badge>
      </td>
    </tr>
    <tr nxTableRow *ngIf="count == 0">
      <td nxTableCell colspan="11">
        <ng-container>
          <div nxRow nxRowJustify="center">
            <div nxCol="12" class="nosearchresult"></div>
            <h3 nxHeadline="subsection-medium" class="nx-font-weight-semibold">
              No Records found !!!
            </h3>
          </div>
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>

<div nxRow class="nx-margin-top-2m nx-margin-right-5xl flot-right">
  <div nxCol="6">
    <nx-pagination
      [nxCount]="count"
      [nxPage]="pageNumber"
      [nxPerPage]="pageSize"
      nxType="advanced"
      (nxGoPrev)="prevPage()"
      (nxGoNext)="nextPage()"
      (nxGoPage)="goToPage($event)"
    >
    </nx-pagination>
  </div>
</div>
