import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ChartType } from '../core/chart.model';
import { Chart } from "chart.js";
import { AuthService } from '../core/auth/auth.service';
import { AppConfig } from 'src/config/app.config';
import { CustomErrorHandler } from '../core/custom-error-handler';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-finance-dashboard',
  templateUrl: './finance-dashboard.component.html',
  styleUrls: ['./finance-dashboard.component.css']
})
export class FinanceDashboardComponent implements OnInit, AfterViewInit {
  chartType: ChartType = ChartType.bar;
  chart: any;
  private _apiUrl: string;
  cdr: ChangeDetectorRef;
  pending : number;
  completed: number;

  constructor(
    private authService: AuthService,
    private appConfig : AppConfig,
    private _cdr: ChangeDetectorRef,
    private errorHandler: CustomErrorHandler,
    private router: Router) {
    this._apiUrl = appConfig.baseUrl;
    this.cdr = _cdr;
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.getDashBoard();
  }

  getDashBoard() {
    this.authService
      .get(
        this._apiUrl + "/api/v1/Dashboard/financeChart")
      .subscribe(
        (response) => {
          if (response) {
            this.pending = response.data[0];
            this.completed = response.data[1];
            this.BindChart(response.label, response.data, "canvas");
          }
        },
        (error) => {
          this.errorHandler.handleError(error);
        }
      );
  }

  GetData(status : any){
    this.router.navigate(['/einvoice'], { queryParams: { InvoiceStatus: status } });
    console.log(status);
  }

  BindChart(label: any, data: any, canvasId: string) {
    this.chart = new Chart(canvasId, {
      type: "bar",
      data: {
        labels: label,
        datasets: [
          {
            data: data,
            borderColor: "#3cba9f",
            backgroundColor: this.poolColors(data.length),
            fill: true,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              fontColor: "#C7C7CC",
              fontSize: 11,
            },
          ],
          yAxes: [
            {
              display: true,
            },
          ],
        },
      },
    });
  }

  changeChartType(type: string) {
      this.chart.config.type = ChartType[this.chartType];
      this.chart.update();
  }

  poolColors(length) {
    var pool = [];
    for (var i = 0; i < length; i++) {
      pool.push(this.dynamicColors());
    }
    return pool;
  }

  dynamicColors() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgba(" + r + "," + g + "," + b + ", 0.5)";
  }

}
