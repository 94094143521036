import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { loginRoutingModule } from './login-routing.module'
import { LoginComponent } from './login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { NxExpertModule } from '@allianz/ngx-ndbx/config';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxLabelModule, NxErrorModule } from '@aposin/ng-aquila/base';
import { NxCardModule } from '@aposin/ng-aquila/card';
import { NxButtonModule } from '@aposin/ng-aquila/button';

import { NxLinkModule } from '@aposin/ng-aquila/link';
import { NxHeaderModule } from '@aposin/ng-aquila/header';
import { NxImageModule } from '@aposin/ng-aquila/image';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxMessageModule } from '@aposin/ng-aquila/message';
import { TitleModule } from '../title/title.module';
//import { LoaderComponent } from '../loader/loader.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxSmallStageModule } from '@aposin/ng-aquila/small-stage';



import { ForgotpasswordComponent } from '../forgotpassword/forgotpassword.component';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotpasswordComponent,
    //LoaderComponent
  ],
  imports: [
    CommonModule,
    loginRoutingModule,
    //NxExpertModule,
    NxInputModule,
    NxFormfieldModule,
    FormsModule,
    ReactiveFormsModule,
    NxGridModule,
    NxLabelModule,
    NxErrorModule,
    NxCardModule,
    NxButtonModule,
    NxLinkModule,
    NxHeaderModule,
    NxImageModule,
    NxIconModule,
    NxMessageModule,
    TitleModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NxModalModule,
    NxSmallStageModule
  ],
  exports: [LoginComponent, ForgotpasswordComponent]
})
export class LoginModule { }
