<div nxLayout="grid">
  <div nxRow>
    <div nxCol="4" nxColOffset="1">
      <nx-formfield>
        <input nxInput placeholder="Enter Keyword" [(ngModel)]="searchTerm" type="search">
        <span nxFormfieldPrefix>
          <nx-icon name="search" size="s"></nx-icon>
        </span>
        <span nxFormfieldSuffix *ngIf="searchTerm" aria-hidden="true" (click)="searchTerm='';getModels()">
          <nx-icon name="close" size="s"></nx-icon>
        </span>
      </nx-formfield>
    </div>
    <div nxCol="2" class="nx-margin-top-2xs">
      <button nxButton="primary small-medium" type="button" (click)="getModels();">Search</button>
    </div>
    <div nxCol="4" class="nx-margin-top-2xs">
      <button (click)="toggleModal()" nxButton="secondary" type="button" style="float: right;">Add
        Model</button>
    </div>
  </div>

  <div nxRow>
    <div nxCol="10" nxColOffset="1">
      <table nxTable condensed nxSort (sortChange)="sortTable($event)">
        <thead>
          <tr nxTableRow>
            <th nxHeaderCell nxSortHeaderCell="modelName">
              Model Name
            </th>
            <th nxHeaderCell nxSortHeaderCell="modelMake">
              Year Of Manufacture
            </th>
            <th nxHeaderCell nxSortHeaderCell="modelWarrenty">
              Warranty
            </th>
            <th nxHeaderCell nxSortHeaderCell="isActive">
              Status
            </th>
            <th nxHeaderCell>
              Edit
            </th>
            <th nxHeaderCell>
              Activate/Deactivate
            </th>

          </tr>
        </thead>
        <tbody>
          <tr nxTableRow *ngFor="let item of modelList">
            <td nxTableCell>{{item.modelName}}</td>
            <td nxTableCell>{{item.modelMake}}</td>
            <td nxTableCell>{{item.modelWarrenty}}</td>
            <td nxTableCell *ngIf="item.isActive =='1' else inActive">
              <nx-badge vibrant type="positive">Active</nx-badge>
            </td>
            <ng-template #inActive>
              <td nxTableCell>
                <nx-badge vibrant type="critical">In Active</nx-badge>
              </td>
            </ng-template>
            <td nxTableCell>
              <button nxIconButton="tertiary small">
                <nx-icon name="edit" size="s" (click)="editModel(item.modelId)"></nx-icon>
              </button>
            </td>

            <td nxTableCell *ngIf="item.isActive == 1">
              <nx-switcher [checked]="true" (change)='confirmModal(item,$event.target.checked)'></nx-switcher>
            </td>
            <td nxTableCell *ngIf="item.isActive == 0">
              <nx-switcher [checked]="false" (change)='confirmModal(item,$event.target.checked)'></nx-switcher>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div nxRow class="nx-margin-top-2m nx-margin-right-5xl" style="float: right;">
    <div nxCol="6" >
      <nx-pagination [nxCount]="count" [nxPage]="pageNumber" [nxPerPage]="pageSize" nxType="advanced"
        (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
      </nx-pagination>
    </div>
  </div>
</div>
<nx-modal #addColor [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="veichleModalOpen"
  [nxShowCloseIcon]="false">
  <div *ngIf="!isSuccess">
    <form name='addmodel-form' [formGroup]="modelForm">

      <div nxLayout="grid">
        <div nxRow nxRowJustify="start,around">
          <h1 nxHeadline="page" *ngIf="!EDIT_FLAG">Add Model</h1>
          <h1 nxHeadline="page" *ngIf="EDIT_FLAG">Update Model</h1>


        </div>
        <div nxRow>
          <div nxCol="12,6" nxAlignSelf="center">
            <nx-formfield nxLabel="Model">
              <input nxInput formControlName="modelName" />
              <nx-error nxFormfieldError>
                <div *ngIf="f.modelName.errors?.required"><strong>Please note:</strong> This field is required!</div>
                <div *ngIf="f.modelName.errors?.pattern"><strong>Please note:</strong> Enter Valid Model Name.
                  <br>Allowed charcters are -_,/.&lt;space&gt; and Alpha numerics</div>
              </nx-error>
            </nx-formfield>
          </div>
          <div nxCol="12,6" nxAlignSelf="center">
            <nx-formfield nxLabel="Year of manufacture">
              <input nxInput formControlName="modelMake" />
              <nx-error nxFormfieldError>
                <strong>Please note:</strong> this field is required!
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow>
          <div nxCol="12,6" nxAlignSelf="center">
            <nx-formfield nxLabel="Warranty">
              <input nxInput type="number" formControlName="modelWarrenty" />
              <nx-error nxFormfieldError>
                <strong>Please note:</strong> this field is required!
              </nx-error>
            </nx-formfield>
          </div>
        </div>
        <div nxRow >
          <div nxCol="12" style="text-align: center;">
            <button type="button" *ngIf="!EDIT_FLAG" (click)="onSave()" nxButton="primary">Add</button>
            <button type="button" *ngIf="EDIT_FLAG" (click)="onUpdate()" nxButton="primary">Update</button>
            <button (click)="toggleModal()" nxButton="secondary" autofocus type="button">Close</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="isSuccess">

    <div nxRow nxRowJustify='start,around'>
      <!-- <div nxCol="12" id="successModalImage" class="success_image">
    </div> -->
    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
      <div nxCol="12">
        <!-- <nx-message nxContext="success">User is added successfully</nx-message> -->
        <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf="!EDIT_FLAG">Model added successfully!</h3>
        <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf="EDIT_FLAG">Model updated successfully!
        </h3>

      </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
      <div nxCol="12">
        <button (click)="toggleModal()" nxButton="block primary">
          close
        </button>
      </div>
    </div>

    <!-- <nx-message nxContext="success">
  User is added successfully.
</nx-message> -->
  </div>

</nx-modal>

<!--Modal for confirmation -->
<nx-modal #confirmModal [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="confirmModalOpen"
  [nxShowCloseIcon]="false">
  <div nxRow nxRowJustify="start,around">
    <h3 nxHeadline="subsection-small">Confirmation</h3>
  </div>
  <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
    <div nxCol="12">
      <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">Are you sure you want to do this action?</h3>
    </div>
  </div>
  <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
    <div nxCol="6">
      <button (click)="updateStatus()" nxButton="block primary">
        Yes
      </button>
    </div>
    <div nxCol="6">
      <button (click)="onNoClick()" nxButton="block primary">
        No
      </button>
    </div>

  </div>
</nx-modal>

<!-- <app-footer></app-footer> -->
