

  <!doctype html>
<title>Site Maintenance</title>

<article>
    <div class='unavailable'></div>
    <h1>We&rsquo;ll be back soon!</h1>
    <div>
        <p>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. If you need to you can always <a href="mailto:#">contact us</a>, otherwise we&rsquo;ll be back online shortly!</p>
        <p>&mdash; AWP India</p>
    </div>
    <div style="text-align: center;">
        <a routerLink=''>
            <button nxIconButton="secondary" aria-label="like on facebook" type="button" >
              <nx-icon name="refresh">
              </nx-icon>
            </button>
          </a>
    </div>
    
</article>