import { SortDirection, SortEvent } from "@aposin/ng-aquila/table";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import * as moment from "moment";
import { AppConfig } from "src/config/app.config";
import { AuthService } from "../core/auth/auth.service";
import { CustomErrorHandler } from "../core/custom-error-handler";
import { PolicyStatus } from "../core/policyStatus.model";

@Component({
  selector: "app-policy-sale-detailed-report",
  templateUrl: "./policy-sale-detailed-report.component.html",
  styleUrls: ["./policy-sale-detailed-report.component.css"],
})
export class PolicySaleDetailedReportComponent implements OnInit {
  @Input() policyStartDate: moment.Moment;
  @Input() policyEndDate: moment.Moment;
  @Input() policyNo: string;
  @Input() policyType: Array<number>;
  @Input() policyStatus: typeof PolicyStatus;
  @Input() VehicleAge: number;
  @Input() model: string;
  reportSearchForm: FormGroup;

  fileName: string;
  ReportData: any;
  exportdata: any = [];
  _apiUrl: string;
  clientId: number;
  userTypeId: number;
  userBaseTypeId: number;
  userId: number;
  cityList: any;
  stateList: any;

  /** sorting starts here */

  sortTable(sort: SortEvent) {
    this.ReportData = this.ReportData.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection
  ) {
    return (a < b ? -1 : 1) * (direction === "asc" ? 1 : -1);
  }
  /** sorting ends here */
  /**Pagination Start */
  count: number = 0;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  prevPage() {
    this.pageNumber--;
    this.getReportData();
  }

  nextPage() {
    this.pageNumber++;
    this.getReportData();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getReportData();
  }
  /**Pagination end */

  constructor(
    private authService: AuthService,
    private appConfig: AppConfig,
    private errorHandler: CustomErrorHandler
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.userBaseTypeId = parseInt(
      atob(localStorage.getItem("USER_BASE_TYPE_ID"))
    );
    this.userTypeId = parseInt(atob(localStorage.getItem("USER_TYPE_ID")));
    this.getStateList();
    this.getCityList();
  }
  ngOnInit(): void {
    this.searchReportFormBuilder();
  }

  searchReportFormBuilder() {
    this.reportSearchForm = new FormGroup({
      startDatepicker: new FormControl(null),
      endDatepicker: new FormControl(null),
      policyNo: new FormControl(null),
      policyType: new FormControl(new Array<number>()),
      VehicleAge: new FormControl(-1),
      model: new FormControl(null),
      ClientId: new FormControl(this.clientId),
      WorkshopId: new FormControl(null),
    });
  }

  loadReport() {
    this.pageNumber = 1;
    this.getReportData();
  }

  getReportData() {
    this.searchReportFormBuilder();
    this.updateReportSearchForm();

    this.authService
      .PostList(
        this._apiUrl +
          `/api/v1/Report/GetPolicySalesReport?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`,
        this.reportSearchForm.value
      )
      .subscribe(
        (response) => {
          if (response) {
            this.ReportData = response.body;
            var pagingProp = JSON.parse(response.headers.get("X-Pagination"));
            this.count = pagingProp.TotalCount;
          }
        },
        (error) => {
          this.errorHandler.handleError(error);
        }
      );
  }

  updateReportSearchForm() {
    if (this.policyStartDate)
      this.reportSearchForm.patchValue({
        startDatepicker: this.policyStartDate.format("LL"),
      });
    if (this.policyStartDate)
      this.reportSearchForm.patchValue({
        startDatepicker: this.policyStartDate.format("LL"),
      });
    if (this.policyEndDate)
      this.reportSearchForm.patchValue({
        endDatepicker: this.policyEndDate.format("LL"),
      });
    if (this.policyNo)
      this.reportSearchForm.patchValue({ policyNo: this.policyNo });
    if (this.policyType)
      this.reportSearchForm.patchValue({ policyType: this.policyType });
    if (this.VehicleAge)
      this.reportSearchForm.patchValue({ VehicleAge: this.VehicleAge });
    if (this.model) this.reportSearchForm.patchValue({ model: this.model });
  }

  exporttoexcel() {
    this.pageNumber = -1;
    this.getExportData();
  }

  getExportData() {
    this.searchReportFormBuilder();
    this.updateReportSearchForm();

    this.authService
      .PostList(
        this._apiUrl +
          `/api/v1/Report/GetPolicySalesDetailsWithGST?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`,
        this.reportSearchForm.value
      )
      .subscribe(
        (response) => {
          if (response) {
            this.exportdata = [];
            response.body.forEach((element) => this.setExportData(element));
            this.bindexcel();
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  setExportData(item: any) {
    var data: any = [];
    data.dealerCode = item.dealerCode;
    data.dealerName = item.name;
    data.dealerState = item.dealerState;
    data.dealerCity = item.dealerCity;
    data.dealerManager = item.asmName;
    data.invoiceNo = item.policyDetails.invoiceNo;
    data.vin = item.policyDetails.vinChassisNo;
    data.licencePlateNo = item.policyDetails.licensePlateNo;
    data.vehicleSaleDate = new Date(
      item.policyDetails.vehicleSaleDate
    ).toLocaleDateString();
    data.sellingAdvisor = item.policyDetails.soldBy;
    data.model = item.policyDetails.model;
    data.policyName = item.policyDetails.policyName;
    data.effectiveDate = new Date(
      item.policyDetails.effectiveDate
    ).toLocaleDateString();
    data.expiryDate = new Date(
      item.policyDetails.expiryDate
    ).toLocaleDateString();
    data.zone = item.zonename;
    data.custName =
      item.policyDetails.customerFirstName +
      " " +
      item.policyDetails.customerLastName;
    data.custMobile = item.policyDetails.customerMobileNo;
    data.custEmail = item.policyDetails.customerEmail;
    data.invoiceDate = new Date(
      item.policyDetails.invoiceDate
    ).toLocaleDateString();

    if (item.policyDetails.policyStatusId == PolicyStatus.Active) {
      data.policyStatus = "Active";
    } else if (item.policyDetails.policyStatusId == PolicyStatus.Cancel) {
      data.policyStatus = "Cancel";
    } else if (item.policyDetails.policyStatusId == PolicyStatus.Modified) {
      data.policyStatus = "Modified";
    } else if (
      item.policyDetails.policyStatusId ==
      PolicyStatus.CancelationApprovalPending
    ) {
      data.policyStatus = "Cancelation Approval Pending";
    } else if (
      item.policyStatusId == PolicyStatus.ModificationApprovalPending
    ) {
      data.policyStatus = "Modification Approval Pending";
    }
    data.customerAddrs = item.policyDetails.customerAddress
    data.customerCity = this.cityList.find(obj => obj.id === item.policyDetails.cityId)?.cityName; 
    data.customerState =  this.stateList.find(obj => obj.id === item.policyDetails.stateId)?.stateName; 
    data.pincode =  item.policyDetails.pincode
    data.baseamount =  item.policyDetails.price
    data.sgst =  item.walletHistory.sgst
    data.cgst =  item.walletHistory.cgst
    data.igst =  item.walletHistory.igst
    data.totalamount =  item.walletHistory.amount

    this.exportdata.push(data);
  }

  bindexcel() {
    import('xlsx').then(xlsx => {
      /* json data is passed over here */
      const ws: import('xlsx').WorkSheet = xlsx.utils.json_to_sheet(this.exportdata);
      /* generate workbook and add the worksheet */
      const wb: import('xlsx').WorkBook = xlsx.utils.book_new();

      ws.A1.v = "Dealer/Workshop Code";
      ws.B1.v = "Dealer/Workshop Name";
      ws.C1.v = "Dealer/Workshop State";
      ws.D1.v = "Dealer/Workshop City";
      ws.E1.v = "Dealer Manager";
      ws.F1.v = "Invoice No";
      ws.G1.v = "Vin Chasis No";
      ws.H1.v = "License Plate No";
      ws.I1.v = "Vehicle Sale Date";
      ws.J1.v = "Selling Advisor";

      ws.K1.v = "Model";
      ws.L1.v = "Policy Name";
      ws.M1.v = "Effective Date";
      ws.N1.v = "Expiry Date";
      ws.O1.v = "Zone";

      ws.P1.v = "Customer Name";
      ws.Q1.v = "Customer Mobile";
      ws.R1.v = "Customer Email";
      ws.S1.v = "Invoice Date";
      ws.T1.v = "Policy Status";
      ws.U1.v = "Customer Address";
      ws.V1.v = "Customer City";
      ws.W1.v = "Customer State";
      ws.X1.v = "Pincode";
      ws.Y1.v = "Base Amount";
      ws.Z1.v = "SGST";
      ws.AA1.v = "CGST";
      ws.AB1.v = "IGST";
      ws.AC1.v = "Total Amount";

      xlsx.utils.book_append_sheet(wb, ws, "Policy_Detailed_Sale_Report");
      /* save to file */
      this.fileName =
        "PolicyDetailedSaleReport_" +
        new Date(Date.now()).toLocaleString().split(",")[0] +
        ".xlsx";
      xlsx.writeFile(wb, this.fileName);
    });
  }

  getStateList() {
    this.authService
      .get(this._apiUrl + `/api/v1/States?pageNumber=-1`)
      .subscribe(
        (response) => {
          if (response) {
            this.stateList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  getCityList() {
    this.authService
      .get(this._apiUrl + `/api/v1/Cities?pageNumber=-1`)
      .subscribe(
        (response) => {
          if (response) {
            this.cityList = response;
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }
}
