import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isActiveCheck'
})
export class IsActiveCheckPipe implements PipeTransform {

  transform(data: any): any {
    if(data != undefined && data != null){
      
      let result = data.filter(t=>t.isActive == 1);
      return result;
    }
  }
}
