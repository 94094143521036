<app-header></app-header>
<app-menu></app-menu>
<div nxLayout="grid" style="margin: 2%;">

    <div nxRow>
        <div nxCol="2" nxColOffset="1">
            <nx-formfield nxLabel="Select Client">
                <nx-dropdown  #selectedClient (selectionChange)="onClientChanges($event.value)">
                  <nx-dropdown-item *ngFor="let item of clientList  | isActiveCheck" [nxValue]="item.clientId">{{item.clientName}}
                  </nx-dropdown-item>
                </nx-dropdown>

            </nx-formfield>
        </div>
        <div nxCol="2">
            <nx-formfield nxLabel="Select User Role">
                <nx-dropdown  #selectedRole (selectionChange)="onUserTypeIdChanges($event.value)">
                  <nx-dropdown-item *ngFor="let item of userTypeList | isActiveCheck" [nxValue]="item.userTypeId">{{item.userType}}
                  </nx-dropdown-item>
                </nx-dropdown>
            </nx-formfield>
        </div>
      <div nxCol="6">
        <button  *ngIf="addNewRoute" (click)="toggleModal(false,0)" nxButton="secondary small-medium" type="button" style="float: right;">Add New Route</button>
      </div>
    </div>
  
    <div nxRow>
      <div nxCol="10" nxColOffset="1">
        <table nxTable zebra>
          <thead>
            <tr nxTableRow>
              <th nxHeaderCell>
                Display Order
              </th>
              <th nxHeaderCell>
                Display Name
              </th>
              <th nxHeaderCell>
                Route
              </th>
              <th nxHeaderCell>
                Status
              </th>
              <th nxHeaderCell>
                Edit
              </th>
              <th nxHeaderCell>
                Activate/Deactivate
              </th>
            </tr>
          </thead>
          <tbody>
            <tr nxTableRow *ngFor="let item of routeList">
              <td nxTableCell>{{item.displayOrder}}</td>
              <td nxTableCell>{{item.displayName}}</td>
              <td nxTableCell>{{item.route}}</td>
              <td nxTableCell *ngIf="item.isActive =='1' else inActive">
                <nx-badge vibrant type="positive">Active</nx-badge>
              </td>
              <ng-template #inActive>
                <td nxTableCell>
                  <nx-badge vibrant type="critical">In Active</nx-badge>
                </td>
              </ng-template>
              <td nxTableCell>
                <button nxButton="tertiary medium">
                  <nx-icon name="edit" size="s" (click)="toggleModal(true,item.id)"></nx-icon>
                </button>
  
              </td>
              <td nxTableCell *ngIf="item.isActive =='1'">
                <nx-switcher [checked]="true" (change)='confirmModal(item,$event.target.checked)'></nx-switcher>
              </td>
              <td nxTableCell *ngIf="item.isActive =='0'">
                <nx-switcher [checked]="false" (change)='confirmModal(item,$event.target.checked)'></nx-switcher>
              </td>
  
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
<app-footer></app-footer>
  
  
  <nx-modal #addColor [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="routeModalOpen"
    [nxShowCloseIcon]="false">
    <div *ngIf="!isSuccess">
      <form name='addroute-form' [formGroup]="routeForm">
  
        <div nxLayout="grid">
          <div nxRow nxRowJustify="start,around">
            <h1 nxHeadline="page" *ngIf="!EDIT_FLAG">Add Route</h1>
            <h1 nxHeadline="page" *ngIf="EDIT_FLAG">Edit Route</h1>
          </div>
          <hr>
          <div nxRow>
            <div nxCol="12,6" nxAlignSelf="center">
              <nx-formfield nxLabel="Display Order">
                <input nxInput type="number" formControlName="displayOrder" />
                <nx-error nxFormfieldError>
                  <strong>Please note:</strong> this field is required!
                </nx-error>
              </nx-formfield>
            </div>
            <div nxCol="12,6" nxAlignSelf="center">
                <nx-formfield nxLabel="Display Name">
                  <input nxInput formControlName="displayName" />
                  <nx-error nxFormfieldError>
                    <strong>Please note:</strong> this field is required!
                  </nx-error>
                </nx-formfield>
            </div>
  
          </div>
          <div nxRow>
            <div nxCol="12" nxAlignSelf="center">
              <nx-formfield nxLabel="Route">
                <input nxInput formControlName="route" />
                <nx-error nxFormfieldError>
                  <strong>Please note:</strong> this field is required!
                </nx-error>
              </nx-formfield>
            </div>
          </div>
  
          <div nxRow style="margin-top: 5%;">
            <div nxCol="12">
              <button (click)="addRouteMaster()" [disabled]="routeForm.invalid" nxButton="primary" type="button" *ngIf="!EDIT_FLAG">Add</button>
              <button (click)="updateRouteMaster()"[disabled]="routeForm.invalid"  nxButton="primary" type="button" *ngIf="EDIT_FLAG">Update</button>
              <button (click)="toggleModal(false,0)" nxButton="secondary" type="button">Close</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="isSuccess">
      <div nxRow nxRowJustify='start,around'>
        <div nxCol="12" id="successModalImage" class="success_image">
        </div>
      </div>
      <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
        <div nxCol="12">
          <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf="!EDIT_FLAG">Route added successfully</h3>
          <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf="EDIT_FLAG">Route updated successfully</h3>
        </div>
      </div>
      <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
        <div nxCol="12">
          <button (click)="toggleModal(false,0)" nxButton="block primary" id="returnButton">
            close
          </button>
        </div>
      </div>
    </div>
  </nx-modal>
  
  <nx-modal #confirmModal [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="confirmModalOpen"
    [nxShowCloseIcon]="false">
    <div nxRow nxRowJustify="start,around">
      <h2 nxHeadline="page">Confirmation</h2>
  
    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
      <div nxCol="12">
        <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">Are you sure you want to do this action?</h3>
      </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
      <div nxCol="6">
        
        <button (click)="changeRouteStatus()" nxButton="block primary" >
          Yes
        </button>
      </div>
      <div nxCol="6">
        <button (click)="confirmModalClose()" nxButton="block primary" >
          No
        </button>
      </div>
    </div>
  </nx-modal>