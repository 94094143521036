import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from "src/config/app.config";
import { NxMessageToastService } from "@aposin/ng-aquila/message";
import { MESSAGE_CONSTS } from "../ValidationMessages";
import { throwError } from "rxjs";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
declare const fp: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  _apiUrl: string;
  isAuthenticated: boolean;
  errorClosed = true;
  errorMessage: string;
  Messages: any;
  ClientConfig: any;
  GCaptcha: string;
  Gsize: string = "invisible";
  @ViewChild("template") templateRef: TemplateRef<any>;
  dialogRef: NxModalRef<any>;
  actionResult: any;
  identifier: any;
  GCaptchaSecret: string;
  @ViewChild("captchaRef") reCaptcha;
  marqueetext: any='';
  showNotification: boolean=false;

  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private authService: AuthService,
    private appConfig: AppConfig,
    private messageToastService: NxMessageToastService,
    public dialogService: NxDialogService
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.GCaptcha = appConfig.gCaptchaKey;
    this.GCaptchaSecret = appConfig.gCaptchaSecret;
    this.Messages = MESSAGE_CONSTS;
    //Fetching Client config based on hosted domain address
    this.ClientConfig = this.router.snapshot.data;
    localStorage.setItem(
      "CLIENT_ID",
      btoa(this.ClientConfig.branding.clientId)
    );
    localStorage.setItem("CLIENT_NAME", this.ClientConfig.branding.clientName);
    localStorage.setItem("DealerReg", this.ClientConfig.branding.isDealerLogin);
    this.loginForm = new FormBuilder().group({
      username: ["", Validators.required],
      password: ["", Validators.required],
      clientId: 0,
      fingerprint: fp.get(),
      captcha: new FormGroup({
        secret: new FormControl(this.GCaptchaSecret, Validators.required),
        response: new FormControl(""),
        remoteip: new FormControl(""),
      }),
    });
  }

  ngOnInit() {
    this.authService.resetcredentials();
  }

  public signIn() {
    if (this.loginForm.invalid) {
      this.messageToastService.open("Please fill all details!!", {
        context: "info",
        duration: 5000,
      });
    } else {
      this.errorClosed = true;
      this.loginForm.patchValue({
        clientId: parseInt(atob(localStorage.getItem("CLIENT_ID"))),
      });

      this.authService
        .post(
          this._apiUrl + "/api/v1/Auth/authenticate",
          btoa(JSON.stringify(this.loginForm.value))
        )
        .subscribe(
          (response) => {
            if (response.success) {
              if (response.isConcurrentSession) {
                this.openSessionWarning();
                this.identifier = response.isConcurrentSession;
              } else {
                this.authService.login(response);
                //Rest Antiforgery token otherwise the first post request will fail
                this.authService.resetAntiforgeryToken().subscribe(
                  (res) => {
                    this.PostLoginRedirections();
                  },
                  (error) => {
                    this.PostLoginRedirections();
                  }
                );
              }
            }
          },
          (error) => {
            this.reCaptcha.reset();
            this.handleError(error);
          }
        );
    }
  }

  PostLoginRedirections() {
    // Navigate to /changepassword if user login for the first time
    if (parseInt(localStorage.getItem("ISFIRSTLOGIN")) == 1) {
      this.route.navigate(["/changepassword"], {
        queryParams: {
          firstlogin: parseInt(atob(localStorage.getItem("CLIENT_ID"))),
        },
      });
    }
  }

  public submit(captchaResponse: string): void {
    if (captchaResponse !== null) {
      this.loginForm.patchValue({
        captcha: {
          response: captchaResponse,
        },
      });
      this.signIn();
    }
  }

  public getmarqueetext() {
    this.authService.get(this._apiUrl + "/api/v1/Common/GetMarquee")
        .subscribe(
        (response) => {
            if (response.success) {
                this.marqueetext = response.data;
                this.showNotification = response.data?true:false;
            }
        },
        (error) => {
            this.handleError(error);
        }
        );
}

  handleError(error) {
    if (error.error instanceof ErrorEvent) {
      // client-side error
      this.errorMessage = `Error : ${error.error.message}`;
    } else {
      // server-side error
      this.errorMessage = `Error : ${error.error.message}`;
    }
    this.errorClosed = false;
    this.messageToastService.open(this.errorMessage, {
      context: "info",
      duration: 5000,
    });
    return throwError(this.errorMessage);
  }

  branding() {
    return this.ClientConfig.branding.brandingClassName;
  }

  openSessionWarning(): void {
    this.dialogRef = this.dialogService.open(this.templateRef, {
      ariaLabel: "Session Warning modal",
      disableClose: false,
      width: "600px",
      height: "400px",
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result == "logout") {
        this.authService.Logout(this.identifier);
        this.authService.resetcredentials();
      }
    });
  }

  closeDialog(result: string) {
    this.dialogRef.close(result);
  }
}
