import { Pipe, PipeTransform } from "@angular/core";
import { RequestIntegrity } from "../core/request-integrety";

@Pipe({
  name: "viewProtected",
})
export class viewProtectedPipe implements PipeTransform {
  constructor(public requestIntegrity: RequestIntegrity) {}

  transform(data: any): any {
    if (data) {
      return this.requestIntegrity.decryptUsingAES256(data);
    }
    return null;
  }
}
