import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConfig } from 'src/config/app.config';
import { AuthService } from '../core/auth/auth.service';
import { CustomErrorHandler } from '../core/custom-error-handler';

@Component({
  selector: 'app-user-reports',
  templateUrl: './user-reports.component.html',
  styleUrls: ['./user-reports.component.css']
})
export class UserReportsComponent implements OnInit {
  private _apiUrl: string;
  clientId: number;
  userTypeList: any;
  userReports: any;
  addPermission: boolean;
  EDIT_FLAG: boolean;
  modalOpen: boolean;
  isSuccess: boolean;
  userReportsForm: FormGroup;
  userType: any;
  reportList: any;
  errorMessage: string;
  confirmModalOpen: boolean;
  deleteId: any;

  constructor(private authService: AuthService, private appConfig : AppConfig, private errorHandler: CustomErrorHandler) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.userReportsFormBuilder();
  }

  userReportsFormBuilder() {
    this.userReportsForm = new FormBuilder().group({
      userTypeId: new FormControl(),
      reportId: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.userReportsFormBuilder();
    this.getUserTypeList();
    this.getReportsList();
  }

  
  toggleModal(EDIT: boolean, id) {
    this.EDIT_FLAG = EDIT;
    this.modalOpen = !this.modalOpen;
    this.isSuccess = false;
  }

  onUserTypeChanges(userTypeId) {
    this.addPermission = true;
    this.userType = userTypeId;
    this.getUserReportsList(userTypeId);
  }

  getReportsList() {
    this.authService.get(this._apiUrl + '/api/v1/UserReports/GetReportList').subscribe(response => {
      if (response) {
        this.reportList = response;
      }
    }, (error) => {
      this.errorHandler.handleError(error);
    });
  }

  getUserReportsList(userTypeId) {
    this.authService.get(this._apiUrl + '/api/v1/UserReports/GetUserReportsByUserType/'+ userTypeId).subscribe(response => {
      if (response) {
        this.userReports = response;
      }
    }, (error) => {
      this.errorHandler.handleError(error);
    });
  }

  getUserTypeList() {
    this.authService.get(this._apiUrl + '/api/v1/UserTypes/client/'+ this.clientId).subscribe(response => {
      if (response) {
        this.userTypeList = response;
      }
    }, (error) => {
      this.errorHandler.handleError(error);
    });
  }

  addUserReportMaster() {
    this.userReportsForm.patchValue({ "userTypeId": this.userType });

    this.authService.post(this._apiUrl + '/api/v1/UserReports', this.userReportsForm.value).subscribe(response => {
      if (response) {
        this.getUserReportsList(this.userType);
        this.getReportsList();
        this.isSuccess = true;
      }
    }, (error) => {
      this.errorHandler.handleError(error);
    });
  }

  confirmModal(id) {
    this.deleteId = id;
    this.confirmModalOpen = true;
  }

  confirmModalClose() {
    this.confirmModalOpen = false;
    this.getUserReportsList(this.userType);
  }

  delete(){
    this.authService.delete(this._apiUrl + '/api/v1/UserReports/' + this.deleteId).subscribe(response => {
      this.getUserReportsList(this.userType);
      this.isSuccess = true;
    }, (error) => {
      this.errorHandler.handleError(error);
    });

    this.confirmModalClose();
  }

}
