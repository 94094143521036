<app-header></app-header>
<app-menu></app-menu>

<div nxLayout="grid">
  <div nxRow>
    <div nxCol="12" class="nx-margin-top-2m">
      <nx-tab-nav-bar>
        <a
          nxTabLink
          *ngFor="let link of links"
          (click)="setActiveLink(link)"
          [active]="currentLink.label === link.label"
          [disabled]="link.disabled"
          [routerLink]="link.path"
        >
          {{ link.label }}
        </a>
      </nx-tab-nav-bar>
      <div class="nx-margin-top-m">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
