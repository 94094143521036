export class policyMaster {
    policyId: number;
    planName: string;
    durationOfPlan: string;
    maximumVehicleAge: string;
    awpRiskPrice: number;
    marketingFund: number;
    customerEngementFund: number;
    dealerMargin: number;
    royality: number;
    totalPrice: number;
    createdBy: string;
    createdDate: number;
    isActive: number;
    clientId: number;
}

