export abstract class AppConfig {
  baseUrl: string;
  authUrl: string;
  payuUrl: string;
  nsmCount: number;
  rsmCount: number;
  asmCount: number;
  financeCount: number;
  gCaptchaKey: string;
  gCaptchaSecret: string;
  maxvehicleAge: number;
  onePayuUrl: string;
  onePayuKey: string;
  onePayuSalt: string;
  responseKey: string;
}
