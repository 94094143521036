<app-header></app-header>
<app-menu></app-menu>
<div nxLayout="grid" class="nx-margin-top-2m wrapper">
    <div nxRow class="nx-margin-x-l">
        <div nxCol="12,12,6,2"  class="nx-margin-top-2m">
            <nx-formfield nxLabel="Start date" >
                <input nxDatefield nxInput [nxDatepicker]="startDatepicker" [(ngModel)]="policyStartDate" />
                <nx-datepicker-toggle [for]="startDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                <nx-datepicker #startDatepicker></nx-datepicker>
                <nx-error nxFormfieldError>
                    <strong>Please note:</strong> this field is required!
                </nx-error>
            </nx-formfield>
        </div>
        <div nxCol="12,12,6,2" class="nx-margin-top-2m">
            <nx-formfield nxLabel="End date" >
                <input nxDatefield nxInput [nxDatepicker]="endDatepicker" [(ngModel)]="policyEndDate" />
                <nx-datepicker-toggle [for]="endDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                <nx-datepicker #endDatepicker></nx-datepicker>
                <nx-error nxFormfieldError>
                    <strong>Please note:</strong> this field is required!
                </nx-error>
            </nx-formfield>
        </div>
        <div nxCol="12,12,6,4" class="nx-margin-top-2m">
            <nx-formfield nxLabel="Type in here to filter (e.g. Dealer Name, Code)">
                <input nxInput [(ngModel)]="filterValue" (ngModelChange)="onFilterValueChange($event)"/>
              </nx-formfield>
        </div>
        <div nxCol="12,12,6,6,2" class="nx-margin-top-2m">
            <button nxButton="primary small-medium" type="button" (click)=getdetails()>GET DETAILS</button>
            <!-- <button nxButton="secondary small-medium" class="nx-margin-left-2xs" type="button"  (click)="reset()">Reset</button> -->
        </div>
    </div>
    <div nxRow class="nx-margin-x-l">
        <div nxCol="12">
            <nx-message nxContext="info">
                <span class="nx-font-weight-bold">Overview Summary:</span><br/>
                Total Recharges : {{currentlyShownPageElements.totalPortalRecharge}} &nbsp;&nbsp;
                Total Online Recharges : {{currentlyShownPageElements.totalPortalOnlineRecharge}} &nbsp;&nbsp;
                Total Offline Recharges : {{currentlyShownPageElements.totalPortalOfflineRecharge}} &nbsp;&nbsp;
                Total Policy Sold : {{currentlyShownPageElements.totalPortalPolicySold}} &nbsp;&nbsp;
                Total cancelled Policy Amount : {{currentlyShownPageElements.totalPoliciesCancelledAmount}} &nbsp;&nbsp;
            </nx-message>
        </div>
    </div>
    <div nxRow class="nx-margin-x-l" >
        <div nxCol="12,12,6,6,4" >
           <p nxCopytext class="nx-margin-bottom-2xs">Matching items: {{count}}</p>
            <table nxTable nxSort (sortChange)="sortTable($event)">
            <thead>
                <tr nxTableRow>
                <th nxHeaderCell nxSortHeaderCell="product">Dealer Name</th>
                <th nxHeaderCell nxSortHeaderCell="contractNumber">Dealer Code</th>
                <th nxHeaderCell nxSortHeaderCell="statusText">Opening Bal</th>
                <th nxHeaderCell nxSortHeaderCell="statusText">Tot.Rchrg</th>
                <th nxHeaderCell nxSortHeaderCell="statusText">Tot.OFF Rchrg</th>
                <th nxHeaderCell nxSortHeaderCell="endingAt">Tot.ON Rchrg</th>
                <th nxHeaderCell nxSortHeaderCell="statusText">Policy Sold Amt</th>
                <th nxHeaderCell nxSortHeaderCell="statusText">Policy cancel Amt</th>
                <th nxHeaderCell nxSortHeaderCell="statusText">Cur.wallet Bal</th>
                <th nxHeaderCell nxSortHeaderCell="statusText">Deviation Amt</th>
                
                </tr>
            </thead>
            <tbody>
                <tr nxTableRow *ngFor="let item of currentlyShownPageElements.dealerTransactionDetails" >
                <td nxTableCell>{{item.dealerName}}</td>
                <td nxTableCell>{{item.dealerCode}}</td>
                <td nxTableCell>{{item.openingBalance}}</td>
                <td nxTableCell>{{item.totalRecharge}}</td>
                <td nxTableCell>{{item.totalOfflineRecharge}}</td>
                <td nxTableCell>{{item.totalOnlineRecharge}}</td>
                <td nxTableCell>{{item.totalPolicySoldAmount}}</td>
                <td nxTableCell>{{item.cancelledPolicies}}</td>
                <td nxTableCell>
                    <nx-link><a routerLink='#'>{{item.currentBalance}}</a></nx-link>
                </td>
                <td nxTableCell>
                    <nx-badge vibrant  [type]="item.isHaveIssue">{{item.deviationAmount}}</nx-badge>
                </td>
                </tr>
                <tr>
                    <td *ngIf="count==0" colspan="8">
                        <ng-container>
                        <div nxRow nxRowJustify="center">
                            <div nxCol="12" class="nosearchresult"></div>
                            <h3 nxHeadline="subsection-medium" class="nx-font-weight-semibold">No Records found !!!</h3>
                        </div>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
            </table>
          
            <nx-pagination
              class="pagination"
              [nxCount]="count"
              [nxPage]="page"
              nxType="advanced"
              [nxPerPage]="elementsPerPage"
              (nxGoPrev)="prevPage()"
              (nxGoNext)="nextPage()"
              (nxGoPage)="goToPage($event)">
            </nx-pagination>
          </div>
          
    </div>
</div>



<app-footer></app-footer>

