import { HttpClient, HttpParams } from "@angular/common/http";
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import {
  FileItem,
  NxFileUploaderComponent,
} from "@aposin/ng-aquila/file-uploader";
import { AppConfig } from "src/config/app.config";
import { AuthService } from "../core/auth/auth.service";
import { DomSanitizer } from "@angular/platform-browser";
import {
  NxFileUploadConfig,
  NxFileUploader,
} from "@aposin/ng-aquila/file-uploader";
import { Subject } from "rxjs";
import { CustomErrorHandler } from "../core/custom-error-handler";
import { NxDialogService } from "@aposin/ng-aquila/modal";

@Component({
  selector: "app-digital-signature",
  templateUrl: "./digital-signature.component.html",
  styleUrls: ["./digital-signature.component.css"],
})
export class DigitalSignatureComponent implements OnInit, OnDestroy {
  public progress: number;
  public message: string;
  @Input() acceptedfiles: string;
  @Input() uploadPath: string;
  @Output() public onUploadFinished = new EventEmitter();
  private _apiUrl: string;
  @ViewChild("documentUpload", { static: false })
  documentUpload: NxFileUploaderComponent;
  public showUploadError: boolean = false;
  errormsg: any;
  thumbnail: any;
  size: any;
  width: number;
  height: number;
  uploadConfig: NxFileUploadConfig = {
    requestUrl: "file-upload",
    options: {
      params: new HttpParams(),
      reportProgress: true,
    },
  };

  uploader = new NxFileUploader(this.uploadConfig, this.http);

  dsForm = new FormGroup({
    documents: new FormControl([], Validators.required),
  });

  private readonly _destroyed = new Subject<void>();

  constructor(
    private http: HttpClient,
    private appConfig: AppConfig,
    private fb: FormBuilder,
    private errorHandler: CustomErrorHandler,
    public dialogService: NxDialogService,
    private authService: AuthService,
    private sanitizer: DomSanitizer
  ) {
    this._apiUrl = appConfig.baseUrl;
  }

  ngOnInit() {
    this.dsForm = this.fb.group({
      documents: [[]],
    });
    this.getDigitalSignature();
  }
  //Upload Digital Signature
  upload() {
    var url: string = this._apiUrl + "/api/v1/Upload/DigitalSignature";

    if (this.uploadPath != null || this.uploadPath != undefined) {
      url = this._apiUrl + this.uploadPath;
    }

    this.showUploadError = false;
    if (!this.documentUpload.value) {
      return;
    }
    const formData = new FormData();
    this.documentUpload.value.forEach((fileItem: FileItem) => {
      if (!fileItem.isUploaded) {
        fileItem.setUploadingState();
        formData.append("uploads[]", fileItem.file, fileItem.name);
      }
    });

    if (
      !this.dsForm.controls["documents"].hasError(
        "NxFileUploadFileTypeNotAccepted"
      ) &&
      !this.dsForm.controls["documents"].hasError("NxFileUploadMaxFileSize")
    ) {
      this.http.post(url, formData, { reportProgress: true }).subscribe(
        (data) => {
          var response: any = data;
          if (response.code == 200) {
            this.documentUpload.value.forEach((fileItem: FileItem) => {
              fileItem.setUploadedState();
            });
            this.onUploadFinished.emit(response);
            this.getDigitalSignature();
            this.errorHandler.handleCustomSuccess(
              "Digital Signature updated successfully!"
            );
          }      
          else {
            this.documentUpload.value.forEach((fileItem: FileItem) => {
              if (!fileItem.isUploaded) {
                fileItem.setErrorState();
              }
            });
            this.errormsg = response.message;
            this.showUploadError = true;
            this.errorHandler.handleCustomError(response.message);
          }
        },
        (error) => {
          this.documentUpload.value.forEach((fileItem: FileItem) => {
            if (!fileItem.isUploaded) {
              fileItem.setErrorState();
            }
          });
          
          this.errormsg = "error occured while uploading.";
          this.showUploadError = true;
        }
      );
    }
  }

  //Show Existing Digital Signature
  getDigitalSignature() {
    this.authService
      .export(this._apiUrl + "/api/v1/DownLoadPage/GetDigitalSignature")
      .subscribe((data) => {
        var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = (_event) => {
          this.thumbnail = reader.result;
        };
      });
  }

  closeModal() {
    this.dialogService.openModals[0].close();
  }
  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
