import { escapeRegExp } from "@angular/compiler/src/util";

export const MESSAGE_CONSTS = {
  /**Validation Regex */
  WRKSHOP_CODE_REGEX: new RegExp(/^[a-zA-Z]{1,4}[0-9]{1,4}$/),
  PAN_NUMBER_REGEX: new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/),
  EMAIL_REGEX: new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/
  ),
  MOBILE_REGEX: new RegExp(/^[0][1-9]\d{9}$|^[1-9]\d{9}$/),
  NUMBER_REGEX: new RegExp(/^[0-9]*$/),
  IFSC_CODE_REGEX: new RegExp(/[A-Z|a-z]{4}[0][0-9]{6}$/),
  NUMBER_PLATE_REGEX: new RegExp(
    /(([A-Z]{2}[0-9]{1,2}(?:[A-Z]*)?[0-9]{4})|([0-9]){2}BH([0-9]){1,4}[a-zA-Z]{1,2})$/
  ),
  GSTNO_REGEX: new RegExp(
    /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/
  ),
  PINCODE_REGEX: new RegExp(/[0-9]{6}$/),
  VIN_REGEX: new RegExp(/\b[(A-H|J-N|P|R-Z|0-9)]{17}\b/),
  PASSWORD_REGEX: new RegExp(
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=.\-_*])([a-zA-Z0-9@#$%^&+=*.\-_]){0,}$/
  ),
  URL_REGEX: new RegExp(
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
  ),
  NAME_REGEX :new RegExp(/^([a-zA-Z ])*$/),
  ADDRESS_REGEX: new RegExp(/^[-_,/\\ @.A-Za-z0-9]{2,45}$/),
  ALLOWED_SPECIALCHAR_REGEX: new RegExp(/^[-_,/\\ .A-Za-z0-9]*$/),
  ALPHA_NUMERIC_REGEX: new RegExp(/^[ A-Za-z0-9]*$/),
  DIGIT_ONLY_REGEX: new RegExp(/^[1-9]\d*$/),
  INVOICE_REGEX: new RegExp(/^(?=.*\d)(?=.*[A-Za-z])[A-Za-z0-9]{1,16}$/),
  /** validation messages */
  GENERAL_ERROR_MSG: "Error Occured.Please try after some time.",
  WRKSHOP_SUCCESS_MSG:
    "Registration is successfull..! You will be notified once request gets approved",
  WALLET_BALANCE_ERROR_MSG:
    "You have no sufficient balance..!,Please recharge your wallet",
  WALLET_PAYMENT_CANCEL_ERROR_MSG: "User Cancelled Transaction.",
  MANAGE_CONTET_ERROR_MSG:
    "Notification Type/Category already Exists/Active.Please inactive to save OR an Error occured.",
  MANAGE_CONTENT_URL_SANITIZATION: "Content Body May contains URL/URI Elements",
  WALLET_LOW_ON_SELL_POLICY:
    "Your wallet does not have minimum balance to sell policy!",
};
