import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { AuthService } from '../core/auth/auth.service';
import { SortEvent, SortDirection } from '@aposin/ng-aquila/table/public-api';
import { AppConfig } from '../../config/app.config';

@Component({
  selector: 'app-acive-dealer-report',
  templateUrl: './acive-dealer-report.component.html',
  styleUrls: ['./acive-dealer-report.component.css']
})
export class AciveDealerReportComponent {
  @Input() policyStartDate: moment.Moment;
  @Input() policyEndDate: moment.Moment;


  fileName: string;
  ReportData: any;
  exportdata: any = [];
  _apiUrl: string;
  clientId: number;
  userTypeId: number;
  userBaseTypeId: number;
  userId: number;

  /** sorting starts here */

  sortTable(sort: SortEvent) {
    this.ReportData = this.ReportData.sort((a, b) => {
      if (sort.active in a && sort.active in b) {
        return this.compare(a[sort.active], b[sort.active], sort.direction);
      }
      return 0;
    });
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection) {

    return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
  }
  /** sorting ends here */
  /**Pagination Start */
  count: number = 0;
  pageNumber: number = 1;
  pageSize: number = 10;
  searchTerm: any = "";
  prevPage() {
    this.pageNumber--;
    this.getReportData();
  }

  nextPage() {
    this.pageNumber++;
    this.getReportData();
  }

  goToPage(n: number) {
    this.pageNumber = n;
    this.getReportData();
  }
  /**Pagination end */

  constructor(private authService: AuthService,
    private appConfig: AppConfig,
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.userBaseTypeId = parseInt(atob(localStorage.getItem("USER_BASE_TYPE_ID")))
    this.userTypeId = parseInt(atob(localStorage.getItem("USER_TYPE_ID")))
  }


  loadReport() {
    this.pageNumber = 1;
    this.getReportData()
  }

  getReportData() {
    this.ReportData = null;
    var startdate = this.policyStartDate == undefined ? "" : this.policyStartDate.format('LL');
    var enddate = this.policyEndDate == undefined ? "" : this.policyEndDate.format('LL');

    this.authService.getList(this._apiUrl + `/api/v1/Report/DealerStatusReport?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}&searchText=${this.searchTerm}&startDatepicker=${startdate}&endDatepicker=${enddate}`)
      .subscribe(response => {
        if (response) {
          this.ReportData = response.body;
          let pagingProp = JSON.parse(response.headers.get('X-Pagination'));
          this.count = pagingProp.TotalCount;
        }
      }, (error) => {
        console.error(error);
      });
  }

  getExportData() {
    var startdate = this.policyStartDate == undefined ? "" : this.policyStartDate.format('LL');
    var enddate = this.policyEndDate == undefined ? "" : this.policyEndDate.format('LL');

    this.authService.getList(this._apiUrl + `/api/v1/Report/DealerStatusReport?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}&searchText=${this.searchTerm}&startDatepicker=${startdate}&endDatepicker=${enddate}`)
      .subscribe(response => {
        if (response) {
          this.exportdata = [];
          response.body.forEach(element => this.setExportData(element));
          this.bindexcel();
        }
      }, (error) => {
        console.error(error);
      });
  }

  setExportData(item: any) {
    var data: any = [];
    data.dealerCode = item.dealerCode
    data.dealerName = item.dealerName
    data.region = item.region
    data.state = item.state
    data.city = item.city
    data.userRole = item.userRole
    data.walletBalance = item.walletBalance
    if (item.isActive == -1)
      data.IsActive = "Requested";
    else if (item.isActive == 1)
      data.IsActive = "Active";
    else if (item.isActive == 0)
      data.IsActive = "Rejected";
    else if (item.isActive == 2)
      data.IsActive = "Removed";
    data.email_Id = item.email_Id
    this.exportdata.push(data);
  }

  exporttoexcel() {
    this.pageNumber = -1;
    this.getExportData();
  }

  bindexcel() {

    import('xlsx').then(xlsx => {
      /* json data is passed over here */
      const ws: import('xlsx').WorkSheet = xlsx.utils.json_to_sheet(this.exportdata);
      /* generate workbook and add the worksheet */
      const wb: import('xlsx').WorkBook = xlsx.utils.book_new();

      ws.A1.v = "Dealer Code";
      ws.B1.v = "Dealer Name";
      ws.C1.v = "Region";
      ws.D1.v = "State";
      ws.E1.v = "City";
      ws.F1.v = "User Role";
      ws.G1.v = "Wallet Balance";
      ws.H1.v = "Status";
      ws.I1.v = "Email";
      xlsx.utils.book_append_sheet(wb, ws, "Active_Dealer_Report");
      /* save to file */
      this.fileName =
        "ActiveDealerReport_" +
        new Date(Date.now()).toLocaleString().split(",")[0] +
        ".xlsx";
        xlsx.writeFile(wb, this.fileName);
    });

  }

}
