<table nxTable zebra class="nx-margin-right-5xl" nxSort (sortChange)="sortTable($event)">
  <thead>
      <tr nxTableRow>
          <th nxHeaderCell nxSortHeaderCell="id">
              Transaction Id
          </th>
          <th nxHeaderCell nxSortHeaderCell="dealerCode">
              Dealer Code
          </th>
          <th nxHeaderCell nxSortHeaderCell="policyNo">
              Policy No
          </th>
          <th nxHeaderCell nxSortHeaderCell="customerName">
              Customer Name
          </th>
          <th nxHeaderCell nxSortHeaderCell="invoiceNo">
              Invoice No
          </th>
          <th nxHeaderCell nxSortHeaderCell="transactionDetails">
              Transaction Details
          </th>
          <th nxHeaderCell nxSortHeaderCell="date">
              Date
          </th>
          <th nxHeaderCell nxSortHeaderCell="unitPrice">
              Unit Price
          </th>
          <th nxHeaderCell nxSortHeaderCell="cessAmount">
            Cess Amount
          </th>
          <th nxHeaderCell nxSortHeaderCell="igst">
              IGST
          </th>
          <th nxHeaderCell nxSortHeaderCell="cgst">
              CGST
          </th>
          <th nxHeaderCell nxSortHeaderCell="sgst">
              SGST
          </th>
          <th nxHeaderCell nxSortHeaderCell="amount">
              Amount
          </th>
          <th nxHeaderCell nxSortHeaderCell="transactionType">
            Transaction Type
          </th>
          <th nxHeaderCell nxSortHeaderCell="outstandingBal">
              Outstanding balance
          </th>
      </tr>
  </thead>
  <tbody style="text-align: center;">
      <tr nxTableRow *ngFor="let Req of ReportData">
          <td nxTableCell>{{Req.transactionId}}</td>
          <td nxTableCell>{{Req.dealerCode}}</td>
          <td nxTableCell>{{Req.policyNo}}</td>
          <td nxTableCell>{{Req.customerName}}</td>
          <td nxTableCell>{{Req.invoiceNo}}</td>
          <td nxTableCell>{{Req.transactionDetails}}</td>
          <td nxTableCell>{{Req.date | date:'MM/dd/yyyy'}}</td>
          <td nxTableCell>{{Req.unitPrice}}</td>
          <td nxTableCell>{{Req.cessAmount}}</td>
          <td nxTableCell>{{Req.igst}}</td>
          <td nxTableCell>{{Req.cgst}}</td>
          <td nxTableCell>{{Req.sgst}}</td>
          <td nxTableCell>{{Req.amount}}</td>
          <td nxTableCell *ngIf="Req.transactionType == 'Credit' else debit">
            <nx-badge type="positive">Credit</nx-badge>
          </td>
            <ng-template #debit>
                <td nxTableCell >
                <nx-badge type="critical">Debit</nx-badge>
                </td>
            </ng-template>
          <td nxTableCell>{{Req.outstandingBal}}</td>
      </tr>
      <tr nxTableRow *ngIf="count==0">
          <td nxTableCell colspan="11">
            <ng-container>
                <div nxRow nxRowJustify="center">
                  <div nxCol="12" class="nosearchresult"></div>
                  <h3 nxHeadline="subsection-medium" class="nx-font-weight-semibold">No Records found !!!</h3>
                </div>
              </ng-container>
          </td>
      </tr>
  </tbody>
</table>

<div nxRow class="nx-margin-top-2m nx-margin-right-5xl" style="float: right;">
  <div nxCol="6">
      <nx-pagination [nxCount]="count" [nxPage]="pageNumber" [nxPerPage]="pageSize" nxType="advanced"
          (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
      </nx-pagination>
  </div>
</div>