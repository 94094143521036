<app-header></app-header>
<app-menu></app-menu>
<div nxLayout="grid" class="nx-margin-top-2xs">
    <div nxRow>
        <div nxCol="4" nxColOffset="1">
            <nx-formfield>
                <input nxInput placeholder="Enter Keyword" [(ngModel)]="searchTerm" type="search">
                <span nxFormfieldPrefix>
                    <nx-icon name="search" size="s"></nx-icon>
                </span>
                <span nxFormfieldSuffix *ngIf="searchTerm" aria-hidden="true" (click)="searchTerm='';getContentList();">
                    <nx-icon name="close" size="s"></nx-icon>
                </span>
            </nx-formfield>
        </div>
        <div nxCol="2" class="nx-margin-top-2xs">
            <button nxButton="primary small-medium" type="button" (click)="getContentList()">Search</button>
        </div>
        <div nxCol="4" class="nx-margin-top-2xs">
            <button (click)="toggleModal(false,0)" nxButton="secondary" type="button" style="float: right;">Add
                Content</button>
        </div>
    </div>

    <div nxRow>
        <div nxCol="10" nxColOffset="1">
            <table nxTable condensed nxSort (sortChange)="sortTable($event)">
                <thead>
                    <tr nxTableRow>
                        <th nxHeaderCell nxSortHeaderCell="id">
                            Content Id
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="subject">
                            Subject
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="body">
                            Body
                        </th>
                        <th nxHeaderCell nxSortHeaderCell="status">
                            Status
                        </th>
                        <th nxHeaderCell >
                            Action
                        </th>
                        <th nxHeaderCell>
                            Activate/Deactivate
                        </th>
                    </tr>
                </thead>
                <tbody style="text-align: center;">
                    <tr nxTableRow *ngFor="let item of contentList">
                        <td nxTableCell>{{item.id}}</td>
                        <td nxTableCell>{{item.subject}}</td>
                        <td nxTableCell class="largetext">{{item.body}}</td>
                        <td nxTableCell *ngIf="item.status else inActive">
                            <nx-badge vibrant type="positive">Active</nx-badge>
                        </td>
                        <ng-template #inActive>
                            <td nxTableCell>
                                <nx-badge vibrant type="critical">In Active</nx-badge>
                            </td>
                        </ng-template>
                        <td nxTableCell>
                            <nx-context-menu #menu="nxContextMenu">
                                <ng-template nxContextMenuContent>
                                    <button nxContextMenuItem type="button"
                                        (click)="toggleModal(true,item.id)">Edit</button>
                                    <button nxContextMenuItem type="button"
                                        (click)="previewModalOpen=true;previewContent=item.body;">Preview</button>
                                </ng-template>
                            </nx-context-menu>
                            <button nxIconButton="tertiary medium" type="button" [nxContextMenuTriggerFor]="menu"
                                aria-label="Open menu" [nxTooltipPosition]="'top'" [nxTooltip]="'Please click here'">
                                <nx-icon aria-hidden="true" name="ellipsis-h"></nx-icon>
                            </button>
                        </td>
                        <td nxTableCell >
                            <nx-switcher [checked]=item.status (change)='confirmModal(item,$event.target.checked)'>
                            </nx-switcher>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div nxRow class="nx-margin-top-2m nx-margin-right-5xl" style="float: right;">
        <div nxCol="6">
            <nx-pagination [nxCount]="count" [nxPage]="pageNumber" [nxPerPage]="pageSize" nxType="advanced" (nxGoPrev)="prevPage()"
              (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
            </nx-pagination>
          </div>
    </div>
</div>

<nx-modal #addContent [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="contentModalOpen"
    [nxShowCloseIcon]="false">
    <div *ngIf="!isSuccess">
        <form name='addContent-form' [formGroup]="ContentForm">
            <div nxLayout="grid">
                <div nxRow nxRowJustify="start,around">
                    <h1 nxHeadline="subsection" *ngIf="!EDIT_FLAG">Add Content</h1>
                    <h1 nxHeadline="subsection" *ngIf="EDIT_FLAG">Edit Content</h1>
                </div>
                <div nxRow>
                    <div nxCol="12,12,12,12" nxAlignSelf="center">
                        <nx-formfield nxLabel="Content Name">
                            <input nxInput formControlName="typeDisplay" />
                            <nx-error nxFormfieldError>
                                <strong>Please note:</strong> this field is required!
                            </nx-error>
                        </nx-formfield>
                    </div>
                    <div nxCol="12,6,6,6" nxAlignSelf="center">
                        <nx-formfield nxLabel="Notification type">
                            <nx-dropdown formControlName="notificationTypeId">
                                <nx-dropdown-item *ngFor="let item of NotificationTypeList" [nxValue]="item.notificationTypeId">
                                    {{item.notificationType}}
                                </nx-dropdown-item>
                            </nx-dropdown>
                            <nx-error nxFormfieldError>
                                <strong>Please note:</strong> this field is required!
                            </nx-error>
                        </nx-formfield>
                    </div>
                    <div nxCol="12,6,6,6" nxAlignSelf="center">
                        <nx-formfield nxLabel="Notification Category">
                            <nx-dropdown formControlName="notificationCategoryId" (selectionChange)="ShowInvoicePatterns($event.value)">
                                <nx-dropdown-item *ngFor="let item of NotificationCategoryList" [nxValue]="item.notificationCategoryId">
                                    {{item.notificationCategory}}
                                </nx-dropdown-item>
                            </nx-dropdown>
                            <nx-error nxFormfieldError>
                                <strong>Please note:</strong> this field is required!
                            </nx-error>
                            <nx-icon *ngIf="invoicePatterns" aria-label="trigger by click" name="info-circle-o" size="s" [nxPopoverTriggerFor]="popoverClick"
                                nxPopoverTrigger="hover" tabindex="0"
                                role="button">
                            </nx-icon>
                            <nx-popover #popoverClick>
                                <p><b>Available Placeholder Patterns</b></p>
                                <p>[MM] - Month</p>
                                <p>[YYYY] - Year</p>
                                <p>[PNO] - Policy Number</p>
                                <p>[PID] - Policy ID - Reset on every year</p>
                                <p>[DLRCODE] - Dealer Code</p>
                                <p>[MMYY] - Month and Year</p>
                                <p>For any static text provide it without [ ] brackets</p>
                            </nx-popover>
                        </nx-formfield>
                    </div>
                    <div nxCol="12,12,12,12" nxAlignSelf="center">
                        <nx-formfield nxLabel="Subject">
                            <input nxInput formControlName="subject" />
                            <nx-error nxFormfieldError>
                                <strong>Please note:</strong> this field is required!
                            </nx-error>
                        </nx-formfield>
                    </div>
                    <div nxCol="12,12,12,12" nxAlignSelf="center">
                        <nx-formfield nxLabel="Body">
                            <textarea rows="5" nxInput type="text" formControlName="body"></textarea>
                            <nx-error nxFormfieldError>
                                <strong>Please note:</strong> this field is required!
                            </nx-error>
                        </nx-formfield>
                    </div>
                </div>
                <div nxRow nxRowAlignItems="center">
                    <div nxCol="12,12,12,12" style="text-align: center;">
                        <button (click)="addContentMaster()" [disabled]="ContentForm.invalid" nxButton="primary"
                            type="button" *ngIf="!EDIT_FLAG">Add</button>
                        <button (click)="updateContentMaster()" [disabled]="ContentForm.invalid" nxButton="primary"
                            type="button" *ngIf="EDIT_FLAG">Update</button>
                        <button class="nx-margin-left-2xs" (click)="toggleModal(false,0)" nxButton="secondary"
                            type="button">Close</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="isSuccess">
        <div nxRow nxRowJustify='start,around'>
            <div nxCol="12" id="successModalImage" class="success_image">
            </div>
        </div>
        <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
            <div nxCol="12">
                <!-- <nx-message nxContext="success">User is added successfully</nx-message> -->
                <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf="!EDIT_FLAG">Content added
                    successfully</h3>
                <h3 class="u-text-align-center" nxHeadline="subsection-xsmall" *ngIf="EDIT_FLAG">Content updated
                    successfully</h3>
            </div>
        </div>
        <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
            <div nxCol="12">
                <button (click)="toggleModal(false,0)" nxButton="block primary" id="returnButton">
                    close
                </button>
            </div>
        </div>
    </div>


</nx-modal>
<!--Modal for confirmation -->
<nx-modal #confirmModal [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="confirmModalOpen"
    [nxShowCloseIcon]="false">
    <div nxRow nxRowJustify="start,around">
        <h3 nxHeadline="subsection-small">Confirmation</h3>
    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
        <div nxCol="12">
            <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">Are you sure you want to do this action?</h3>
        </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
        <div nxCol="6">
            <button (click)="updateContentMaster();confirmModalOpen=false;" nxButton="block primary">
                Yes
            </button>
        </div>
        <div nxCol="6">
            <button (click)="confirmModalClose()" nxButton="block primary">
                No
            </button>
        </div>
    </div>
    <!-- <nx-message nxContext="success">
        User is added successfully.
      </nx-message> -->
</nx-modal>
<!-- preview Modal starts here -->
<nx-modal #previewModal [nxHideOnClickOutside]="false" [nxHideOnEsc]="false" *ngIf="previewModalOpen" (nxClose)="previewModalOpen=false">
    <div innerHTML="{{previewContent}}"></div>
</nx-modal>
<!-- preview Modal ends here -->

<app-footer></app-footer>