import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';

@Component({
  selector: 'app-clientmanagement',
  templateUrl: './clientmanagement.component.html',
  styleUrls: ['./clientmanagement.component.css']
})
export class ClientmanagementComponent implements OnInit {

  _apiUrl: string;
  clientId: number;
  clientList: any;
  clientModalOpen: boolean = false;
  clientForm: FormGroup;
  EDIT_FLAG: boolean = false;
  confirmModalOpen: boolean = false;
  isSuccess: boolean = false;
  clientData: any = {};
  Id: number = 0;
  planColumn = [
    {"name":"AWP Risk Price","value":"AwpRiskPrice"},
    {"name":"Dealer Margin","value":"DealerMargin"},
    {"name":"Customer Engagement Fund","value":"CustomerEngementFund"},
    {"name":"Hyundai Margin","value":"Royality"},
  ];
  toggleModal() {
    this.EDIT_FLAG = false;
    this.useFormBuilder();
    this.isSuccess = false;
    this.clientModalOpen = !this.clientModalOpen;
  }
  constructor(private authService: AuthService, private appConfig : AppConfig) {

    this._apiUrl = appConfig.baseUrl;
    this.useFormBuilder();
  }

  ngOnInit() {
    this.getClients();
  }
  toTextMulti(value): any {
    if (value) {
      if (Array.isArray(value)) {
        return value.map(item => item.toUpperCase()).join(', ');
      } else {
        return value.toUpperCase();
      }
    }
    return '';
  }
  useFormBuilder() {
    this.clientForm = new FormBuilder().group({
      clientId: 0,
      clientName: ['', Validators.required],
      clientUrl: ['', Validators.required],
      brandingClassName: [''],
      created: new Date(),
      updated: new Date(),
      isActive: 1,
      balanceDeductFrom:null,
      balanceDeductFromArray:[null, Validators.required],
      isDealerLogin:null,
      minWalletBalance:null,
      sMSAPI:null
    });
  }
  //get all Client
  getClients() {
    this.authService.get(this._apiUrl + '/api/v1/Client').subscribe(response => {
      if (response) {
        this.clientList = response;
      }
    }, (error) => {
      console.error(error);
    });
  }
//add Client
  onSave() {
    this.clientForm.patchValue({
      balanceDeductFrom: this.clientForm.value.balanceDeductFromArray.toString()
    });
    this.authService.post(this._apiUrl + '/api/v1/Client', this.clientForm.value).subscribe(response => {
      if (response) {
        this.getClients();
        this.isSuccess = true;
      }
    }, (error) => {
      console.error(error);
    });
  }
//update Client
  onUpdate() {
    this.clientForm.patchValue({
      balanceDeductFrom: this.clientForm.value.balanceDeductFromArray.toString()
    });
    this.authService.put(this._apiUrl + '/api/v1/Client/' + this.clientForm.controls.clientId.value, this.clientForm.value).subscribe(response => {
      this.getClients();
      this.isSuccess = true;
    }, (error) => {
      console.error(error);
    });
  }
  //get data for editing the Client
  editClient(id: number) {
    this.Id = id;
    this.authService.get(this._apiUrl + '/api/v1/Client/' + this.Id).subscribe(response => {
      if (response) {
        this.clientForm.patchValue({
          clientId: response.clientId,
          clientName: response.clientName,
          clientUrl: response.clientUrl,
          brandingClassName:response.brandingClassName,
          created: response.created,
          updated: response.updated,
          isActive: response.isActive,
          balanceDeductFromArray:response.balanceDeductFrom==null? null: response.balanceDeductFrom.split(","),
          isDealerLogin:response.isDealerLogin,
          minWalletBalance:response.minWalletBalance,
          sMSAPI:response.smsapi, 
        });
        this.clientModalOpen = true;
        this.EDIT_FLAG = true;
      }
    }, (error) => {
      console.error(error);
    });
  }
  //activate deactivate Client
  updateStatus() {
    this.confirmModalOpen = false;
    this.authService.put
      (this._apiUrl + '/api/v1/Client/' + this.clientData.clientId, this.clientData).subscribe(response => {
        if (response) {
          this.getClients();
        }
      }, (error) => {
        console.error(error);
      });

  }
  confirmModal(data: any, isChecked: boolean) { //modal yes button click

    this.confirmModalOpen = true;
    data.isActive = isChecked ? 1: 0; //set the current status
    this.clientData = data;

  }
  onNoClick() { //modal no button click
    this.confirmModalOpen = false;
    this.clientData = {};
    this.getClients();
  }

}
