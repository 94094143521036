import { Component, OnInit} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from "src/config/app.config";
import { NxDialogService, NxModalRef } from "@aposin/ng-aquila/modal";
import { DigitalSignatureComponent } from "../digital-signature/digital-signature.component";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  _apiUrl;
  userName;
  userEmail;
  userRole;
  userId;
  walletBalance: number;
  private isAuthorizedSubscription: Subscription = new Subscription();
  public isAuthorized = false;
  DealerReg: boolean;

  dsDialogRef!: NxModalRef<DigitalSignatureComponent>;

  constructor(
    private router: Router,
    private authService: AuthService,
    private appConfig: AppConfig,
    public dialogService: NxDialogService
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.userName = localStorage.getItem("USER_NAME");
    this.userEmail = localStorage.getItem("USER_EMAIL");
    this.userRole = atob(localStorage.getItem("userRole"));
    this.DealerReg =
      localStorage.getItem("DealerReg") == "true" && this.userRole == "Dealer";
  }

  ngOnInit() {}
  public logOutUser() {
    this.authService.Logout(localStorage.getItem("IDENTIFIER"));
    this.authService.resetcredentials();
    this.router.navigate([""]);
  }

  openDSComponent(): void {
    this.dsDialogRef = this.dialogService.open(DigitalSignatureComponent, {
      ariaLabel: "Digital Signature",
      showCloseIcon: true,
    });
  }
}
