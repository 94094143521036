export enum Status{
    InActive = 0,
    Active = 1
}

export enum WalletStatus{
    Requested=0,
    Credited=1,
    Rejected=2,
    Intiated=3,
    Cancelled=4,
}