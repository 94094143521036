<app-header></app-header>
<app-menu></app-menu>

<div nxLayout="grid" class="nx-margin-top-2m">
  <div nxRow nxRowAlignItems="start,start,center,center">
    <div nxCol="12,6,2" nxColOffset="1">
      <nx-formfield>
        <input
          nxInput
          placeholder="Enter Keyword"
          [(ngModel)]="searchTerm"
          type="search"
        />
        <span nxFormfieldPrefix>
          <nx-icon name="search" size="s"></nx-icon>
        </span>
        <span
          nxFormfieldSuffix
          *ngIf="searchTerm"
          aria-hidden="true"
          (click)="searchTerm = ''; getAllWalletRequestByUserRole()"
        >
          <nx-icon name="close" size="s"></nx-icon>
        </span>
      </nx-formfield>
    </div>
    <div nxCol="12,6,2">
      <nx-formfield nxLabel="Wallet Status">
        <nx-dropdown
          [(ngModel)]="SelectedWalletRequestStatus"
          [nxIsMultiselect]="true"
        >
          <nx-dropdown-item
            *ngFor="let type of walletStatus | keyvalue"
            [nxValue]="WalletStatuses[type.value]"
          >
            {{ type.value }}
          </nx-dropdown-item>
        </nx-dropdown>
      </nx-formfield>
    </div>

    <div nxCol="12,6,1" class="nx-margin-top-2xs">
      <button
        nxButton="primary"
        type="button"
        (click)="getAllWalletRequestByUserRole()"
      >
        Search
      </button>
    </div>
    <div
      nxCol="12,6,3"
      class="nx-margin-top-2xs"
      *ngIf="
        (Userrole === 'WorkShop' || DealerReg) && userPermission.isOnlinePayment
      "
    >
      <button
        (click)="togglePaymentModalPopup('online')"
        nxButton="secondary"
        type="button"
        style="float: right"
      >
        Add Amount(Payment Gateway)
      </button>
    </div>
    <div nxCol="12,6,2" class="nx-margin-top-2xs" *ngIf="isOfflinePayment">
      <button (click)="openFromTemplate()" nxButton="secondary" type="button">
        Wallet Request
      </button>
    </div>
  </div>

  <div
    nxRow
    nxRowJustify="start,start,around"
    nxRowAlignContent="end,around,stretch"
  >
    <div nxCol="12,10" *ngIf="count != 0">
      <table nxTable condensed nxSort (sortChange)="sortTable($event)">
        <thead>
          <tr nxTableRow>
            <th nxHeaderCell nxSortHeaderCell="approvedDate">
              Approved/Payment Date
            </th>
            <th nxHeaderCell nxSortHeaderCell="transactionId">
              Transaction ID
            </th>
            <th nxHeaderCell nxSortHeaderCell="paymentMethod">
              Payment Method
            </th>
            <th nxHeaderCell nxSortHeaderCell="approvedRemarks">Remark</th>
            <th
              nxHeaderCell
              *ngIf="userPermission.approveReject"
              nxSortHeaderCell="dealerCode"
            >
              Dealer Code
            </th>
            <th
              nxHeaderCell
              *ngIf="userPermission.approveReject"
              nxSortHeaderCell="dealerName"
            >
              Dealer Name
            </th>
            <th nxHeaderCell nxSortHeaderCell="requestDate">Request Date</th>
            <th nxHeaderCell nxSortHeaderCell="requestedAmount">Amount</th>
            <th nxHeaderCell nxSortHeaderCell="grantedAmt">Granted Amount</th>
            <th nxHeaderCell nxSortHeaderCell="status">Status</th>
            <th
              nxHeaderCell
              *ngIf="userPermission.acceptWallet || userPermission.rejectWallet"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody style="text-align: center">
          <tr nxTableRow *ngFor="let Req of walletRequestList">
            <td *ngIf="Req.approvedDate != null" nxTableCell>
              {{ Req.approvedDate | date: "dd/MM/yyyy" }}
            </td>
            <td *ngIf="Req.approvedDate == null" nxTableCell>
              {{ Req.paymentDate | date: "dd/MM/yyyy" }}
            </td>
            <td nxTableCell>{{ Req.transactionId }}</td>
            <td nxTableCell>{{ Req.paymentMethod }}</td>
            <td nxTableCell>{{ Req.approvedRemarks }}</td>
            <td nxTableCell *ngIf="userPermission.approveReject">
              {{ Req.dealerCode }}
            </td>
            <td nxTableCell *ngIf="userPermission.approveReject">
              {{ Req.dealerName }}
            </td>
            <td nxTableCell>{{ Req.requestDate | date: "dd/MM/yyyy" }}</td>
            <td nxTableCell>{{ Req.requestedAmount }}</td>
            <td nxTableCell>{{ Req.grantedAmt }}</td>
            <td
              nxTableCell
              *ngIf="Req.status == WalletStatuses.Credited; else pending"
            >
              <nx-badge type="positive">Approved/Credited</nx-badge>
            </td>
            <ng-template #pending>
              <td
                nxTableCell
                *ngIf="Req.status == WalletStatuses.Requested; else rejected"
              >
                <nx-badge type="negative">Requested</nx-badge>
              </td>
            </ng-template>
            <ng-template #rejected>
              <td
                nxTableCell
                *ngIf="Req.status == WalletStatuses.Rejected; else cancelled"
              >
                <nx-badge
                  type="critical"
                  [nxTooltipPosition]="'left'"
                  [nxTooltip]="
                    'This Payment is failed. In case amount has been deducted from your account, the same will be credited in your wallet within 24 hrs.'
                  "
                  >Rejected/Failed</nx-badge
                >
              </td>
            </ng-template>
            <ng-template #cancelled>
              <td
                nxTableCell
                *ngIf="Req.status == WalletStatuses.Cancelled; else failed"
              >
                <nx-badge
                  type="critical"
                  [nxTooltipPosition]="'left'"
                  [nxTooltip]="
                    'The payment process terminated in between the Transaction. In case amount has been deducted from your account, the same will be credited in your wallet within 24 hrs.'
                  "
                  >Cancelled</nx-badge
                >
              </td>
            </ng-template>
            <ng-template #failed>
              <td nxTableCell>
                <nx-badge
                  type="critical"
                  [nxTooltipPosition]="'left'"
                  [nxTooltip]="
                    'This Payment is Processing by Bank, we will update you in 24 hrs.'
                  "
                  >Processing</nx-badge
                >
              </td>
            </ng-template>

            <td
              nxTableCell
              *ngIf="userPermission.acceptWallet || userPermission.rejectWallet"
            >
              <nx-context-menu #menu="nxContextMenu">
                <ng-template nxContextMenuContent>
                  <button
                    *ngIf="userPermission.acceptWallet"
                    type="button"
                    nxContextMenuItem
                    [disabled]="Req.status !== WalletStatuses.Requested"
                    (click)="toogleApproveRejectModal(true, Req)"
                  >
                    Approve
                  </button>
                  <button
                    *ngIf="userPermission.rejectWallet"
                    type="button"
                    nxContextMenuItem
                    [disabled]="Req.status !== WalletStatuses.Requested"
                    (click)="toogleApproveRejectModal(false, Req)"
                  >
                    Reject
                  </button>
                </ng-template>
              </nx-context-menu>
              <button
                nxIconButton="tertiary medium"
                type="button"
                [nxContextMenuTriggerFor]="menu"
                aria-label="Open menu"
                [nxTooltipPosition]="'top'"
                [nxTooltip]="'Please click here'"
              >
                <nx-icon aria-hidden="true" name="ellipsis-h"></nx-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        nxRow
        class="nx-margin-top-2m nx-margin-right-xl"
        style="float: right"
      >
        <div nxCol="6">
          <nx-pagination
            [nxCount]="count"
            [nxPage]="pageNumber"
            [nxPerPage]="pageSize"
            nxType="advanced"
            (nxGoPrev)="prevPage()"
            (nxGoNext)="nextPage()"
            (nxGoPage)="goToPage($event)"
          >
          </nx-pagination>
        </div>
      </div>
    </div>
    <div nxCol="12,10" class="nx-margin-top-2xl" *ngIf="count == 0">
      <ng-container>
        <div nxRow nxRowJustify="center">
          <div nxCol="12" class="nosearchresult"></div>
          <h3 nxHeadline="subsection-medium" class="nx-font-weight-semibold">
            No Records found !!!
          </h3>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<app-footer></app-footer>

<nx-modal
  [nxHideOnClickOutside]="false"
  [nxHideOnEsc]="false"
  *ngIf="walletRequestView"
  [nxShowCloseIcon]="false"
>
  <div nxLayout="grid" *ngIf="!isSuccess" style="margin-top: 2%">
    <div nxRow nxRowJustify="start,around">
      <h3 nxHeadline="subsection-xsmall" *ngIf="reqDecision">
        Approve Wallet Request
      </h3>
      <h3 nxHeadline="subsection-xsmall" *ngIf="!reqDecision">
        Reject Wallet Request
      </h3>
    </div>
    <form [formGroup]="walletRequestApproveRejectForm">
      <div nxRow *ngIf="reqDecision">
        <div nxCol="12,12,6,4">
          <nx-formfield nxLabel="Payment Method">
            <input nxInput formControlName="paymentMethod" readonly />
          </nx-formfield>
        </div>
        <div nxCol="12,12,6,4">
          <nx-formfield nxLabel="Transaction Details">
            <input nxInput formControlName="paymentMethodDetails" readonly />
          </nx-formfield>
        </div>
        <div nxCol="12,12,12,4">
          <nx-formfield nxLabel="Transaction Date">
            <input nxInput formControlName="paymentDate" readonly />
          </nx-formfield>
        </div>
      </div>
      <div nxRow *ngIf="reqDecision">
        <div nxCol="12,12,6">
          <nx-formfield nxLabel="Requested Amount">
            <input
              nxInput
              formControlName="requestedAmount"
              attr.data-test="{{ requestedWalletAmount }}"
              readonly
            />
          </nx-formfield>
        </div>
        <div nxCol="12,12,6">
          <nx-formfield nxLabel="Granted Amount">
            <input
              nxInput
              formControlName="grantedAmt"
              type="number"
              required
            />
            <nx-error nxFormfieldError>
              <div *ngIf="ARFormControls.grantedAmt.errors?.required">
                <strong>Please note:</strong>Enter Granted Amount.
              </div>
              <div *ngIf="ARFormControls.grantedAmt.errors?.pattern">
                <strong>Please note:</strong>Accept numbers only.
              </div>
              <div *ngIf="ARFormControls.grantedAmt.errors?.max">
                <strong>Please note:</strong>Granted Amount cannot be greater
                than Requested Amount.
              </div>
            </nx-error>
          </nx-formfield>
        </div>
      </div>
      <div nxRow>
        <div nxCol="12,12,6">
          <nx-formfield nxLabel="Requestor Remarks">
            <textarea
              nxInput
              formControlName="remark"
              rows="2"
              cols="65"
              type="text"
              required
              readonly
            ></textarea>
          </nx-formfield>
        </div>
        <div nxCol="12,12,6">
          <nx-formfield nxLabel="Approved Remarks">
            <textarea
              nxInput
              formControlName="approvedRemarks"
              rows="2"
              cols="65"
              type="text"
              required
            ></textarea>
            <nx-error nxFormfieldError>
              <div *ngIf="ARFormControls.approvedRemarks.errors?.required">
                <strong>Please note:</strong>Enter Remark.
              </div>
              <div *ngIf="ARFormControls.approvedRemarks.errors?.pattern">
                <strong>Please note:</strong> Enter Valid Remark. <br />Allowed
                charcters are -_,/.&lt;space&gt; and Alpha numerics
              </div>
            </nx-error>
          </nx-formfield>
        </div>
      </div>
      <div nxRow *ngIf="reqDecision">
        <div nxCol="12,12,6,4" *ngIf="extn == 'pdf'">
          <h4><strong>Uploaded files</strong></h4>
          <nx-icon
            name="pdf"
            class="nx-margin-right-2xs"
            size="m"
            style="color: red"
          ></nx-icon>
          <span style="vertical-align: top">{{ filename }}</span>
        </div>
        <div nxCol="12,12,6,4" *ngIf="extn != 'pdf'">
          <h4><strong>Uploaded files</strong></h4>
          <nx-icon
            name="img"
            class="nx-margin-right-2xs"
            size="m"
            style="color: rgb(95, 11, 231)"
          ></nx-icon>
          <span style="vertical-align: top">{{ filename }}</span>
        </div>
        <div nxCol="12,12,4">
          <h5>&nbsp;</h5>
          <button
            nxButton="tertiary small"
            type="button"
            (click)="download(walletListData.uploadedDoc)"
          >
            <nx-icon name="launch" class="nx-margin-right-2xs"></nx-icon>Open
          </button>
        </div>
      </div>
      <div nxRow>
        <div
          nxCol="10"
          nxColOffset="1"
          style="text-align: center"
          class="nx-margin-top-2m"
        >
          <button
            nxButton="primary"
            type="button"
            (click)="saveARWalletRequest('offline')"
            [disabled]="checksavewalletDisable"
          >
            {{ saveWalletText }}
            <nx-spinner
              nxSize="medium"
              class="nx-margin-x-2xs"
              *ngIf="saveWalletInProgress"
            ></nx-spinner>
          </button>
          <button
            nxButton="emphasis"
            type="button"
            (click)="toggleWalletARModalPopup()"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="isSuccess">
    <div nxRow nxRowJustify="start,around">
      <div nxCol="12" style="text-align: center">
        <nx-icon
          name="check-circle"
          size="m"
          style="color: green"
          *ngIf="reqDecision"
        ></nx-icon>
        <nx-icon
          name="check-circle"
          size="m"
          style="color: red"
          *ngIf="!reqDecision"
        ></nx-icon>
      </div>
    </div>
    <div nxRow nxRowJustify="center" class="nx-margin-top-m nx-margin-bottom-s">
      <div nxCol="12">
        <h3
          class="u-text-align-center"
          *ngIf="reqDecision"
          nxHeadline="subsection-xsmall"
        >
          Approved successfully
        </h3>
        <h3
          class="u-text-align-center"
          *ngIf="!reqDecision"
          nxHeadline="subsection-xsmall"
        >
          Rejected successfully
        </h3>
      </div>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
      <div nxCol="12">
        <button (click)="toggleWalletARModalPopup()" nxButton="block primary">
          close
        </button>
      </div>
    </div>
  </div>
</nx-modal>

<nx-modal
  *ngIf="walletRequestFormView"
  [nxHideOnClickOutside]="false"
  [nxHideOnEsc]="false"
  [nxShowCloseIcon]="false"
>
  <div nxLayout="grid" *ngIf="!isSuccess" style="margin-top: 2%">
    <div
      nxRow
      nxRowJustify="start,start,around"
      nxRowAlignContent="end,around,stretch"
    >
      <div nxCol="12">
        <h1 style="text-align: center" nxHeadline="page">Wallet Request</h1>
        <form [formGroup]="walletRequestForm">
          <div nxRow nxRowJustify="around">
            <div nxCol="12">
              <nx-formfield nxLabel="Requested Amount">
                <nx-formfield-label class="required">
                  Requested Amount
                </nx-formfield-label>
                <input
                  nxInput
                  formControlName="requestedAmount"
                  type="number"
                />
                <nx-error nxFormfieldError>
                  <div
                    *ngIf="RequestFormControls.requestedAmount.errors?.required"
                  >
                    <strong>Please note:</strong>Enter Request Amount.
                  </div>
                  <div
                    *ngIf="RequestFormControls.requestedAmount.errors?.pattern"
                  >
                    <strong>Please note:</strong>Accept numbers only.
                  </div>
                </nx-error>
              </nx-formfield>
            </div>
          </div>
          <div nxRow nxRowJustify="around">
            <div nxCol="12,12,12,6">
              <nx-formfield nxLabel="Transaction Date">
                <nx-formfield-label class="required">
                  Transaction Date</nx-formfield-label
                >
                <input
                  nxDatefield
                  nxInput
                  [nxDatepicker]="paymentDate"
                  formControlName="paymentDate"
                />
                <span nxFormfieldHint>MM/DD/YYYY</span>
                <nx-datepicker-toggle
                  [for]="paymentDate"
                  nxFormfieldSuffix
                ></nx-datepicker-toggle>
                <nx-datepicker #paymentDate></nx-datepicker>
                <nx-error nxFormfieldError>
                  <div *ngIf="RequestFormControls.paymentDate.errors?.required">
                    <strong>Please note:</strong>Select Transaction Date.
                  </div>
                </nx-error>
              </nx-formfield>
            </div>
            <div nxCol="12,12,12,6">
              <nx-formfield nxLabel="Transaction Details">
                <nx-formfield-label class="required">
                  Transaction Details</nx-formfield-label
                >
                <input
                  nxInput
                  formControlName="paymentMethodDetails"
                  type="text"
                />
                <nx-error nxFormfieldError>
                  <div
                    *ngIf="
                      RequestFormControls.paymentMethodDetails.errors?.pattern
                    "
                  >
                    <strong>Please note:</strong>Enter Valid Details.
                    <br />Allowed charcters are -_,/.&lt;space&gt; and Alpha
                    numerics
                  </div>
                </nx-error>
              </nx-formfield>
            </div>
          </div>
          <div nxRow nxRowJustify="around">
            <div nxCol="12,12,12,6">
              <nx-formfield nxLabel="Payment Method">
                <nx-formfield-label class="required"
                  >Payment Method</nx-formfield-label
                >
                <nx-dropdown formControlName="paymentMethod">
                  <nx-dropdown-item nxValue="NEFT"></nx-dropdown-item>
                  <nx-dropdown-item nxValue="IMPS"></nx-dropdown-item>
                </nx-dropdown>
                <nx-error nxFormfieldError>
                  <div
                    *ngIf="RequestFormControls.paymentMethod.errors?.required"
                  >
                    <strong>Please note:</strong>Select Payment Method.
                  </div>
                </nx-error>
              </nx-formfield>
            </div>
            <div nxCol="12,12,12,6">
              <nx-label
                >Upload Proof(image/pdf only)
                <strong style="color: red">*</strong></nx-label
              >
              <app-upload
                [acceptedfiles]="accpetedTypes"
                (onUploadFinished)="checkUploadFinished($event)"
              ></app-upload>
            </div>
          </div>
          <div nxRow nxRowJustify="around">
            <div nxCol="12">
              <nx-formfield nxLabel="Remark">
                <nx-formfield-label class="required">Remark</nx-formfield-label>
                <textarea
                  nxInput
                  formControlName="remark"
                  rows="2"
                  cols="75"
                  type="text"
                  required
                ></textarea>
                <nx-error nxFormfieldError>
                  <div *ngIf="RequestFormControls.remark.errors?.required">
                    <strong>Please note:</strong>Enter Remark.
                  </div>
                  <div *ngIf="RequestFormControls.remark.errors?.pattern">
                    <strong>Please note:</strong>Enter Valid Remark.
                    <br />Allowed charcters are -_,/.&lt;space&gt; and Alpha
                    numerics
                  </div>
                </nx-error>
              </nx-formfield>
            </div>
          </div>
          <div nxRow nxRowJustify="around">
            <div nxCol="12,12,8,6" nxColoffset="2" style="text-align: center">
              <button
                nxButton="cta"
                type="button"
                (click)="saveWalletRequest('offline')"
                [disabled]="checkDisable"
              >
                {{ saveWalletText }}
                <nx-spinner
                  nxSize="medium"
                  class="nx-margin-x-2xs"
                  *ngIf="saveWalletInProgress"
                ></nx-spinner>
              </button>
              <button
                nxButton="emphasis"
                type="button"
                (click)="togglePaymentModalPopup('offline')"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div nxLayout="grid" *ngIf="isSuccess" style="margin-top: 2%">
    <div nxRow nxRowJustify="start,around">
      <div nxCol="12" style="text-align: center">
        <nx-icon name="check-circle" size="m" style="color: green"></nx-icon>
      </div>
    </div>
    <div nxRow nxRowJustify="start,around">
      <h3 class="u-text-align-center" nxHeadline="subsection-xsmall">
        Request Submitted successfully
      </h3>
    </div>
    <div class="nx-margin-top-2m" nxRow nxRowJustify="center">
      <div nxCol="12">
        <button
          (click)="togglePaymentModalPopup('offline')"
          nxButton="block primary"
        >
          close
        </button>
      </div>
    </div>
  </div>
</nx-modal>

<ng-template #disclaimerTemplate>
  <div nxModalContent>
    <nx-card>
      <nx-card-header>
        <div>
          <nx-icon
            name="product-bank-monument-info"
            class="nx-margin-right-s"
            size="m"
            style="display: inline-block"
          ></nx-icon>
          <h3
            nxHeadline="subsection-medium"
            class="nx-font-weight-bold"
            style="display: inline-block"
          >
          AWP ASSISTANCE (INDIA)<br/>PRIVATE LIMITED
          </h3>
        </div>
        <hr />
      </nx-card-header>
      <table class="table typography-demo-table">
        <tbody>
          <tr>
            <td class="left">
              <p nxCopytext="large">Bank Account Number</p>
            </td>
            <td class="left"><code class="clr-code"></code></td>
            <td class="left">
              <p nxCopytext="large">054143367001</p>
            </td>
          </tr>
          <tr>
            <td class="left">
              <p nxCopytext="large">Bank Name</p>
            </td>
            <td class="left"><code class="clr-code"></code></td>
            <td class="left">
              <p nxCopytext="large">The Hongkong and Shanghai Corporation Limited</p>
            </td>
          </tr>
          <tr>
            <td class="left">
              <p nxCopytext="large">Branch Address</p>
            </td>
            <td class="left"></td>
            <td class="left">
              <p nxCopytext="large">      
                JMD Regent Square, DLF Phase- II, <br /> 
                Mehrauli-Gurgaon Rd, Gurugram, Haryana 122001
              </p>
            </td>
          </tr>
          <tr>
            <td class="left">
              <p nxCopytext="large">IFSC Code</p>
            </td>
            <td class="left"><code class="clr-code"></code></td>
            <td class="left">
              <p nxCopytext="large">HSBC0110005</p>
            </td>
          </tr>
        </tbody>
      </table>
    </nx-card>
  </div>
  <hr />
  <p nxCopytext="normal">
    <span class="nx-font-weight-bold">Please Note </span><br />
    Payment made through NEFT/Cheque should be in favour of AWP Assistance India
    Pvt ltd. Followed by the workshop code mentioned on the NEFT/cheque <br />
    After deposit of payment your account will be updated in 3 working days.
  </p>
  <div nxModalActions>
    <button
      nxModalClose="Disagree"
      class="nx-margin-bottom-0 nx-margin-right-xs"
      nxButton="small emphasis"
      type="button"
    >
      Go back
    </button>
    <button
      nxModalClose="Agree"
      class="nx-margin-bottom-0"
      nxButton="small cta"
      type="button"
    >
      I understand
    </button>
  </div>
</ng-template>

<!-- Online Payment -->
<nx-modal
  *ngIf="onlinepayment"
  [nxHideOnClickOutside]="false"
  [nxHideOnEsc]="false"
  [nxShowCloseIcon]="false"
>
  <app-online-payment (closeModal)="onCloseModal($event)"></app-online-payment>
</nx-modal>
