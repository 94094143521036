
export class planMaster {
    planId: number;
    planName: string;
    durationOfPlan: string;
    maximumVehicleAge: string;
    awpRiskPrice: number;
    marketingFund: number;
    customerEngementFund: number;
    dealerMargin: number;
    royality: number;
    totalPrice: number;
    createdBy: string;
    createdDate: string;
    updatedDate :string;
    isActive: number;
    clientId: number;
    discountPolicyCount : number = 0;
    discountedPlanId : number = 0;
    isDiscountedPlan : Boolean = false;
    unityPlanId: number;
    unityBrand: number;
    unityProductName: number;
    zoneId:Array<number>;
}

export class offerModel{
    ZoneIds : Array<number>;
    OfferName : string;
    StartDate : string;
    EndDate : string;
    DiscountPercent : number;
    IsActive : number;
    CreatedBy : string;
    ClientId : number;
}

