<form [formGroup]="uploadInvoiceForm" *ngIf="modalOpen">
  <nx-small-stage
    class="nx-margin-bottom-m border-radius"
    appearance="expert"
    narrow
  >
    <nx-small-stage-image
      nxSmallStageImageEnd
      src="assets/illustrations/man_holding_certificate.png"
    >
    </nx-small-stage-image>
    <h2 nxHeadline="subsection-large" class="nx-font-weight-regular">
      Upload Invoice
    </h2>
  </nx-small-stage>
  <nx-radio-group [(ngModel)]="uploadType" formControlName="types">
    <div class="horizontal-buttons">
      <nx-radio nxValue="S" class="nx-margin-bottom-m">System Invoice</nx-radio>
      <nx-radio nxValue="C" class="nx-margin-bottom-m nx-margin-left-s"
        >Custom Invoice</nx-radio
      >
    </div>
  </nx-radio-group>
  <nx-file-uploader
    #documentUpload
    formControlName="documents"
    maxFileSize="{{ 5 * 1024 * 1024 }}"
    accept="application/pdf"
    required
  >
    <nx-label size="small" class="nx-margin-bottom-3xs"
      >Select a file to upload</nx-label
    >
    <span class="nx-margin-bottom-s" nxFileUploadHint
      >maximum file size 5MB</span
    >
    <nx-file-uploader-drop-zone
      class="nx-margin-bottom-m border-radius"
    >
      <span class="nx-margin-right-2xs">Drag your files here or click </span>
      <button
        nxButton="primary small-medium"
        type="button"
        class="nx-margin-bottom-s"
        nxFileUploadButton
      >
        <nx-icon name="plus" class="nx-margin-right-2xs"></nx-icon>
        Add File
      </button>
    </nx-file-uploader-drop-zone>
    <nx-error
      *ngIf="
        uploadInvoiceForm.controls['documents'].hasError(
          'NxFileUploadFileTypeNotAccepted'
        )
      "
    >
      File
      {{
        uploadInvoiceForm.controls["documents"].getError(
          "NxFileUploadFileTypeNotAccepted"
        ).fileName | json
      }}
      can not be uploaded. This file type is not supported!
    </nx-error>
    <nx-error
      *ngIf="
        uploadInvoiceForm.controls['documents'].hasError(
          'NxFileUploadMaxFileSize'
        )
      "
    >
      File
      {{
        uploadInvoiceForm.controls["documents"].getError(
          "NxFileUploadMaxFileSize"
        ).fileName | json
      }}
      can not be uploaded. File size exceeds size limit!
    </nx-error>
  </nx-file-uploader>
  <div nxRow nxRowJustify="start,around">
    <div nxCol="5" class="align-center">
      <button
        nxButton="primary medium"
        type="button"
        [disabled]="documentUpload.value && !documentUpload.value.length"
        (click)="upload()"
      >
        SUBMIT
      </button>
      <button
        type="button"
        class="nx-margin-left-2xs"
        nxButton="secondary medium"
        (click)="closeModal()"
      >
        close
      </button>
    </div>
  </div>
</form>
<form [formGroup]="uploadedInvoiceForm" *ngIf="uploadedInvoices">
  <nx-small-stage
    class="nx-margin-bottom-m border-radius"
    appearance="expert"
    narrow
  >
    <nx-small-stage-image
      nxSmallStageImageEnd
      src="assets/illustrations/man_holding_certificate.png"
    >
    </nx-small-stage-image>
    <h2 nxHeadline="subsection-large" class="nx-font-weight-regular">
      Uploaded Invoice
    </h2>
  </nx-small-stage>
  <div nxRow nxRowJustify="start,around">
    <div nxCol="12">
      <div class="scroll-container">
        <table nxTable>
          <thead class="align-center">
            <tr nxTableRow>
              <th nxHeaderCell class="w-200">Uploaded Type</th>
              <th nxHeaderCell class="w-300">Uploaded Date</th>
              <th nxHeaderCell>Download</th>
            </tr>
          </thead>
          <tbody>
            <tr nxTableRow *ngFor="let item of invoiceList">
              <td nxTableCell class="w-200">{{ item.uploadType }}</td>
              <td nxTableCell class="w-300">
                {{ item.uploadedDate | date: "MM/dd/yyyy" }}
              </td>
              <td nxTableCell>
                <button
                  nxButton="tertiary"
                  aria-label="view"
                  type="button"
                  (click)="download(item)"
                >
                  <nx-icon name="arrow-download"></nx-icon>
                </button>
              </td>

              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div nxRow nxRowJustify="start,around" class="align-center">
    <button
      type="button"
      class="nx-margin-top-3m"
      nxButton="secondary small"
      (click)="closeModal()"
    >
      close
    </button>
  </div>
</form>
