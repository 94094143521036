import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth/auth.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { JwtModule } from "@auth0/angular-jwt";
import { RoleGuardService } from './auth/role-guard.service';
import { CustomErrorHandler } from './custom-error-handler';
import { RequestIntegrity } from './request-integrety';

export function tokenGetter() {
  return localStorage.getItem("API_ACCESS_TOKEN");
}

@NgModule({
  imports: [
    CommonModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["example.com"],
        blacklistedRoutes: ["http://example.com/examplebadroute/"],
      },
    }),
  ],
  declarations: [],
  providers: [
    AuthService,
    AuthGuardService,
    RoleGuardService,
    CustomErrorHandler,
    RequestIntegrity
  ]
})
export class CoreModule { }
