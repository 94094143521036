<form [formGroup]="downloadInvoiceForm" *ngIf="downloadInvoiceOpen">
  <nx-small-stage
    class="nx-margin-bottom-m border-radius"
    appearance="expert"
    narrow
  >
    <nx-small-stage-image
      nxSmallStageImageEnd
      src="assets/illustrations/man_holding_certificate.png"
    ></nx-small-stage-image>
    <h2 nxHeadline="subsection-large" class="nx-font-weight-regular">
      Download Invoice
    </h2>
  </nx-small-stage>
  <div nxRow nxRowJustify="start,around">
    <div nxCol="12" *ngIf="signatureView">
      <nx-formfield nxLabel="Invoice Number">
        <nx-formfield-label class="required">Invoice Number</nx-formfield-label>
        <input nxInput formControlName="invoiceNumber" />
        <nx-error nxFormfieldError>
          <div *ngIf="f.invoiceNumber.errors?.required">
            <strong>Please note:</strong> Enter Invoice Number.
          </div>
          <div *ngIf="f.invoiceNumber.errors?.pattern">
            <strong>Please note:</strong> Enter Valid data. <br />Allowed Alpha
            numerics of atleast one character and one digit with maximum of 16 characters.
          </div>
        </nx-error>
      </nx-formfield>
    </div>
    <div nxCol="12">
      <nx-radio-group [(ngModel)]="downloadType" formControlName="types">
        <div class="horizontal-buttons">
          <nx-radio
            nxValue="S"
            class="nx-margin-bottom-m"
            (nxValueChange)="downloadTypeChange($event.value)"
            >System Invoice</nx-radio
          >
          <nx-radio
            nxValue="C"
            class="nx-margin-bottom-m nx-margin-left-s"
            (nxValueChange)="downloadTypeChange($event.value)"
            >Custom Invoice</nx-radio
          >
        </div>
      </nx-radio-group>
    </div>
  </div>
  <div nxRow nxRowJustify="start,around" *ngIf="signatureView">
    <div nxCol="12">
      <nx-checkbox
        [(ngModel)]="checked"
        class="nx-margin-bottom-s"
        formControlName="digitalSign"
        >Add Signature.</nx-checkbox
      >
    </div>
  </div>
  <div nxRow class="nx-margin-top-xs" nxRowJustify="start,around">
    <div nxCol="10" nxAlignSelf="center" class="align-center">
      <button
        nxButton="primary medium"
        [disabled]="!downloadInvoiceForm.valid"
        (click)="invoiceNumberCheck()"
      >
        <nx-icon name="pdf" class="nx-margin-right-2xs color-red"></nx-icon>
        Download
      </button>
      <button
        nxButton="secondary medium"
        (click)="closeModal()"
        class="nx-margin-left-2xs"
      >
        Close
      </button>
    </div>
  </div>
</form>
