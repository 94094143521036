<app-header></app-header>
<app-menu></app-menu>
<!-- <app-sidenav></app-sidenav> -->

<div nxLayout="grid">
  <div nxRow style="padding-top: 2%">
    <div nxCol="12,12,12,4">
      <nx-selectable-card appearance="expert">
        <h2 nxHeadline="subsection-small">TOTAl REVENUE (BTD)</h2>
        <canvas id="canvas" width="350" height="300"></canvas>
        <div style="text-align: center; color: #ff6384">
          <h3>POLICIES SOLD ON THIS WEEK: {{ ChartCal?.btdPolicies }}</h3>
          <h3>TOTAL : {{ ChartCal?.btdAmount }}</h3>
        </div>
        <nx-radio-toggle
          [nxStyle]="'small'"
          name="btdChartType"
          [(ngModel)]="btdChartType"
          (click)="changeChartType('BTD')"
        >
          <nx-radio-toggle-button nxValue="1" [nxSelected]="true">
            <nx-icon name="product-stock-data-graph"></nx-icon>
          </nx-radio-toggle-button>
          <nx-radio-toggle-button nxValue="2">
            <nx-icon name="product-share-graph-arrow-up"></nx-icon>
          </nx-radio-toggle-button>
          <nx-radio-toggle-button nxValue="3">
            <nx-icon name="in-progress"></nx-icon>
          </nx-radio-toggle-button>
        </nx-radio-toggle>
      </nx-selectable-card>
    </div>

    <div nxCol="12,12,12,4">
      <nx-selectable-card appearance="expert">
        <h2 nxHeadline="subsection-small">TOTAl REVENUE (MTD)</h2>
        <canvas id="canvas1" width="350" height="300"></canvas>
        <div style="text-align: center; color: #36a2eb">
          <h3>POLICIES SOLD ON CURRENT MONTH : {{ ChartCal?.mtdPolicies }}</h3>
          <h3>TOTAL : {{ ChartCal?.mtdAmount }}</h3>
        </div>
        <nx-radio-toggle
          [nxStyle]="'small'"
          name="mtdChartType"
          [(ngModel)]="mtdChartType"
          (click)="changeChartType('MTD')"
        >
          <nx-radio-toggle-button nxValue="1" [nxSelected]="true">
            <nx-icon name="product-stock-data-graph"></nx-icon>
          </nx-radio-toggle-button>
          <nx-radio-toggle-button nxValue="2">
            <nx-icon name="product-share-graph-arrow-up"></nx-icon>
          </nx-radio-toggle-button>
          <nx-radio-toggle-button nxValue="3">
            <nx-icon name="in-progress"></nx-icon>
          </nx-radio-toggle-button>
        </nx-radio-toggle>
      </nx-selectable-card>
    </div>

    <div nxCol="12,12,12,4">
      <nx-selectable-card appearance="expert">
        <h2 nxHeadline="subsection-small">TOTAl REVENUE (YTD)</h2>
        <canvas id="canvas2" width="350" height="300"></canvas>
        <div style="text-align: center; color: #cc65fe">
          <h3>POLICIES SOLD ON CURRENT YEAR : {{ ChartCal?.ytdPolicies }}</h3>
          <h3>TOTAL : {{ ChartCal?.ytdAmount }}</h3>
        </div>
        <nx-radio-toggle
          [nxStyle]="'small'"
          name="ytdChartType"
          [(ngModel)]="ytdChartType"
          (click)="changeChartType('YTD')"
        >
          <nx-radio-toggle-button nxValue="1" [nxSelected]="true">
            <nx-icon name="product-stock-data-graph"></nx-icon>
          </nx-radio-toggle-button>
          <nx-radio-toggle-button nxValue="2">
            <nx-icon name="product-share-graph-arrow-up"></nx-icon>
          </nx-radio-toggle-button>
          <nx-radio-toggle-button nxValue="3">
            <nx-icon name="in-progress"></nx-icon>
          </nx-radio-toggle-button>
        </nx-radio-toggle>
      </nx-selectable-card>
    </div>
  </div>
</div>
<app-footer></app-footer>

<!-- Update Mandarory details of workshop/Dealer if its missing -->
<nx-modal
  *ngIf="isShowWorkshopEdit"
  [nxHideOnClickOutside]="false"
  [nxHideOnEsc]="false"
  [nxShowCloseIcon]="false"
>
  <app-workshop-edit (closeModal)="onCloseModal($event)"></app-workshop-edit>
</nx-modal>
