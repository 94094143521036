import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { LoginModule } from './login/login.module';
import { AdminModule } from './admin/admin.module';
import { CoreModule } from './core/core.module';
/**for bd,nsm,rsm */
import { AddpolicyComponent } from './addpolicy/addpolicy.component';
/**for workshop */
import { NxTabsModule } from '@aposin/ng-aquila/tabs';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NxDatefieldModule } from '@aposin/ng-aquila/datefield';
import { NxNativeDateModule } from '@aposin/ng-aquila/datefield';
import { NxMomentDateModule } from '@aposin/ng-aquila/moment-date-adapter';
import { NxCheckboxModule } from '@aposin/ng-aquila/checkbox';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxTableModule, NxSortHeaderIntl } from '@aposin/ng-aquila/table';
import { NxBadgeModule } from '@aposin/ng-aquila/badge';
import { NxSwitcherModule } from '@aposin/ng-aquila/switcher';
import { NxHeaderModule } from '@aposin/ng-aquila/header';
import { NxImageModule } from '@aposin/ng-aquila/image';
import { NxLinkModule } from '@aposin/ng-aquila/link';
import { NxPopoverModule } from '@aposin/ng-aquila/popover';
import { WorkshopregistrationComponent } from './workshopregistration/workshopregistration.component';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';

import { NxSpinnerModule } from '@aposin/ng-aquila/spinner';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule, HttpClient } from '@angular/common/http';
//OpenIdIntegartion
import { AuthModule, ConfigResult, OidcConfigService, OidcSecurityService, OpenIdConfiguration } from 'angular-auth-oidc-client';
const oidc_configuration = 'assets/auth.clientConfiguration.json';

import { AuthInterceptor } from './http.interceptor';
import { NxMessageModule } from '@aposin/ng-aquila/message';
import { VehicledetailsComponent } from './vehicledetails/vehicledetails.component';
import { PlandetailsComponent } from './plandetails/plandetails.component';
import { CustomerdetailsComponent } from './customerdetails/customerdetails.component';
import { PaymentdetailsComponent } from './paymentdetails/paymentdetails.component';
import { TitleModule } from './title/title.module';

import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxCircleToggleModule } from '@aposin/ng-aquila/circle-toggle';
import { DownloadComponent } from './download/download.component';
import { NxCardModule } from '@aposin/ng-aquila/card';
import { UserTypeComponent } from './user-type/user-type.component';
import { UserPermissionsComponent } from './user-permissions/user-permissions.component';
import { LoaderComponent } from './loader/loader.component';
import { EventEmitterService } from './event-emitter.service';
import { SortingService } from './sorting.service';
import { NxMaskModule } from '@aposin/ng-aquila/mask';
import { NxPageSearchModule } from '@aposin/ng-aquila/page-search';
import { LoginResolve } from './login/login.resolve';
import { RecaptchaModule } from 'ng-recaptcha';
import { JsonAppConfigService } from 'src/config/json-app-config.service';
import { AppConfig } from 'src/config/app.config';
import { NxProgressbarModule } from '@aposin/ng-aquila/progressbar';
import { NxProgressStepperModule } from '@aposin/ng-aquila/progress-stepper';
import { UserReportsComponent } from './user-reports/user-reports.component';
import { NdbxIconModule } from '@allianz/ngx-ndbx/icon';
import { NxSmallStageModule } from '@aposin/ng-aquila/small-stage';
import { PayonlineComponent } from "./payonline/payonline.component";
import { PayOnlineResolve } from "./payonline/payonline.resolve";
import { PayuResponseComponent } from "./payu-response/payu-response.component";

export function loadConfig(oidcConfigService: OidcConfigService) {
  return () => oidcConfigService.load(oidc_configuration);
}

//To load config from app.config.json
export function initializerFn(jsonAppConfigService: JsonAppConfigService) {
  return () => {
    jsonAppConfigService.load();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AddpolicyComponent,
    WorkshopregistrationComponent,
    VehicledetailsComponent,
    PlandetailsComponent,
    CustomerdetailsComponent,
    PaymentdetailsComponent,
    DownloadComponent,
    UserTypeComponent,
    UserPermissionsComponent,
    LoaderComponent,
    UserReportsComponent,
    PayonlineComponent,
    PayuResponseComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    LoginModule,
    AdminModule,
    CoreModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
    BrowserAnimationsModule,
    NxTabsModule,
    NxGridModule,
    NxFormfieldModule,
    NxInputModule,
    NxDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    NxDatefieldModule,
    NxNativeDateModule,
    NxMomentDateModule,
    NxCheckboxModule,
    NxButtonModule,
    NxIconModule,
    NxTableModule,
    NxBadgeModule,
    NxSwitcherModule,
    NxHeaderModule,
    NxImageModule,
    NxLinkModule,
    NxSpinnerModule,
    NxPopoverModule,
    AuthModule.forRoot(),
    NxMessageModule,
    NxHeadlineModule,
    TitleModule,
    NxModalModule,
    NxCircleToggleModule,
    NxCardModule,
    NxMaskModule,
    NxPageSearchModule,
    RecaptchaModule,
    NxProgressbarModule,
    NxProgressStepperModule,
    NdbxIconModule,
    NxSmallStageModule

  ],
   
    exports: [AddpolicyComponent, WorkshopregistrationComponent,],
  providers: [

    EventEmitterService,
    { provide: NxSortHeaderIntl, useClass: SortingService },
    LoginResolve,
    {
      provide: AppConfig,
      deps: [HttpClient],
      useExisting: JsonAppConfigService
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [JsonAppConfigService],
      useFactory: initializerFn
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    PayOnlineResolve,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
