import { EventEmitter, Injectable } from '@angular/core';
import { Observable,BehaviorSubject } from 'rxjs';
import { sellPolicyData } from './addpolicy/sellpolicyData.model';
import { Step } from './core/step.model';

@Injectable({
  providedIn: 'root'
})
export class DatastorageService {

  public myData : any;
  nextClick: BehaviorSubject<Step> = new BehaviorSubject(null);

  constructor() { }

  getData() {
    return this.myData;
  }

  setData(data: sellPolicyData) {
    this.myData=data;
  }

  clearData(data: sellPolicyData) {
    this.myData = null;
  }
}
