<app-header></app-header>
<app-menu></app-menu>

<form class="form-example  nx-margin-top-xl" [formGroup]="formGroup">
    <div nxLayout="grid">
        <div nxRow>
            <div nxCol="10" nxColOffset="1">
                <h2 nxHeadline="section">
                    Encrypt values for configuration
                </h2>
            </div>
        </div>
      <div nxRow>
        <div nxCol="10" nxColOffset="1">
          <nx-formfield nxLabel="Encrption Value">
            <input nxInput formControlName="value" />
            <nx-error nxFormfieldError>
              <strong>Please note:</strong> this field is required!
            </nx-error>
          </nx-formfield>
        </div>
      </div>
      <div nxRow>       
        <div nxCol="10" nxColOffset="1">
            <nx-formfield nxLabel="Encryption Key">
              <input nxInput formControlName="key" />
              <nx-error nxFormfieldError>
                Y<strong>Please note:</strong> this field is required!
              </nx-error>
            </nx-formfield>
          </div>
      </div>
      <div nxRow>
        <div nxCol="5" nxColOffset="1">
          <button nxButton type="button"(click)="onEncryptClick()" [disabled]="!formGroup.valid">Encrypt</button>
        </div>
        <div nxCol="5" nxColOffset="1">
            <button nxButton type="button" (click)="onDecryptClick()"  [disabled]="!formGroup.valid">Decrypt</button>
        </div>
      </div>

      <div nxRow>
        <div nxCol="10" nxColOffset="1">
            <nx-formfield nxLabel="Result">
                <input nxInput disabled type="text" value="{{result}}"/>
            </nx-formfield>
            <button nxButton type="button" (click)="copyInputMessage()" >Copy Result</button>
            </div>
        </div>
    </div>
</form>
  
 
<app-footer></app-footer>
