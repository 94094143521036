<app-header></app-header>
<app-menu></app-menu>
<div nxLayout="grid" style="margin-top: 5px;">
    <form [formGroup]="workshopReportForm">
        <div nxRow>
            <div nxCol="12,6,4" nxAlignSelf="center" nxColOffset="1">
                <nx-formfield nxLabel="Report Type">
                    <nx-dropdown #reportType (selectionChange)="OnChangeReportType(reportType.value)">
                        <nx-dropdown-item nxValue="policysales">Policy Sales</nx-dropdown-item>
                    </nx-dropdown>
                </nx-formfield>
            </div>
            <div nxCol="12,6,3" nxAlignSelf="center">
                <nx-formfield nxLabel="Start date">
                    <input nxDatefield nxInput formControlName="startDatepicker" [nxDatepicker]="startDate" readonly/>
                    <nx-datepicker-toggle [for]="startDate" nxFormfieldSuffix></nx-datepicker-toggle>
                    <nx-datepicker #startDate></nx-datepicker>
                    <nx-error nxFormfieldError>
                        <strong>Please note:</strong> this field is required!
                    </nx-error>
                </nx-formfield>
            </div>
            <div nxCol="12,6,3" nxAlignSelf="center">
                <nx-formfield nxLabel="End date">
                    <input nxDatefield nxInput formControlName="endDatepicker" [nxDatepicker]="endDate" readonly/>
                    <nx-datepicker-toggle [for]="endDate" nxFormfieldSuffix></nx-datepicker-toggle>
                    <nx-datepicker #endDate></nx-datepicker>
                    <nx-error nxFormfieldError>
                        <strong>Please note:</strong> this field is required!
                    </nx-error>
                </nx-formfield>
            </div>

        </div>
        <div nxRow>
            <div nxCol="12,10" nxAlignSelf="center" nxColOffset="1">
                <nx-accordion>
                    <nx-expansion-panel>
                        <nx-expansion-panel-header>
                            <nx-expansion-panel-title>Advanced Search</nx-expansion-panel-title>
                        </nx-expansion-panel-header>
                        <div *ngIf="reportType.value=='policysales'">
                            <div nxRow>
                                <div nxCol="12,6" nxAlignSelf="center">
                                    <nx-formfield nxLabel="Policy No">
                                        <input nxInput formControlName="policyNo" type="text" />
                                    </nx-formfield>
                                </div>
                                <div nxCol="12,6" nxAlignSelf="center">
                                    <nx-formfield nxLabel="Policy type">
                                        <nx-dropdown formControlName="policyType" [nxIsMultiselect]="true">
                                            <nx-dropdown-item nxValue={{policyStatus.Active}}>Issued Policy
                                            </nx-dropdown-item>
                                            <nx-dropdown-item nxValue={{policyStatus.Cancel}}>Cancelled Policy
                                            </nx-dropdown-item>
                                            <nx-dropdown-item nxValue={{policyStatus.Modified}}>Modified Policy
                                            </nx-dropdown-item>
                                        </nx-dropdown>
                                    </nx-formfield>
                                </div>
                            </div>
                            <div nxRow>
                                <div nxCol="12,6" nxAlignSelf="center">
                                    <nx-formfield nxLabel="Age of Vehicle (In Years)">
                                        <nx-dropdown formControlName="VehicleAge">
                                            <nx-dropdown-item *ngFor="let index of [0,1,2,3,4,5,6,7]" [nxValue]=index>
                                            </nx-dropdown-item>
                                        </nx-dropdown>
                                    </nx-formfield>
                                </div>
                                <div nxCol="12,6" nxAlignSelf="center">
                                    <nx-formfield nxLabel="Model">
                                        <nx-dropdown formControlName="model">
                                            <nx-dropdown-item *ngFor="let Model of Models" [nxValue]="Model.modelName">
                                            </nx-dropdown-item>
                                        </nx-dropdown>
                                    </nx-formfield>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="reportType.value==undefined">
                            <div nxRow>
                                <div nxCol="12" nxAlignSelf="center">
                                    <div class="nosearch"></div>
                                    <div class="nosearch-text">Please select report type to see advanced serach</div>
                                </div>
                            </div>
                        </div>
                    </nx-expansion-panel>
                </nx-accordion>
            </div>
            <div nxCol="12,10" nxColOffset="1" class="nx-margin-top-2m">
                <button nxButton="primary small-medium" type="button"
                    (click)="getWorkShopReport(reportType.value)">Search</button>
                <button nxButton="secondary small-medium" type="button" (click)="reset()">Reset</button>
                <button nxButton="tertiary small-medium" [disabled]="count==0" type="button"
                    (click)="exporttoexcel()" style="float: right;">
                    <nx-icon name="xls" class="nx-margin-right-2xs"></nx-icon>Export
                </button>
            </div>
        </div>
    </form>
</div>
<div nxRow class="nx-margin-top-2m" *ngIf="count!=0">
    <div nxCol="10" nxColOffset="1">
        <table nxTable zebra nxSort (sortChange)="sortTable($event)" style="text-align: center;">
            <thead>
                <tr nxTableRow>
                    <th nxHeaderCell nxSortHeaderCell="policyNo">
                        Policy Number
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="vinChassisNo">
                        Vin Number
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="vinChassisNo">
                        Dealer Code
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="invoiceDate">
                        Policy Sale Date
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="effectiveDate">
                        Policy Start Date
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="policyStatusId">
                        Status
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="price">
                        Total Amount
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr nxTableRow *ngFor="let item of policyList">
                    <td nxTableCell>{{item.policyDetails.policyNo}}</td>
                    <td nxTableCell>{{item.policyDetails.vinChassisNo}}</td>
                    <td nxTableCell>{{item.policyDetails.dealerCode}}</td>
                    <td nxTableCell>{{item.policyDetails.invoiceDate | date}}</td>
                    <td nxTableCell>{{item.policyDetails.effectiveDate | date}}</td>
                    <td nxTableCell *ngIf="item.policyDetails.policyStatusId == policyStatus.Active else cancel">
                        <nx-badge type="positive">Issued</nx-badge>
                    </td>
                    <ng-template #cancel>
                        <td nxTableCell *ngIf="item.policyDetails.policyStatusId == policyStatus.Cancel else modified">
                            <nx-badge type="negative">Cancelled</nx-badge>
                        </td>
                    </ng-template>
                    <ng-template #modified>
                        <td nxTableCell>
                            <nx-badge type="critical">Modified</nx-badge>
                        </td>
                    </ng-template>
                    <td nxTableCell>{{item.policyDetails.unitPrice}}</td>
                </tr>

            </tbody>
        </table>

    </div>
</div>
<div nxRow class="nx-margin-top-2m nx-margin-right-5xl" style="float: right;" *ngIf="count!=0">
    <div nxCol="6">
        <nx-pagination [nxCount]="count" [nxPage]="pageNumber" [nxPerPage]="pageSize" nxType="advanced"
            (nxGoPrev)="prevPage()" (nxGoNext)="nextPage()" (nxGoPage)="goToPage($event)">
        </nx-pagination>
    </div>
</div>

<app-footer></app-footer>