import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { AuthService } from "../core/auth/auth.service";
import { AppConfig } from 'src/config/app.config';
import { SortDirection, SortEvent } from '@aposin/ng-aquila/table';
import { NxMessageToastService } from "@aposin/ng-aquila/message";
import { throwError } from 'rxjs';

@Component({
  selector: "app-citymaster",
  templateUrl: "./citymaster.component.html",
  styleUrls: ["./citymaster.component.css"],
})
export class CitymasterComponent implements OnInit {
  cityModalOpen: boolean = false;
  confirmModalOpen: boolean = false;
  cityForm: FormGroup;
  _apiUrl: string;
  clientId: number;
  cityList = [];
  stateList = [];
  isSuccess: boolean = false;
  EDIT_FLAG: boolean;
  id;
  cityDetails = {};
  errorMessage: string;
  closed: boolean;

/** sorting starts here */
sortTable(sort: SortEvent) {//Sorts the table data by a certain category.
  this.cityList = this.cityList.sort((a, b) => {
    if (sort.active in a && sort.active in b) {
      return this.compare(a[sort.active], b[sort.active], sort.direction);
    }
    return 0;
  });
}

compare(
  a: number | string | Date,
  b: number | string | Date,
  direction: SortDirection) {

  return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
}
/** sorting ends here */

/**Pagination Start */
count: number;
pageNumber: number = 1;
pageSize: number = 10;
searchTerm: any = "";
prevPage() {
  this.pageNumber--;
  this.getCityList();
}

nextPage() {
  this.pageNumber++;
  this.getCityList();
}

goToPage(n: number) {
  this.pageNumber = n;
    this.getCityList();
}
/**Pagination end */

  constructor(
    private authService: AuthService,
    private appConfig : AppConfig,
    private messageToastService: NxMessageToastService,
  ) {
    this._apiUrl = appConfig.baseUrl;
    this.clientId = parseInt(atob(localStorage.getItem("CLIENT_ID")));
    this.cityFormBuilder();
  }

  ngOnInit() {
    this.getCityList();
  }

  cityFormBuilder() {
    this.cityForm = new FormGroup({
      cityName: new FormControl("", [Validators.required]),
      clientId: new FormControl(this.clientId),
      id: new FormControl(""),
      isActive: new FormControl(1),
      stateId: new FormControl("", [Validators.required]),
    });
  }

  getCityList() {
    this.authService.getList(this._apiUrl + "/api/v1/Cities" + "?pageNumber=" + this.pageNumber + "&pageSize=" + this.pageSize + "&searchText=" + this.searchTerm).subscribe(response => {
      if (response) {
        this.cityList = response.body;
        var pagingProp = JSON.parse(response.headers.get('X-Pagination'));
        this.count = pagingProp.TotalCount;
      }
    }, (error) => {
      console.error(error);
    });
  }
  toggleModal(EDIT: boolean, cityId) {
    this.getStateDetails();
    this.EDIT_FLAG = EDIT;
    this.id = cityId;
    this.cityModalOpen = !this.cityModalOpen;
    this.isSuccess = false;
    if (this.EDIT_FLAG) {
      this.getCityDetails(cityId);
    }
    if (!this.cityModalOpen) {
      this.cityFormBuilder();
    }
  }
  addCityMaster() {

    let cityAddForm = {
      cityName: this.cityForm.value.cityName,
      stateId: this.cityForm.value.stateId,
      isActive: 1,
      clientId: this.clientId,
    };
    this.authService.post(this._apiUrl + "/api/v1/Cities", cityAddForm).subscribe(
      (response) => {
        if (response) {
          this.getCityList();
          this.isSuccess = true;
        }
      },
      (error) => {
        console.error(error);
        this.handleError(error);
      }
    );
  }
  getCityDetails(cityId) {
    this.authService.get(this._apiUrl + "/api/v1/Cities/" + cityId).subscribe(
      (response) => {
        if (response) {
          //console.log(response);

          this.cityForm.setValue({
            cityName: response.cityName,
            id: response.id,
            clientId: response.clientId,
            isActive: response.isActive,
            stateId: response.stateId,
          });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  getStateDetails() {
    this.authService
      .get(this._apiUrl + "/api/v1/States/GetActiveStates/client/" + this.clientId)
      .subscribe(
        (response) => {
          if (response) {
            this.stateList = response;
            //console.log("state list", this.stateList);
          }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  updateCityMaster() {
    this.authService
      .put(this._apiUrl + "/api/v1/Cities/" + this.id, this.cityForm.value)
      .subscribe(
        (response) => {
          // if (response) {
          this.getCityList();
          this.isSuccess = true;
          // }
        },
        (error) => {
          console.error(error);
          this.handleError(error);
        }
      );
  }

  confirmModalClose() {
    this.confirmModalOpen = false;
    this.getCityList();
    this.cityFormBuilder();
  }

  confirmModal(cityDetails, isChecked: boolean) {
    let isActive;
    this.confirmModalOpen = true;

    if (isChecked) {
      isActive = 1;
    } else {
      isActive = 0;
    }

    this.cityForm.setValue({
      cityName: cityDetails.cityName,
      id: cityDetails.id,
      clientId: this.clientId,
      isActive: isActive,
      stateId: cityDetails.stateIdNavigation.id,
    });
  }

  changeCityStatus() {
    this.cityForm.value;
    this.authService
      .put(
        this._apiUrl + "/api/v1/Cities/" + this.cityForm.controls.id.value,
        this.cityForm.value
      )
      .subscribe(
        (response) => {
          // if (response) {
          this.getCityList();
          this.confirmModalClose();

          // }
        },
        (error) => {
          console.error(error);
        }
      );
  }

  handleError(error) {
    if (error.error instanceof ErrorEvent) {
      // client-side error
      this.errorMessage = `Error : ${error.error.message}`;
    } else {
      // server-side error
      this.errorMessage = `Error : ${error.error}`;
    }
    this.closed = false;
    this.messageToastService.open(this.errorMessage, {
      context: "info",
      duration: 5000,
    });
    return throwError(this.errorMessage);
  }
}
