export enum UserType{
    SysAdmin = 1,
    Admin = 2,
    BD = 3,
    Dealer = 4,
    WorkShop= 5,
    RSM = 6,
    NSM = 7,
    AzPAdmin = 8,
    Finance = 9
}